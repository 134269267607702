import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Texts from "./components/Texts/Texts";
import Books from "./components/Books/Books";
import NoteBooks from "./components/NoteBooks/NoteBooks";
import AllBooks from "./components/AllBooks/AllBooks";
import AllTexts from "./components/AllTexts/AllTexts";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchTexts,
  fetchBooks,
  deleteBookStatItems,
  fetchBooksToAdd,
  addBooks,
  fetchNotebooks,
  deleteNotebook,
  updateNotebook,
  fetchAllBooks,
  deleteBook,
  updateBook,
  fetchAllTexts,
  deleteText,
  updateText
} from "./state/actions";

const TextsContainer = () => {
  const dispatch = useDispatch();
  const { id, role } = useSelector(state => state.auth);
  const { texts, books, availableBooks, notebooks, allbooks, alltexts } = useSelector(state => state.texts);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTexts({pageNum: 0, pageSize: 10}, id));
    dispatch(fetchBooks({pageNum: 0, pageSize: 10}, id));
    dispatch(fetchNotebooks({pageNum: 0, pageSize: 10}, id));
    dispatch(fetchAllBooks({pageNum: 0, pageSize: 10}));
    dispatch(fetchAllTexts({pageNum: 0, pageSize: 10}));
  }, [id, dispatch]);

  const handleDeleteBookStatItems = (bookSha1, onSuccess) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchBooks({pageNum: 0, pageSize: 10}, id));
    };
    dispatch(deleteBookStatItems(bookSha1, id, () => handleSuccess(onSuccess)));
  }

  const handleDeleteNotebook = (bookSha1, onSuccess) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchNotebooks({pageNum: 0, pageSize: 10}, id));
    };
    dispatch(deleteNotebook(bookSha1, id, () => handleSuccess(onSuccess)));
  }

  const handleUpdateNotebook = (bookSha1, payload, onSuccess, onFail) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchNotebooks({pageNum: 0, pageSize: 10}, id));
    };
    dispatch(updateNotebook(bookSha1, payload, () => handleSuccess(onSuccess)), onFail);
  }

  const handleDeleteBook = (bookSha1, onSuccess) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchAllBooks({pageNum: 0, pageSize: 10}));
    };
    dispatch(deleteBook(bookSha1, () => handleSuccess(onSuccess)));
  };

  const handleUpdateBook = (bookSha1, payload, onSuccess, onFail) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchAllBooks({pageNum: 0, pageSize: 10}));
    };
    dispatch(updateBook(bookSha1, payload, () => handleSuccess(onSuccess)), onFail);
  };

  const handleDeleteText = (textSha1, onSuccess) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchAllTexts({pageNum: 0, pageSize: 10}));
    };
    dispatch(deleteText(textSha1, () => handleSuccess(onSuccess)));
  };

  const handleUpdateText = (textSha1, payload, onSuccess, onFail) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchAllTexts({pageNum: 0, pageSize: 10}));
    };
    dispatch(updateText(textSha1, payload, () => handleSuccess(onSuccess)), onFail);
  };

  return (
    <div className="tabs">
      <Tabs defaultActiveKey="texts">
        <Tab eventKey="texts" title="Texts">
          <Texts
            data={texts}
            onFetchTexts={(pagination, searchTerm) => dispatch(fetchTexts(pagination, id, searchTerm))}
          />
        </Tab>
        
        <Tab eventKey="books" title="Books">
          <Books
            owner={id}
            data={books}
            availableBooks={availableBooks}
            onFetchBooks={(pagination, searchTerm) => dispatch(fetchBooks(pagination, id, searchTerm))}
            onDeleteBook={handleDeleteBookStatItems}
            onFetchBooksToAdd={(pagination, searchTerm) => dispatch(fetchBooksToAdd(pagination, id, searchTerm))}
            onAddBooks={(payload, onComplete) => dispatch(addBooks(payload, id, onComplete))}
          />
        </Tab>

        <Tab eventKey="notebooks" title="Notebooks">
          <NoteBooks
            data={notebooks}
            onFetchNotebooks={(pagination, searchTerm) => dispatch(fetchNotebooks(pagination, id, searchTerm))}
            onDeleteNotebook={handleDeleteNotebook}
            onUpdateNotebook={handleUpdateNotebook}
          />
        </Tab>
        
        {
          role === "gk_admin" && (
            <Tab eventKey="all-books" title="All Books">
              <AllBooks
                data={allbooks}
                onFetchAllBooks={(pagination, searchTerm) => dispatch(fetchAllBooks(pagination, searchTerm))}
                onDeleteBook={handleDeleteBook}
                onUpdateBook={handleUpdateBook}
              />
            </Tab>
          )
        }

        {
          role === "gk_admin" && (
            <Tab eventKey="all-texts" title="All Texts">
              <AllTexts
                data={alltexts}
                onFetchAllTexts={(pagination, searchTerm) => dispatch(fetchAllTexts(pagination, searchTerm))}
                onDeleteText={handleDeleteText}
                onUpdateText={handleUpdateText}
              />
            </Tab>
          )
        }
      </Tabs>
    </div>
  );
};

export default TextsContainer;
