import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import Nav from "./Nav/Nav";
import Header from "./Header/Header";
import Notification from "../../Notification/Notification";
import Alert from "../../Alert";

import "./UserLayout.scss";

const UserLayout = (props) => {
  const { apiError, notice } = useSelector(state => state.common);
  const { subscription = {} } = useSelector(state => state.subscription);
  const { context = {}, auto_renew_status } = subscription;

  const membershipDaysLeft = context?.trial_due && dayjs(context.trial_due).diff(dayjs(), 'd');

  const showNotification =  typeof auto_renew_status !== "undefined"
    && auto_renew_status === 0
    && typeof membershipDaysLeft === "number"
    && membershipDaysLeft < 14;

  const noticeMessage = membershipDaysLeft <= 0
    ? "Your Premium Membership Free Trial has expired."
    : `Your Premium Membership Free Trial ends in ${membershipDaysLeft} days.`

  return (
    <div className="user-layout">
      <aside className="left">
        <Nav />
      </aside>
      <div className="right">
        <Header />
        <div className="content-container">
          {
            showNotification && (
              <Notification>
                <p>
                  {noticeMessage} <Link to="/plans">Subscribe Now</Link>
                </p>
              </Notification>
            )
          }
          <div className="content">{props.children}</div>
        </div>
      </div>
      <Alert {...(apiError || notice)} />
    </div>
  );
};

export default UserLayout;
