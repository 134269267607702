import React, { Fragment, useState } from "react";
import { Accordion } from "react-bootstrap";

import ActionModal from "../../../components/ActionModal/ActionModal";

import CollectionItem from "./CollectionItem";
import EditCollectionModal from "./EditCollectionModal";
import AddBookModal from "./AddBookModal";
import EditBookModal from "./EditBookModal";

const Collections = (props) => {
  const {
    owner,
    canEdit,
    collections,
    books,
    availableBooks,
    onUpdateCollection,
    onDeleteCollection,
    onFetchBooks,
    onAddBooks,
    onUpdateBook,
    onRemoveBook
  } = props;

  const [collectionToEdit, setCollectionToEdit] = useState(null);
  const [collectionToDelete, setCollectionToDelete] = useState(null);

  const [collectionToAddTo, setCollectionToAddTo] = useState(false);
  const [bookToEdit, setBookToEdit] = useState(null);
  const [bookToDelete, setBookToDelete] = useState(null);

  const handleDeleteCollection = () => {
    if (typeof onDeleteCollection === "function") {
      onDeleteCollection(collectionToDelete,
        () => setCollectionToDelete(null))
    }
  };

  const handleRemoveBook = () => {
    if (typeof onRemoveBook === "function") {
      onRemoveBook(bookToDelete,
        () => setBookToDelete(null))
    }
  };

  return (
    <Fragment>
      <Accordion defaultActiveKey="0">
        {
          collections.map((coll, index) => {
            return (
              <CollectionItem
                key={coll.title}
                eventKey={index.toString()}
                canEdit={canEdit}
                title={coll.title}
                data={books[index]}
                onAdd={() => setCollectionToAddTo({index, title: coll.title, id: coll.id})}
                onDelete={() => setCollectionToDelete(coll.title)}
                onEdit={() => setCollectionToEdit(coll)}
                onEditBook={(book) => setBookToEdit(book)}
                onDeleteBook={(book) => setBookToDelete(book)} />
            );
          })
        }
      </Accordion>
      <ActionModal
        showModal={!!collectionToDelete}
        title="Delete Collection"
        actionText="Delete"
        onClose={() => setCollectionToDelete(null)}
        onAction={handleDeleteCollection}>
          Do you want to delete this collection?
      </ActionModal>
      <EditCollectionModal
        value={collectionToEdit}
        show={!!collectionToEdit}
        onClose={() => setCollectionToEdit(null)}
        onUpdateCollection={onUpdateCollection} />
      <AddBookModal
        owner={owner}
        collection={collectionToAddTo}
        showModal={!!collectionToAddTo}
        books={availableBooks}
        onClose={() => setCollectionToAddTo(null)}
        onFetchBooks={onFetchBooks}
        onAddBooks={onAddBooks}
      />
      <EditBookModal
        value={bookToEdit}
        show={!!bookToEdit}
        onClose={() => setBookToEdit(null)}
        onUpdateBook={onUpdateBook} />
      <ActionModal
        showModal={!!bookToDelete}
        title="Delete Book"
        actionText="Delete"
        onClose={() => setBookToDelete(null)}
        onAction={handleRemoveBook}>
          Do you want to delete this book?
      </ActionModal>
    </Fragment>
  );
};

export default Collections;
