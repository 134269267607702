import React, { Component } from "react";
import { connect } from "react-redux";
import { apiUrl } from "../../config";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import "./emailverification.css";

const axios = require("axios");

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      hidenavStatus: false
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hidenav !== nextProps.hidenav) {
      this.setState({ hidenavStatus: this.props.hidenav });
    }
  }

  changeToken = event => {
    this.setState({ token: event.target.value });
  };

  getToken = () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Prefer: "count=exact",
      Authorization: "Bearer " + this.props.token
    };
    let url = apiUrl + "rpc/request_email_verify_token";
    let data = { email: this.props.usermail };
    axios
      .post(url, data, {
        headers: headers
      })
      .then(response => {
        swal("", "You got token successfully. Please check inbox of your email.", "success");
      })
      .catch(error => {
        swal("", "Can't get token now. Please try again later.", "error");
      });
  };

  verifyEmail = () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Prefer: "count=exact",
      Authorization: "Bearer " + this.props.token
    };
    let url = apiUrl + "rpc/verify_email";
    let data = { email: this.props.usermail, token: this.state.token };
    axios
      .post(url, data, {
        headers: headers
      })
      .then(response => {
        this.props.dispatch({
          type: "EMAILVERIFY",
          payload: 1
        });
        swal("", "Your email is verified successfully.", "success");
      })
      .catch(error => {
        swal("", "Your email is not verified. Please try again later.", "error");
      });
  };

  render() {
    return (
      <div className="kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed">
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <div
              className={
                this.state.hidenavStatus === false
                  ? "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                  : "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper navbar-hided"
              }
              id="kt-wrapper"
            >
              <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt-content">
                <div className="kt-container kt-container--fluid kt-grid__item kt-grid_-item--fluid">
                  <div className="email-verify">
                    <div className="get-token">
                      <span>You don't have token yet?</span>
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          this.getToken();
                        }}
                      >
                        Get Token
                      </button>
                    </div>
                    <div className="verify-email">
                      <input
                        name="token"
                        type="text"
                        className="form-control"
                        placeholder="Enter token here"
                        id="search-input"
                        value={this.state.token}
                        onChange={this.changeToken}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.verifyEmail();
                        }}
                        disabled={this.state.token === "" ? true : false}
                      >
                        Verify Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.rpc.tokens,
    role: state.rpc.role,
    usermail: state.rpc.usermail,
    hidenav: state.rpc.hidenav,
    id: state.rpc.id,
    emailverify: state.rpc.emailverify
  };
};
export default connect(mapStateToProps)(Organization);
