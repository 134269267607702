import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import BasicDetails  from "./components/BasicDetails";
import Details from "./components/Details";
import Subscriptions from "./components/Subscriptions";
import Classes from "./components/Classes";
import Books from "./components/Books";
import Texts from "./components/Texts";
import Emails from "./components/Emails";
import EventsList from "./components/EventsList";
import SubPayments from "./components/SubPayments";
import UserPosts from "./components/UserPosts";

import "./index.scss";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchMember,
  fetchSubscription,
  fetchMemberClasses,
  fetchMemberBooks,
  fetchMemberTexts,
  changePassword,
  deleteMemberBook,
  deleteMemberText,
  unsubscribeUser,
  fetchEmailsSent,
  fetchEvents,
  fetchMemberPayments,
  fetchUserPosts,
  updatePlanExpiry,
  submitCoupon,
  createPostFeed,
  removeFromPostsFeed
} from "./state/actions";

import "./member.scss";

const Member = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { nextRoute } = useSelector(state => state.common);
  const { role: userRole } = useSelector(state => state.auth);
  const { member, subscription, plan, classes, books, texts, emails, events, payments, userPosts } = useSelector(state => state.memberProfile);
  const { avatar, user_id, name, email, email_verified, role, level, study_reason, region, lang, last_modified, feed_owner } = member;
  const { name: planName, context = {} } = plan;
  const { bt_monthly_price } = context;


  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMember(id));
    dispatch(fetchSubscription(id));
    dispatch(fetchMemberClasses(id));
    dispatch(fetchMemberBooks(id));
    dispatch(fetchMemberTexts(id));
    dispatch(fetchEvents({pageNum: 0, pageSize: 10}, id));
    dispatch(fetchMemberPayments({pageNum: 0, pageSize: 10}, id));
    dispatch(fetchUserPosts({pageNum: 0, pageSize: 10}, id));
  }, [dispatch, id]);

  useEffect(() => {
    if (email) {
      dispatch(fetchEmailsSent(encodeURIComponent(email)));
    }
  }, [email, dispatch]);

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  const handleSwitchSubscription = (onComplete) => {
    const handleSuccess = () => {
      dispatch(fetchSubscription(id));
      if (typeof onComplete === 'function') {
        onComplete();
      }
    };

    dispatch(unsubscribeUser({
      user_id: user_id
    }, handleSuccess, onComplete));
  };

  const handleFetchEvents = (pagination, searchTerm) => {
    dispatch(fetchEvents(pagination, id, searchTerm));
  };

  const handleFetchPayments = (pagination, searchTerm) => {
    dispatch(fetchMemberPayments(pagination, id, searchTerm));
  };

  const handleFetchUserPosts = (pagination, searchTerm) => {
    dispatch(fetchUserPosts(pagination, id, searchTerm));
  }

  const handleExpiryDateChange = (payload, onComplete) => {
    const { id: subscriptionId } = subscription;
    const handleSuccess = () => {
      dispatch(fetchSubscription(id));
      if (typeof onComplete === 'function') {
        onComplete();
      }
    };

    dispatch(updatePlanExpiry(subscriptionId, {
      ...payload,
      id: subscriptionId
    }, handleSuccess, onComplete));
  };

  const handleApplyCoupon = (payload, onComplete) => {
    const handleSuccess = () => {
      dispatch(fetchSubscription(id));
      if (typeof onComplete === 'function') {
        onComplete();
      }
    };
  
    dispatch(submitCoupon({
      ...payload,
      user_id: id
    }, handleSuccess, onComplete));
  };

  const handleAddPostFeed = (payload, onSuccess, onFail) => {
    dispatch(createPostFeed(payload, () => {
      onSuccess();
      dispatch(fetchMember(id));
    }, onFail));
  };

  const handleRemovePostFeed = (payload, onSuccess) => {
    dispatch(removeFromPostsFeed(payload, () => {
      onSuccess();
      dispatch(fetchMember(id));
    }));
  };

  return (
    <div className="member-profile">
      <div>
        <BasicDetails
          avatar={avatar}
          userId={user_id}
          userName={name}
          email={email}
          verified={email_verified}
          feed_owner={feed_owner}
          role={role}
          onPasswordChange={(data, onComplete) => dispatch(changePassword({ email, ...data}, onComplete))}
          onAddPostFeed={handleAddPostFeed}
          onRemovePostFeed={handleRemovePostFeed}
        />
      </div>
      <div className="member-details-tabs">
        <Tabs className="tabs">
          <Tab eventKey="details" title="Details">
            <Details level={level} goal={study_reason} region={region} lang={lang} joined={last_modified} />
          </Tab>
          <Tab eventKey="subscription" title="Subscription">
            <Subscriptions
              {...subscription}
              planName={planName}
              monthlyPrice={bt_monthly_price}
              onSwitchSubscription={handleSwitchSubscription}
              onExpiryDateChange={handleExpiryDateChange}
              onSubmitCoupon={handleApplyCoupon}
            />
          </Tab>
          <Tab eventKey="classes" title="Classes">
            <Classes classes={classes} onSearch={(term) => dispatch(fetchMemberClasses(id, term))} />
          </Tab>
          <Tab eventKey="books" title="Books">
            <Books
              books={books}
              onSearch={(term) => dispatch(fetchMemberBooks(id, term))}
              onDelete={(data, onComplete)=> dispatch(deleteMemberBook(data, onComplete))} />
          </Tab>
          <Tab eventKey="texts" title="Texts">
            <Texts
              texts={texts}
              onSearch={(term) => dispatch(fetchMemberTexts(id, term))}
              onDelete={(data, onComplete)=> dispatch(deleteMemberText(data, onComplete))} />
          </Tab>
          <Tab eventKey="emails" title="Emails">
            <Emails emails={emails} />
          </Tab>
          <Tab eventKey="events" title="Events">
            <EventsList data={events} onFetchEvents={handleFetchEvents} />
          </Tab>
          <Tab eventKey="payments" title="Sub Payments">
            <SubPayments data={payments} role={userRole} onFetchEvents={handleFetchPayments} />
          </Tab>
          <Tab eventKey="user-posts" title="User Posts">
            <UserPosts data={userPosts} onFetchUserPosts={handleFetchUserPosts}  />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Member;
