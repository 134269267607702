import { makeApiCall } from "../helpers/api-client";
import * as Actions from "../constants/actions";

const getSubscriptionSuccess = payload => ({
  type: Actions.GET_SUBSCRIPTION_SUCCESS,
  payload
});

const getSubscriptionFailure = payload => ({
  type: Actions.GET_SUBSCRIPTION_FAILURE,
  payload
});

export const getSubscription = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_subscriptions?owner=eq.${id}&order=id.desc`);
    const planId = response.data[0]?.plan_id;
    const source = response.data[0]?.payment;
    dispatch(getSubscriptionSuccess(response.data[0], source));

    if (planId) {
      dispatch(getPlanPrice(planId, source))
    }
  } catch (error) {
    dispatch(getSubscriptionFailure(error));
  }
};

export const getPlanPrice = (id, source) => async dispatch => {
  try {
    let paymentMethod = "bt_subscribe_price";
    if (source === "google") {
      paymentMethod = "google_subscribe_price";
    } else if (source === "apple") {
      paymentMethod = "apple_subscribe_price";
    }

    const response = await makeApiCall(`fc_subscriptions?select=*,plan:fc_plans(${paymentMethod}, currency)`);
    dispatch(getSubscriptionPriceSuccess(response.data[0].plan));
  } catch (error) {
    dispatch(getSubscriptionPriceFailure(error));
  }
};

const getSubscriptionPriceSuccess = payload => ({
  type: Actions.GET_SUBSCRIPTION_PRICE_SUCCESS,
  payload
});

const getSubscriptionPriceFailure = payload => ({
  type: Actions.GET_SUBSCRIPTION_PRICE_FAILURE,
  payload
});
