import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import DataTable from "./DataTable";
import DeleteModal from "./DeleteModal";
import Level from "./Fields/Level";
import StudyReason from "./Fields/StudyReason";
import Website from "./Fields/Website";
import Bio from "./Fields/Bio";
import Location from "./Fields/Location";

import "./UserDetails.scss";

dayjs.extend(relativeTime);
const formatDate = (date) => date && dayjs(date).format('DD/MM/YYYY');
const timeFromNow = (date) => date && dayjs(date).fromNow();

const UserInfo = (props) => {
  const {
    id,
    role,
    email,
    user,
    onImgError,
    onSave,
    onDeleteAccount
  } = props;

  const getUserDetails = () => {
    const {
      avatar,
      level,
      study_reason,
      region,
      display_lang,
      bio,
      location,
      website,
      last_modified
    } = user;

    const handleSave = (fieldName, value) => {
      const userInfo = {
        avatar,
        level,
        study_reason,
        region,
        display_lang
      };

      if (typeof onSave === "function") {
        onSave({
          ...userInfo,
          [fieldName]: value
        });
      }
    };

    return [
      (role === "gk_admin" ? ['User Id', id] : []),
      ['Email', email],
      [
        'Role',
        <span>
          <img src={`/img/${role}.png`} alt="role" className="size-24-24" onError={onImgError} /> {role}
        </span>
      ],
      [
        'Level',
        <Level value={level} onSave={(val) => handleSave('level', val)} />
      ],
      [
        'Study Reason',
        <StudyReason value={study_reason} onSave={(val) => handleSave('study_reason', val)} />
      ],
      [
        'Region',
        <span><img src={`/img/flags/${region}.svg`} alt="region" onError={onImgError} className="size-24-24" />{region}</span>
      ],
      [
        'Display Language',
        <span><img src={`/img/flags/${display_lang}.svg`} alt="display lamguage" onError={onImgError} /> {display_lang}</span>
      ],
      [
        'Website',
        <Website value={website} onSave={(val) => handleSave('website', val)} />
      ],
      [
        'Bio',
        <Bio value={bio} onSave={(val) => handleSave('bio', val)} />
      ],
      [
        'Location',
        <Location value={location} onSave={(val) => handleSave('location', val)} />
      ],
      ['Joined', `${formatDate(last_modified)} (${timeFromNow(last_modified)})`]
    ];
  };

  return (
    <div className="user-details">
      <DataTable rows={getUserDetails()} />
      <div className="footer">
        <DeleteModal email={email} onDelete={onDeleteAccount} />
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  role: PropTypes.string,
  email: PropTypes.string,
  userName: PropTypes.string,
  user: PropTypes.object,
  onSave: PropTypes.func,
  onDeleteAccount: PropTypes.func
};

export default UserInfo;
