import React from "react";
import Nav from "../Nav";

class AdminLayout extends React.Component {
  render(props) {
    return (
      <>
        <Nav />
        <div className="main-content">{this.props.children}</div>
      </>
    );
  }
}

export default AdminLayout;
