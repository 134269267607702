import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({ 
  name: yup
    .string()
    .required("This field cannot be empty"),
  trial_days: yup
    .number()
    .transform(value => Number(value))
    .required("This field cannot be empty"),
  currency: yup
    .string()
    .required("This field cannot be empty"),
  billing_freq: yup
    .number()
    .transform(value => Number(value))
    .required("This field cannot be empty"),
  period: yup
    .number()
    .transform(value => Number(value))
    .required("This field cannot be empty"),
  description: yup
    .string()
    .required("This field cannot be empty"),
  apple_subscribe_price: yup
    .number()
    .transform(value => Number(value))
    .required("This field cannot be empty"),
  apple_plan_id: yup
    .string()
    .required("This field cannot be empty"),
  apple_discount: yup
    .string()
    .required("This field cannot be empty"),
  apple_monthly_price: yup
    .string()
    .required("This field cannot be empty"),
  google_subscribe_price: yup
    .number()
    .transform(value => Number(value))
    .required("This field cannot be empty"),
  google_plan_id: yup
    .string()
    .required("This field cannot be empty"),
  google_discount: yup
    .string()
    .required("This field cannot be empty"),
  google_monthly_price: yup
    .string()
    .required("This field cannot be empty"),
  bt_subscribe_price: yup
    .number()
    .transform(value => Number(value))
    .required("This field cannot be empty"),
  bt_plan_id: yup
    .string()
    .required("This field cannot be empty"),
  bt_discount: yup
    .string()
    .required("This field cannot be empty"),
  bt_monthly_price: yup
    .string()
    .required("This field cannot be empty")
});

const EditPlanModal = ({ showModal, defaultValues, onClose, onUpdatePlan  }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const {
      apple_plan_id,
      apple_subscribe_price,
      billing_freq,
      bt_plan_id,
      bt_subscribe_price,
      context = {},
      currency,
      description,
      google_plan_id,
      google_subscribe_price,
      name,
      period,
      trial_days
    } = defaultValues;

    const {
      apple_discount,
      apple_monthly_price,
      bt_discount,
      bt_monthly_price,
      google_discount,
      google_monthly_price
    } = context;

    reset({
      apple_plan_id,
      apple_subscribe_price,
      billing_freq,
      bt_plan_id,
      bt_subscribe_price,
      apple_discount,
      apple_monthly_price,
      bt_discount,
      bt_monthly_price,
      google_discount,
      google_monthly_price,
      currency,
      description,
      google_plan_id,
      google_subscribe_price,
      name,
      period,
      trial_days
    });
  }, [reset, defaultValues]);

  const onSubmit = (payload) => {
    const {
      apple_discount,
      apple_monthly_price,
      bt_discount,
      bt_monthly_price,
      google_discount,
      google_monthly_price,
      ...values
    } = payload;
    
    const finalPayload = {
      ...values,
      context: {
        apple_discount,
        apple_monthly_price,
        bt_discount,
        bt_monthly_price,
        google_discount,
        google_monthly_price
      }
    }
    setSubmitting(true);
    onUpdatePlan(finalPayload, () => {
      setSubmitting(false);
      handleClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Coupon</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="trial_days">
            <Form.Label>Trial Days</Form.Label>
            <Form.Control
              name="trial_days"
              type="number"
              placeholder=""
              ref={register}
              isInvalid={!!errors.trial_days}
            />
            <Form.Control.Feedback type="inValid">{errors.trial_days?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="currency">
            <Form.Label>Currency</Form.Label>
            <Form.Control
              name="currency"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.currency}
            />
            <Form.Control.Feedback type="inValid">{errors.currency?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="billing_freq">
            <Form.Label>Billing Frequency</Form.Label>
            <Form.Control
              name="billing_freq"
              type="number"
              placeholder=""
              ref={register}
              isInvalid={!!errors.billing_freq}
            />
            <Form.Control.Feedback type="inValid">{errors.billing_freq?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="period">
            <Form.Label>Period</Form.Label>
            <Form.Control
              name="period"
              type="number"
              placeholder=""
              ref={register}
              isInvalid={!!errors.period}
            />
            <Form.Control.Feedback type="inValid">{errors.period?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="apple_subscribe_price">
            <Form.Label>Apple Subscribe Price</Form.Label>
            <Form.Control
              name="apple_subscribe_price"
              type="number"
              placeholder=""
              ref={register}
              isInvalid={!!errors.apple_subscribe_price}
            />
            <Form.Control.Feedback type="inValid">{errors.apple_subscribe_price?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="apple_plan_id">
            <Form.Label>Apple Plan ID</Form.Label>
            <Form.Control
              name="apple_plan_id"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.apple_plan_id}
            />
            <Form.Control.Feedback type="inValid">{errors.apple_plan_id?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="apple_discount">
            <Form.Label>Apple Discount</Form.Label>
            <Form.Control
              name="apple_discount"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.apple_discount}
            />
            <Form.Control.Feedback type="inValid">{errors.apple_discount?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="apple_monthly_price">
            <Form.Label>Apple Monthly Price</Form.Label>
            <Form.Control
              name="apple_monthly_price"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.apple_monthly_price}
            />
            <Form.Control.Feedback type="inValid">{errors.apple_monthly_price?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="google_subscribe_price">
            <Form.Label>Google Subscribe Price</Form.Label>
            <Form.Control
              name="google_subscribe_price"
              type="number"
              placeholder="Enter Coupon Code Here"
              ref={register}
              isInvalid={!!errors.google_subscribe_price}
            />
            <Form.Control.Feedback type="inValid">{errors.google_subscribe_price?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="google_plan_id">
            <Form.Label>Google Plan ID</Form.Label>
            <Form.Control
              name="google_plan_id"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.google_plan_id}
            />
            <Form.Control.Feedback type="inValid">{errors.google_plan_id?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="google_discount">
            <Form.Label>Google Discount</Form.Label>
            <Form.Control
              name="google_discount"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.google_discount}
            />
            <Form.Control.Feedback type="inValid">{errors.google_discount?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="google_monthly_price">
            <Form.Label>Google Monthly Price</Form.Label>
            <Form.Control
              name="google_monthly_price"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.google_monthly_price}
            />
            <Form.Control.Feedback type="inValid">{errors.google_monthly_price?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="bt_subscribe_price">
            <Form.Label>Braintree Subscribe Price</Form.Label>
            <Form.Control
              name="bt_subscribe_price"
              type="number"
              placeholder=""
              ref={register}
              isInvalid={!!errors.bt_subscribe_price}
            />
            <Form.Control.Feedback type="inValid">{errors.bt_subscribe_price?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="bt_plan_id">
            <Form.Label>Braintree Plan ID</Form.Label>
            <Form.Control
              name="bt_plan_id"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.bt_plan_id}
            />
            <Form.Control.Feedback type="inValid">{errors.bt_plan_id?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="bt_discount">
            <Form.Label>Braintree Discount</Form.Label>
            <Form.Control
              name="bt_discount"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.bt_discount}
            />
            <Form.Control.Feedback type="inValid">{errors.bt_discount?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="bt_monthly_price">
            <Form.Label>Braintree Monthly Price</Form.Label>
            <Form.Control
              name="bt_monthly_price"
              type="text"
              placeholder=""
              ref={register}
              isInvalid={!!errors.bt_monthly_price}
            />
            <Form.Control.Feedback type="inValid">{errors.bt_monthly_price?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditPlanModal;

