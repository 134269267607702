import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

const DeleteModal = ({ email, onDelete }) => {
  const [show, setShow] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [emailMismatchError, setEmailMismatchError] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const checkEmail = (ev) => {
    const value = ev.target.value;
    if (validateEmail(value)) {
      setEmailMismatchError(email !== value);
      setEnableDelete(email === value);
    } else {
      setEnableDelete(false);
    }
  };

  return (
    <Fragment>
      <Button onClick={() => setShow(true)}>Delete Account</Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete your account? This operation <strong>CANNOT</strong> be undone</p>
          <br/>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Please type your email to confirm"
              onChange={checkEmail}
            />
            {emailMismatchError && (
              <Form.Control.Feedback type="inValid">Provided email does not match with profile email</Form.Control.Feedback>
            )}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!enableDelete} onClick={onDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

DeleteModal.propTypes = {
  userName: PropTypes.string,
  onDelete: PropTypes.func
};

export default DeleteModal;
