import React from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

import "./UserHome.scss";

const UserHome = () => {
  const { username } = useSelector(state =>  state.auth);
  return (
    <div className="user-home">
      <h2 className="title">你好, {username}</h2>
      <section>
        <p>To use FullChinese, install the FullChinese app on your
          iPhone, iPad, Android phone or Android tablet
        </p>
        <div className="download">
          <a href="https://www.fullchinesehelp.com/download-on-ios.html" target="_blank" rel="noopener noreferrer">
            <img src="/img/app-store.jpg" alt="Download on App Store" />
          </a>
          <a href="https://www.fullchinesehelp.com/download-on-android.html" target="_blank" rel="noopener noreferrer">
            <img src="/img/google-play.png" alt="Download on Google Play" />
          </a>
        </div>
      </section>
      <section>
        <h3>Get familiar with FullChinese, watch this</h3>
        <div className="video-container">
          <iframe
            title="Get familiar with FullChinese"
            width="548"
            height="310"
            src="https://www.youtube.com/embed/rLa84yVjyXM?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
      </section>
      <section>
        <h3>Quick Start: Start using FullChinese in 3 min</h3>
        <div className="quick-start">
          <section>
            <h4>iPhone and iPad users</h4>
            <div>
              <video id="video-192110333789023709_html5_api" controls poster="https://www.fullchinesehelp.com/uploads/b/49434587-679831426413248243/fullchinese_teacherdownloadfromappstore_316.jpg" preload="none" src="https://www.fullchinesehelp.com/uploads/b/49434587-679831426413248243/fullchinese_teacherdownloadfromappstore_316.mp4">
                <source src="https://www.fullchinesehelp.com/uploads/b/49434587-679831426413248243/fullchinese_teacherdownloadfromappstore_316.mp4" type="video/mp4" />
              </video>
            </div>
          </section>
          <section>
          <h4>Android users</h4>
            <div>
              <video id="video-736787254516093296_html5_api" controls poster="https://www.fullchinesehelp.com/uploads/b/49434587-679831426413248243/fullchinese_teacherdownloadfromappstore_635.jpg" preload="none" src="https://www.fullchinesehelp.com/uploads/b/49434587-679831426413248243/fullchinese_teacherdownloadfromappstore_635.mp4">
                <source src="/uploads/b/49434587-679831426413248243/fullchinese_teacherdownloadfromappstore_635.mp4" type="video/mp4"/>
              </video>
            </div>
          </section>
        </div>
      </section>
      <section className="boot-camp">
        <h3>Ten Day FullChinese Bootcamp</h3>
        <p>
          See how much you can learn in 10 days.
          <br />
          Change the way you study Chinese.
        </p>
        <div>
          <img src="/img/boot-camp.jpg" alt="FullChinese Bootcamp" />
        </div>
        <div>
          <a href="https://www.fullchinesehelp.com/bootcamp.html" target="_blank" rel="noopener noreferrer">
            <Button variant="primary">
              Learn More
            </Button>
          </a>
        </div>
      </section>
    </div>
  );
};

export default UserHome;
