import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError } from "../../../common/actions/actions";
import { ytapiUrl } from "../../../config";

import * as Actions from "./action-types";

export const fetchVideoTranscript = (videoId, onComplete) => async dispatch => {
  try {
    const response = await makeApiCall(`/srt?videoId=${videoId}`, {
      baseURL: ytapiUrl,
      method: "get"
    });

    dispatch(fetchVideoTranscriptSuccess(response.data[0]));

    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (error) {
    dispatch(fetchVideoTranscriptFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch video transcript. Please try again."
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

const fetchVideoTranscriptSuccess = (payload) => ({
  type: Actions.FETCH_VIDEO_TRANSCRIPT_SUCCESS,
  payload
});

const fetchVideoTranscriptFail = () => ({
  type: Actions.FETCH_VIDEO_TRANSCRIPT_FAIL
});
