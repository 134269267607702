import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Field from "./Field";

const Level = ({ value: val, onSave }) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  return (
    <Field value={value} showIcon={true} onSave={onSave}>
      <Form.Control
        name="level"
        as="select"
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
      >
        <option value="beginner">Beginner</option>
        <option value="low-intermediate">Low-Intermediate</option>
        <option value="high-intermediate">High-Intermediate</option>
        <option value="advanced">Advanced</option>
      </Form.Control>
    </Field>
  );
};

Level.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func
};

export default Level;