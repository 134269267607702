import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const renderLink = ({ row }) => {
  const { id } = row;
  return (
    <Link to={`/classes/details/${id}`}>Details</Link>
  );
};

const columns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'description', headerName: 'Description', width: 400 },
  { field: 'nb_members', headerName: 'Members', width: 200 },
  { field: 'nb_books', headerName: 'Books', width: 150 },
  { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatDate },
  { field: 'id', headerName: '-', width: 100, renderCell: renderLink}
];

const Classes = ({ classes, onSearch }) => {
  const classRows = classes.map((item) => item.class).filter((item) => item !== null);
  return (
    <div className="classes">
      <SearchHeader
        count={classes.length}
        countTitle="Classes"
        placeHolder="Search class here"
        onSearch={onSearch}
      />
      <div className="classes-results">
        <DataGrid
          className="classes-grid"
          autoHeight={true}
          rows={classRows}
          columns={columns}
          pageSize={10}
         />
      </div>
    </div>
  );
};

Classes.propTypes = {
  classes: PropTypes.array,
  onSearch: PropTypes.func
};

export default Classes;
