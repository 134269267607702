import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Swal from 'sweetalert2'
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { resetAllNotices } from "../common/actions/actions";

import "react-toastify/dist/ReactToastify.css";

const Alert = (props) => {
  const dispatch = useDispatch();
  const { type, alertType, message, options } = props;

  useEffect(() => {
    const hasOptions = Object.keys(options).length > 0;
    if (message || hasOptions) {
      if (type === "toast") {
        const options = {
          onClose: () => dispatch(resetAllNotices())
        };
        toast[alertType](message, options);
      } else if (type === "alert") {
        if (hasOptions) {
          Swal.fire({...options, willClose: () => dispatch(resetAllNotices())});
        } else {
          Swal.fire({
            text: message,
            icon: alertType,
            willClose: () => dispatch(resetAllNotices())
          });
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [type, message, dispatch]);

  return (<ToastContainer />);
};

Alert.propTypes = {
  type: PropTypes.oneOf(["toast", "alert"]),
  alertType: PropTypes.oneOf(["success", "warning", "error"]),
  message: PropTypes.string,
  options: PropTypes.object
};

Alert.defaultProps = {
  type: "alert",
  alertType: "error",
  message: "",
  options: {}
};

export default Alert;
