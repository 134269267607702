import {
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_SUCCESS,
  UPDATE_ORG_DATA,
  GET_ORG_ROLE_SUCCESS,
  FETCH_ORG_BOOKS_SUCCESS,
  FETCH_ORG_BOOKS_SHA_SUCCESS,
  FETCH_BOOKS_SUCCESS,
  FETCH_ORG_CLASSES_SUCCESS,
  FETCH_ORG_MEMBERS_SUCCESS
} from "./action-types";

const initialValue = {
  organizations: [],
  currentOrganization: {},
  orgRole: null,
  orgBooks: [],
  books: [],
  bookSha: [],
  orgClasses: [],
  orgMembers: []
};

const organizationsReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {...state, organizations: payload}
    case FETCH_ORGANIZATION_SUCCESS:
      return {...state, currentOrganization: payload}
    case UPDATE_ORG_DATA:
      return {
        ...state,
        currentOrganization: { ...state.currentOrganization, ...payload }
      }
    case GET_ORG_ROLE_SUCCESS:
      return {
        ...state,
        orgRole: payload
      }
    case FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        books: payload
      }
    case FETCH_ORG_BOOKS_SUCCESS:
      return {
        ...state,
        orgBooks: payload
      }
    case FETCH_ORG_BOOKS_SHA_SUCCESS:
      return {
        ...state,
        bookSha: payload
      }
    case FETCH_ORG_CLASSES_SUCCESS:
      return {
        ...state,
        orgClasses: payload
      }
    case FETCH_ORG_MEMBERS_SUCCESS:
      return {
        ...state,
        orgMembers: payload
      }
    default:
      return state;
  }
};

export default organizationsReducer;
