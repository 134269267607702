import React from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

const DictionaryItem = ({ data, columns, gridKey }) => {
  return (
    <div className="dictionary-results">
      <DataGrid
        className="dictionary-grid"
        autoHeight={true}
        rows={data}
        columns={columns}
        pageSize={10}
        getRowId={row => row[gridKey]}
      />
    </div>
  );
};

DictionaryItem.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  gridKey: PropTypes.string
};

export default DictionaryItem;