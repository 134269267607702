import { FETCH_MEMBERS_SUCCESS } from "./action-types";

const initialValue = {
  members: {
    numRecords: 0,
    rows: []
  },
  currentMember: {}
};

const membersReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_MEMBERS_SUCCESS:
      return {...state, members: payload}
    default:
      return state;
  }
};

export default membersReducer;
