import React, { Component } from "react";
import { apiUrl } from "../../../config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MdClear } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import swal from "sweetalert";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import PaginationNew from "../../Pagination";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const axios = require("axios");

class Books extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: [],
      searchBook: "",
      title: "",
      description: "",
      obj: {},
      hidenavStatus: false,
      bookstat: [],
      books: [],
      arr: [],
      isChecked: false,
      bookSha1: ""
    };
  }
  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hidenav !== nextProps.hidenav) {
      this.setState({ hidenavStatus: this.props.hidenav });
    }
  }

  getbookstat = (start, end) => {
    let apiObj = {};
    apiObj.headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      Range: `${start}-${end}`,
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.token
    };
    apiObj.method = "GET";
    let url =
      this.state.searchBook.toLowerCase() === "family"
        ? "fc_admin_bookstatitems?owner=eq." +
          this.props.userid +
          "&book_title=ilike.%25" +
          this.state.searchBook.toLowerCase() +
          "%25"
        : "fc_admin_bookstatitems?owner=eq." +
          this.props.userid +
          "&book_title=ilike.%" +
          this.state.searchBook.toLowerCase() +
          "%";
    url = apiUrl + url;
    apiObj.url = url;
    axios(apiObj)
      .then(response => {
        response.data.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
        this.setState({
          store: response.data,
          booksLength: response.headers["content-range"].split("/")[1]
        });
        this.setTableState(response.data);
      })
      .catch(error => {
        swal("", "Can't get books of this user. Please try again later.", "error");
      });
  };

  searchBook = event => {
    this.setState({ searchBook: event.target.value });
  };

  addDefaultSrc = ev => {
    ev.target.src = "/img/book-cover.png";
  };

  goDetails = (cell, row) => {
    this.props.dispatch({ type: "SHA1", payload: row.book_sha1 });
    this.props.history.push("/books/BookDetails", { response: row });
  };

  clickOthers = row => {
    this.setState({ bookSha1: row.book_sha1 });
  };

  setTableState = data => {
    if (data && data.length > 0) {
      data.forEach(item => {
        this.setState({ [item.title]: false });
      });
    }
  };

  changeCurrentData = (start, end) => {
    this.getbookstat(start, end);
  };

  searchButton = () => {
    this.getbookstat(0, 9);
  };

  render() {
    const { searchBook } = this.state;

    const titleAction = (cell, row) => {
      return (
        <div className="st-table-bookTitle">
          <img
            src={
              row.book_cover_uri && row.book_cover_uri.slice(-4) === ".png"
                ? `https://fullapi.s3.amazonaws.com/${row.book_cover_uri}`
                : "/img/book-cover.png"
            }
            alt="imae"
            onError={this.addDefaultSrc}
          />
          <span>{cell}</span>
        </div>
      );
    };

    const authorAction = (cell, row) => {
      return <span>by {cell}</span>;
    };

    const modifiedAction = (cell, row) => {
      var year = cell.slice(0, 4);
      var month = cell.slice(5, 7);
      var day = cell.slice(8, 10);
      return (
        <span>
          {day}/{month}/{year}
        </span>
      );
    };

    const detailsAction = (cell, row) => {
      return (
        <div
          onClick={() => {
            this.goDetails(cell, row);
          }}
        >
          <p className="st-table-details">Details</p>
        </div>
      );
    };

    const othersAction = (cell, row) => {
      return (
        <div>
          <Button
            id="PopoverFocusBooks"
            type="button"
            style={{ border: "none" }}
            onClick={() => {
              this.clickOthers(row);
            }}
          >
            <img src="/img/basic-more.svg" alt="ima" className="size-24-24" />
          </Button>

          <UncontrolledPopover placement="bottom" target="PopoverFocusBooks" trigger="legacy">
            <PopoverBody>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <Button data-toggle="modal" data-target="#editBook">
                  Edit
                </Button> */}
                <Button data-toggle="modal" data-target="#deleteBookStat">
                  Delete
                </Button>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    };

    const columns = [
      {
        dataField: "book_title",
        text: "",
        sort: true,
        formatter: titleAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Title</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "book_description",
        text: "",
        sort: true,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Description</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "author",
        text: "",
        sort: true,
        formatter: authorAction,

        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Author</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "last_modified",
        text: "",
        sort: true,
        formatter: modifiedAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Modified</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "details",
        text: "",
        formatter: detailsAction
      },
      {
        dataField: "others",
        text: "",
        formatter: othersAction
      }
    ];

    return (
      <div id="books-gkadmin">
        <div className="kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed">
          <div
            className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid"
            style={{ marginBottom: 20 }}
          >
            <div className="btt-subheader">
              <div className="kt-total-collections">
                <span className="content-subheader-title">{this.state.booksLength}&nbsp;&nbsp;Books</span>
              </div>
              <div className="col-md-6 my-auto">
                <div className="searchbox input-group md-form form-sm form-2 pl-0">
                  <input
                    name="org"
                    type="text"
                    className="form-control"
                    placeholder="Search books here"
                    value={searchBook}
                    onChange={this.searchBook}
                    aria-label="Search"
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        this.searchButton();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary input-group-text amber lighten-3"
                      id="basic-text1"
                      onClick={() => {
                        this.searchButton();
                      }}
                    >
                      <i className="fas fa-search text-grey" aria-hidden="true" style={{ width: 15 }}></i>
                    </button>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
            <BootstrapTable
              keyField="sha1"
              data={this.state.store}
              columns={columns}
              tabIndexCell
              responsive
              wrapperClasses="table-responsive second-description-table"
            />
            <PaginationNew data={this.state.booksLength} changeCurrentData={this.changeCurrentData} />
          </div>
        </div>

        {/* Modal Part */}

        <div
          className="modal fade"
          id="deleteBookStat"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-warning" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Book
                </h5>
                <span data-dismiss="modal" aria-label="Close">
                  <MdClear />
                </span>
              </div>
              <div className="modal-body">Do you want to Delete this book?</div>
              <div className="modal-footer">
                <button type="button" className="btn btn-elevate btn-pill modal-cancel" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-elevate btn-pill modal-ok"
                  onClick={this.deleteBookStat}
                  data-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.rpc.tokens,
    role: state.rpc.role,
    store: state.rpc.store,
    storetitle: state.rpc.storetitle,
    id: state.rpc.id,
    hidenav: state.rpc.hidenav,
    bookstatitems: state.rpc.bookstatitems
  };
};

export default connect(mapStateToProps)(withRouter(Books));
