export const FETCH_TEMPLATES_SUCCESS = "app/openai/FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_FAIL = "app/openai/FETCH_TEMPLATES_FAIL";

export const FETCH_TEMPLATE_SUCCESS = "app/openai/FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAIL = "app/openai/FETCH_TEMPLATE_FAIL";

export const CREATE_TEMPLATE_SUCCESS = "app/openai/CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAIL = "app/openai/CREATE_TEMPLATE_FAIL";

export const UPDATE_TEMPLATE_SUCCESS = "app/openai/UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAIL = "app/openai/UPDATE_TEMPLATE_FAIL";

export const DELETE_TEMPLATE_SUCCESS = "app/openai/DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAIL = "app/openai/DELETE_TEMPLATE_FAIL";
