import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import classNames from "classnames";

import "./Password.scss";

const Password = props => {
  const { name, showIcon, innerRef, ...otherProps } = props;
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="password-field">
      <Form.Control
        name={name}
        ref={innerRef}
        type={showPass ? "text": "password"}
        {...otherProps}
      />
      { showIcon && 
          <i
            className={classNames("fas", { "fa-eye": !showPass, "fa-eye-slash": showPass })}
            onClick={() => setShowPass(!showPass)}>
          </i>
      }
    </div>
  );
};

Password.propTypes = {
  name: PropTypes.string.isRequired,
  showIcon: PropTypes.bool
};

export default Password;