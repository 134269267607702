import React from "react";
import PropTypes from "prop-types";
import "./DataTable.scss";

const DataTable = ({ rows }) => {
  return (
    <div className="data-table">
      {rows.map((cols = [], index) => (
        <div className="data-row" key={index}>
          { cols.map((content, index) => (
              <div key={index} className={index === 0 ? "heading" : "data-col"}>
                {content}
              </div>
            ))
          }
        </div>
      ))}
    </div>
  );
};

DataTable.propTypes = {
  rows: PropTypes.array
};

export default DataTable;