import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import "./SearchBar.scss";

const SearchBar = ({ placeholder, onChange, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (ev) => {
    const value = ev.target.value ? ev.target.value.toLowerCase() : "";
    setSearchTerm(value);

    if (typeof onChange === "function") {
      onChange(value);
    }
  };

  const handleSearch = (ev) => {
    ev.preventDefault();
    if (typeof onSearch === "function") {
      onSearch(searchTerm);
    }
  };

  return (
    <form className="search-bar" onSubmit={handleSearch}>
      <input
        type="text"
        className="search-input"
        placeholder={placeholder}
        onChange={handleChange} />
      <Button type="submit" onClick={handleSearch} className="search-btn">
        <img src="/img/magnifying-glass-black.png" alt="search" />
      </Button>
    </form>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
