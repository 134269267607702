import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import "./Menu.scss";

const Menu = ({ config }) => {
  const currentPath = window.location.pathname;
  const dispatch = useDispatch();
  return (
    <div className="menu">
      {
        config.map((item, index) => {
          const { name, link, icon, alt, onClick } = item;
          const classes = classNames({
            'selected': link === currentPath
          });
          const isExternalLink = link.includes("https://");
          const handleClick = typeof onClick === "function" ? (ev) => onClick(ev, dispatch) : null;
          return !isExternalLink ? (
            <Link to={link} className={classes} key={index} onClick={handleClick}>
              <img src={icon} alt={alt} className="size-24-24" />
              <span> {name}</span>
            </Link>
          ) : (
            <a href={link} className={classes} key={index}>
              <span> {name}</span>
            </a>
          );
        })
      }
    </div>
  );
};

Menu.propTypes = {
  config: PropTypes.array
}

export default Menu;
