export const FETCH_MEMBER = "app/member/FETCH_MEMBER";
export const FETCH_MEMBER_SUCCESS = "app/member/FETCH_MEMBER_SUCCESS";
export const FETCH_MEMBER_FAIL = "app/member/FETCH_MEMBER_FAIL";

export const FETCH_MEMBER_SUBSCRIPTION = "app/member/FETCH_MEMBER_SUBSCRIPTION";
export const FETCH_MEMBER_SUBSCRIPTION_SUCCESS = "app/member/FETCH_MEMBER_SUBSCRIPTION_SUCCESS";
export const FETCH_MEMBER_SUBSCRIPTION_FAIL = "app/member/FETCH_MEMBER_SUBSCRIPTION_FAIL";
export const UPDATE_EXPIRY_DATE_SUCCESS = "app/member/UPDATE_EXPIRY_DATE_SUCCESS";
export const UPDATE_EXPIRY_DATE_FAIL = "app/member/UPDATE_EXPIRY_DATE_FAIL";

export const UNSUBSCRIBE_MEMBER = "app/member/UNSUBSCRIBE_MEMBER";
export const UNSUBSCRIBE_MEMBER_SUCCESS = "app/member/UNSUBSCRIBE_MEMBER_SUCCESS";
export const UNSUBSCRIBE_MEMBER_FAIL = "app/member/UNSUBSCRIBE_MEMBER_FAIL";

export const FETCH_PLAN_SUCCESS = "app/member/FETCH_PLAN_SUCCESS";

export const FETCH_MEMBER_CLASSES = "app/member/FETCH_MEMBER_CLASSES";
export const FETCH_MEMBER_CLASSES_SUCCESS = "app/member/FETCH_MEMBER_CLASSES_SUCCESS";
export const FETCH_MEMBER_CLASSES_FAIL = "app/member/FETCH_MEMBER_CLASSES_FAIL";

export const FETCH_MEMBER_BOOKS = "app/member/FETCH_MEMBER_BOOKS";
export const FETCH_MEMBER_BOOKS_SUCCESS = "app/member/FETCH_MEMBER_BOOKS_SUCCESS";
export const FETCH_MEMBER_BOOKS_FAIL = "app/member/FETCH_MEMBER_BOOKS_FAIL";
export const DELETE_MEMBER_BOOK_SUCCESS = "app/member/DELETE_MEMBER_BOOK_SUCCESS";

export const FETCH_MEMBER_TEXTS = "app/member/FETCH_MEMBER_TEXTS";
export const FETCH_MEMBER_TEXTS_SUCCESS = "app/member/FETCH_MEMBER_TEXTS_SUCCESS";
export const FETCH_MEMBER_TEXTS_FAIL = "app/member/FETCH_MEMBER_TEXTS_FAIL";
export const DELETE_MEMBER_TEXT_SUCCESS = "app/member/DELETE_MEMBER_TEXT_SUCCESS";

export const FETCH_EMAILS_SUCCESS = "app/member/FETCH_EMAILS_SUCCESS";
export const FETCH_EMAILS_FAIL = "app/member/FETCH_EMAILS_FAIL";

export const FETCH_EVENTS_SUCCESS = "app/member/FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAIL = "app/member/FETCH_EVENTS_FAIL";

export const FETCH_MEMBER_PAYMENTS_SUCCESS = "app/member/FETCH_MEMBER_PAYMENTS_SUCCESS";
export const FETCH_MEMBER_PAYMENTS_FAIL = "app/member/FETCH_MEMBER_PAYMENTS_FAIL";

export const FETCH_USER_POSTS_SUCCESS = "app/member/FETCH_USER_POSTS_SUCCESS";
export const FETCH_USER_POSTS_FAIL = "app/member/FETCH_USER_POSTS_FAIL";

export const CREATE_POST_FEEDS_SUCCESS = "app/member/CREATE_POST_FEEDS_SUCCESS";
export const CREATE_POST_FEEDS_FAIL = "app/member/CREATE_POST_FEEDS_FAIL";

export const REMOVE_FROM_POST_FEEDS_SUCCESS = "app/member/REMOVE_FROM_POST_FEEDS_SUCCESS";
export const REMOVE_FROM_POST_FEEDS_FAIL = "app/member/REMOVE_FROM_POST_FEEDS_FAIL";
