import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({ 
  json_text: yup
    .string()
    .required("This field cannot be empty")
});

const EditJSONModal = ({ showModal, defaultValues = {}, onClose, onUpdateData }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = (payload) => {
    const {  json_text } = payload;
    setSubmitting(true);
    onUpdateData({
      json_text: JSON.parse(json_text)
    }, () => {
      setSubmitting(false);
      handleClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit JSON Text</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="signup-form">
        <Modal.Body>
          <Form.Group controlId="json_text">
            <Form.Label>JSON Text</Form.Label>
            <Form.Control
              name="json_text"
              as="textarea"
              rows={40}
              placeholder="Enter content here"
              ref={register}
              isInvalid={!!errors.json_text}
            />
            <Form.Control.Feedback type="inValid">{errors.json_text?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditJSONModal;

