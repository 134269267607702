import React, { useEffect, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import EditConfigModal from "./EditConfigModal";

import "./config.scss";

const schema = yup.object().shape({
  otp_verify: yup.boolean(),
  call_to_action: yup.boolean(),
  max_events: yup.number().typeError('Max events should be a number').required("This field cannot be empty"),
  config_time_to_live: yup.number().typeError('Time to Live should be a number').required("This field cannot be empty"),
  max_nb_texts_before_subscribe: yup.number().typeError('Max texts should be a number').required("This field cannot be empty")
});

const Config = ({ data, onUpdate }) => {
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [submitting, setSubmitting] = useState(false); 
  const getValues = useCallback((cfg) => {
    const values = {};
    const { conf_info = [] } = cfg;
    conf_info.forEach(({ name, value }) => {
      values[name] = value
    });

    return {
      otp_verify: values["otp-verify"],
      call_to_action: values["call-to-action-1"],
      max_events: values["max-events"],
      config_time_to_live: values["config-time-to-live"],
      max_nb_texts_before_subscribe: values["max-nb-texts-before-subscribe"]
    };
  }, [data])

  const onSubmit = (payload) => {
    setSubmitting(true);
    const { conf_info = [] } = data;
    const { otp_verify, call_to_action, max_events, config_time_to_live, max_nb_texts_before_subscribe } = payload;
    const updatedValues = {
      "otp-verify": otp_verify,
      "call-to-action-1": call_to_action,
      "max-events": max_events,
      "config-time-to-live": config_time_to_live,
      "max-nb-texts-before-subscribe": max_nb_texts_before_subscribe
    };
    const updatedConfInfo = [];
    conf_info.forEach((item) => {
      const { name } = item;
      const val = updatedValues[name];
      const updatedItem = {...item, value: val };
      updatedConfInfo.push(updatedItem);
    });

    onUpdate({
      version_num: data.version_num,
      conf_info: updatedConfInfo
    }, () => {
      setSubmitting(false);
    }, () => {
      setSubmitting(false);
    });
  };

  const defaultValues = getValues(data);

  const { register, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, data]);

  const handleShowConfigModal = () => {
    setShowConfigModal(true);
  };

  const handleUpdateJSON = (payload, onSuccess, onFail) => {
    onUpdate(payload.json_text, onSuccess, onFail);
  };

  return (
    <div className="device-config__config">
      <h4>Other Device Configuration</h4>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row}>
            <Col xs="12">
              <Form.Check
                name="otp_verify"
                type="checkbox"
                ref={register}
                label="Run OTP-Verify Email before Sign Up"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col xs="12">
              <Form.Check
                name="call_to_action"
                type="checkbox"
                ref={register}
                label="Run Call To Action after Signup"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Maximum Number of Events to record</Form.Label>
            <Col xs="1">
              <Form.Control
                name="max_events"
                type="number"
                placeholder=""
                ref={register}
                isInvalid={!!errors.max_events}
              />
              <Form.Control.Feedback type="inValid">{errors.max_events?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Number of days after which the config needs to be reloaded</Form.Label>
            <Col xs="1">
              <Form.Control
                name="config_time_to_live"
                type="number"
                placeholder=""
                ref={register}
                isInvalid={!!errors.config_time_to_live}
              />
              <Form.Control.Feedback type="inValid">{errors.config_time_to_live?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Number of texts allowed without subscription</Form.Label>
            <Col xs="1">
              <Form.Control
                name="max_nb_texts_before_subscribe"
                type="number"
                placeholder=""
                ref={register}
                isInvalid={!!errors.max_nb_texts_before_subscribe}
              />
              <Form.Control.Feedback type="inValid">{errors.max_nb_texts_before_subscribe?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Button type="button" variant="primary" onClick={handleShowConfigModal}>
            View/Edit JSON Text
          </Button>

          <Button type="submit" variant="primary" className="save-btn" disabled={submitting}>
            Save
          </Button>
        </Form>
        <EditConfigModal
          showModal={showConfigModal}
          defaultValues={{ json_text: JSON.stringify({ version_num: data.version_num, conf_info: data.conf_info }, undefined, 4) }}
          onClose={() => setShowConfigModal(false)}
          onUpdateData={handleUpdateJSON} />
      </div>
    </div>
  )
}

export default Config
