import { FETCH_EMAILS_SUCCESS, FETCH_NOTIFIER_EMAILS_SUCCESS } from "./action-types";

const initialValue = {
  emails: {
    numRecords: 0,
    rows: []
  },
  notifierEmails: {
    numRecords: 0,
    rows: []
  }
};

const emailsReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_EMAILS_SUCCESS:
      return {...state, emails: payload}
    case FETCH_NOTIFIER_EMAILS_SUCCESS:
      return {...state, notifierEmails: payload}
    default:
      return state;
  }
};

export default emailsReducer;
