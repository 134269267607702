import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { throwApiError } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchMembers = (pagination, queryString) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_users?${queryString}`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { headers, data } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchMembersSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchMembersFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch members. Please try again."
    }));
  }
};

const fetchMembersSuccess = (payload) => ({
  type: Actions.FETCH_MEMBERS_SUCCESS,
  payload
});

const fetchMembersFail = () => ({
  type: Actions.FETCH_MEMBERS_FAIL
});

export const getMemberProfile = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_users?user_id=eq.${id}`);
    dispatch(getMemberProfileSuccess(response.data[0]));
  } catch (error) {
    dispatch(getMemberProfileFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch members. Please try again."
    }));
  }
};

const getMemberProfileSuccess = (payload) => ({
  type: Actions.GET_MEMBER_PROFILE_SUCCESS,
  payload
});

const getMemberProfileFail = () => ({
  type: Actions.GET_MEMBER_PROFILE_FAIL
});
