import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";

const NotifierEmails = ({ data, onFetchNotifierEmails }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchNotifierEmails({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchNotifierEmails({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchNotifierEmails({ pageNum: 0, pageSize }, searchTerm);
  };

  const formatBillingDate = ({ row }) => {
    const { next_billing_date } = row;
    return dayjs(next_billing_date).format('DD/MM/YYYY');
  };

  const formatModifiedDate = ({ row }) => {
    const { last_modified } = row;
    return dayjs(last_modified).format('DD/MM/YYYY');
  };
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'owner', headerName: 'Owner', width: 100 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'email_verified', headerName: 'Email Verified', width: 150 },
    { field: 'plan_name', headerName: 'Plan Name', width: 200 },
    { field: 'next_billing_date', headerName: 'Next Billing', width: 230, renderCell: formatBillingDate },
    { field: 'last_modified', headerName: 'Last Modified', width: 230, renderCell: formatModifiedDate }
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Emails"
        placeHolder="Search emails here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.id}
        />
      </div>
    </div>
  );
};

NotifierEmails.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchNotifierEmails: PropTypes.func
};

export default NotifierEmails;
