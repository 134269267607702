import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import LinkButton from "../../../LinkButton/LinkButton";
import Menu from "../Nav/Menu";
import { getValidTabs } from "../Nav/menu-items";

import "./HamburgerMenu.scss";

const getSecondaryTabs = (avatar) => ([
  {
    name: 'About',
    link: 'https://www.fullchinesehelp.com/about-us.html',
    alt: 'About'
  },
  {
    name: 'Contact',
    link: 'https://www.fullchinesehelp.com/contact-us.html',
    alt: 'Contact'
  },
  {
    name: 'FAQ',
    link: 'https://www.fullchinesehelp.com/faq-by-topics.html',
    alt: 'FAQ'
  },
  {
    name: 'Settings',
    link: '/settings',
    icon: avatar,
    alt: 'Settings'
  },
  {
    name: 'Sign Out',
    link: '/',
    icon: '/img/logout.png',
    alt: 'Sign out',
    onClick: (ev, dispatch) => { 
      localStorage.clear();
      dispatch({type: "RESET_APP"});
    }
  }
]);

const HamburgerMenu = ({ avatar, userName }) => {
  const { role } = useSelector(state => state.auth);
  const [ menuOpen, setMenuOpen ] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const validTabs = getValidTabs(role);
  const secondaryTabs = getSecondaryTabs(avatar, userName);

  const triggerClasses = classNames({
    "trigger": true,
    "open": menuOpen
  });

  return (
    <div className="hamburger-menu">
      <LinkButton onClick={toggleMenu}>
        <span className={triggerClasses}></span>
      </LinkButton>
     {
        menuOpen && ReactDOM.createPortal(
          <div className="menu-container-mobile">
            <Menu config={validTabs} />
            <div className="secondary-menu">
              <Menu config={secondaryTabs} />
            </div>
          </div>,
          document.body
        )
      }
    </div>
  );
};

HamburgerMenu.propTypes = {
  avatar: PropTypes.string
};

HamburgerMenu.defaultProps = {
  avatar: "/img/newavatar.png"
}

export default HamburgerMenu;