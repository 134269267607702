export const THROW_API_ERROR = "THROW_API_ERROR";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";

export const GET_SUBSCRIPTION_PRICE_SUCCESS = "GET_SUBSCRIPTION_PRICE_SUCCESS";
export const GET_SUBSCRIPTION_PRICE_FAILURE = "GET_SUBSCRIPTION_PRICE_FAILURE";
