import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { throwApiError } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchEvents = (pagination) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall("fc_events", {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchEventsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchEventsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch events. Please try again."
    }));
  }
};

const fetchEventsSuccess = (payload) => ({
  type: Actions.FETCH_EVENTS_SUCCESS,
  payload
});

const fetchEventsFail = () => ({
  type: Actions.FETCH_EVENTS_FAIL
});
