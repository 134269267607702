import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup.object().shape({
  book_title: yup.string().required("This field cannot be empty"),
  book_description: yup.string().required("This field cannot be empty")
});

const EditNotebookModal = ({ value = {}, show, onClose, onUpdateNotebook }) => {

  const [submitting, setSubmitting] = useState(false);

  const { book_title, book_description } = value || {};
  const defaultValues = {
    book_title,
    book_description
  };

  const { register, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  const onSubmit = (values) => {
    const { sha1 } = value;
    setSubmitting(true);
    onUpdateNotebook(sha1, values , () => {
      setSubmitting(false);
      onClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Book</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
        <Form.Group controlId="book_title">
            <Form.Label></Form.Label>
            <Form.Control
              name="book_title"
              type="text"
              placeholder="Enter title here"
              ref={register}
              isInvalid={!!errors.book_title}
            />
            <Form.Control.Feedback type="inValid">{errors.book_title?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="book_description">
            <Form.Label></Form.Label>
            <Form.Control
              name="book_description"
              as="textarea"
              rows={5}
              placeholder="Enter description here"
              ref={register}
              isInvalid={!!errors.book_description}
            />
            <Form.Control.Feedback type="inValid">{errors.book_description?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditNotebookModal;
