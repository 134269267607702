import React, { useEffect, useState } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import EditField from "../../../components/Fields/EditField";
import LinkButton from "../../../components/LinkButton/LinkButton";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import DeleteModal from "./DeleteModal";
import OrganizationResources from "./OrganizationResources";

import { resetCommon } from "../../../common/actions/actions";
import useImageUpload from "../../../helpers/hooks/use-image-upload";
import {
  fetchOrganization,
  getOrgRole,
  updateOrganization,
  uploadOrgImage,
  deleteOrganization
} from "../state/actions";

import "./organization-details.scss";

const schema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty")
});

const OrganizationDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const { nextRoute } = useSelector(state => state.common);
  const { id, role } = useSelector(state => state.auth);
  const { currentOrganization, orgRole } = useSelector(state => state.organizations);
  const { name, description } = currentOrganization;
  const [orgImage, setOrgImage] = useState("/img/book-cover.png");
  const [orgImageFile, setOrgImageFile] = useState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [key, formData] = useImageUpload(orgImageFile);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchOrganization(orgId));
    dispatch(getOrgRole(id, orgId));
  }, [dispatch, orgId, id]);

  useEffect(() => {
    if (key) {
      const updateImage = () => {
        dispatch(updateOrganization(orgId, { image: key }));
      };
      dispatch(uploadOrgImage(formData, updateImage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const defaultValues = {
    name,
    description
  };
  const { register, errors, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const handleChangeOrgImage = (ev) => {
    const file = ev.target.files[0];
    setOrgImageFile(file);
    setOrgImage(URL.createObjectURL(file));
  };

  const handleDeleteOrg = () => {
    dispatch(deleteOrganization(orgId));
  };

  const handleSave = (field) => {
    const values = getValues();
    if (values[field]) {
      dispatch(updateOrganization(orgId, { [field]: values[field] }));
    }
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  const isEditor = orgRole === "org_admin" || role === "gk_admin" || role === "support";

  return (
    <div className="organization-detail-container">
      <div className="organization-detail">
        <div className="header">
          <LinkButton onClick={() => history.goBack()}>
            <img src="/img/back.svg" alt="back button" className="size-24-24" />
          </LinkButton>
          <img src={orgImage} alt="organization logo" />
          <h3>{name}</h3>
          { 
            isEditor && (
              <div>
                <ActionIcon icon="action-trashcan" label="DELETE" onClick={() => setShowDeleteModal(true)} />
                <DeleteModal showModal={showDeleteModal} onDelete={handleDeleteOrg} />
              </div>
            )
          }
        </div>
        <div>
          <Form>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Organization Name</Form.Label>
              <Col xs="8">
                <EditField name="name" value={name} canEdit={isEditor} onSave={() => handleSave("name")}>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Organization Name"
                    ref={register}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Description</Form.Label>
              <Col xs="8">
                <EditField name="description" canEdit={isEditor} value={description} onSave={() => handleSave("description")}>
                  <Form.Control
                    name="description"
                    as="textarea"
                    placeholder="Enter Organization Description"
                    ref={register}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row form-row-image">
              <Form.Label column xs="4">Organization Cover</Form.Label>
              <Col xs="8">
                <div className="org-img-container">
                  <img src={orgImage} alt="organization logo" className="org-img"/>
                  { isEditor && <Form.File label="Upload Image" onChange={handleChangeOrgImage} /> }
                </div>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="tabs">
        <OrganizationResources orgId={orgId} isEditor={isEditor || role === "teacher"} />
      </div>
    </div>
  );
};

export default OrganizationDetails;
