import {
  FETCH_PAYMENTS_SUCCESS,
  FETCH_COUPONS_SUCCESS,
  FETCH_PLANS_SUCCESS
} from "./action-types";

const initialValue = {
  payments: {
    numRecords: 0,
    rows: []
  },
  coupons: [],
  plans: []
};

const paymentsReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_PAYMENTS_SUCCESS:
      return {...state, payments: payload}
    case FETCH_COUPONS_SUCCESS:
      return {...state, coupons: payload}
    case FETCH_PLANS_SUCCESS:
      return {...state, plans: payload}
    default:
      return state;
  }
};

export default paymentsReducer;
