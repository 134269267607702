import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "./EditWordModal.scss";

const EditWordModal = ({ showModal, defaultValue = {}, onClose, onUpdate }) => {
  const getDefaultValue = () => ({
    priority: defaultValue.priority,
    phonetic: defaultValue.phonetic,
    defs: defaultValue.defs
  });

  const [show, setShow] = useState(false);
  const [defaultValues, setDefaultValues] = useState(getDefaultValue());

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    setDefaultValues(getDefaultValue());
  }, [defaultValue]);

  const handleClose = () => {
    setShow(false);
    setDefaultValues({});
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const schema = yup.object().shape({
    //chinese: yup.string().required("This field cannot be empty"),
    priority: yup.number().required("This field cannot be empty"),
    phonetic: yup.string().required("This field cannot be empty"),
    defs: yup.string().required("This field cannot be empty")
  });

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleUpdate = (values) => {
    if (typeof onUpdate === "function") {
      onUpdate(defaultValue.id, values);
    }
  };

  return (
    <Modal size="lg" centered show={show} onHide={handleClose} className="edit-character-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit Word</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <Modal.Body>
          {
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Priority</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="priority"
                  type="number"
                  placeholder="Enter Priority"
                  ref={register}
                  isInvalid={!!errors.priority}
                />
                <Form.Control.Feedback type="inValid">{errors.priority?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          }
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Phonetic</Form.Label>
            <Col xs="8">
              <Form.Control
                name="phonetic"
                type="text"
                placeholder="Enter phonetic"
                ref={register}
                isInvalid={!!errors.phonetic}
              />
              <Form.Control.Feedback type="inValid">{errors.phonetic?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Defs</Form.Label>
            <Col xs="8">
              <Form.Control
                name="defs"
                type="text"
                placeholder="Enter Defs"
                ref={register}
                isInvalid={!!errors.defs}
              />
              <Form.Control.Feedback type="inValid">{errors.defs?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditWordModal.propTypes = {
  showModal: PropTypes.bool,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func
};

export default EditWordModal;
