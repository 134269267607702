import { useState, useEffect } from "react";
import { throttle } from 'throttle-debounce';

const getDeviceSize = (width) => {
  if(width < 320) {
    return 'xs';
  } else if(width >= 320 && width < 720 ) {
    return 'sm';
  } else if(width >= 720 && width < 1024) {
    return 'md';
  } else if(width >= 1024) {
    return 'lg';
  }
};

const useViewportSize = () => {
  const [size, setSize] = useState(() => getDeviceSize(window.innerWidth));

  useEffect(() => {

    const setViewPortSize = () => throttle(200, false, () => {
      setSize(getDeviceSize(window.innerWidth));
    });

    const throttledFn = setViewPortSize();

    window.addEventListener('resize', throttledFn);

    return () => {
      window.removeEventListener('resize', throttledFn);
    }
  }, []);

  return size;
};

export default useViewportSize;
