import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import MoreItems from "../../../../components/MoreItems/MoreItems";

import NewClassModal from "./NewClassModal";
import DeleteClassModal from "./DeleteClassModal";

import { resetCommon } from "../../../../common/actions/actions";
import {
  fetchOrgClasses,
  addClass,
  deleteClass
} from "../../state/actions";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const Classes = ({ orgId }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.auth);
  const { orgClasses } = useSelector(state => state.organizations);

  const [showNewModal, setShowNewModal] = useState(false);
  const [toDeleteRecordId, setToDeleteRecordId] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const renderLink = ({ row }) => {
    const { id } = row;
    return (
      <div className="grid-actions">
        <Link to={`/classes/details/${id}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  }
  
  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'max_members', headerName: 'Members', width: 150 },
    { field: 'max_books', headerName: 'Books', width: 150 },
    { field: 'last_modified', headerName: 'Modified', width: 200, valueGetter: formatDate },
    { field: 'id', headerName: '-', width: 100, renderCell: renderLink}
  ];

  const composeFilters = (searchTerm = "") => {
    return `org_id=eq.${orgId}&name=ilike.%25${searchTerm}%25&order=last_modified.desc`;
  };

  useEffect(() => {
    dispatch(fetchOrgClasses(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    dispatch(fetchOrgClasses(composeFilters(searchTerm)));
  };

  const handleAddNewClass = (payload) => {
    dispatch(addClass(id, {...payload, org_id: orgId}, () => {
      handleSearch();
    }));
    setShowNewModal(false);
  };

  const handleDelete = (id) => {
    const onSuccess = () => {
      dispatch(fetchOrgClasses(composeFilters()));
    };
    setToDeleteRecordId(null);
    dispatch(deleteClass(id, onSuccess));
  };

  return (
    <div className="organization-books">
      <SearchHeader
        count={orgClasses.length}
        countTitle="Classes"
        placeHolder="Search classes"
        addComponent={
          <ActionIcon
            icon="action-add-folder"
            label="ADD CLASS"
            onClick={() => setShowNewModal(true)} />
        }
        onSearch={handleSearch}
      />
      <div className="classes-results">
        <DataGrid
          className="classes-grid"
          autoHeight={true}
          rows={orgClasses}
          columns={columns}
          pageSize={10}
         />
      </div>
      <NewClassModal
        showModal={showNewModal}
        onHide={() => setShowNewModal(false)}
        onAddNewClass={handleAddNewClass} />
      <DeleteClassModal
        showModal={!!toDeleteRecordId}
        recordId={toDeleteRecordId}
        onHide={() => setToDeleteRecordId(null)}
        onDelete={handleDelete} />
     </div>
  );
};

Classes.propTypes = {
  orgId: PropTypes.string
};

export default Classes;