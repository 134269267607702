import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";

import Conditions from "./components/Conditions";
import SignUp from "./components/SignUp";

import { resetCommon } from '../../common/actions/actions';

const SignUpFlow = () => {
  const dispatch = useDispatch();

  const { nextRoute } = useSelector(state => state.common);

  const [conditionsComplete, setConditionsComplete] = useState(false);
  const [role, setRole] = useState("student");

  useEffect(() => {
    dispatch(resetCommon());
    localStorage.clear();

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const handleConditionsComplete = (value) => {
    setRole(value[0].role);
    setConditionsComplete(true);
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="sign-up">
      {
        !conditionsComplete ? (
          <Conditions onConditionsComplete={handleConditionsComplete} />
        ): <SignUp role={role} />
      }
    </div>
  );
};

export default SignUpFlow;
