import React, { useEffect, useState, useCallback } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import ActionModal from "../../../../components/ActionModal/ActionModal";
import EditField from "../../../../components/Fields/EditField";
import useQuery from "../../../../helpers/hooks/use-query";

import BookTexts from "./BookTexts";
import BookPages from "./BookPages";

import { resetCommon } from "../../../../common/actions/actions";

import {
  fetchSelectedBook,
  updateSelectedBook,
  deleteBook,
  uploadFile
} from "../../state/actions";

import "./book-details.scss";

const schema = yup.object().shape({
  title: yup.string().required("This field cannot be empty"),
  author: yup.string().required("This field cannot be empty"),
  book_type: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty")
});

const BookDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: selectedSha1 } = useParams();
  const query = useQuery();
  const pageType = query.get("type");
  const { nextRoute } = useSelector(state => state.common);
  const { id, role } = useSelector(state => state.auth);
  const { selectedBook } = useSelector(state => state.texts);
  const { title, author, book_type, description, cover_uri, isNotebook } = selectedBook;

  const isEditable = role === "gk_admin" || isNotebook;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedBook(selectedSha1, id));
  }, [selectedSha1, id, dispatch]);

  const defaultValues = {
    title,
    author,
    book_type,
    description
  };
  const { register, errors, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBook]);

  const addDefaultBookCover = (ev) => {
    ev.target.src = "/img/book-cover.png";
  };

  const handleDelete = () => {
    dispatch(deleteBook(selectedSha1, null, () => {
      setShowDeleteModal(false);
      history.goBack();
    }));
  };

  const handleSave = (field) => {
    const values = getValues();
    if (values[field]) {
      dispatch(updateSelectedBook(
        selectedSha1,
        { [field]: values[field] },
        () => dispatch(fetchSelectedBook(selectedSha1, id))
      ));
    }
  };

  const handleUploadBookCover = (ev) => {
    const file = ev.target.files[0];

    dispatch(uploadFile({ file }, "png", () => {
      document.getElementById("book-cover-image-thumb").src = URL.createObjectURL(file);
      document.getElementById("book-cover-image").src = URL.createObjectURL(file);
    }));
  };

  const getBookType = useCallback(() => {
    if (book_type === 0) {
      return "Audio";
    } else if (book_type === 1) {
      return "Video";
    } else {
      return null;
    }
  }, [book_type]);

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="book-details">
      <div className="section-top">
        <div className="header">
          <LinkButton onClick={() => history.goBack()}>
            <img src="/img/back.svg" alt="back button" className="size-24-24" />
          </LinkButton>
          <img
            src={
              cover_uri && cover_uri.slice(-4) === ".png"
                ? `https://fullapi.s3.amazonaws.com/${cover_uri}`
                : "/img/book-cover.png"
              }
              className="img-fluid"
              id="book-cover-image-thumb"
              alt="book-cover" />
          <h3>{title}</h3>
          {isEditable && (
              <div>
                <ActionIcon icon="action-trashcan" label="DELETE" onClick={() => setShowDeleteModal(true)} />
                <ActionModal
                  showModal={showDeleteModal}
                  title="Delete Book"
                  actionText="Delete"
                  onClose={() => setShowDeleteModal(false)}
                  onAction={handleDelete}
                >
                  Do you want to delete this book?
                </ActionModal>
              </div>
            )
          }
        </div>
        <div>
          <Form>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Book Title</Form.Label>
              <Col xs="8">
                <EditField name="title" value={title} canEdit={isEditable} onSave={() => handleSave("title")}>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Enter book title here"
                    ref={register}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="inValid">{errors.title?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Author</Form.Label>
              <Col xs="8">
                <EditField name="author" value={author} canEdit={isEditable} onSave={() => handleSave("author")}>
                  <Form.Control
                    name="author"
                    type="text"
                    placeholder="Enter author here"
                    ref={register}
                    isInvalid={!!errors.author}
                  />
                  <Form.Control.Feedback type="inValid">{errors.author?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Book Type</Form.Label>
              <Col xs="8">
                <EditField name="book_type" value={getBookType()} canEdit={isEditable} onSave={() => handleSave("book_type")}>
                  <Form.Control
                    name="book_type"
                    as="select"
                    ref={register}
                    isInvalid={!!errors.book_type}
                  >
                    <option value="0">Audio</option>
                    <option value="1">Video</option>
                  </Form.Control>
                  <Form.Control.Feedback type="inValid">{errors.book_type?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Description</Form.Label>
              <Col xs="8">
                <EditField name="description" canEdit={isEditable} value={description} onSave={() => handleSave("description")}>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={8}
                    placeholder="Enter description here"
                    ref={register}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Book Cover</Form.Label>
              <Col xs="8">
                <div className="book-cover-field">
                  <img
                    onError={addDefaultBookCover}
                    src={
                      cover_uri && cover_uri.slice(-4) === ".png"
                        ? `https://fullapi.s3.amazonaws.com/${cover_uri}`
                        : "/img/book-cover.png"
                    }
                    className="img-fluid"
                    id="book-cover-image"
                    alt="class-logo"
                  />
                  <div>
                    <label>
                      <span className="upload-btn">UPLOAD</span>
                      <Form.Control
                        type="file"
                        name="file"
                        accept=".png"
                        onChange={handleUploadBookCover}
                        className="book-cover-input" />
                    </label>
                  </div>
                </div>
                
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="section-bottom">
        <div className="tabs">
          <Tabs defaultActiveKey="texts">
            <Tab eventKey="texts" title="Texts">
              <BookTexts bookSha={selectedSha1} />
            </Tab>
            {
              pageType === "notebook" && (
                <Tab eventKey="pages" title="Pages">
                  <BookPages bookSha={selectedSha1} />
                </Tab>
              )
            }
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default BookDetails;
