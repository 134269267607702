import { GET_AWS_CREDENTIALS_SUCCESS } from "./action-types";

const initialValue = {
  awsCredentials: {}
};

const createProfileReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case GET_AWS_CREDENTIALS_SUCCESS:
      return {...state, awsCredentials: payload}
    default:
      return state;
  }
};

export default createProfileReducer;
