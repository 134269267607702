import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import MoreItems from "../../../../components/MoreItems/MoreItems";

import AddTextModal from "./AddTextModal";
import DeleteTextModal from "./DeleteTextModal";

import { resetCommon } from "../../../../common/actions/actions";
import {
  fetchClassTexts,
  fetchTexts,
  addTexts,
  deleteText
} from "../../state/actions";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};


const Texts = ({ classId }) => {
  const dispatch = useDispatch();
  const { classTexts, texts } = useSelector(state => state.classes);

  const [showNewModal, setShowNewModal] = useState(false);
  const [toDeleteRecordId, setToDeleteRecordId] = useState('');

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const renderLink = ({ row }) => {
    const { text_sha1: id } = row;
    return (
      <div className="grid-actions">
        <Link to={`/texts/details/${id}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  }
  
  const columns = [
    { field: 'text_title', headerName: 'Title', width: 400 },
    { field: 'last_modified', headerName: 'Created', width: 250, valueGetter: formatDate },
    { field: 'id', headerName: '.', width: 300, renderCell: renderLink}
  ];

  const composeFilters = (searchTerm = "") => {
    return `class_id=eq.${classId}&text_title=ilike.%25${searchTerm}%25&order=last_modified.desc`;
  };

  useEffect(() => {
    dispatch(fetchClassTexts(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    dispatch(fetchClassTexts(composeFilters(searchTerm)));
  };

  const handleAddTexts = (data) => {
    const onSuccess = () => {
      dispatch(fetchClassTexts(composeFilters()));
    };
    dispatch(addTexts(data, onSuccess));
    setShowNewModal(false);
  };

  const handleDelete = (id) => {
    const onSuccess = () => {
      dispatch(fetchClassTexts(composeFilters()));
    };
    dispatch(deleteText(classId, id, onSuccess));
    setToDeleteRecordId(null);
  };

  return (
    <div className="organization-books">
      <SearchHeader
        count={classTexts.length}
        countTitle="Texts"
        placeHolder="Search texts"
        addComponent={<ActionIcon icon="action-add-folder" label="ADD TEXTS" onClick={() => setShowNewModal(true)} />}
        onSearch={handleSearch}
      />
      <div className="books-results">
        <DataGrid
          className="books-grid"
          autoHeight={true}
          rows={classTexts}
          columns={columns}
          pageSize={10}
        />
      </div>
      <AddTextModal
        classId={classId}
        texts={texts}
        showModal={showNewModal}
        onHide={() => setShowNewModal(false)}
        onFetchTexts={(searchTerm = "") => dispatch(fetchTexts(classId, searchTerm))}
        onAddTexts={handleAddTexts} />
      <DeleteTextModal
        showModal={!!toDeleteRecordId}
        recordId={toDeleteRecordId}
        onHide={() => setToDeleteRecordId(null)}
        onDelete={handleDelete} />
    </div>
  );
};

Texts.propTypes = {
  classId: PropTypes.string
};

export default Texts;