 /**
  * Texts
  */
 
export const FETCH_TEXTS_SUCCESS = "app/texts/FETCH_TEXTS_SUCCESS";
export const FETCH_TEXTS_FAIL = "app/texts/FETCH_TEXTS_FAIL";

export const FETCH_USER_TEXTS_SHA_SUCCESS = "app/texts/FETCH_USER_TEXTS_SHA_SUCCESS";
export const FETCH_USER_TEXTS_SHA_FAIL = "app/texts/FETCH_USER_TEXTS_SHA_FAIL";

export const ADD_TEXTS_SUCCESS = "app/texts/ADD_TEXTS_SUCCESS";
export const ADD_TEXTS_FAIL = "app/texts/ADD_TEXTS_FAIL";

export const FETCH_SELECTED_TEXT_SUCCESS = "app/texts/FETCH_SELECTED_TEXT_SUCCESS";
export const FETCH_SELECTED_TEXT_FAIL = "app/texts/FETCH_SELECTED_TEXT_FAIL";

export const UPDATE_SELECTED_TEXT_SUCCESS = "app/texts/UPDATE_SELECTED_TEXT_SUCCESS";
export const UPDATE_SELECTED_TEXT_FAIL = "app/texts/UPDATE_SELECTED_TEXT_FAIL";

export const DELETE_SELECTED_TEXT_SUCCESS = "app/texts/DELETE_SELECTED_TEXT_SUCCESS";
export const DELETE_SELECTED_TEXT_FAIL = "app/texts/DELETE_SELECTED_TEXT_FAIL";

export const CREATE_TEXT_SUCCESS = "app/texts/CREATE_TEXT_SUCCESS";
export const CREATE_TEXT_FAIL = "app/texts/CREATE_TEXT_FAIL";

export const RESET_SELECTED_TEXT = "app/texts/CREATE_TEXT_SUCCESS";

 /**
 * Books
 */

export const FETCH_BOOKS_SUCCESS = "app/texts/FETCH_BOOKS_SUCCESS";
export const FETCH_BOOKS_FAIL = "app/texts/FETCH_BOOKS_FAIL";

export const FETCH_USER_BOOKS_SHA_SUCCESS = "app/texts/FETCH_USER_BOOKS_SHA_SUCCESS";
export const FETCH_USER_BOOKS_SHA_FAIL = "app/texts/FETCH_USER_BOOKS_SHA_FAIL";

export const ADD_BOOKS_SUCCESS = "app/texts/ADD_BOOKS_SUCCESS";
export const ADD_BOOKS_FAIL = "app/texts/ADD_BOOKS_FAIL";

export const DELETE_BOOK_STATITEMS_SUCCESS = "app/texts/DELETE_BOOK_STATITEMS_SUCCESS";
export const DELETE_BOOK_STATITEMS_FAIL = "app/texts/DELETE_BOOK_STATITEMS_FAIL";

export const FETCH_BOOKS_TO_ADD_SUCCESS = "app/texts/FETCH_BOOKS_TO_ADD_SUCCESS";
export const FETCH_BOOKS_TO_ADD_FAIL = "app/texts/FETCH_BOOKS_TO_ADD_FAIL";

export const FETCH_BOOK_LIST_TO_ADD_SUCCESS = "app/texts/FETCH_BOOK_LIST_TO_ADD_SUCCESS";
export const FETCH_BOOK_LIST_TO_ADD_FAIL = "app/texts/FETCH_BOOK_LIST_TO_ADD_FAIL";

export const FETCH_SELECTED_BOOK_SUCCESS = "app/texts/FETCH_SELECTED_BOOK_SUCCESS";
export const FETCH_SELECTED_BOOK_FAIL = "app/texts/FETCH_SELECTED_BOOK_FAIL";

export const UPDATE_SELECTED_BOOK_SUCCESS = "app/texts/UPDATE_SELECTED_BOOK_SUCCESS";
export const UPDATE_SELECTED_BOOK_FAIL = "app/texts/UPDATE_SELECTED_BOOK_FAIL";

export const FETCH_BOOK_TEXTS_SUCCESS = "app/texts/FETCH_BOOK_TEXTS_SUCCESS";
export const FETCH_BOOK_TEXTS_FAIL = "app/texts/FETCH_BOOK_TEXTS_FAIL";

export const FETCH_BOOK_PAGES_SUCCESS = "app/texts/FETCH_BOOK_PAGES_SUCCESS";
export const FETCH_BOOK_PAGES_FAIL = "app/texts/FETCH_BOOK_PAGES_FAIL";

export const FETCH_PAGE_PREVIEW = "app/texts/FETCH_PAGE_PREVIEW";
export const FETCH_PAGE_PREVIEW_DATA_SUCCESS = "app/texts/FETCH_PAGE_PREVIEW_DATA_SUCCESS";
export const FETCH_PAGE_PREVIEW_DATA_FAIL = "app/texts/FETCH_PAGE_PREVIEW_DATA_FAIL";

export const PUBLISH_TO_NEWSFEED_SUCCESS = "app/texts/PUBLISH_TO_NEWSFEED_SUCCESS";
export const PUBLISH_TO_NEWSFEED_FAIL = "app/texts/PUBLISH_TO_NEWSFEED_FAIL";

export const FETCH_BOOK_TEXTS_SHALIST_SUCCESS = "app/texts/FETCH_BOOK_TEXTS_SHALIST_SUCCESS";

export const FETCH_AVAILABLE_TEXTS_SUCCESS = "app/texts/FETCH_AVAILABLE_TEXTS_SUCCESS";
export const FETCH_AVAILABLE_TEXTS_FAIL = "app/texts/FETCH_AVAILABLE_TEXTS_FAIL";

export const ADD_TEXT_TO_BOOK_SUCCESS = "app/texts/ADD_TEXT_TO_BOOK_SUCCESS";
export const ADD_TEXT_TO_BOOK_FAIL = "app/texts/ADD_TEXT_TO_BOOK_FAIL";

export const UPDATE_BOOK_TEXT_SUCCESS = "app/texts/UPDATE_BOOK_TEXT_SUCCESS";
export const UPDATE_BOOK_TEXT_FAIL = "app/texts/UPDATE_BOOK_TEXT_FAIL";

export const DELETE_BOOK_TEXT_SUCCESS = "app/texts/DELETE_BOOK_TEXT_SUCCESS";
export const DELETE_BOOK_TEXT_FAIL = "app/texts/DELETE_BOOK_TEXT_FAIL";

export const RESET_SELECTED_BOOK = "app/texts/RESET_SELECTED_BOOK";

/**
 * Notebooks
 */

export const FETCH_NOTEBOOKS_SUCCESS = "app/texts/FETCH_NOTEBOOKS_SUCCESS";
export const FETCH_NOTEBOOKS_FAIL = "app/texts/FETCH_NOTEBOOKS_FAIL";

export const ADD_NOTEBOOK_SUCCESS = "app/texts/ADD_NOTEBOOK_SUCCESS";
export const ADD_NOTEBOOK_FAIL = "app/texts/ADD_NOTEBOOK_FAIL";

export const EDIT_NOTEBOOK_SUCCESS = "app/texts/EDIT_NOTEBOOK_SUCCESS";
export const EDIT_NOTEBOOK_FAIL = "app/texts/EDIT_NOTEBOOK_FAIL";

export const DELETE_NOTEBOOK_SUCCESS = "app/texts/DELETE_NOTEBOOK_SUCCESS";
export const DELETE_NOTEBOOK_FAIL = "app/texts/DELETE_NOTEBOOK_FAIL";

export const CREATE_BOOK_SUCCESS = "app/texts/CREATE_BOOK_SUCCESS";
export const CREATE_BOOK_FAIL = "app/texts/CREATE_BOOK_FAIL";

export const UPDATE_NOTEBOOK_SUCCESS = "app/texts/UPDATE_NOTEBOOK_SUCCESS";
export const UPDATE_NOTEBOOK_FAIL = "app/texts/UPDATE_NOTEBOOK_FAIL";

/**
 * All Books
 */

export const FETCH_ALL_BOOKS_SUCCESS = "app/texts/FETCH_ALL_BOOKS_SUCCESS";
export const FETCH_ALL_BOOKS_FAIL = "app/texts/FETCH_ALL_BOOKS_FAIL";

export const DELETE_BOOK_SUCCESS = "app/texts/DELETE_BOOK_SUCCESS";
export const DELETE_BOOK_FAIL = "app/texts/DELETE_BOOK_FAIL";

export const UPDATE_BOOK_SUCCESS = "app/texts/UPDATE_BOOK_SUCCESS";
export const UPDATE_BOOK_FAIL = "app/texts/UPDATE_BOOK_FAIL";

/**
 * All Texts
 */

export const FETCH_ALL_TEXTS_SUCCESS = "app/texts/FETCH_ALL_TEXTS_SUCCESS";
export const FETCH_ALL_TEXTS_FAIL = "app/texts/FETCH_ALL_TEXTS_FAIL";

export const DELETE_TEXT_SUCCESS = "app/texts/DELETE_TEXT_SUCCESS";
export const DELETE_TEXT_FAIL = "app/texts/DELETE_TEXT_FAIL";

export const UPDATE_TEXT_SUCCESS = "app/texts/UPDATE_TEXT_SUCCESS";
export const UPDATE_TEXT_FAIL = "app/texts/UPDATE_TEXT_FAIL";