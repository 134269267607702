import React, { useEffect, useState } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import EditField from "../../../components/Fields/EditField";
import LinkButton from "../../../components/LinkButton/LinkButton";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import DeleteModal from "./DeleteModal";
import ClassResources from "./ClassResources";

import { resetCommon } from "../../../common/actions/actions";

import {
  fetchClass,
  fetchClassRole,
  updateClass,
  deleteClass
} from "../state/actions";

import "./class-details.scss";

const schema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty"),
  max_members: yup.number().typeError('Max members must be a number').required("This field cannot be empty"),
  max_books: yup.number().typeError('Max books must be a number').required("This field cannot be empty"),
  join_token: yup.string().required("This field cannot be empty")
});

const ClassDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classId } = useParams();

  const { nextRoute } = useSelector(state => state.common);
  const { id, role } = useSelector(state => state.auth);
  const { currentClass, classRole } = useSelector(state => state.classes);
  const { name, description, max_members, max_books, join_token } = currentClass;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchClass(classId));
    dispatch(fetchClassRole(id, classId));
  }, [dispatch, classId, id]);

  const defaultValues = {
    name,
    description,
    max_members,
    max_books,
    join_token
  };

  const { register, errors, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass]);


  const handleDeleteClass = () => {
    dispatch(deleteClass(classId));
  };

  const handleSave = (field) => {
    const values = getValues();
    if (values[field]) {
      dispatch(updateClass(classId, { [field]: values[field] }));
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
  }

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  const isEditor = classRole === "teacher" || role === "gk_admin" || role === "support";

  return (
    <div className="class-detail-container">
      <div className="class-detail">
        <div className="header">
          <LinkButton onClick={() => history.goBack()}>
            <img src="/img/back.svg" alt="back button" className="size-24-24" />
          </LinkButton>
          <h3>{name}</h3>
          <div>
            <ActionIcon icon="action-trashcan" label="DELETE" onClick={() => setShowDeleteModal(true)} />
            <DeleteModal showModal={showDeleteModal} onDelete={handleDeleteClass} />
          </div>
        </div>
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Name</Form.Label>
              <Col xs="8">
                <EditField name="name" value={name} canEdit={isEditor} onSave={() => handleSave("name")}>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Class Name"
                    ref={register}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Description</Form.Label>
              <Col xs="8">
                <EditField name="description" canEdit={isEditor} value={description} onSave={() => handleSave("description")}>
                  <Form.Control
                    sm="10"
                    name="description"
                    as="textarea"
                    placeholder="Enter Description"
                    ref={register}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Max Members</Form.Label>
              <Col xs="8">
                <EditField name="max_members" canEdit={isEditor} value={max_members} onSave={() => handleSave("max_members")}>
                  <Form.Control
                    name="max_members"
                    type="number"
                    placeholder="Enter max members"
                    ref={register}
                    isInvalid={!!errors.max_members}
                  />
                  <Form.Control.Feedback type="inValid">{errors.max_members?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Max Books</Form.Label>
              <Col xs="8">
                <EditField name="max_books" canEdit={isEditor} value={max_books} onSave={() => handleSave("max_books")}>
                  <Form.Control
                    name="max_books"
                    type="number"
                    placeholder="Enter max books"
                    ref={register}
                    isInvalid={!!errors.max_books}
                  />
                  <Form.Control.Feedback type="inValid">{errors.max_books?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Join Token</Form.Label>
              <Col xs="8">
                <EditField name="join_token" canEdit={isEditor} value={join_token} onSave={() => handleSave("join_token")}>
                  <Form.Control
                    name="join_token"
                    type="text"
                    placeholder="Enter join token"
                    ref={register}
                    isInvalid={!!errors.join_token}
                  />
                  <Form.Control.Feedback type="inValid">{errors.join_token?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="tabs">
        <ClassResources classId={classId} classRole={classRole} isEditor={isEditor} />
      </div>
    </div>
  );
};

export default ClassDetails;
