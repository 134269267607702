import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";

import PricingCard from "./PricingCard";

import { resetCommon, getLoginStatus } from '../../common/actions/actions';
import {
  getPlans,
  verifyCouponCode,
  redeemCoupon,
  subscribe
} from "./state/actions";

import "./plans.scss";
import { Link } from "react-router-dom";

const Plans = () => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.auth.id)
  const { plans, coupon } = useSelector(state => state.plans);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [selectedSubscription, setSelectSubscription] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  useEffect(() => {
    if (coupon) {
      setShowCouponModal(true);
    }
  }, [coupon]);

  const schema = yup.object().shape({
    coupoun: yup.string().required("Please enter a valid coupon code")
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    dispatch(verifyCouponCode(data));
  };

  const handleClose = () => {
    setShowCouponModal(false);
  };

  const handleRedeemSuccess = () => {
    setSubmitting(false);
    setRedeemSuccess(true);
  };

  const handleRedeemFailure = () => {
    setSubmitting(false);
  };

  const handleRedeem = () => {
    setSubmitting(true);
    dispatch(redeemCoupon({
      coupoun: coupon.coupoun,
      ...(id && { id })
    }, handleRedeemSuccess, handleRedeemFailure));
  };

  const handleSubscribe = (duration, price, index) => {
    const onSuccess = () => {
      dispatch(subscribe({duration, price, index}));
      setSelectSubscription(true);
    };

    const onFail = () => {
      // Redirect to login
      setUserNotLoggedIn(true);
    };

    dispatch(getLoginStatus(onSuccess, onFail));
  };

  if (redeemSuccess) {
    return <Redirect to="/user/home" />;
  }

  if (selectedSubscription) {
    return <Redirect to="/paypal" />;
  }

  if (userNotLoggedIn) {
    return <Redirect to={{
      pathname: "/login",
      state: {
        redirect: "/plans"
      }
    }} />;
  }

  return (
    <div className="pricing-plans">
      <div className="logo">
        <Link to="/user/home">
          <img alt="Fullchinese Logo" src="/img/logo-white.svg" />
        </Link>
      </div>
      <header>
        <h2 className="title">Try FullChinese PRO, free for 14 days</h2>
        <p className="sub-title">Choose a plan, try out 14 days free trial, cancel at anytime.</p>
        <div className="coupon-form">
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Row>
              <Col xs={8}>
                <Form.Control
                  name="coupoun"
                  ref={register}
                  placeholder="Enter coupon code"
                />
                <Form.Control.Feedback type="inValid">{errors.coupoun?.message}</Form.Control.Feedback>
              </Col>
              <Col xs={4}>
                <Button type="submit" className="submit-btn">
                  REDEEM
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </header>
      <div className="plans">
        {(plans || []).map((plan, index) => {
          const { name, bt_subscribe_price, context: { bt_monthly_price, bt_discount }} = plan;
          return (
            <PricingCard
              key={index}
              frequency={name}
              price={bt_subscribe_price}
              monthlyPrice={bt_monthly_price}
              savings={bt_discount}
              trialPeriod={14}
              onSubscribe={() => handleSubscribe(name, bt_subscribe_price, index + 1 )} />
          );
        })}

      </div>
      <div className="inclusions">
        <h3>All Plans Include</h3>
        <ul>
          <li>Full access to dozens of books and texts</li>
          <li>Downloading materials for offline use</li>
          <li>Entire library of Chinese characters, words and sentences</li>
        </ul>
      </div>
      <Modal
        show={showCouponModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Redeem Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="verification-message">This coupon has been successfully verified. Click on confirm to redeem the coupon now.</p>
          <div>
            <div>Coupon: {coupon?.coupoun}</div>
            <div>User Level: {coupon?.user_level}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={submitting} onClick={handleRedeem}>Redeem</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Plans;
