import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";
import uuidv4 from "uuid/v4";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../../components/SearchHeader/SearchBar";

import "./add-book-modal.scss";

const AddBookModal = (props) => {
  const {
    owner,
    showModal,
    books,
    onClose,
    onFetchBooks,
    onAddBooks
  } = props;

  const { numRecords, rows } = books;

  const [selectedBooks, setSelectedBooks] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (showModal) {
      setSelectedBooks([]);
    }
  }, [showModal]);

  const handleSelectBook = (ev, data) => {
    const selected = ev.target.checked;
    const selectedBook = {
      sha1: uuidv4(),
      book_title: data.title,
      book_sha1: data.sha1,
      book_description: data.description,
      author: data.author,
      owner: owner,
      permission: "readonly",
      book_cover_uri: data.cover_uri
    };
    if(selected) {
      setSelectedBooks(selectedBooks.concat([selectedBook]));
    } else {
      setSelectedBooks(selectedBooks.filter(book => book.book_sha1 !== data.sha1));
    }
  };

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        onClick={(ev) => handleSelectBook(ev, row)}
      />
    );
  };
  const columns = [
    { field: 'title', headerName: 'Title', width: 400 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'author', headerName: 'Author', width: 120 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'id', headerName: selectedBooks.length, width: 50, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    onFetchBooks({ pageNum: 0, pageSize: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchBooks({pageNum: 0, pageSize}, searchTerm);
  };

  const handlePageChange = ({ page, pageSize }) => {
    onFetchBooks({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchBooks({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSubmit = () => {
    if (typeof onAddBooks === "function") {
      onAddBooks(selectedBooks, () => {
        setSelectedBooks([]);
        onClose();
      });
    }
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={onClose} className="texts-add-book">
        <Modal.Header closeButton>
          <Modal.Title>Add Books to Your Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="book-search">
            <SearchBar placeholder="Search: Book with Title" onSearch={handleSearch} />
          </div>
          <div className="records-info">
            <span>{numRecords && `${numRecords} Books`}</span>
            <span>{`${selectedBooks.length} books selected`}</span>
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={rows}
              columns={columns}
              pageSize={10}
              disableVirtualization={true}
              hideFooterSelectedRowCount={true}
              rowsPerPageOptions={[10, 20, 50]}
              rowCount={numRecords}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AddBookModal.propTypes = {
  owner: PropTypes.number,
  showModal: PropTypes.bool,
  books: PropTypes.object,
  onClose: PropTypes.func,
  onFetchBooks: PropTypes.func,
  onAddBooks: PropTypes.func
};

export default AddBookModal;
