import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionModal from "../../../components/ActionModal/ActionModal";
import MoreItems from "../../../components/MoreItems/MoreItems";
import LinkButton from "../../../components/LinkButton/LinkButton";

import "./Books.scss";


const Books = ({ books, onSearch, onDelete }) => {
  const [recordIdToDelete, setToDeleteRecordId] = useState(false);

  const handleDelete = () => {
    if (recordIdToDelete) {
      onDelete(recordIdToDelete, () => setToDeleteRecordId(null));
    }
  };

  const formatDate = ({ row }) => {
    const { last_modified } = row;
    return dayjs(last_modified).format('DD/MM/YYYY');
  };
  
  const renderBookTitle = ({row}) => {
    const { book_title, book_cover_uri } = row;
    const imgLink = book_cover_uri ? `https://fullapi.s3.amazonaws.com/${row.book_cover_uri}` : "/img/book-cover.png";
  
    const addDefaultSrc = (ev) => { ev.target.src = "/img/book-cover.png" };
    return (
      <span>
        <img src={imgLink} className="book-title-img" alt="book cover" onError={addDefaultSrc} /> {book_title}
      </span>
    );
  };

  const renderLink = ({ row }) => {
    const { book_sha1: id } = row;
    return (
      <div className="grid-actions">
        <Link to={`/books/details/${id}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'book_title', headerName: 'Title', width: 400, renderCell: renderBookTitle },
    { field: 'book_description', headerName: 'Description', width: 500 },
    { field: 'author', headerName: 'Author', width: 250 },
    { field: 'last_modified', headerName: 'Modified', width: 200, valueGetter: formatDate },
    { field: 'id', headerName: '.', width: 300, renderCell: renderLink}
  ];

  return (
    <div className="member-books">
      <SearchHeader
        count={books.length}
        countTitle="Books"
        placeHolder="Search books"
        onSearch={onSearch}
      />
      <div className="books-results">
        <DataGrid
          className="books-grid"
          autoHeight={true}
          rows={books}
          columns={columns}
          pageSize={10}
          getRowId={({ sha1 }) => sha1}
         />
      </div>
      <ActionModal
        showModal={!!recordIdToDelete}
        title="Delete Book"
        actionText="Delete"
        onClose={() => setToDeleteRecordId(null)}
        onAction={handleDelete}>
          Do you want to delete this book?
      </ActionModal>
    </div>
  );
};

Books.propTypes = {
  books: PropTypes.array,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func
};

export default Books;
