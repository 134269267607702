import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import MoreItems from "../../../../components/MoreItems/MoreItems";

import AddBookModal from "./AddBookModal";
import DeleteBookModal from "./DeleteBookModal";

import { resetCommon } from "../../../../common/actions/actions";
import {
  fetchOrgBooks,
  fetchBooks,
  addBooks,
  deleteBook
} from "../../state/actions";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};


const Books = ({ orgId }) => {
  const dispatch = useDispatch();
  const { orgBooks, books } = useSelector(state => state.organizations);

  const [showNewModal, setShowNewModal] = useState(false);
  const [toDeleteRecordId, setToDeleteRecordId] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const renderLink = ({ row }) => {
    const { book_sha1: id } = row;
    return (
      <div className="grid-actions">
        <Link to={`/books/details/${id}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  }
  
  const columns = [
    { field: 'book_title', headerName: 'Title', width: 400 },
    { field: 'book_description', headerName: 'Description', width: 500 },
    { field: 'book_level', headerName: 'Level', width: 250 },
    { field: 'publish_time', headerName: 'Created', width: 200, valueGetter: formatDate },
    { field: 'id', headerName: '.', width: 300, renderCell: renderLink}
  ];

  const composeFilters = (searchTerm = "") => {
    return `org_id=eq.${orgId}&book_title=ilike.%25${searchTerm}%25&order=publish_time.desc`;
  };

  useEffect(() => {
    dispatch(fetchOrgBooks(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    dispatch(fetchOrgBooks(composeFilters(searchTerm)));
  };

  const handleAddBooks = (data) => {
    const onSuccess = () => {
      dispatch(fetchOrgBooks(composeFilters()));
    };
    dispatch(addBooks(data, onSuccess));
    setShowNewModal(false);
  };

  const handleDelete = (id) => {
    const onSuccess = () => {
      dispatch(fetchOrgBooks(composeFilters()));
    };
    dispatch(deleteBook(orgId, id, onSuccess));
    setToDeleteRecordId(null);
  };

  return (
    <div className="organization-books">
      <SearchHeader
        count={orgBooks.length}
        countTitle="Books"
        placeHolder="Search books"
        addComponent={<ActionIcon icon="action-add-folder" label="ADD BOOKS" onClick={() => setShowNewModal(true)} />}
        onSearch={handleSearch}
      />
      <div className="books-results">
        <DataGrid
          className="books-grid"
          autoHeight={true}
          rows={orgBooks}
          columns={columns}
          pageSize={10}
         />
      </div>
      <AddBookModal
        orgId={orgId}
        books={books}
        showModal={showNewModal}
        onHide={() => setShowNewModal(false)}
        onFetchBooks={(searchTerm = "") => dispatch(fetchBooks(orgId, searchTerm))}
        onAddBooks={handleAddBooks} />
      <DeleteBookModal
        showModal={!!toDeleteRecordId}
        recordId={toDeleteRecordId}
        onHide={() => setToDeleteRecordId(null)}
        onDelete={handleDelete} />
    </div>
  );
};

Books.propTypes = {
  orgId: PropTypes.string
};

export default Books;