export const FETCH_NEWSFEED_SUCCESS = "app/newsfeed/FETCH_NEWSFEED_SUCCESS";
export const FETCH_NEWSFEED_FAIL = "app/newsfeed/FETCH_NEWSFEED_FAIL";

export const RESET_PREVIEW_DATA = "app/newsfeed/RESET_PREVIEW_DATA";
export const FETCH_NEWSFEED_ITEM_SUCCESS = "app/newsfeed/FETCH_NEWSFEED_ITEM_SUCCESS";
export const FETCH_NEWSFEED_ITEM_FAIL = "app/newsfeed/FETCH_NEWSFEED_ITEM_FAIL";

export const REMOVE_FROM_NEWSFEED_SUCCESS = "app/newsfeed/REMOVE_FROM_NEWSFEED_SUCCESS";
export const REMOVE_FROM_NEWSFEED_FAIL = "app/newsfeed/REMOVE_FROM_NEWSFEED_FAIL";

export const UPDATE_NEWSFEED_SUCCESS = "app/newsfeed/UPDATE_NEWSFEED_SUCCESS";
export const UPDATE_NEWSFEED_FAIL = "app/newsfeed/UPDATE_NEWSFEED_FAIL";
