import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import EventsList from "./components/EventsList";

import { resetCommon } from "../../common/actions/actions";
import { fetchEvents } from "./state/actions";

const Events = () => {
  const dispatch = useDispatch();
  const { events } = useSelector(state => state.events);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEvents({pageNum: 0, pageSize: 10}));
  }, [dispatch]);

  const handleFetchEvents = (pageData, searchTerm) => {
    dispatch(fetchEvents(pageData));
  };

  return (
    <div>
      <EventsList data={events} onFetchEvents={handleFetchEvents} />
    </div>
  );
};

export default Events;
