import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import "./Field.scss";

const Field = ({ value, showIcon, children, onSave, hasError }) => {
  const [ isEditing, setIsEditing ] = useState(false);

  const handleClick = () => {
    if (isEditing && typeof onSave === "function") {
      onSave(value);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="profile-field">
      <div className="profile-input-field">
        {!isEditing ? (
          <span>
            {
              showIcon && <img
                alt={value}
                src={`/img/${value}.png`}
                className="size-24-24"
              />
            } {value}
          </span>) : children
        }
      </div>
      <Button disabled={hasError} variant="link" className="edit-save">
        <img
          alt={isEditing ? "edit": "save"}
          src={!isEditing ? "/img/action-edit.svg" : "/img/action-save.png"}
          className="size-24-24"
          onClick={handleClick} />
      </Button>
    </div>
  );
};

Field.propTypes = {
  value: PropTypes.string,
  showIcon: PropTypes.bool,
  children: PropTypes.object,
  onSave: PropTypes.func
};

export default Field;
