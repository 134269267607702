import React, { useEffect, useState } from "react";
import { Link, Redirect, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import UserInfo from "./components/UserInfo";
import Payments from "./components/Payments";
import VerticalNav from "./components/VerticalNav";
import AccountSecurity from "./components/AccountSecurity";
import BillingAndPlans from "./components/BillingAndPlans";

import { resetCommon } from "../../common/actions/actions";
import {
  getProfile,
  getSubscription,
  getPayments,
  updateProfile,
  deleteAccount,
  changeAvatar,
  unSubscribe
} from "./state/actions";

import "./Profile.scss";

const Profile = () => {
  const dispatch = useDispatch();
  const { id, username, role, email } = useSelector(state =>  state.auth);
  const { nextRoute } = useSelector(state => state.common);
  const { userInfo, payments, subscription } = useSelector(state => state.profile);
  const { plan } = useSelector(state => state.subscription);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getSubscription(id));
    dispatch(getPayments(id));
  }, [dispatch, id]);

  const [avatarSrc, setAvatarSrc] = useState("/img/newavatar.png");

  const handleImgError = (ev) => {
    ev.target.src = "/img/undefined.png";
  };

  const handleChangeAvatar = (ev) => {
    const file = ev.target.files[0];
    setAvatarSrc(URL.createObjectURL(file));
    dispatch(changeAvatar({ file, userInfo, id }));
  };

  const handleUnsubscribe = () => {
    const paymentMode = subscription.payment;

    if (paymentMode === "apple") {
      window.open("https://apps.apple.com/account/billing");
    } else if (paymentMode === "google") {
      const googlePackage = subscription?.context?.package_name || "com.fullchinesePro";
      const googlePlanId = subscription?.googlePlanId;
      const url =
        `https://play.google.com/store/account/subscriptions?sku=${googlePlanId}&package=${googlePackage}`;
      window.open(url);
    } else {
      dispatch(unSubscribe({
        sub_id: subscription.plan_id
      }, () => {
        dispatch(getSubscription(id));
      }));
    }
  };

  const handleSave = (value) => {
    dispatch(updateProfile(id, value));
  };

  const handleDeleteAccount = () => {
    dispatch(deleteAccount(email));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="settings">
      <div className="settings-left">
        <div className="header">
          <Link to="/user/home" className="back-btn">
            <img
              src="/img/back.svg"
              alt="back"
              className="size-24-24"
            />
          </Link>
          <img src={avatarSrc} className="avatar" alt="avatar" />
          <div>
            <h3 className="user-name">{username}</h3>
            <p>Your Personal Account</p>
          </div>
        </div>
        <VerticalNav />
      </div>
      <div className="settings-right">
        <Switch>
          <Route exact path="/settings">
            <div className="user-details">
              <UserInfo
                id={id}
                role={role}
                email={email}
                userName={username}
                user={userInfo}
                onImgError={handleImgError}
                onSave={handleSave}
                onDeleteAccount={handleDeleteAccount} />
            </div>
          </Route>
          <Route exact path="/settings/security">
            <AccountSecurity />
          </Route>
          <Route path="/settings/billing">
            <BillingAndPlans
              plan={plan}
              subscription={subscription}
              payments={payments}
              onUnSubscribe={handleUnsubscribe}
            />
          </Route>
          <Route path="/settings/payments">
            <Payments data={payments} onSearch={(queryString) => dispatch(getPayments(id, queryString))} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Profile;