export const formatUser = (data) => {
  console.log(data);
  const { me, token } = data;
  const { id, name, email, email_verified, role } = me;

  return {
    id,
    username: name,
    email,
    role,
    emailVerified: email_verified,
    token
  }
};