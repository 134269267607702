import React, { useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import MoreItems from "../../../components/MoreItems/MoreItems";
import LinkButton from "../../../components/LinkButton/LinkButton";
import ActionModal from "../../../components/ActionModal/ActionModal";

import NewPlanModal from "./NewPlanModal";
import EditPlanModal from "./EditPlanModal";


const PlanManagement = ({ data, handlers }) => {
  const { onSearch, onAddPlan, onUpdatePlan, onDeletePlan } = handlers;

  const [showNewPlanModal, setShowNewPlanModal] = useState(false);
  const [planToEdit, setPlanToEdit] = useState(null);
  const [planToDelete, setPlanToDelete] = useState(null);
  
  const renderLink = ({ row }) => {
    const { id } = row;

    return (
      <div className="grid-actions">
        <MoreItems>
          <div>
            <LinkButton onClick={() => setPlanToEdit(row)}>
              Edit
            </LinkButton>
          </div>
          <div>
            <LinkButton onClick={() => setPlanToDelete(id)}>Delete</LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'trial_days', headerName: 'Trial Days', width: 150 },
    { field: 'period', headerName: 'Period', width: 150 },
    { field: 'apple_subscribe_price', headerName: 'Apple Price', width: 150 },
    { field: 'google_subscribe_price', headerName: 'Google Price', width: 150 },
    { field: 'bt_subscribe_price', headerName: 'Braintree Price', width: 150 },
    { field: 'apple_plan_id', headerName: 'Apple Plan Id', width: 150 },
    { field: 'google_plan_id', headerName: 'Google Plan Id', width: 150 },
    { field: 'bt_plan_id', headerName: 'Braintree Plan Id', width: 150 },
    { field: 'action', headerName: '.', width: 300, renderCell: renderLink}
  ];

  const handleDeletePlan = () => {
    if (typeof onDeleteCoupon === "function") {
      onDeletePlan(planToDelete, () => {
        setPlanToDelete(null);
      });
    }
  }

  return (
    <div>
      <SearchHeader
        count={data.length}
        countTitle="Coupons"
        placeHolder="Search coupon here"
        addComponent={<ActionIcon icon="action-add-folder" label="NEW COUPON" onClick={() => setShowNewPlanModal(true)} />}
        onSearch={onSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={data}
          columns={columns}
          pageSize={10}
        />
      </div>
      <NewPlanModal
        showModal={showNewPlanModal}
        onClose={() => setShowNewPlanModal(false)}
        onAddPlan={onAddPlan} />
      <EditPlanModal
        showModal={!!planToEdit}
        defaultValues={planToEdit || {}}
        onClose={() => setPlanToEdit(null)}
        onUpdatePlan={onUpdatePlan} />
      <ActionModal
        showModal={!!planToDelete}
        title="Delete Plan"
        actionText="Delete"
        onClose={() => setPlanToDelete(null)}
        onAction={handleDeletePlan}>
          Do you want to delete this plan?
      </ActionModal>
    </div>
  );
};

PlanManagement.propTypes = {
  data: PropTypes.array,
  handlers: PropTypes.shape({
    onSearch: PropTypes.func,
    onAddPlan: PropTypes.func,
    onUpdatePlan: PropTypes.func,
    onDeletePlan: PropTypes.func
  })
};

export default PlanManagement;

