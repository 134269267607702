import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PropTypes from "prop-types";

import Members from "./Members/Members";
import Books from "./Books/Books";
import Texts from "./Texts/Texts";

const ClassResources = ({ classId, classRole, isEditor }) => {
  return (
    <Tabs defaultActiveKey="members">
      {
        isEditor && (
          <Tab eventKey="members" title="Members">
            <Members classId={classId} />
          </Tab>
        )
      }
      {
        classRole && (
          <Tab eventKey="books" title="Books">
            <Books classId={classId} classRole={classRole} />
          </Tab>
        )
      }
      {
        classRole && (
          <Tab eventKey="texts" title="Texts">
            <Texts classId={classId} classRole={classRole} />
          </Tab>
        )
      }
    </Tabs>
  );
};

ClassResources.propTypes = {
  classId: PropTypes.string,
  classRole: PropTypes.string,
  isEditor: PropTypes.bool
};

export default ClassResources;
