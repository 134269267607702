import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import VideoTranscripts from "./components/VideoTranscripts";

import { resetCommon } from "../../common/actions/actions";
import { fetchVideoTranscript } from "./state/actions";

import "./transcripts.scss";

const Videos = () => {
  const dispatch = useDispatch();
  const { data: { transcript } } = useSelector(state => state.ytTranscripts);

  const [videoId, setVideoId] = useState("");
  const [submitting, setSubmitting] = useState("");

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const handleFetchVideoTranscripts = () => {
    if (videoId) {
      setSubmitting(true);
      dispatch(fetchVideoTranscript(videoId, () => setSubmitting(false)));
    }
  };

  return (
    <div className="youtube-transcripts">
      <div className="search-form">
        <Form.Control
          placeholder="Video Id"
          onChange={(ev) => setVideoId(ev.target.value)}
        />
        <Button
          type="submit"
          disabled={submitting}
          onClick={handleFetchVideoTranscripts}>
            Get Video Transcript
        </Button>
      </div>
      <VideoTranscripts data={transcript} />
    </div>
  );
};

export default Videos;
