import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import ActionModal from "../../../components/ActionModal/ActionModal";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import LinkButton from "../../../components/LinkButton/LinkButton";
import MoreItems from "../../../components/MoreItems/MoreItems";

import TimestampEditModal from "./TimestampEditModal";
import PageDetailModal from "./PageDetailModal";

const Pages = (props) => {
  const {
    data,
    selectedPage,
    onFetchNewsFeed,
    onUpdateNewsfeed,
    onRemove,
    onPagePreview
  } = props;
  const dispatch = useDispatch();

  const { numRecords, rows } = data;

  const [searchTerm, setSearchTerm] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToShow, setItemToShow] = useState(null);
  const [timestampToEdit, setTimestampToEdit] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    dispatch(onFetchNewsFeed({ pageNum: page, pageSize }, searchTerm || ""));
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    dispatch(onFetchNewsFeed({ pageNum: 0, pageSize }, searchTerm || ""));
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    dispatch(onFetchNewsFeed({ pageNum: 0, pageSize }, searchTerm || ""));
  };

  const handleTimestampChange = (values, onSuccess, onFail) => {
    const { id, ...payload } = values;
    if (typeof onUpdateNewsfeed === "function") {
      onUpdateNewsfeed(id, payload, onSuccess, onFail);
    }
  };

  const handleRemoveNewsfeed = () => {
    if (typeof onRemove === "function") {
      onRemove(itemToDelete, () => {
        setItemToDelete(null);
      });
    }
  };

  const renderThumbnailStatus = ({ row }) => {
    const { thumbnail } = row;
    return thumbnail ? "Yes" : "No";
  };

  const renderLink = ({ row }) => {
    const { id, page_sha1, timeline } = row;

    const handleShowPagePreview = () => {
      setItemToShow(page_sha1);
      if (typeof onPagePreview === "function") {
        onPagePreview(page_sha1);
      }
    };
  
    return (
      <div className="grid-actions">
        <LinkButton onClick={handleShowPagePreview}>
          Details
        </LinkButton>
        <MoreItems>
          <div>
            <LinkButton onClick={() => setItemToDelete(id)}>
              Remove
            </LinkButton>
          </div>
          <div>
            <LinkButton onClick={() => setTimestampToEdit({ id, timeline })}>
              Change Timestamp
            </LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 400 },
    { field: 'thumbnail', headerName: 'Thumbnail', width: 140, renderCell: renderThumbnailStatus },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'timeline', headerName: 'Timestamp', width: 250 },
    { field: 'actions', headerName: '.', width: 200, renderCell: renderLink}
  ];

  return (
    <div className="book-texts-section">
      <SearchHeader
        count={numRecords}
        countTitle="Pages"
        placeHolder="Search pages here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.page_sha1}
        />
      </div>
      <ActionModal
        showModal={!!itemToDelete}
        title="Remove Page"
        actionText="Remove"
        onClose={() => setItemToDelete(null)}
        onAction={handleRemoveNewsfeed}>
          Do you want to remove this newsfeed item?
      </ActionModal>
      <TimestampEditModal
        showModal={!!timestampToEdit}
        values={timestampToEdit}
        onClose={() => setTimestampToEdit(null)}
        onTimestampChange={handleTimestampChange}
     />
     <PageDetailModal
        sha1={itemToShow}
        showModal={!!itemToShow}
        data={selectedPage}
        onClose={() => setItemToShow(null)}
      />
    </div>
  );
};

Pages.propTypes = {
  data: PropTypes.object,
  selectedPage: PropTypes.object,
  onFetchNewsFeed: PropTypes.func,
  onRemove: PropTypes.func,
  onPagePreview: PropTypes.func
};

export default Pages;
