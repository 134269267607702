import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import UserType from "./UserType";
import ConfirmDevice from "./ConfirmDevice";
import BasicChinese from "./BasicChinese";

import "./conditions.scss";

const Conditions = ({ onConditionsComplete }) => {
  const steps = {
    UserType: UserType,
    ConfirmDevice: ConfirmDevice,
    BasicChinese: BasicChinese
  };
  const [nextComponent, setNextComponent] = useState("UserType");
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatus, setStepStatus] = useState(false);
  const [values, setValues] = useState([]);
  const [userType, setUserType] = useState(null);

  useEffect(() => {

    if (stepStatus && typeof onConditionsComplete === "function") {
      onConditionsComplete(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepStatus]);

  const handleStepComplete = (value) => {
    const vals = Array.from(values);
    vals.push(value);
    setValues(vals);

    if (currentStep === 0) {
      const role = value.role;
      setUserType(role);

      setNextComponent("ConfirmDevice");
      setCurrentStep(currentStep + 1);
    }

    if (currentStep === 1) {
      if (userType === "student") {
        setNextComponent("BasicChinese");
        setCurrentStep(currentStep + 1);
      }

      if (userType === "teacher") {
        setStepStatus(true);
      }
    }

    if (currentStep === 2) {
      if (userType === "student") {
        setStepStatus(true);
      }
    }
  };

  const CurrentStep = steps[nextComponent];

  return <CurrentStep onStepComplete={handleStepComplete} />
};

Conditions.propTypes = {
  onConditionsComplete: PropTypes.func
};

export default Conditions;