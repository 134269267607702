import React, { useState } from "react";
import PropTypes from "prop-types";

import { DataGrid } from "@material-ui/data-grid";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";

const renderURL = ({ row }) => {
  const { url } = row;

  return url ? (
    <a href={url} target="_blank">Link</a>
  ): (
    <span>N/A</span>
  );
};


const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'url', headerName: 'URL', width: 270, renderCell: renderURL },
  { field: 'time_posted', headerName: 'Time Posted', width: 230 }
];

const UserPosts = ({ data, onFetchUserPosts }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchUserPosts({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchUserPosts({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchUserPosts({ pageNum: 0, pageSize }, searchTerm);
  };

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="User Posts"
        placeHolder="Search user posts here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.id}
        />
      </div>
    </div>
  );
};

UserPosts.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchUserPosts: PropTypes.func
};

export default UserPosts;
