import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CancelSubscriptionModal = ({ onUnSubscribe }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleUnsubscribe = () => {
    handleClose();
    onUnSubscribe();
  };


  return (
    <div>
      <div>
        <Link onClick={() => setShow(true)}>
          Cancel Subscription
        </Link>
      </div>
      
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Subscription Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to unsubscribe?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUnsubscribe}>
            Unsubscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

CancelSubscriptionModal.propTypes = {
  status: PropTypes.string,
  onUnSubscribe: PropTypes.func
};

export default CancelSubscriptionModal;
