import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import LinkButton from "../../../components/LinkButton/LinkButton";
import MoreItems from "../../../components/MoreItems/MoreItems";

import AddTextModal from "./AddTextModal";
import DeleteTextModal from "./DeleteTextModal";

import "./texts.scss";

const getTitle = ({ row }) => {
  const { text: { title } } = row;
  return title;
};

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const getModifiedDate = ({ row }) => {
  const { text: { last_modified }} = row;
  return last_modified ? formatDate(last_modified) : null;
};

const renderSound = ({ row }) => {
  const { numSounds } = row;
  const iconToDisplay = numSounds > 0 ? "/img/status-sound.svg" : "/img/status-sound-no.svg";
  return (
    <span>
      <img src={iconToDisplay} className="size-24-24" alt="sound"/> {numSounds > 0 ? numSounds : "No sound"}
    </span>
  );
};

const Texts = (props) => {
  const { data, allTexts, onFetchTexts, onFetchAllTexts, onAddTexts, onDeleteText } = props;
  const { numRecords, rows } = data;
  const [pageSize, setPageSize] = useState(10);
  const [showNewModal, setShowNewModal] = useState(false);
  const [toDeleteRecordId, setToDeleteRecordId] = useState('');

  const renderLink = ({ row }) => {
    const { id, text_sha1 } = row;
  
    return (
      <div className="grid-actions">
        <Link to={`/texts/details/${text_sha1}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'text.title', headerName: 'Title', width: 400, valueGetter: getTitle },
    { field: 'text.last_modified', headerName: 'Modified', width: 150, valueGetter: getModifiedDate },
    { field: 'numSounds', headerName: 'Sound', width: 180, renderCell: renderSound },
    { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
  ];

  const handlePageChange = ({ page, pageSize }) => {
    onFetchTexts({ pageNum: page, pageSize });
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchTexts({ pageNum: 0, pageSize });
  };

  const handleAddTexts = (payload) => {
    if (typeof onAddTexts === "function") {
      onAddTexts(payload, () => setShowNewModal(false))
    }
  };
  const handleDelete = (payload) => {
    if (typeof onDeleteText === "function") {
      onDeleteText(payload, () => setToDeleteRecordId(null));
    }
  };

  const renderActions = () => {
    return (
      <div className="text-actions">
        <ActionIcon icon="action-add" label="New Text" to="/texts/new" />
        <ActionIcon icon="action-import" label="Add Texts" onClick={() => setShowNewModal(true)} />
      </div>
    );
  };

  return (
    <div className="device-config__texts">
      <SearchHeader
        count={numRecords}
        countTitle="Texts"
        placeHolder="Search texts here"
        addComponent={renderActions()}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.id}
        />
      </div>
      <AddTextModal
        texts={allTexts}
        showModal={showNewModal}
        onHide={() => setShowNewModal(false)}
        onFetchTexts={onFetchAllTexts}
        onAddTexts={handleAddTexts} />
      <DeleteTextModal
        showModal={!!toDeleteRecordId}
        recordId={toDeleteRecordId}
        onHide={() => setToDeleteRecordId(null)}
        onDelete={handleDelete} />
    </div>
  );
};

Texts.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  allTexts: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchTexts: PropTypes.func,
  onAddTexts: PropTypes.func,
  onDeleteText: PropTypes.func
};

export default Texts;
