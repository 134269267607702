import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import MoreItems from "../../../../components/MoreItems/MoreItems";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionModal from "../../../../components/ActionModal/ActionModal";

import EditTextModal from "./EditTextModal";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};

const renderSound = ({ row }) => {
  const { json_text: { sounds } } = row;
  const numSounds = Array.isArray(sounds) ? sounds.length : 0;
  const iconToDisplay = numSounds > 0 ? "/img/status-sound.svg" : "/img/status-sound-no.svg";
  return (
    <span>
      <img src={iconToDisplay} className="size-24-24" alt="sound"/> {numSounds > 0 ? numSounds : "No sound"}
    </span>
  );
};

const AllTexts = ({ data, onFetchAllTexts, onDeleteText, onUpdateText }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [textToDelete, setTextToDelete] = useState(null);
  const [textToEdit, setTextToEdit] = useState(null);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchAllTexts({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchAllTexts({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchAllTexts({ pageNum: 0, pageSize }, searchTerm);
  };

  const handleDeleteText = () => {
    if (typeof onDeleteText === "function") {
      onDeleteText(textToDelete, () => {
        setTextToDelete(null);
      });
    }
  };

  const renderLink = ({ row }) => {
    const { sha1, level, plan } = row;
  
    return (
      <div className="grid-actions">
        <Link to={`/texts/details/${sha1}`}>Details</Link>
        <MoreItems>
        <div>
            <LinkButton onClick={
              () => setTextToEdit({
                sha1,
                level,
                plan
              })}>
              Edit
            </LinkButton>
          </div>
          <div>
            <LinkButton onClick={() => setTextToDelete(sha1)}>Delete</LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'title', headerName: 'Title', width: 400 },
    { field: 'numSounds', headerName: 'Sound', width: 180, renderCell: renderSound },
    { field: 'author', headerName: 'Author', width: 200 },
    { field: 'plan', headerName: 'Plan', width: 150 },
    { field: 'level', headerName: 'Level', width: 150 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Texts"
        placeHolder="Search texts here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
      <ActionModal
        showModal={!!textToDelete}
        title="Delete Book"
        actionText="Delete"
        onClose={() => setTextToDelete(null)}
        onAction={handleDeleteText}>
          Do you want to delete this text?
      </ActionModal>
      <EditTextModal
        show={!!textToEdit}
        value={textToEdit}
        onUpdateText={onUpdateText}
        onClose={() => setTextToEdit(null)}
      />
    </div>
  );
};

AllTexts.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchAllTexts: PropTypes.func,
  onDeleteText: PropTypes.func
};

export default AllTexts;
