import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const ConfirmDevice = ({ onStepComplete }) => {
  const [value, setValue] = useState(false);

  const handleChange = (ev) => {
    setValue({
      hasDevice: ev.target.checked
    });
  };

  const handleContinue = () => {
    onStepComplete(value);
  };


  return (
    <div className="condition-container">
      <div className="condition-content">
        <h3>
          Install our app
        </h3>
        <p className="sub-text">You need an iOS device (iPhone or iPad) or an Android or Android Tablet to use FullChinese Pro</p>
        <div className="device-img">
          <img src="/img/devices.svg" alt="devices" />
        </div>
        <div className="condition-checkbox">
          <Form.Check
            onChange={handleChange}
            type="checkbox"
            label="I have an iPhone, iPad, Android phone or Android tablet"
            id="device-status"
          />
        </div>
      </div>
      <div className="condition-actions">
        <Button type="submit" variant="primary" disabled={!value.hasDevice} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </div>
  );
};

ConfirmDevice.propTypes = {
  onStepComplete: PropTypes.func
};

export default ConfirmDevice;