import * as Actions from "./action-types";

const initialState = {
  collections: [],
  books: [],
  availableBooks: {
    numRecords: 0,
    rows: []
  }
};

const mergeBooks = (state, payload) => {
  const { index, data } = payload;
  const books = [].concat(state.books);
  
  books[index] = data;

  return {
    ...state,
    books
  };
};

const storeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.FETCH_COLLECTIONS_SUCCESS: {
      return {...state, collections: payload};
    }
    case Actions.FETCH_COLLECTION_BOOKS_SUCCESS: {
      return mergeBooks(state, payload);
    }
    case Actions.FETCH_BOOKS_SUCCESS: {
      return {...state, availableBooks: payload };
    }
    default:
      return state;
  }
};

export default storeReducer;
