import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../components/SearchHeader/SearchBar";

import "./add-book-modal.scss";

const AddBookModal = (props) => {
  const {
    collection,
    showModal,
    books,
    onClose,
    onFetchBooks,
    onAddBooks
  } = props;

  const { numRecords, rows } = books;

  const [selectedBooks, setSelectedBooks] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (showModal) {
      setSelectedBooks([]);
    }
  }, [showModal]);

  const handleSelectBook = (ev, data) => {
    const selected = ev.target.checked;
    const selectedBook = {
      collection_id: collection.id,
      collection_title: collection.title,
      idx: Math.floor(Math.random() * 2000 + 1),
      book_title: data.title,
      book_sha1: data.sha1,
      book_description: data.description,
      book_level: data.level,
      author: data.author,
      book_cover_uri: data.cover_uri,
      price: data.price,
      plan: data.plan
    };
    if(selected) {
      setSelectedBooks(selectedBooks.concat([selectedBook]));
    } else {
      setSelectedBooks(selectedBooks.filter(book => book.book_sha1 !== data.sha1));
    }
  };

  const renderPrice = ({ row }) => {
    const { price } = row;
    return (
      <span>{typeof price === 'number' ? `$${price}` : '$0'}</span>
    );
  };

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        onClick={(ev) => handleSelectBook(ev, row)}
      />
    );
  };

  const planOptions = ['free', 'silver', 'gold', 'diamond'];
  const columns = [
    { field: 'title', headerName: 'Title', width: 120 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'author', headerName: 'Author', width: 100 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'price', headerName: 'Price', width: 100, editable: true, type: 'number', renderCell: renderPrice  },
    { field: 'plan', headerName: 'Plan', width: 100, editable: true, type: 'singleSelect', valueOptions: planOptions },
    { field: 'id', headerName: selectedBooks.length, width: 50, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    if (showModal === true) {
      onFetchBooks({ pageNum: 0, pageSize: 10 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchBooks({pageNum: 0, pageSize}, searchTerm);
  };

  const handlePageChange = ({ page, pageSize }) => {
    onFetchBooks({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchBooks({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSubmit = () => {
    if (typeof onAddBooks === "function") {
      setSubmitting(true);
      onAddBooks(collection, selectedBooks, () => {
        setSubmitting(false);
        setSelectedBooks([]);
        onClose();
      }, () => {
        setSubmitting(false);
      });
    }
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={onClose} className="store-add-book">
        <Modal.Header closeButton>
          <Modal.Title>Add Books to Your Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="book-search">
            <SearchBar placeholder="Search: Book with Title" onSearch={handleSearch} />
          </div>
          <div className="records-info">
            <span>{numRecords && `${numRecords} Books`}</span>
            <span>{`${selectedBooks.length} books selected`}</span>
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={rows}
              columns={columns}
              pageSize={10}
              disableVirtualization={true}
              hideFooterSelectedRowCount={true}
              rowsPerPageOptions={[10, 20, 50]}
              rowCount={numRecords}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" disabled={submitting} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting} onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AddBookModal.propTypes = {
  owner: PropTypes.number,
  showModal: PropTypes.bool,
  books: PropTypes.object,
  onClose: PropTypes.func,
  onFetchBooks: PropTypes.func,
  onAddBooks: PropTypes.func
};

export default AddBookModal;
