import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { setNextRoute, resetCommon } from "../../common/actions/actions";
import { verifyEmail } from "./state/actions";

import "./verify-email.scss";

const VALIDATION_STATE = {
  VALIDATING: 'VALIDATING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const { nextRoute } = useSelector(state => state.common);

  const [validationState, setValidationState] = useState(VALIDATION_STATE.VALIDATING);

  const params = new URLSearchParams(window.location.search);
  const active_token = params.get("token");
  const email = decodeURIComponent(params.get("email"));

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);
  
  useEffect(() => {
    if (active_token && email) {
      dispatch(verifyEmail({
        token: active_token,
        email
      }, onSuccess, onFailure));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_token, email]);

  const onSuccess = () => {
    setValidationState(VALIDATION_STATE.SUCCESS);
  };

  const onFailure = () => {
    setValidationState(VALIDATION_STATE.FAIL);
  };

  const getContent = () => {
    if (validationState === VALIDATION_STATE.VALIDATING) {
      return (
        <div className="verifying">
          <Spinner animation="border" role="status"/>
          <h3>Verifying Email...</h3>
        </div>
      );
    } else if (validationState === VALIDATION_STATE.SUCCESS) {
      return (
        <div className="verification-status">
          <h3>Verification Successful</h3>
          <div>
            <Button>
              <Link to="/">Go to Homepage</Link>
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="verification-status">
          <h3>Verification Failed</h3>
          <p>The email does not exist or has been verified already.</p>
          <div><Button><Link to="/">Go to Homepage</Link></Button></div>
        </div>
      );
    }
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="verify-email">
      {getContent()}
    </div>
  );
};

export default VerifyEmail;
