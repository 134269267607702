export const FETCH_TEXTS_SUCCESS = "app/device-config/FETCH_TEXTS_SUCCESS";
export const FETCH_TEXTS_FAIL = "app/device-config/FETCH_TEXTS_FAIL";

export const ADD_TEXTS_SUCCESS = "app/device-config/ADD_TEXTS_SUCCESS";
export const ADD_TEXTS_FAIL = "app/device-config/ADD_TEXTS_FAIL";

export const DELETE_TEXT_SUCCESS = "app/device-config/DELETE_TEXT_SUCCESS";
export const DELETE_TEXT_FAIL = "app/device-config/DELETE_TEXT_FAIL";

export const FETCH_CONFIG_SUCCESS = "app/device-config/FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAIL = "app/device-config/FETCH_CONFIG_FAIL";

export const UPDATE_CONFIG_SUCCESS = "app/device-config/UPDATE_CONFIG_SUCCESS";
export const UPDATE_CONFIG_FAIL = "app/device-config/UPDATE_CONFIG_FAIL";

export const FETCH_ALL_TEXTS_SUCCESS = "app/device-config/FETCH_ALL_TEXTS_SUCCESS";
export const FETCH_ALL_TEXTS_FAIL = "app/device-config/FETCH_ALL_TEXTS_FAIL";
