import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../components/LinkButton/LinkButton";

import { resetCommon } from "../../../common/actions/actions";

import { createTemplate } from "../state/actions";

import "../open-ai-form.scss";

const schema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  content: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty"),
  sample_data: yup.string().required("This field cannot be empty")
});

const NewTemplate = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { nextRoute } = useSelector(state => state.common)

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    const { sample_data } = data
    dispatch(createTemplate({
      ...data,
      sample_data: JSON.parse(sample_data) //JSON.stringify(sample_data.replace(/\s+/g, ' ').trim())
    }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="open-ai-form">
      <div className="header">
        <div className="left">
          <LinkButton onClick={() => history.goBack()}>
            <img src="/img/back.svg" alt="back button" className="size-24-24" />
          </LinkButton>
          <h3>New Template</h3>
        </div>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Name</Form.Label>
            <Col xs="8">
              <Form.Control
                name="name"
                type="text"
                placeholder="Enter Template Name"
                ref={register}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Content</Form.Label>
            <Col xs="8">
              <Form.Control
                sm="10"
                name="content"
                as="textarea"
                placeholder="Enter Content"
                ref={register}
                isInvalid={!!errors.content}
              />
              <Form.Control.Feedback type="inValid">{errors.content?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Description</Form.Label>
            <Col xs="8">
              <Form.Control
                sm="10"
                name="description"
                as="textarea"
                placeholder="Enter Description"
                ref={register}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Sample Data</Form.Label>
            <Col xs="8">
              <Form.Control
                sm="10"
                name="sample_data"
                as="textarea"
                placeholder="Enter Sample Data"
                ref={register}
                isInvalid={!!errors.sample_data}
              />
              <Form.Control.Feedback type="inValid">{errors.sample_data?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className="actions">
            <Button type="submit">
              Create
            </Button>
            <Button type="button" variant="outline-primary" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NewTemplate;
