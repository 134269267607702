import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import EmailsList from "./components/EmailsList";
import NotifierEmails from "./components/NotifierEmails";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchEmails,
  fetchNotifierEmails
} from "./state/actions";

//import "./Members.scss";

const Emails = () => {
  const dispatch = useDispatch();
  const { emails, notifierEmails } = useSelector(state => state.emails);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  /*
  const composeFilters = () => {
    return `or=(name.ilike.%25${searchTerm}%25,email.ilike.%25${searchTerm}%25)&role=in.(${selectedRoles.join(",")})&order=last_modified.desc&last_modified=gte.${dateRange[0]}&last_modified=lte.${dateRange[1]}`;
  };
  */

  useEffect(() => {
    dispatch(fetchEmails({pageNum: 0, pageSize: 10}, composeFilters()));
    dispatch(fetchNotifierEmails({pageNum: 0, pageSize: 10}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const composeFilters = (searchTerm = "") => {
    return `recipient=ilike.%25${searchTerm}%25&order=sent_at.desc`;
  };

  const handleFetchEmails = (pageData, searchTerm) => {
    dispatch(fetchEmails(pageData, composeFilters(searchTerm)));
  };

  const handleFetchNotifierEmails = (pageData, searchTerm) => {
    dispatch(fetchNotifierEmails(pageData));
  };

  return (
    <div className="emails">
      <Tabs defaultActiveKey="emails">
        <Tab eventKey="emails" title="Emails">
          <EmailsList data={emails} onFetchEmails={handleFetchEmails} />
        </Tab>
        <Tab eventKey="notifierEmails" title="Notifier Emails">
          <NotifierEmails data={notifierEmails} onFetchNotifierEmails={handleFetchNotifierEmails} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Emails;