import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import LinkButton from "../../../components/LinkButton/LinkButton";
import ActionModal from "../../../components/ActionModal/ActionModal";
import ChangePasswordModal from "./ChangePasswordModal";
import AddPostFeedModal from "./AddPostFeedModal";

import "./BasicDetails.scss";

const getVerificationText = (status) => {
  let statusText = '';
  if (status === 0) {
    statusText = 'Email unverified';
  } else if (status === 1) {
    statusText = 'Email verified';
  } else if (status === -1) {
    statusText = 'Email blocked';
  } else if (status === -2) {
    statusText = 'Account archived';
  }

  return statusText;
};

const BasicDetails = (props) => {
  const history = useHistory();
  const {
    avatar,
    userId,
    userName,
    email,
    verified,
    role,
    feed_owner,
    onPasswordChange,
    onAddPostFeed,
    onRemovePostFeed
  } = props;

  const [avatarSrc, setAvatarSrc] = useState(`https://fullapi.s3.amazonaws.com/${avatar}`);
  const [showPassChangeModal, setShowPassChangeModal] = useState(false);
  const [showAddPostFeedModal, setShowAddPostFeedModal] = useState(false);
  const [showRemovePostFeedModal, setShowRemovePostFeedModal] = useState(false);

  const verificationText = getVerificationText(verified);

  const setDefaultAvatar = () => {
    setAvatarSrc("/img/newavatar.png");
  };

  const handleShowModal = () => {
    setShowPassChangeModal(true);
  };

  const handleCloseModal = () => {
    setShowPassChangeModal(false);
  };

  const handleRemovePostFeed = () => {
    onRemovePostFeed(userId, () => {
      setShowRemovePostFeedModal(false)
    });
  };

  return (
    <div className="member-profile-basic">
      <div className="header">
        <LinkButton onClick={() => history.goBack()} className="back-btn">
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <img src={avatarSrc} className="avatar" alt="avatar" onError={setDefaultAvatar} />
        <div>
          <h3 className="user-name">{userName}</h3>
          <span>{verificationText}</span>
        </div>
      </div>
      <div className="details">
        <div className="row">
          <span className="col1">User ID</span>
          <span className="col2">{userId}</span>
        </div>
        <div className="row">
          <span className="col1">Email</span>
          <span className="col2">{email}</span>
        </div>
        <div className="row">
          <span className="col1">Role</span>
          <span className="col2"><img src={`/img/${role}.png`} alt="user role" className="size-24-24"/>{role}</span>
        </div>
        <div className="row">
          <span className="col1">Password</span>
          <span className="col2"><Button variant="primary" onClick={handleShowModal}>Change Password</Button></span>
          <ChangePasswordModal showModal={showPassChangeModal} onClose={handleCloseModal} onPasswordChange={onPasswordChange} />
        </div>
        <div className="row">
          <span className="col1">Can Post</span>
          <span className="col2">
            {
              feed_owner ? (
                <Button variant="primary" onClick={() => setShowRemovePostFeedModal(true)}>Remove</Button>
              ) : (
                <Button variant="primary" onClick={() => setShowAddPostFeedModal(true)}>Allow</Button>
              )
            }
          </span>
          <AddPostFeedModal
            userId={userId}
            showModal={showAddPostFeedModal}
            onAddPostFeed={onAddPostFeed}
            onClose={() => setShowAddPostFeedModal(false)}
          />
          <ActionModal
            showModal={showRemovePostFeedModal}
            title="Delete Post Feed"
            actionText="Delete"
            onClose={() => setShowRemovePostFeedModal(false)}
            onAction={handleRemovePostFeed}>
              Do you want to remove this post feed?
          </ActionModal>
        </div>
      </div>
    </div>
  );
};

BasicDetails.propTypes = {
  avatar: PropTypes.string,
  userId: PropTypes.number,
  userName: PropTypes.string,
  email: PropTypes.string,
  verified: PropTypes.number,
  role: PropTypes.string,
  onPasswordChange: PropTypes.func,
  onAddPostFeed: PropTypes.func,
  onRemovePostFeed: PropTypes.func
};

export default BasicDetails;
