import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import MoreItems from "../../../../components/MoreItems/MoreItems";

import NewMemberModal from "./NewMemberModal";
import DeleteMemberModal from "./DeleteMemberModal";

import { resetCommon } from "../../../../common/actions/actions";
import {
  fetchOrgMembers,
  addMembers,
  deleteMember
} from "../../state/actions";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const Members = ({ orgId }) => {
  const dispatch = useDispatch();
  const { orgMembers } = useSelector(state => state.organizations);

  const [showNewModal, setShowNewModal] = useState(false);
  const [toDeleteRecordId, setToDeleteRecordId] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const renderLink = ({ row }) => {
    const { user_id, email } = row;
    return (
      <div className="grid-actions">
        <Link to={`/members/details/${user_id}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(email)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  }
  
  const columns = [
    { field: 'user_name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'plan', headerName: 'Plan', width: 150 },
    { field: 'role', headerName: 'Role', width: 150 },
    { field: 'expire', headerName: 'Expire', width: 150, valueGetter: formatDate },
    { field: 'id', headerName: '-', width: 100, renderCell: renderLink}
  ];

  const composeFilters = (searchTerm = "") => {
    return `org_id=eq.${orgId}&email=ilike.%25${searchTerm}%25&order=last_modified.desc`;
  };

  useEffect(() => {
    dispatch(fetchOrgMembers(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    dispatch(fetchOrgMembers(composeFilters(searchTerm)));
  };

  const handleAddNewMember = (row) => {
    if (Array.isArray(row) && row.length > 0) {
      dispatch(addMembers(row, () => {
        dispatch(fetchOrgMembers(composeFilters()));
      }));
      setShowNewModal(false);
    }
  };

  const handleDelete = (email) => {
    const onSuccess = () => {
      dispatch(fetchOrgMembers(composeFilters()));
    };
    dispatch(deleteMember(orgId, email, onSuccess));
    setToDeleteRecordId(null);
  };

  return (
    <div className="organization-books">
      <SearchHeader
        count={orgMembers.length}
        countTitle="Members"
        placeHolder="Search members"
        addComponent={<ActionIcon icon="action-add-folder" label="ADD MEMBER" onClick={() => setShowNewModal(true)} />}
        onSearch={handleSearch}
      />
      <div className="classes-results">
        <DataGrid
          className="classes-grid"
          autoHeight={true}
          rows={orgMembers}
          columns={columns}
          pageSize={10}
        />
      </div>
      <NewMemberModal
        showModal={showNewModal}
        orgId={orgId}
        onHide={() => setShowNewModal(false)}
        onAddNewMember={handleAddNewMember}
      />
      <DeleteMemberModal
        showModal={!!toDeleteRecordId}
        recordId={toDeleteRecordId}
        onHide={() => setToDeleteRecordId(null)}
        onDelete={handleDelete}
      />
    </div>
  );
};

Members.propTypes = {
  orgId: PropTypes.string
};

export default Members;