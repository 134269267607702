import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import AppRoute from "./components/AppRoute";
import PrivateRoute from "./components/PrivateRoute";
import AdminLayout from "./components/Layout/AdminLayout";
import PublicLayout from "./components/Layout/PublicLayout";
import UserLayout from "./components/Layout/UserLayout/UserLayout";
import UserLayoutWide from "./components/Layout/UserLayout/UserLayoutWide";
import AccountLayout from "./components/Layout/AccountLayout/AccountLayout";

import Login from "./pages/Login/";
import ChangePass from "./pages/Password/ChangePass";
import SignUp from "./pages/SignUp";
import Organization from "./pages/Organization";
import NewOrganization from "./pages/Organization/new";
import Classes from "./pages/Classes/";
import ClassDetails from "./pages/Classes/details";
import ClassNew from "./pages/Classes/new";
// import Books from "./pages/Texts/";
import Texts from "./pages/Texts";
//import BookDetails from "./pages/Texts/Books/BooksDetails";
import BookDetails from "./pages/Texts/components/Books/BookDetails";
import TextDetails from "./pages/Texts/components/Texts/TextDetails";
import Landing from "./pages/HomePage/";
import LandingPage from "./pages/LandingPage/";
import Members from "./pages/Members/";
import Member from "./pages/Member";
import Profile from "./pages/Profile";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import CreateProfile from "./pages/CreateProfile/";
import Store from "./pages/Store";
import CreateCollection from "./pages/Store/components/NewCollection";
import Dictionary from "./pages/Dictionary/";
import DictionaryCharacter from "./pages/Dictionary/components/Character";
import DictionaryWord from "./pages/Dictionary/components/Word";
import ResetPass from "./pages/Password/ResetPass";
import ResetPassword from "./pages/ResetPassword/";
import ResetPasswordNotice from "./pages/ResetPasswordNotice";
import ChangePassword from "./pages/ChangePassword";
import ChangePasswordNotice from "./pages/ChangePasswordNotice";
import Paypal from "./pages/PayPal/";
import PlanTest from "./pages/PlanTest/";
import Plans from "./pages/Plans/";
import SubscribeSuccess from "./pages/PayPal/SubscribeSuccess";
import TermsOfService from "./components/HomePage/Footer/TermsOfService";
import RefundPolicy from "./components/HomePage/Footer/RefundPolicy";
import Privacy from "./components/HomePage/Footer/Privacy";
import Error from "./pages/PayPal/Error";
import MembersDetails from "./pages/Members_old/MembersDetails";
// import CreateBooks from "./pages/Texts/Notebooks/CreateBooks/";
import CreateBook from "./pages/Texts/components/NoteBooks/NewBook";
//import CreateCollection from "./pages/Store/CreateCollection/";
// import CreateText from "./pages/CreateText";
import CreateText from "./pages/Texts/components/Texts/NewText";
import Payments from "./pages/Payments";


import OrganizationDetails from "./pages/Organization/details";
import OrganizationNewClass from "./pages/Organization_old/newClass";
import UserHome from "./pages/UserHome";
import VerifyEmail from "./pages/VerifyEmail";
import Unsubscribe from "./pages/Unsubscribe";
import Newsfeed from "./pages/Newsfeed";
import Events from "./pages/Events";
import Emails from "./pages/Emails";
import Videos from "./pages/Videos";
import VideoDetails from "./pages/Videos/components/Videos/VideoDetails";
import NewDiscoveryVideo from "./pages/Videos/components/DiscoveryVideos/NewDiscoveryVideo";
import DeviceConfig from "./pages/DeviceConfig";
import YouTube from "./pages/YouTubeTranscripts";
import OpenAITemplates from "./pages/OpenAITemplates";
import NewOpenAITemplate from "./pages/OpenAITemplates/new";
import EditOpenAITemplate from "./pages/OpenAITemplates/edit";
import PostFeeds from "./pages/PostFeeds";

import "./styles/app.css";
import "./styles/brand-navy.css";
import "./styles/icons.css";
import "./styles/aside-navy.css";
import "./styles/custom.css";
import "./styles/pricing.css";
import "./styles/create.css";
import "./styles/members.scss";
import "./styles/membersDetails.css";
import "./styles/common.css";
import "./styles/books.css";
import "./styles/data-grid.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Switch>
            <AppRoute exact path="/" layout={PublicLayout} component={Landing} />
            <AppRoute exact path="/free-trial" layout={PublicLayout} component={LandingPage} />
            <PrivateRoute exact path="/planTest" layout={PublicLayout} component={PlanTest} />
            <AppRoute exact path="/plans" layout={PublicLayout} component={Plans} />
            <AppRoute exact path="/login" layout={AccountLayout} component={Login} />
            <AppRoute exact path="/signup" layout={AccountLayout} component={SignUp} />
            <PrivateRoute exact path="/createprofile" layout={PublicLayout} component={CreateProfile} />
            <PrivateRoute exact path="/subscribesuccess" layout={PublicLayout} component={SubscribeSuccess} />
            <AppRoute exact path="/terms.html" layout={PublicLayout} component={TermsOfService} />
            <AppRoute exact path="/refund-policy.html" layout={PublicLayout} component={RefundPolicy} />
            <AppRoute exact path="/privacy.html" layout={PublicLayout} component={Privacy} />
            <PrivateRoute exact path="/Error" layout={PublicLayout} component={Error} />
            <PrivateRoute exact path="/paypal" layout={PublicLayout} component={Paypal} />
            <AppRoute exact path="/ResetPass" layout={PublicLayout} component={ResetPass} />
            <AppRoute exact path="/resetpassword" layout={AccountLayout} component={ResetPassword} />
            <AppRoute exact path="/resetpassword/notice" layout={AccountLayout} component={ResetPasswordNotice} />
            <AppRoute exact path="/changepassword" layout={AccountLayout} component={ChangePassword} />
            <AppRoute exact path="/changepassword/notice" layout={AccountLayout} component={ChangePasswordNotice} />
            <PrivateRoute exact path="/memberdetail" layout={AdminLayout} component={MembersDetails} />
            <AppRoute exact path="/changepass" layout={AdminLayout} component={ChangePass} />
            <PrivateRoute exact path="/organization" layout={UserLayout} component={Organization} />
            <PrivateRoute exact path="/classes" layout={UserLayout} component={Classes} />
            <PrivateRoute exact path="/classes/details" layout={AdminLayout} component={ClassDetails} />
            <PrivateRoute exact path="/books" layout={UserLayout} component={Texts} />
            {/*<PrivateRoute exact path="/books/BookDetails" layout={AdminLayout} component={BookDetails} />*/}
            <PrivateRoute exact path="/payments" layout={UserLayout} component={Payments} />
            {/*<PrivateRoute exact path="/texts/textdetails" layout={AdminLayout} component={TextDetails} />*/}
            <PrivateRoute exact path="/texts/details/:id" layout={UserLayout} component={TextDetails} />
            <PrivateRoute exact path="/books/details/:id" layout={UserLayout} component={BookDetails} />
            <PrivateRoute exact path="/members" layout={UserLayout} component={Members} />
            <PrivateRoute exact path="/members/details/:id" layout={UserLayout} component={Member} />
            <PrivateRoute exact path="/settings" layout={UserLayoutWide} component={Profile} />
            <PrivateRoute exact path="/settings/billing" layout={UserLayoutWide} component={Profile} />
            <PrivateRoute exact path="/settings/payments" layout={UserLayoutWide} component={Profile} />
            <PrivateRoute exact path="/settings/security" layout={UserLayoutWide} component={Profile} />
            <AppRoute exact path="/emailverification" layout={AdminLayout} component={EmailVerification} />
            <PrivateRoute exact path="/store" layout={UserLayout} component={Store} />
            <PrivateRoute exact path="/store/new-collection" layout={UserLayout} component={CreateCollection} />
            <PrivateRoute exact path="/dictionary" layout={UserLayout} component={Dictionary} />
            <PrivateRoute exact path="/dictionary/character/:id" layout={UserLayout} component={DictionaryCharacter} />
            <PrivateRoute exact path="/dictionary/word/:id" layout={UserLayout} component={DictionaryWord} />
            <PrivateRoute exact path="/books/new" layout={UserLayout} component={CreateBook} />
            <PrivateRoute exact path="/texts/new" layout={UserLayout} component={CreateText} />
            <PrivateRoute exact path="/organization/details" layout={AdminLayout} component={OrganizationDetails} />
            <PrivateRoute exact path="/organization/details/:orgId" layout={UserLayout} component={OrganizationDetails} />
            <PrivateRoute exact path="/organization/new" layout={UserLayout} component={NewOrganization} />
            <PrivateRoute exact path="/organization/newclass" layout={AdminLayout} component={OrganizationNewClass} />
            <PrivateRoute exact path="/classes/new" layout={UserLayout} component={ClassNew} />
            <PrivateRoute exact path="/classes/details/:classId" layout={UserLayout} component={ClassDetails} />
            <PrivateRoute exact path="/user/home" layout={UserLayout} component={UserHome} />
            <PrivateRoute exact path="/newsfeed" layout={UserLayout} component={Newsfeed} />
            <PrivateRoute exact path="/events" layout={UserLayout} component={Events} />
            <PrivateRoute exact path="/emails" layout={UserLayout} component={Emails} />
            <PrivateRoute exact path="/videos" layout={UserLayout} component={Videos} />
            <PrivateRoute exact path="/videos/details/:id" layout={UserLayout} component={VideoDetails} />
            <PrivateRoute exact path="/discovery-videos/new" layout={UserLayout} component={NewDiscoveryVideo} />
            <PrivateRoute exact path="/device-config" layout={UserLayout} component={DeviceConfig} />
            <PrivateRoute exact path="/youtube" layout={UserLayout} component={YouTube} />
            <PrivateRoute exact path="/openai-templates" layout={UserLayout} component={OpenAITemplates} />
            <PrivateRoute exact path="/openai-templates/new" layout={UserLayout} component={NewOpenAITemplate} />
            <PrivateRoute exact path="/openai-templates/details/:id" layout={UserLayout} component={EditOpenAITemplate} />
            <PrivateRoute exact path="/post-feeds" layout={UserLayout} component={PostFeeds} />
            <AppRoute exact path="/verify-email" layout={AccountLayout} component={VerifyEmail} />
            <AppRoute exact path="/unsubscribe-email" layout={AccountLayout} component={Unsubscribe} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}

export default App;
