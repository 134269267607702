import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';

import { Link } from "react-router-dom";

import { resetCommon } from '../../common/actions/actions';

import "./change-password-notice.scss";

const ChangePasswordNotice = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  return (
    <div className="change-pass-notice-container">
      <h3 className="title">Change Password</h3>
      <p className="sub-title">
        Successfuly changed your password!
      </p>
      <p className="center-text">Continue to the <Link to="/login">Sign In screen</Link></p>
    </div>
  );
};

export default ChangePasswordNotice;