import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../../components/SearchHeader/SearchBar";

import "./text-selection-modal.scss";

const TextSelectionModal = (props) => {
  const {
    showModal,
    videoTexts,
    onClose,
    onFetchVideoTexts,
    onAddVideoText
  } = props;

  const { numRecords, rows } = videoTexts;

  const [selectedTexts, setSelectedTexts] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [submitting, setSubmitting]  = useState(false);

  useEffect(() => {
    if (showModal) {
      setSelectedTexts([]);
    }
  }, [showModal]);

  const handleSelectText = (ev, data) => {
    const selected = ev.target.checked;

    const selectedBook = {
      video_text_sha1: data.sha1,
      priority: 1,
      tags: [],
      level: "beginner"
    };

    if(selected) {
      setSelectedTexts(selectedTexts.concat([selectedBook]));
    } else {
      setSelectedTexts(selectedTexts.filter(text => text.video_text_sha1 !== data.sha1));
    }
  };

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        defaultChecked={selectedTexts.find((text) => text.video_text_sha1 === row.sha1)}
        onClick={(ev) => handleSelectText(ev, row)}
      />
    );
  };
  const columns = [
    { field: 'title', headerName: 'Title', width: 500 },
    { field: 'id', headerName: 'Select', width: 150, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    onFetchVideoTexts({ pageNum: 0, pageSize: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchVideoTexts({pageNum: 0, pageSize}, searchTerm);
  };

  const handlePageChange = ({ page, pageSize }) => {
    onFetchVideoTexts({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchVideoTexts({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSubmit = () => {
    if (typeof onAddVideoText === "function") {
      setSubmitting(true);
      onAddVideoText(selectedTexts, () => {
        setSubmitting(false);
        setSelectedTexts([]);
        onClose();
      }, () => {
        setSubmitting(false);
      });
    }
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={onClose} className="video-texts-add-video">
        <Modal.Header closeButton>
          <Modal.Title>Add Discovery Video from Texts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-search">
            <SearchBar placeholder="Search Video Texts" onSearch={handleSearch} />
          </div>
          <div className="records-info">
            <span>{numRecords && `${numRecords} Texts`}</span>
            <span>{`${selectedTexts.length} texts selected`}</span>
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={rows}
              columns={columns}
              pageSize={10}
              disableVirtualization={true}
              hideFooterSelectedRowCount={true}
              rowsPerPageOptions={[10, 20, 50]}
              rowCount={numRecords}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting} onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

TextSelectionModal.propTypes = {
  showModal: PropTypes.bool,
  videoTexts: PropTypes.object,
  onClose: PropTypes.func,
  onFetchVideoTexts: PropTypes.func,
  onAddVideoText: PropTypes.func
};

export default TextSelectionModal;
