import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import ActionModal from "../../../../components/ActionModal/ActionModal";
import EditField from "../../../../components/Fields/EditField";

import EditJSONModal from "./EditJSONModal";

import { resetCommon } from "../../../../common/actions/actions";

import {
  fetchSelectedText,
  updateSelectedText,
  deleteSelectedText,
  resetSelectedText,
  uploadFile
} from "../../state/actions";

import "./text-details.scss";

const schema = yup.object().shape({
  title: yup.string().required("This field cannot be empty"),
  author: yup.string().required("This field cannot be empty"),
  content: yup.string().required("This field cannot be empty"),
  sounds: yup.number().typeError('Sounds must be a number').required("This field cannot be empty"),
});

const TextDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: selectedSha1 } = useParams();

  const { nextRoute } = useSelector(state => state.common);
  const { id, role } = useSelector(state => state.auth);
  const { selectedText } = useSelector(state => state.texts);
  const { title, author, json_text  = {}, last_modified, owner } = selectedText;
  const { sounds, content: description } = json_text;
  const isOwner = role === "gk_admin" || owner === id;
  const numSounds = Array.isArray(sounds) ? sounds.length : 0;
  let soundPath = Array.isArray(sounds) && sounds.length > 0 ? sounds[0].path : null;
  if (soundPath && !soundPath.includes("s3.amazonaws.com")) {
    soundPath = `https://fullenglish.s3.amazonaws.com/${soundPath}`;
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showJSONModal, setShowJSONModal] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => {
      dispatch(resetCommon());
      dispatch(resetSelectedText());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedText(selectedSha1));
  }, [selectedSha1, dispatch]);

  const defaultValues = {
    title,
    author,
    content: description,
    nb_sounds: numSounds
  };
  const { register, errors, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedText]);

  const handleDelete = () => {
    dispatch(deleteSelectedText(selectedSha1), () => setShowDeleteModal(false));
  };

  const handleSave = (field) => {
    const values = getValues();
    if (values[field]) {
      dispatch(updateSelectedText(selectedSha1, { [field]: values[field] }));
    }
  };

  const handleUploadAudio = (ev) => {
    const file = ev.target.files[0];

    dispatch(uploadFile({ file }, "mp3", () => {
      document.getElementById("audio_player").src = URL.createObjectURL(file);
    }));
  };

  const handleUpdateJSON = (payload, onSuccess, onFail) => {
    dispatch(updateSelectedText(selectedSha1, payload, onSuccess, onFail));
  }

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="text-details">
      <div className="header">
        <LinkButton onClick={() => history.goBack()}>
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <img src="/img/book-cover.png" className="img-fluid" alt="book-cover" />
        <h3>{title}</h3>
        {isOwner && (
            <div>
              <ActionIcon icon="action-trashcan" label="DELETE" onClick={() => setShowDeleteModal(true)} />
              <ActionModal
                showModal={showDeleteModal}
                title="Delete Text"
                actionText="Delete"
                onClose={() => setShowDeleteModal(false)}
                onAction={handleDelete}
              >
                Do you want to delete this text?
              </ActionModal>
            </div>
          )
        }
      </div>
      <div>
          <Form>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Text Title</Form.Label>
              <Col xs="8">
                <EditField name="title" value={title} canEdit={isOwner} onSave={() => handleSave("title")}>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Enter text title here"
                    ref={register}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="inValid">{errors.title?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Author</Form.Label>
              <Col xs="8">
                <EditField name="author" value={author} canEdit={isOwner} onSave={() => handleSave("author")}>
                  <Form.Control
                    name="author"
                    type="text"
                    placeholder="Enter author here"
                    ref={register}
                    isInvalid={!!errors.author}
                  />
                  <Form.Control.Feedback type="inValid">{errors.author?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Audio</Form.Label>
              <Col xs="8">
                <div className="audio-field">
                  {
                    soundPath ? (
                      <audio controls="controls" preload="auto" id="audio_player">
                        <source
                          src={soundPath}
                          type="audio/mpeg"
                          id="audio_source"
                        />
                      </audio>
                    ) : <span></span>
                  }
                  <label>
                    <span className="upload-btn">UPLOAD</span>
                    <Form.Control
                      type="file"
                      name="file"
                      accept=".mp3"
                      onChange={handleUploadAudio}
                      className="audio-input" />
                  </label>
                </div>
                
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Content</Form.Label>
              <Col xs="8">
                <EditField name="content" canEdit={false} value={description} onSave={() => handleSave("content")}>
                  <Form.Control
                    name="content"
                    as="textarea"
                    rows={8}
                    placeholder="Enter content here"
                    ref={register}
                    isInvalid={!!errors.content}
                  />
                  <Form.Control.Feedback type="inValid">{errors.content?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Sound</Form.Label>
              <Col xs="8">
                <EditField name="nb_sounds" value={numSounds} canEdit={isOwner} onSave={() => handleSave("nb_sounds")}>
                  <Form.Control
                    name="nb_sounds"
                    type="number"
                    placeholder="Enter number of sounds here"
                    ref={register}
                    isInvalid={!!errors.sounds}
                  />
                  <Form.Control.Feedback type="inValid">{errors.sounds?.message}</Form.Control.Feedback>
                </EditField>
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} className="form-row">
              <Col xs="12">
                {
                  isOwner && (
                    <>
                      <Button
                        type="button"
                        className="full-width"
                        onClick={() => setShowJSONModal(true)}>
                          View/Edit JSON Text
                      </Button>
                      <EditJSONModal
                        showModal={showJSONModal}
                        defaultValues={{ json_text: JSON.stringify(json_text) }}
                        onClose={() => setShowJSONModal(false)}
                        onUpdateData={handleUpdateJSON} />
                    </>
                  )
                }
              </Col>
            </Form.Group>
          </Form>
      </div>
    </div>
  );
};

export default TextDetails;
