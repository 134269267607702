import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";
import Button from "react-bootstrap/Button";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionModal from "../../../components/ActionModal/ActionModal";

const formatDate = (field, row) => {
  const fieldVal = row[field];
  return dayjs(fieldVal).format('DD/MM/YYYY');
};

const formatAutoRenew = ({ row }) => {
  const { auto_renew_status } = row;
  return auto_renew_status === 1 ? 'Yes' : 'No'
};

const SubPayments = ({ role, data, onFetchSubPayments }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [details, setDetails] = useState(null);

  const isAdmin = role === "gk_admin";

  const handlePageChange = ({ page, pageSize }) => {
    onFetchSubPayments({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchSubPayments({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchSubPayments({ pageNum: 0, pageSize }, searchTerm);
  };

  const renderDetails = ({ row }) => {
    return (
      <Button onClick={() => setDetails(row)}>
        Details
      </Button>
    );
  }
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'currency', headerName: 'Currency', width: 120 },
    { field: 'transaction_id', headerName: 'Transaction ID', width: 180 },
    { field: 'payment_product_id', headerName: 'Product ID', width: 180 },
    { field: 'payment', headerName: 'Payment', width: 120 },
    { field: 'paid_through_date', headerName: 'Paid Through', width: 150, valueGetter: ({ row }) => formatDate('paid_through_date', row) },
    { field: 'next_billing_date', headerName: 'Next Billing', width: 150, valueGetter: ({ row }) => formatDate('next_billing_date', row) },
    { field: 'auto_renew_status', headerName: 'Auto Renew', width: 130, valueGetter: formatAutoRenew },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: ({ row }) => formatDate('last_modified', row) },
    ...(isAdmin ? [{ field: 'none', headerName: '.', width: 150, renderCell: renderDetails}] : [])
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Sub Payments"
        placeHolder="Search subpayments here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.id}
        />
      </div>
      <ActionModal
        showModal={details}
        title="Sub Payment Details"
        onClose={() =>  setDetails(null)}
      >
        <pre>{JSON.stringify(details, null, '\t')}</pre>
      </ActionModal>
    </div>
  );
};

SubPayments.propTypes = {
  role: PropTypes.string,
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchSubPayments: PropTypes.func
};

export default SubPayments;
