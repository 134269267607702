import uuid from 'uuid/v4';

import * as Actions from "./action-types";

import { makeApiCall } from "../../../helpers/api-client";
import { setNextRoute, throwApiError } from "../../../common/actions/actions";

export const getAwsCredentials = () => async dispatch => {
  try {
    const response = await makeApiCall(`s3/signed?resource=images/${uuid()}.png`, {
      baseURL: "https://server.fullchinese.com/fastapi/",
      method: "get"
    });
    const { fields: { key, AWSAccessKeyId, policy, signature, acl } } = response.data;
    dispatch(getAwsCredentialsSuccess({
      key,
      AWSAccessKeyId,
      policy,
      signature,
      acl
    }));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};

const getAwsCredentialsSuccess = (payload) => ({
  type: Actions.GET_AWS_CREDENTIALS_SUCCESS,
  payload
});

export const createProfile = (id, payload, picPayload) => async dispatch => {
  try {
    await makeApiCall(`fc_profile?id=eq.${id}`, {
      method: "post",
      data: payload
    });

    if (picPayload instanceof FormData) {
      dispatch(uploadAvatar(picPayload));
    } else {
      dispatch(setNextRoute("/plans"));
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to update profile. Please try again later"
    }));
  }
};

export const uploadAvatar = (payload) => async dispatch => {
  try {
    await makeApiCall("/", {
      baseURL: "https://fullapi.s3.amazonaws.com",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: payload
    });
    dispatch(setNextRoute("/PlanTest"));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};
