import React from "react";
import { useSelector } from "react-redux";

import Alert from "../Alert";

const PublicLayout = (props) => {
  const { apiError } = useSelector(state => state.common);

  return (
    <>
      {/* <Nav /> */}
      {props.children}
      <Alert {...apiError} />
    </>
  );
}

export default PublicLayout;
