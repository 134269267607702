export const FETCH_VIDEOS_SUCCESS = "app/videos/FETCH_VIDEOS_SUCCESS";
export const FETCH_VIDEOS_FAIL = "app/videos/FETCH_VIDEOS_FAIL";

export const FETCH_SELECTED_VIDEO_SUCCESS = "app/videos/FETCH_SELECTED_VIDEO_SUCCESS";
export const FETCH_SELECTED_VIDEO_FAIL = "app/videos/FETCH_SELECTED_VIDEO_FAIL";

export const FETCH_DISCOVERY_VIDEOS_SUCCESS = "app/videos/FETCH_DISCOVERY_VIDEOS_SUCCESS";
export const FETCH_DISCOVERY_VIDEOS_FAIL = "app/videos/FETCH_DISCOVERY_VIDEOS_FAIL";

export const ADD_DISCOVERY_VIDEO_SUCCESS = "app/videos/ADD_DISCOVERY_VIDEO_SUCCESS";
export const ADD_DISCOVERY_VIDEO_FAIL = "app/videos/ADD_DISCOVERY_VIDEO_FAIL";

export const UPDATE_DISCOVERY_VIDEO_SUCCESS = "app/videos/UPDATE_DISCOVERY_VIDEO_SUCCESS";
export const UPDATE_DISCOVERY_VIDEO_FAIL = "app/videos/UPDATE_DISCOVERY_VIDEO_FAIL";

export const DELETE_DISCOVERY_VIDEO_SUCCESS = "app/videos/DELETE_DISCOVERY_VIDEO_SUCCESS";
export const DELETE_DISCOVERY_VIDEO_FAIL = "app/videos/DELETE_DISCOVERY_VIDEO_FAIL";

export const FETCH_USER_TEXTS_SUCCESS = "app/videos/FETCH_USER_TEXTS_SUCCESS";
export const FETCH_USER_TEXTS_FAIL = "app/videos/FETCH_USER_TEXTS_FAIL";

export const ADD_DISCOVERY_VIDEO_YOUTUBE_SUCCESS = "app/videos/ADD_DISCOVERY_VIDEO_YOUTUBE_SUCCESS";
export const ADD_DISCOVERY_VIDEO_YOUTUBE_FAIL = "app/videos/ADD_DISCOVERY_VIDEO_YOUTUBE_FAIL";
