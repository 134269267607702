import { apiUrl } from "../config";
import axios from "axios";

/**
 * To Do
 * Save an read auth token from authToken key only once refactoring completed
 */

const excludeList401 = ['/rpc/me'];

const getAuthToken = () => {
  const state = JSON.parse(localStorage.getItem("state"));
  return state?.rpc?.tokens || localStorage.getItem("authToken");
};

const ax = axios.create({
  baseURL: apiUrl,
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

ax.interceptors.request.use((config) => {
  const authToken = getAuthToken();
  if (authToken) {
    config.headers['Authorization'] = 'Bearer ' + authToken;
  }
  return config;
}, (error) => Promise.reject(error));

ax.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error?.response?.status === 401 && !excludeList401.includes(error?.request?.config?.url)) {
      window.location.href = "/login";
      localStorage.clear();
    }
    return Promise.reject(error);
});

export const makeApiCall = (endPoint, options) => {
  return ax({
    url: endPoint,
    ...options
  });
};
