import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import * as yup from "yup";

import Password from "../../../components/Fields/Password";

import { signUp } from '../state/actions';

import "./signup.scss";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("This field cannot be empty")
    .min(5, 'Name should be atleast 5 character'),
  email: yup.string().email("Invalid email format").required("This field cannot be empty"),
  password: yup
    .string()
    .required("This field cannot be empty")
    .min(6, "Password should be atleast 6 character")
});

const SignUp = ({ role }) => {
  const dispatch = useDispatch();
  
  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    const stopSubmitting = () => setSubmitting(false);
    setSubmitting(true);
    dispatch(signUp({...data, role}, () => {
      stopSubmitting();
      if (typeof window !== 'undefined') {
        window.gtag('event', 'conversion', {'send_to': 'AW-10803848228/XO0JCL_BiJQDEKTI1p8o'});
      }
    }, stopSubmitting));
  };

  return (
    <div className="signup-container">
      <h3 className="title">Sign Up to FullChinese Pro</h3>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="signup-form">
        <Form.Group controlId="formGroupUsername">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Enter user name"
            ref={register}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Your Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email address"
            ref={register}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="inValid">{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <Form.Label>Password</Form.Label>
          <Password
            name="password"
            placeholder="Password"
            innerRef={register}
            isInvalid={!!errors.password}
            showIcon={!errors.password}
          />
          <Form.Control.Feedback type="inValid">{errors.password?.message}</Form.Control.Feedback>
        </Form.Group>
        <div className="login-actions">
          <span>
            <label>Have an account? <Link to="/login">Sign In</Link></label>
          </span>
          <Button type="submit" disabled={submitting}>
            SIGN UP
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SignUp;
