import React from "react";
import { Link } from "react-router-dom";
import HeaderDesktop from "./HeaderDesktop";

import "./HeaderDesktopWide.scss";

const HeaderDesktopWide = (props) => {

  return (
    <div className="header-desktop-wide">
      <div className="brand">
        <Link to="/user/home">
          <img alt="Logo" src="/img/logo-white.svg" />
        </Link>
      </div>
      <div className="menu-top">
        <HeaderDesktop {...props} />
      </div>
    </div>
  );
};

export default HeaderDesktopWide;