import { makeApiCall } from "../../../helpers/api-client"
import { throwApiError } from "../../../common/actions/actions";
import { getStartAndEndRecord } from "../../../helpers/utils";
import * as Actions from "./action-types"

export const fetchEmails = (pagination, query) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_mails?${query}`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchEmailsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchEmailsFail())
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch emails"
    }));
  }
};

const fetchEmailsSuccess = payload => ({
  type: Actions.FETCH_EMAILS_SUCCESS,
  payload
});

const fetchEmailsFail = payload => ({
  type: Actions.FETCH_EMAILS_FAIL,
  payload
});

export const fetchNotifierEmails = (pagination) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall("fc_next_notifier_user", {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchNotifierEmailsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchNotifierEmailsFail())
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch notifier emails"
    }));
  }
};

const fetchNotifierEmailsSuccess = payload => ({
  type: Actions.FETCH_NOTIFIER_EMAILS_SUCCESS,
  payload
});

const fetchNotifierEmailsFail = payload => ({
  type: Actions.FETCH_NOTIFIER_EMAILS_FAIL,
  payload
});

