


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useViewportSize from "../../../../helpers/hooks/use-viewport-size";

import HeaderDesktopWide from "./HeaderDesktopWide";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

import { getProfile } from "../../../../actions/profile";
import { getSubscription } from "../../../../actions/subscription";

const Header = ({ wideView }) => {
  const viewportSize = useViewportSize();
  const dispatch = useDispatch();
  const { id, username } = useSelector(state => state.auth);
  const { avatar } = useSelector(state => state.profile);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getSubscription(id));
  }, [id, dispatch]);

  return viewportSize === 'lg'
    ? (wideView ? <HeaderDesktopWide userName={username} avatar={avatar} /> : <HeaderDesktop userName={username} avatar={avatar} />)
    : <HeaderMobile avatar={avatar} />;
};

export default Header;