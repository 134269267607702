import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";

import Password from "../../components/Fields/Password";

import { resetCommon, showNotice } from '../../common/actions/actions';
import { changePassword } from './state/actions';

import "./change-password.scss";

const schema = yup.object().shape({
  password: yup.string().required("This field cannot be empty")
    .min(6, "Password should be atleast 6 character"),
  verifyPassword: yup.string().required("This field cannot be empty")
    .oneOf([yup.ref('password'), null], "Passwords must match"),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { nextRoute } = useSelector(state => state.common);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const email = urlParams.get('email');

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    const validateEmail =(email) => {
      const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      return re.test(email);
    }

    let message = "";
    if (!token && !email) {
      message = "Invalid token and email";
    } else if (!token) {
      message = "Invalid token";
    } else if (!email || !validateEmail(email)) {
      message = "Invalid email";
    }

    dispatch(showNotice({
      message
    }));
  }, [dispatch, email, token]);
  
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ password }) => {
    dispatch(changePassword({ password, token, email }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="change-pass-container">
      <h3 className="title">Change Password</h3>
      <p className="sub-title">Please enter your new password below</p>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="change-form">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>New Password</Form.Label>
          <Password
              name="password"
              placeholder="Password"
              innerRef={register}
              isInvalid={!!errors.password}
              showIcon={!errors.password}
            />
          <Form.Control.Feedback type="inValid">{errors.password?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <Form.Label>Verify Password</Form.Label>
          <Password
            name="verifyPassword"
            placeholder="Password"
            innerRef={register}
            isInvalid={!!errors.verifyPassword}
            showIcon={!errors.verifyPassword}
          />
          <Form.Control.Feedback type="inValid">{errors.verifyPassword?.message}</Form.Control.Feedback>
        </Form.Group>
        <div className="actions">
          <Button type="submit">
            CHANGE PASSWORD
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
