import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteBookModal = (props) => {
  const { showModal, recordId, onHide, onDelete } = props;

  return (
    <Fragment>
      <Modal centered show={showModal} onHide={onHide} className="new-class-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to delete this book?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={() => onDelete(recordId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

DeleteBookModal.propTypes = {
  showModal: PropTypes.bool,
  recordId: PropTypes.string,
  onHide: PropTypes.bool,
  onDelete: PropTypes.func
};

export default DeleteBookModal;
