import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError, setNextRoute } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchOrganizations = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_organization?${queryString}`);
    dispatch(fetchOrganizationsSuccess(response.data));
  } catch (error) {
    dispatch(fetchOrganizationsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch organizations. Please try again."
    }));
  }
};

const fetchOrganizationsSuccess = (payload) => ({
  type: Actions.FETCH_ORGANIZATIONS_SUCCESS,
  payload
});

const fetchOrganizationsFail = () => ({
  type: Actions.FETCH_ORGANIZATIONS_FAIL
});

export const fetchOrganization = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_organization?id=eq.${id}`);
    dispatch(fetchOrganizationSuccess(response.data[0]));
  } catch (error) {
    dispatch(fetchOrganizationFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch organization. Please try again."
    }));
  }
};

const fetchOrganizationSuccess = (payload) => ({
  type: Actions.FETCH_ORGANIZATION_SUCCESS,
  payload
});

const fetchOrganizationFail = () => ({
  type: Actions.FETCH_ORGANIZATION_FAIL
});


export const createOrganization = (payload) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_organization`, {
      method: 'post',
      data: payload
    });
    dispatch(createOrganizationSuccess(response.data));
    dispatch(setNextRoute("/organization"));
  } catch (error) {
    dispatch(createOrganizationFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to add the organization at this moment. Please try again later."
    }));
  }
};

const createOrganizationSuccess = (payload) => ({
  type: Actions.CREATE_ORGANIZATION_SUCCESS,
  payload
});

const createOrganizationFail = () => ({
  type: Actions.CREATE_ORGANIZATION_FAIL
});

export const uploadOrgImage = (payload, onSuccess) => async dispatch => {
  try {
    await makeApiCall("/", {
      baseURL: "https://fullapi.s3.amazonaws.com",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: payload
    });
    dispatch(uploadOrgImageSuccess());
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(uploadOrgImageFail());
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};

const uploadOrgImageSuccess = (payload) => ({
  type: Actions.UPLOAD_ORG_IMAGE_SUCCESS,
  payload
});

const uploadOrgImageFail = () => ({
  type: Actions.UPLOAD_ORG_IMAGE_FAIL
});

export const getOrgRole = (id, orgId) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_org_members?user_id=eq.${id}&org_id=eq.${orgId}`);
    dispatch(getOrgRoleSuccess(response.data[0].role));
  } catch (error) {
    dispatch(getOrgRoleFail());
  }
};

const getOrgRoleSuccess = (payload) => ({
  type: Actions.GET_ORG_ROLE_SUCCESS,
  payload
});

const getOrgRoleFail = () => ({
  type: Actions.GET_ORG_ROLE_FAIL
});

export const updateOrganization = (id, payload) => async dispatch => {
  try {
    dispatch(updateOrganizationData(payload));
    const response = await makeApiCall(`fc_organization?id=eq.${id}`, {
      method: 'patch',
      data: payload
    });
    dispatch(updateOrganizationSuccess(response.data));
  } catch (error) {
    dispatch(updateOrganizationFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to update organization at this moment. Please try again later."
    }));
  }
};

const updateOrganizationData = (payload) => ({
  type: Actions.UPDATE_ORG_DATA,
  payload
});

const updateOrganizationSuccess = (payload) => ({
  type: Actions.UPDATE_ORG_SUCCESS,
  payload
});

const updateOrganizationFail = () => ({
  type: Actions.UPLOAD_ORG_FAIL
});

export const deleteOrganization = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_organization?id=eq.${id}`, {
      method: 'delete'
    });

    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Organization has been Removed! Redirecting to Organizations page..."
    }));

    dispatch(deleteOrganizationSuccess(response.data));
    setTimeout(() => dispatch(setNextRoute("/organization")), 3000);
  } catch (error) {
    dispatch(deleteOrganizationFail());
    dispatch(throwApiError({
      type: "toast",
      message: "This Organization cannot be Removed. Please remove all resources of organization and try again."
    }));
  }
};

const deleteOrganizationSuccess = (payload) => ({
  type: Actions.DELETE_ORGANIZATION_SUCCESS,
  payload
});

const deleteOrganizationFail = () => ({
  type: Actions.DELETE_ORGANIZATION_FAIL
});


/**
 * Books
 */

export const fetchOrgBooks = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_org_books?${queryString}`);
    dispatch(fetchOrgBooksSuccess(response.data));
  } catch (error) {
    dispatch(fetchOrgBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchOrgBooksSuccess = (payload) => ({
  type: Actions.FETCH_ORG_BOOKS_SUCCESS,
  payload
});

export const fetchOrgBooksSha = (orgId) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_org_books?org_id=eq.${orgId}&select=book_sha1`);
    dispatch(fetchOrgBooksShaSuccess(response.data.map(({ book_sha1 }) => book_sha1)));
  } catch (error) {
    dispatch(fetchOrgBooksShaFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchOrgBooksShaSuccess = (payload) => ({
  type: Actions.FETCH_ORG_BOOKS_SHA_SUCCESS,
  payload
});

const fetchOrgBooksShaFail = () => ({
  type: Actions.FETCH_ORG_BOOKS_SHA_FAIL
});

const fetchOrgBooksFail = () => ({
  type: Actions.FETCH_ORG_BOOKS_FAIL
});

export const fetchBooks = (orgId, searchTerm) => async dispatch => {
  try {
    const bookShaResponse = await makeApiCall(`fc_org_books?org_id=eq.${orgId}&select=book_sha1`);
    const bookShaList = bookShaResponse.data.map(({ book_sha1 }) => book_sha1);
    const response = await makeApiCall(`fc_books?sha1=not.in.(${bookShaList})&title=ilike.%25${searchTerm}%25`);
    dispatch(fetchBooksSuccess(response.data));
  } catch (error) {
    dispatch(fetchBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchBooksSuccess = (payload) => ({
  type: Actions.FETCH_BOOKS_SUCCESS,
  payload
});

const fetchBooksFail = () => ({
  type: Actions.FETCH_BOOKS_FAIL
});

export const addBooks = (payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall("fc_org_books", {
      method: 'post',
      data: payload
    });
    dispatch(addBooksSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Books added successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(addBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to add books at the moment. Please try again later."
    }));
  }
};

const addBooksSuccess = (payload) => ({
  type: Actions.ADD_BOOKS_SUCCESS,
  payload
});

const addBooksFail = () => ({
  type: Actions.ADD_BOOKS_FAIL
});

export const deleteBook = (orgId, bookSha1, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(
      `fc_org_books?org_id=eq.${orgId}&book_sha1=eq.${bookSha1}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteBookSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Book deleted successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(deleteBookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to delete book at the moment. Please try again later."
    }));
  }
};

const deleteBookSuccess = (payload) => ({
  type: Actions.DELETE_BOOK_SUCCESS,
  payload
});

const deleteBookFail = () => ({
  type: Actions.DELETE_BOOK_FAIL
});

/**
 * Classes
 */

export const fetchOrgClasses = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_classes?${queryString}`);
    dispatch(fetchOrgClassesSuccess(response.data));
  } catch (error) {
    dispatch(fetchOrgClassesFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch classes. Please try again."
    }));
  }
};

const fetchOrgClassesSuccess = (payload) => ({
  type: Actions.FETCH_ORG_CLASSES_SUCCESS,
  payload
});

const fetchOrgClassesFail = () => ({
  type: Actions.FETCH_ORG_CLASSES_FAIL
});

export const addClass = (id, payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_classes?user_id=eq.${id}`, {
      method: 'post',
      data: payload
    });
    dispatch(addClassSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Class added successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(addClassFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to add class at the moment. Please try again later."
    }));
  }
};

const addClassSuccess = (payload) => ({
  type: Actions.ADD_CLASS_SUCCESS,
  payload
});

const addClassFail = () => ({
  type: Actions.ADD_CLASS_FAIL
});

export const deleteClass = (id, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(
      `fc_classes?id=eq.${id}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteClassSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Class deleted successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(deleteClassFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Please remove all the member and books of the class  to delete this class."
    }));
  }
};

const deleteClassSuccess = (payload) => ({
  type: Actions.DELETE_CLASS_SUCCESS,
  payload
});

const deleteClassFail = () => ({
  type: Actions.DELETE_CLASS_FAIL
});

/**
 * Members
 */

export const fetchOrgMembers = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_org_members?${queryString}`);
    dispatch(fetchOrgMembersSuccess(response.data));
  } catch (error) {
    dispatch(fetchOrgMembersFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch members. Please try again."
    }));
  }
};

const fetchOrgMembersSuccess = (payload) => ({
  type: Actions.FETCH_ORG_MEMBERS_SUCCESS,
  payload
});

const fetchOrgMembersFail = () => ({
  type: Actions.FETCH_ORG_MEMBERS_FAIL
});

export const addMembers = (payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_org_members`, {
      method: 'post',
      data: payload
    });
    dispatch(addMembersSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Members added successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(addMembersFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to add the organization at this moment. Please try again later."
    }));
  }
};

const addMembersSuccess = (payload) => ({
  type: Actions.ADD_MEMBERS_SUCCESS,
  payload
});

const addMembersFail = () => ({
  type: Actions.ADD_MEMBERS_FAIL
});

export const deleteMember = (orgId, email, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(
      `fc_org_members?org_id=eq.${orgId}&email=eq.${email}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteMemberSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Member deleted successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(deleteMemberFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Failed to delete member. Please try again later."
    }));
  }
};

const deleteMemberSuccess = (payload) => ({
  type: Actions.DELETE_MEMBER_SUCCESS,
  payload
});

const deleteMemberFail = () => ({
  type: Actions.DELETE_MEMBER_FAIL
});
