import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
import { fastapiUrl } from "../../config";
import "braintree-web";
import DropIn from "braintree-web-drop-in-react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const axios = require("axios");
class Paypal extends Component {
  instance;
  constructor(props) {
    super(props);

    this.state = {
      clientToken: null,
      isOpen: null,
      isSubmitting: false
    };
  }
  componentDidMount() {
    this.getToken();
  }

  getToken = e => {
    const headers = {
      Authorization: "Bearer " + this.props.token,
      "Content-Type": "application/json"
    };
    let url = fastapiUrl + "bt/client_token";
    axios
      .get(url, {
        headers: headers
      })
      .then(response => {
        this.setState({
          clientToken: response.data.token
        });
      })
      .catch(error => {});
  };

  async buy() {
    // Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Prefer: "count=exact",
      Authorization: "Bearer " + this.props.token
    };
    let data = { client_nonce: nonce, plan_id: this.props.planid };
    let url = fastapiUrl + "bt/subscribe";

    this.setState({
      isSubmitting: true
    });

    axios
      .post(url, data, {
        headers: headers
      })
      .then(response => {
        this.props.history.push("/subscribesuccess");
        this.props.dispatch({
          type: "SUBSCRIBE_DETAIL",
          payload: response.data
        });
      })
      .catch(error => {
        swal("", error.response.data.detail, "error");
        this.setState({
          isSubmitting: false
        });
      });
  }

  skip = () => {
    this.props.history.push("/plans");
  };

  render() {
    if (!this.state.clientToken) {
      return (
        <div>
          <div
            className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid"
            style={{ paddingTop: 250, paddingLeft: "720px" }}
          >
            <Loader
              type="Rings"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={30000} //3 secs
              style={{ margin: "auto" }}
            />
            <h1>Loading...</h1>
          </div>
        </div>
      );
    } else {
      return (
        <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid payment-container">
          <div className="row payment-row">
            <button
              className="goSignIn"
              onClick={() => {
                this.skip();
              }}
            >
              Skip
            </button>
            <div className="col-xl-12">
              <div className="text-center pb-5">
                <img alt="immm" src="/img/logo.svg" className="img-fluid mt-5" aly="logo" />
              </div>
              <div className="text-center">
                <img
                  alt="immm"
                  src="img/creditcard.svg"
                  className="img-fluid justify-content card-img"
                ></img>
              </div>
              <h2 className="font-weight-bold text-center py-3" style={{ color: "#fa694b" }}>
                Your Total is ${this.props.price} for {this.props.duration} Subscription
              </h2>
              <h3 className="payment-title text-center py-3">Pay securely using your Credit or Debit card</h3>
              <div className="kt-portlet kt-portlet--contain payment-form">
                <div className="kt-blog-grid-v2 payment-details">
                  <DropIn
                    options={{
                      authorization: this.state.clientToken
                    }}
                    onInstance={instance => (this.instance = instance)}
                  />
                  <div className="row">
                    <div className="col-12  m-auto py-4 payment-btn">
                      <button
                        className="btn btn-brand btn-elevate btn-pill mx-3 landingBtn"
                        onClick={this.buy.bind(this)}
                        disabled={this.state.isSubmitting}
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStatetoProps = state => {
  return {
    token: state.auth.token,
    usertoken: state.auth.id,
    planid: state.plans.selectedPlan.index,
    price: state.plans.selectedPlan.price,
    duration: state.plans.selectedPlan.duration,
    role: state.auth.role
  };
};

export default connect(mapStatetoProps)(Paypal);
