import React, { useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import MoreItems from "../../../components/MoreItems/MoreItems";
import LinkButton from "../../../components/LinkButton/LinkButton";
import ActionModal from "../../../components/ActionModal/ActionModal";

import NewCouponModal from "./NewCouponModal";
import EditCouponModal from "./EditCouponModal";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');


const CouponManagement = ({ data, handlers }) => {
  const { onSearch, onAddCoupon, onUpdateCoupon, onDeleteCoupon } = handlers;

  const [showNewCouponModal, setShowNewCouponModal] = useState(false);
  const [couponToEdit, setCouponToEdit] = useState(null);
  const [couponToDelete, setCouponToDelete] = useState(null);

  const formatModifiedDate = (row) => {
    const { last_modified } = row;
    return formatDate(last_modified);
  };
  
  const renderLink = ({ row }) => {
    const { id, code, period, redeem_times } = row;
    const valuesToEdit = {
      id,
      code,
      period,
      redeem_times
    };

    return (
      <div className="grid-actions">
        <MoreItems>
          <div>
            <LinkButton onClick={() => setCouponToEdit(valuesToEdit)}>
              Edit
            </LinkButton>
          </div>
          <div>
            <LinkButton onClick={() => setCouponToDelete(id)}>Delete</LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'code', headerName: 'Coupon', width: 200 },
    { field: 'period', headerName: 'Period', width: 200 },
    { field: 'redeem_times', headerName: 'Redeem Times', width: 200 },
    { field: 'last_modified', headerName: 'Modified', width: 300, valueGetter: formatModifiedDate },
    { field: 'id', headerName: '.', width: 300, renderCell: renderLink}
  ];

  const handleDeleteCoupon = () => {
    if (typeof onDeleteCoupon === "function") {
      onDeleteCoupon(couponToDelete, () => {
        setCouponToDelete(null);
      });
    }
  }

  return (
    <div>
      <SearchHeader
        count={data.length}
        countTitle="Coupons"
        placeHolder="Search coupon here"
        addComponent={<ActionIcon icon="action-add-folder" label="NEW COUPON" onClick={() => setShowNewCouponModal(true)} />}
        onSearch={onSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={data}
          columns={columns}
          pageSize={10}
        />
      </div>
      <NewCouponModal
        showModal={showNewCouponModal}
        onClose={() => setShowNewCouponModal(false)}
        onAddCoupon={onAddCoupon} />
      <EditCouponModal
        showModal={!!couponToEdit}
        defaultValues={couponToEdit || {}}
        onClose={() => setCouponToEdit(null)}
        onUpdateCoupon={onUpdateCoupon} />
      <ActionModal
        showModal={!!couponToDelete}
        title="Delete Coupon"
        actionText="Delete"
        onClose={() => setCouponToDelete(null)}
        onAction={handleDeleteCoupon}>
          Do you want to delete this coupon?
      </ActionModal>
    </div>
  );
};

CouponManagement.propTypes = {
  data: PropTypes.array,
  handlers: PropTypes.shape({
    onSearch: PropTypes.func,
    onAddCoupon: PropTypes.func,
    onUpdateCoupon: PropTypes.func,
    onDeleteCoupon: PropTypes.func
  })
};

export default CouponManagement;
