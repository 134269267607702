import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";
import Button from "react-bootstrap/Button";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import EmailDataModal from "../../Member/components/EmailDataModal";
import EmailContentModal from "../../Member/components/EmailContentModal";

const EmailsList = ({ data, onFetchEmails }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [emailData, setEmailData] = useState(false);
  const [content, setContent] = useState(false);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchEmails({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchEmails({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchEmails({ pageNum: 0, pageSize }, searchTerm);
  };

  const formatDate = ({ row }) => {
    const { sent_at } = row;
    return dayjs(sent_at).format('DD/MM/YYYY H:m:s');
  };

  const renderData = ({ row }) => {
    const { data } = row;
    return data && (
      <Button onClick={() => setEmailData(data)}>
        View Data
      </Button>
    );
  };

  const renderContent = ({ row }) => {
    const { content } = row;
    return true && (
      <Button onClick={() => setContent(content)}>
        View Content
      </Button>
    );
  };
  
  const columns = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'recipient', headerName: 'Recipient', width: 300 },
    { field: 'sent_at', headerName: 'Sent At', width: 230, renderCell: formatDate },
    { field: 'template', headerName: 'Template', width: 170 },
    { field: 'data', headerName: 'Data', width: 150, renderCell: renderData },
    { field: 'content', headerName: 'Content', width: 150, renderCell: renderContent }
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Emails"
        placeHolder="Search emails here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.id}
        />
      </div>
      <EmailDataModal
        showModal={!!emailData}
        data={emailData}
        onClose={() => setEmailData(null)}
      />
      <EmailContentModal
        showModal={!!content}
        content={content}
        onClose={() => setContent(null)}
      />
    </div>
  );
};

EmailsList.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchEmails: PropTypes.func
};

export default EmailsList;
