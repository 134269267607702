import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import SearchBar from "../../components/SearchHeader/SearchBar";

import DictionaryItem from "./components/DictionaryItem";

import { getColumns } from "./config";

import { resetCommon } from "../../common/actions/actions";
import { fetchCharacters, fetchWords, fetchSentences } from "./state/actions";

import "./dictionary.scss";

const Dictionary = () => {
  const dispatch = useDispatch();
  const { characters, words, sentences } = useSelector(state => state.dictionary);

  const [keyword, setKeyword] = useState();

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const handleChange = () => {

  };

  const handleSearch = (val) => {
    setKeyword(val);
    dispatch(fetchCharacters(val));
    dispatch(fetchWords(val));
    dispatch(fetchSentences(val));
  };

  const columns = getColumns({
    keyword
  });

  return (
    <div className="dictionary">
      <div>
        <SearchBar
          placeholder="Search by Chinese Characters"
          onChange={handleChange}
          onSearch={handleSearch} />
      </div>
      <div className="tabs">
        <Tabs defaultActiveKey="characters">
          <Tab eventKey="characters" title="Characters">
            <DictionaryItem data={characters} columns={columns['characters']} gridKey="character" />
          </Tab>
          <Tab eventKey="words" title="Words">
            <DictionaryItem data={words} columns={columns['words']} gridKey="id" />
          </Tab>
          <Tab eventKey="sentences" title="Sentences">
            <DictionaryItem data={sentences} columns={columns['sentences']} gridKey="chinese" />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Dictionary;
