import React, { Component } from "react";
import { Link } from "react-router-dom";

class Error extends Component {
  render() {
    return (
      <div>
        <div className="text-center">
          <img src="/img/logo.png" className="img-fluid mt-5" alt="imgh" />
        </div>

        <div className="card-body text-center ">
          <img src="/img/errorpay.svg" v="img-fluid mt-5" alt="imgh" style={{ height: "57vh", width: "auto" }} />
          <h2 className="display-5">There Something Broked. Please try again later</h2>
          <Link to="/login">
            <button
              type="submit"
              id="kt_login_submit"
              className="btn btn-brand btn-elevate btn-pill mx-3 landingBtn w-25 h-5"
            >
              Login
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Error;
