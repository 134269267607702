import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteWordModal = (props) => {
  const { showModal, recordId, onClose, onDelete } = props;

  return (
    <Fragment>
      <Modal centered show={showModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Definition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to delete this definition?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={() => onDelete(recordId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

DeleteWordModal.propTypes = {
  showModal: PropTypes.bool,
  recordId: PropTypes.number,
  onClose: PropTypes.func,
  onDelete: PropTypes.func
};

export default DeleteWordModal;
