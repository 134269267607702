import React from "react";
import PropTypes from "prop-types";

import { DataGrid } from "@material-ui/data-grid";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";

const columns = [
  { field: 'text', headerName: 'Text', width: 700 },
  { field: 'start', headerName: 'Start', width: 200 },
  { field: 'duration', headerName: 'End', width: 250 },
];

const VideoTranscripts = ({ data }) => (
  <div>
    <SearchHeader
      count={data.length}
      countTitle="Video Transcripts"
    />
    <div>
      <DataGrid
        autoHeight={true}
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        rowCount={data.length}
        getRowId={row => row.text}
      />
    </div>
  </div>
);

VideoTranscripts.propTypes = {
  data: PropTypes.array
};

export default VideoTranscripts;
