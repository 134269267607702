import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import MoreItems from "../../../../components/MoreItems/MoreItems";

import NewMemberModal from "./NewMemberModal";
import DeleteMemberModal from "./DeleteMemberModal";

import { resetCommon } from "../../../../common/actions/actions";
import {
  fetchClassMembers,
  addMembers,
  deleteMember
} from "../../state/actions";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const Members = ({ classId }) => {
  const dispatch = useDispatch();
  const { classMembers } = useSelector(state => state.classes);

  const [showNewModal, setShowNewModal] = useState(false);
  const [toDeleteRecordId, setToDeleteRecordId] = useState('');

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const renderLink = ({ row }) => {
    const { id, user_id } = row;
    return (
      <div className="grid-actions">
        <Link to={`/members/details/${user_id}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  }
  
  const columns = [
    { field: 'user_name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'role', headerName: 'Role', width: 150 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatDate },
    { field: 'id', headerName: '-', width: 100, renderCell: renderLink}
  ];

  const composeFilters = (searchTerm = "") => {
    return `class_id=eq.${classId}&email=ilike.%25${searchTerm.toLowerCase()}%25&order=last_modified.desc`;
  };

  useEffect(() => {
    dispatch(fetchClassMembers(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    dispatch(fetchClassMembers(composeFilters(searchTerm)));
  };

  const handleAddNewMember = (row) => {
    if (Array.isArray(row) && row.length > 0) {
      dispatch(addMembers(row, () => {
        dispatch(fetchClassMembers(composeFilters()));
      }));
      setShowNewModal(false);
    }
  };

  const handleDelete = (email) => {
    const onSuccess = () => {
      dispatch(fetchClassMembers(composeFilters()));
    };
    dispatch(deleteMember(toDeleteRecordId, onSuccess));
    setToDeleteRecordId(null);
  };

  return (
    <div className="class-members">
      <SearchHeader
        count={classMembers.length}
        countTitle="Members"
        placeHolder="Search members"
        addComponent={<ActionIcon icon="action-add-folder" label="ADD MEMBER" onClick={() => setShowNewModal(true)} />}
        onSearch={handleSearch}
      />
      <div className="classes-results">
        <DataGrid
          className="classes-grid"
          autoHeight={true}
          rows={classMembers}
          columns={columns}
          pageSize={10}
        />
      </div>
      <NewMemberModal
        showModal={showNewModal}
        classId={classId}
        onHide={() => setShowNewModal(false)}
        onAddNewMember={handleAddNewMember}
      />
      <DeleteMemberModal
        showModal={!!toDeleteRecordId}
        recordId={toDeleteRecordId}
        onHide={() => setToDeleteRecordId(null)}
        onDelete={handleDelete}
      />
    </div>
  );
};

Members.propTypes = {
  classId: PropTypes.string
};

export default Members;