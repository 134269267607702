import { makeApiCall } from "../../helpers/api-client";
import * as Actions from "./action-types";

const defaultError = {
  type: "toast",
  message: "There was a problem with your request. Please try again."
};

const defaultNotice = {
  message: "Something went wrong. Please try again."
};

export const throwApiError = (payload = defaultError) => ({
  type: Actions.THROW_API_ERROR,
  payload
});

export const resetApiError = () => ({
  type: Actions.RESET_API_ERROR
});

export const showNotice = (payload = defaultNotice) => ({
  type: Actions.SHOW_NOTICE,
  payload
});

export const resetNotice = () => ({
  type: Actions.RESET_NOTICE
});

export const setNextRoute = payload => ({
  type: Actions.SET_NEXT_ROUTE,
  payload
});

export const resetNextRoute = () => ({
  type: Actions.RESET_NEXT_ROUTE
});

export const resetAllNotices = () => ({
  type: Actions.RESET_ALL_NOTICES
});

export const resetCommon = () => ({
  type: Actions.RESET_ALL
});

export const getLoginStatus = (onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall("/rpc/me");
    if (typeof onSuccess === "function") {
      onSuccess(response);
    }
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
  }
};
