import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Link } from "react-router-dom";

import { resetCommon } from '../../common/actions/actions';

import "./reset-password-notice.scss";

const ResetPasswordNotice = () => {
  const dispatch = useDispatch();
  const { resetEmail } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  return (
    <div className="reset-notice-container">
      <h3 className="title">Reset Password</h3>
      <p className="sub-title">
        If we found a matching account for {resetEmail}, a password reset link will be landing in your email inbox.
      </p>
      <p className="center-text">Go back to <Link to="/">FullChinese Pro homepage</Link></p>
      <p className="center-text">Email didn’t arrive? <Link to="/resetpassword">Try again</Link></p>
    </div>
  );
};

export default ResetPasswordNotice;

