import {
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_SUBSCRIPTION_SUCCESS,
  FETCH_PLAN_SUCCESS,
  FETCH_MEMBER_CLASSES_SUCCESS,
  FETCH_MEMBER_BOOKS_SUCCESS,
  FETCH_MEMBER_TEXTS_SUCCESS,
  FETCH_EMAILS_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_MEMBER_PAYMENTS_SUCCESS,
  FETCH_USER_POSTS_SUCCESS
} from "./action-types";

const initialValue = {
  member: {},
  subscription: {},
  plan: {},
  classes: [],
  books: [],
  texts: [],
  emails: [],
  events: {
    numRecords: 0,
    rows: []
  },
  payments: {
    numRecords: 0,
    rows: []
  },
  userPosts: {
    numRecords: 0,
    rows: []
  }
};

const memberReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_MEMBER_SUCCESS:
      return {...state, member: payload}
    case FETCH_MEMBER_SUBSCRIPTION_SUCCESS:
      return {...state, subscription: payload}
    case FETCH_PLAN_SUCCESS:
      return {...state, plan: payload}
    case FETCH_MEMBER_CLASSES_SUCCESS:
      return {...state, classes: payload}
    case FETCH_MEMBER_BOOKS_SUCCESS:
      return {...state, books: payload}
    case FETCH_MEMBER_TEXTS_SUCCESS:
      return {...state, texts: payload}
    case FETCH_EMAILS_SUCCESS:
      return {...state, emails: payload}
    case FETCH_EVENTS_SUCCESS:
      return {...state, events: payload}
    case FETCH_MEMBER_PAYMENTS_SUCCESS:
      return {...state, payments: payload}
    case FETCH_USER_POSTS_SUCCESS:
      return {...state, userPosts: payload}
    default:
      return state;
  }
};

export default memberReducer;
