import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Accordion, Card } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";

import dayjs from "dayjs";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import MoreItems from "../../../components/MoreItems/MoreItems";
import LinkButton from "../../../components/LinkButton/LinkButton";

import "./collection-item.scss";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = (row) => {
  const { last_modified } = row;
  return formatDate(last_modified);
};

const formatPrice = ({ row }) => {
  const { price } = row;

  return `$${price}`;
};

const CollectionItem = (props) => {
  const {
    eventKey,
    title,
    canEdit,
    data = [],
    onFetchCollection,
    onDelete,
    onEdit,
    onAdd,
    onEditBook,
    onDeleteBook
  } = props;
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchCollection({ pageNum: page, pageSize });
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchCollection({ pageNum: 0, pageSize });
  };

  const renderLink = ({ row }) => {
    const { book_sha1, price, plan, downloads, idx } = row;
  
    return (
      <div className="grid-actions">
        <Link to={`/books/details/${book_sha1}`}>Details</Link>
        {
          canEdit && (
            <MoreItems>
              <div>
                <LinkButton onClick={() => onEditBook({
                  index: parseInt(eventKey),
                  idx,
                  title,
                  bookSha1: book_sha1,
                  price,
                  plan,
                  downloads
                })}>
                  Edit
                </LinkButton>
              </div>
              <div>
                <LinkButton onClick={() => onDeleteBook({
                  index: parseInt(eventKey),
                  title,
                  bookSha1: book_sha1
                })}>
                  Delete
                </LinkButton>
              </div>
            </MoreItems>
          )
        }
      </div>
    );
  };

  const columns = [
    { field: 'book_title', headerName: 'Title', width: 400 },
    { field: 'book_description', headerName: 'Description', width: 300 },
    { field: 'author', headerName: 'Author', width: 120 },
    { field: 'book_level', headerName: 'Level', width: 100 },
    { field: 'price', headerName: 'Price', width: 100, valueGetter: formatPrice },
    { field: 'plan', headerName: 'Plan', width: 100 },
    { field: 'downloads', headerName: 'Downloads', width: 120 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
  ];

  const handleAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onAdd(title);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete();
  };

  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        <div className="collection-header">
          <div>
            <h4>{title}</h4>
            <div>{data.length} Books</div>
          </div>
          {
            canEdit && (
              <div className="collection-actions">
                <ActionIcon icon="action-add" label="NEW BOOKS" onClick={handleAdd} />
                <ActionIcon icon="action-edit" label="EDIT" onClick={handleEdit} />
                <ActionIcon icon="action-trashcan" label="DELETE" onClick={handleDelete} />
              </div>
            )
          }
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <DataGrid
          autoHeight={true}
          rows={data}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={data.length}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Accordion.Collapse>
    </Card>
  );
};

export default CollectionItem;
