import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Field from "./Field";

const isValidUrl = (url) => {
  const urlRegex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
  const result = url.match(urlRegex);

  return result !== null;
};

const Website = ({ value: val, onSave }) => {
  const [value, setValue] = useState(val);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(val);
  }, [val]);

  const handleChange = (ev) => {
    const val = ev.target.value;
    const trimmedValue = val.trim();
    setValue(trimmedValue);
    setError(!isValidUrl(trimmedValue));
  };

  return (
    <Field value={value} onSave={onSave} hasError={error}>
      <Form.Control
        name="website"
        type="text"
        placeholder="Enter Website"
        value={value}
        isInvalid={error}
        onChange={handleChange}
      />
      {
        error && (
          <Form.Control.Feedback type="inValid">
            Invalid website address
          </Form.Control.Feedback>
        )
      }
    </Field>
  );
};

Website.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func
};

export default Website;