import React from "react";
import Footer from "../../components/HomePage/Footer";
import Header from "../../components/HomePage/Header";

import IntroVideo from "./IntroVideo";
import CTA from "./cta";

import "./landing.scss";

const HomePage = () => {

  return (
    <div className="kt-login-v2 home-page">
      <Header />
      <div className="introduction-part flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="text-part">
            <p className="The-most-efficient-w">The most efficient way to learn Chinese</p>
            <p className="Learn-Chinese-all-th">
              Learn Chinese all the way to complete fluency with fully integrated learning experience
            </p>
            <CTA />
            <p className="Basic-knowledge-of-C">
              Basic knowledge of Chinese is required for the proper learning experience. <br />
            </p>
          </div>
          <div className="image-part">
            <img src="/img/image-a.png" alt="ima" />
          </div>
        </div>
      </div>

      <IntroVideo />

      <div className="texts-and-lessons flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="image-part">
            <img src="/img/image-b.png" alt="ima" className="w-100" />
          </div>
          <div className="text-part">
            <p className="title">Hundreds of Texts and Lessons</p>
            <p className="content">
              Every text you study is fully recorded, by native speakers. Every sentence is pre-translated, and every
              word is annotated. Make the most of your study time and understand everything.
            </p>
          </div>
        </div>
      </div>
      <div className="study-your-content flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="text-part">
            <p className="title">Study Your Own Content</p>
            <p className="content">
              Not only can you study huge amount of FullChinese content, but you can also study your own content. We
              use FullChinese proprietary AI solution to pre-analyze these texts.
            </p>
            <CTA hideDownloadOptions={true} />
          </div>
          <div className="image-part">
            <img src="/img/image-c.png" alt="ima" className="w-100" />
          </div>
        </div>
      </div>
      <div className="dictionary-part flex-justify-center">
        <div className="elements-part">
          <div className="part-1">
            <div className="content-detail content-detail-1">
              <img src="/img/icon-a.png" alt="ima" />
              <p className="title">More than 100,000 words dictionary</p>
              <p className="content">Characters are decomposed for better memorizatoin</p>
            </div>
            <div className="content-detail content-detail-2">
              <img src="/img/icon-b.png" alt="ima" />
              <p className="title">15,000 sentences dictionary</p>
              <p className="content">All recorded by native speakers</p>
            </div>
          </div>
          <div className="part-2">
            <div className="content-detail content-detail-3">
              <img src="/img/icon-c.png" alt="ima" />
              <p className="title">Flashcards and Quizzes</p>
              <p className="content">Build your own flashcards, decks. Separate what’s important and what’s not.</p>
            </div>
            <div className="content-detail content-detail-4">
              <p className="title">And much more…</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="who-can-use-fullchi">
        <p className="title">Who can use FullChinese?</p>
        <p className="content">To use FullChinese, you need to know the basics:</p>
        <div className="character-part flex-align-center">
          <div className="pinyin">
            <img src="/img/icon-pinyin.png" alt="pinyin" />
            <p>Pinyin</p>
          </div>
          <div className="few-characters">
            <img src="/img/icon-characters.png" alt="character" />
            <p>Few Characters</p>
          </div>
        </div>
        <div className="learn-more">
          <div className="introduct-part">
            <div className="image-texts">
              <img src="/img/icon-students.png" alt="students" />
              <div className="content-details">
                <p className="learn-title">Students</p>
                <p className="learn-content">From Beginner to Fluent</p>
              </div>
            </div>
          </div>
          <div className="introduct-part">
            <div className="image-texts">
              <img src="/img/icon-students.png" alt="teachers" />
              <div className="content-details">
                <p className="learn-title">College or High School Teachers</p>
                <p className="learn-content">大学和高中老师</p>
              </div>
            </div>
          </div>
          <div className="introduct-part">
            <div className="image-texts">
              <img src="/img/icon-tutors.png" alt="tutors" />
              <div className="content-details">
                <p className="learn-title">One on One Tutors</p>
                <p className="learn-content">对一对教中</p>
              </div>
            </div>
          </div>
          <CTA />
        </div>
      </div>
      <div className="short-learning-time flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="image-part">
            <img src="/img/image-g.png" alt="ima" className="w-100" />
          </div>
          <div className="text-part">
            <p className="title">Short Learning Time, Faster Fluency</p>
            <p className="content">
              FullChinese completely revolutionized the whole process of learning Chinese by presenting the right information at the right time.<br /><br/>
              The app is designed to feed your brain with pieces of information at a designated time making your learning process more enjoyable and efficient. 
            </p>
          </div>
        </div>
      </div>
      <div className="all-in-one flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="title-part">
            <p className="title">All-in-one Tool for Serious Learners</p>
            <p className="content">
              Many students in Top US Universities have seen massive improvement in their Chinese proficiency using
              FullChinese
            </p>
          </div>
          <div className="flow-part">
            <p className="content">
              FullChinese completely transformed the way I engaged with my Chinese language development. I was so impressed by the fact that the app contains everything I need to study. I’ve never seen a platform that serves not only as a textbook, but also contains quizzes and listening practice. I am so impressed with all of the features included in the app. <br /><br/>For example, I can easily flag and save unfamiliar words for later studying. The app creates flashcard decks for the words flagged within a given text. I found this feature one of the most helpful considering I am always using and making flashcards to study words. Now I can practice reading, writing, listening, and speaking at the touch of a button. I love how you can adjust the speed of listening comprehension; it creates an individualized experience. I would recommend this app for anyone wanting to take their language skills to the next level!"
            </p>
            <p className="name">Gretchen W.,</p>
            <p className="job">Wellesley College ‘22</p>
          </div>
        </div>
      </div>
      <div className="mobile-introduct flex-justify-center">
        <div className="elements-part flex-align-center">
          <div className="text-part">
            <img src="/img/app-logo.svg" alt="logo" />
            <p className="Learn-Chinese-all-th">Start Learning with FullChinese</p>
            <CTA />
          </div>
          <div className="image-part">
            <img src="/img/image-k.png" alt="ima" className="w-100" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
