import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import PaymentHistory from "./components/PaymentHistory";
import CouponManagement from "./components/CouponManagement";
import PlanManagement from "./components/PlanManagement";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchPayments,
  fetchCoupons,
  addNewCoupon,
  updateCoupon,
  deleteCoupon,
  fetchPlans,
  addNewPlan,
  updatePlan,
  deletePlan
} from "./state/actions";

const Payments = () => {
  const dispatch = useDispatch();

  const { id } = useSelector(state => state.auth);
  const { payments, coupons, plans } = useSelector(state => state.payments);
  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPayments({pageNum: 0, pageSize: 10}));
    dispatch(fetchCoupons());
    dispatch(fetchPlans());
  }, [dispatch]);

  const handleCouponSearch = (searchTerm) => dispatch(fetchCoupons(searchTerm));
  const handleCouponActionComplete = (onComplete) => {
    if (typeof onComplete === "function") {
      onComplete();
    }
    dispatch(fetchCoupons());
  };

  const handleAddCoupon = (payload, onComplete) => {
    const finalPayload = {
      ...payload,
      owner: id,
      user_level: "silver",
    };

    dispatch(addNewCoupon(finalPayload, () => handleCouponActionComplete(onComplete)));
  };

  const handleUpdateCoupon = (id, payload, onSuccess, onFail) => {
    dispatch(updateCoupon(id, payload, () => handleCouponActionComplete(onSuccess), onFail));
  };

  const handleDeleteCoupon = (id, onComplete) => {
    dispatch(deleteCoupon(id, () => handleCouponActionComplete(onComplete)));
  }

  const handlePlanSearch = (searchTerm) => dispatch(fetchPlans(searchTerm));
  const handlePlansActionComplete = (onComplete) => {
    if (typeof onComplete === "function") {
      onComplete();
    }
    dispatch(fetchPlans());
  };

  const handleAddPlan = (payload, onSuccess, onFail) => {
    dispatch(addNewPlan(payload, () => handlePlansActionComplete(onSuccess), onFail));
  };
  const handleUpdatePlan = (id, payload, onSuccess, onFail) => {
    dispatch(updatePlan(id, payload, onSuccess, onFail));
  };

  const handleDeletePlan = (id, onComplete) => {
    dispatch(deletePlan(id, () => handlePlansActionComplete(onComplete)));
  };

  return (
    <div>
      <Tabs className="tabs">
        <Tab eventKey="payments" title="Payment History">
          <PaymentHistory
            data={payments}
            onFetchPayments={(pagination, searchTerm) => dispatch(fetchPayments(pagination, searchTerm))}
          />
        </Tab>
        <Tab eventKey="subscription" title="Coupon Management">
          <CouponManagement
            data={coupons}
            handlers={{
              onSearch: handleCouponSearch,
              onAddCoupon: handleAddCoupon,
              onUpdateCoupon: handleUpdateCoupon,
              onDeleteCoupon: handleDeleteCoupon
            }}
          />
        </Tab>
        <Tab eventKey="classes" title="Plan Management">
          <PlanManagement
            data={plans}
            handlers={{
              onSearch: handlePlanSearch,
              onAddPlan: handleAddPlan,
              onUpdatePlan: handleUpdatePlan,
              onDeletePlan: handleDeletePlan
            }}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Payments;