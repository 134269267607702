import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import ActionModal from "../../../../components/ActionModal/ActionModal";
import MoreItems from "../../../../components/MoreItems/MoreItems";
import LinkButton from "../../../../components/LinkButton/LinkButton";

import EditNotebookModal from "./EditNotebookModal";

import "./notebooks.scss";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};

const renderBookTitle = ({ row }) => {
  const { book_cover_uri, book_title } = row;

  const handleError = (ev) => ev.target.src = "/img/book-cover.png";

  return (
    <span>
      <img
        className="book-cover"
        src={
          book_cover_uri && book_cover_uri.slice(-4) === ".png"
            ? `https://fullapi.s3.amazonaws.com/${row.book_cover_uri}`
            : "/img/book-cover.png"
        }
        alt="book cover"
        onError={handleError}
      />
      {book_title}
    </span>
  )
}

const NoteBooks = ({ data, onFetchNotebooks, onDeleteNotebook, onUpdateNotebook }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [bookToDelete, setBookToDelete] = useState(null);
  const [bookToEdit, setBookToEdit] = useState(null);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchNotebooks({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchNotebooks({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchNotebooks({ pageNum: 0, pageSize }, searchTerm);
  };

  const handleDeleteBook =  () => {
    if (typeof onDeleteNotebook === "function") {
      onDeleteNotebook(bookToDelete, () => {
        setBookToDelete(null);
      });
    }
  };

  const renderLink = ({ row }) => {
    const { sha1, book_sha1, book_title, book_description } = row;
  
    return (
      <div className="grid-actions">
        <Link to={`/books/details/${book_sha1}?type=notebook`}>Details</Link>
        <MoreItems>
        <div>
            <LinkButton onClick={() => setBookToEdit({
              sha1,
              book_title,
              book_description
            })}>
              Edit
            </LinkButton>
          </div>
          <div>
            <LinkButton onClick={() => setBookToDelete(book_sha1)}>Delete</LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'book_title', headerName: 'Title', width: 400, renderCell: renderBookTitle },
    { field: 'book_description', headerName: 'Description', width: 400 },
    { field: 'author', headerName: 'Author', width: 140 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
  ];

  return (
    <div className="texts-notebooks">
      <SearchHeader
        count={numRecords}
        countTitle="Notebooks"
        placeHolder="Search notebooks here"
        addComponent={<ActionIcon icon="action-add-folder" label="New Notebook" to="/books/new" />}
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
      <ActionModal
        showModal={!!bookToDelete}
        title="Delete Notebook"
        actionText="Delete"
        onClose={() => setBookToDelete(null)}
        onAction={handleDeleteBook}
      >
        Do you want to delete this notebook?
      </ActionModal>
      <EditNotebookModal
        show={!!bookToEdit}
        value={bookToEdit}
        onUpdateNotebook={onUpdateNotebook}
        onClose={() => setBookToEdit(null)}
      />
    </div>
  );
};

NoteBooks.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchNotebooks: PropTypes.func,
  onDeleteNotebook: PropTypes.func,
  onUpdateNotebook: PropTypes.func
};

export default NoteBooks;
