import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Pages from "./components/Pages";
import NewsFeedPreview from "./components/NewsFeedPreview";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchNewsfeed,
  removeFromNewsfeed,
  updateNewsfeed,
  fetchPagePreviewData
} from "./state/actions";

const NewsFeedContainer = () => {
  const dispatch = useDispatch();
  const { pages, selectedPage } = useSelector(state => state.newsfeed);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNewsfeed({
      pageNum: 0,
      pageSize: 10
    }));
  }, [dispatch]);

  const refreshNewsfeed = () => {
    dispatch(fetchNewsfeed({
      pageNum: 0,
      pageSize: 10
    }));
  };

  const handleFetchNewsfeed = (pagination, searchTerm) => {
    dispatch(fetchNewsfeed(pagination, searchTerm));
  };

  const handleUpdateNewsfeed = (id, payload, onSuccess, onFail) => {
    const handleSuccess = () => {
      if (typeof onSuccess === 'function') {
        refreshNewsfeed();
        onSuccess();
      }
    };
    dispatch(updateNewsfeed(id, payload, handleSuccess, onFail));
  }

  const handleRemove = (id, onSuccess, onFail) => {
    const handleSuccess = () => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      refreshNewsfeed();
    };

    dispatch(removeFromNewsfeed(id, handleSuccess, onFail));
  };

  const handleFetchPagePreview = (id) => {
    dispatch(fetchPagePreviewData(id));
  };

  return (
    <div className="tabs">
      <Tabs defaultActiveKey="pages">
        <Tab eventKey="pages" title="Pages">
          <Pages
            data={pages}
            selectedPage={selectedPage}
            onFetchNewsFeed={handleFetchNewsfeed}
            onUpdateNewsfeed={handleUpdateNewsfeed}
            onRemove={handleRemove}
            onPagePreview={handleFetchPagePreview} />
        </Tab>
        <Tab eventKey="mobile" title="Mobile View">
          <NewsFeedPreview data={pages?.rows} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default  NewsFeedContainer;

