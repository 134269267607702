import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import MoreItems from "../../../../components/MoreItems/MoreItems";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionModal from "../../../../components/ActionModal/ActionModal";

import AddTextModal from "./AddTextModal";
import EditTextModal from "./EditTextModal";

import {
  fetchBookTextsShaList,
  fetchBookTexts,
  fetchAvailableTexts,
  addTextsToBook,
  deleteBookText,
  updateBookText,
} from "../../state/actions";

import "./book-texts.scss";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified && formatDate(last_modified);
};

const renderSound = ({ row }) => {
  const { nb_sounds } = row;
  const iconToDisplay = nb_sounds > 0 ? "/img/status-sound.svg" : "/img/status-sound-no.svg";
  return (
    <span>
      <img src={iconToDisplay} className="size-24-24" alt="sound"/> {nb_sounds > 0 ? nb_sounds : "No sound"}
    </span>
  );
};

const BookTexts = ({ bookSha }) => {
  const dispatch = useDispatch();

  const { id } = useSelector(state => state.auth);
  const { bookTexts, availableTexts } = useSelector(state => state.texts);
  const { textShaList, numRecords, rows } = bookTexts;

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showAddTextModal, setShowAddTextModal] = useState(false);
  const [textToDelete, setTextToDelete] = useState(null);
  const [textToEdit, setTextToEdit] = useState(null);

  const refreshTextsList = useCallback(() => {
    dispatch(fetchBookTextsShaList(bookSha, (shaList) => {
      dispatch(fetchBookTexts({
        pageNum: 0,
        pageSize: 10
      }, bookSha))

      dispatch(fetchAvailableTexts({
        pageNum: 0,
        pageSize: 10
      }, id, shaList));
    }));

    setPage(0);
  }, [id, bookSha, dispatch]);

  useEffect(() => {
    refreshTextsList();
  }, [refreshTextsList]);

  const handlePageChange = ({ page, pageSize }) => {
    setPage(page);
    dispatch(fetchBookTexts({ pageNum: page, pageSize }, bookSha, searchTerm || ""));
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPage(0);
    setPageSize(pageSize);
    dispatch(fetchBookTexts({ pageNum: 0, pageSize }, bookSha, searchTerm || ""));
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setPage(0);
    dispatch(fetchBookTexts({ pageNum: 0, pageSize }, bookSha ,searchTerm || ""));
  };

  const handleAddTexts = (payload, onSuccess, onFail) => {
    const handleSuccess = () => {
      refreshTextsList();
      onSuccess();
    }

    dispatch(addTextsToBook(payload, handleSuccess, onFail));
  }

  const handleSearchAvailableTexts = (pagination, searchTerm) => {
    dispatch(fetchAvailableTexts(pagination, id, textShaList, searchTerm));
  };

  const handleRemoveText = () => {
    dispatch(deleteBookText(bookSha, textToDelete, () => {
      refreshTextsList();
      setTextToDelete(null);
    }))
  };

  const handleUpdateText = (textSha, payload, onSuccess, onFail) => {
    dispatch(updateBookText(bookSha, textSha, payload, onSuccess, onFail));
  };

  const renderActions = () => {
    return (
      <div className="text-actions">
        <ActionIcon icon="action-add" label="New Text" to="/texts/new" />
        <ActionIcon icon="action-import" label="Add Texts" onClick={() => setShowAddTextModal(true)} />
      </div>
    );
  };

  const renderLink = ({ row }) => {
    const { sha1, idx } = row;
  
    return (
      <div className="grid-actions">
        <Link to={`/texts/details/${sha1}`}>Details</Link>
        <MoreItems>
          <div>
            <div>
              <LinkButton onClick={() => setTextToEdit({ sha1, idx })}>Edit</LinkButton>
            </div>
            <div>
              <LinkButton onClick={() => setTextToDelete(sha1)}>Remove</LinkButton>
            </div>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'title', headerName: 'Title', width: 400 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'nb_sounds', headerName: 'Sound', width: 120, renderCell: renderSound },
    { field: 'actions', headerName: '.', width: 150, renderCell: renderLink}
  ];

  return (
    <div className="book-texts-section">
      <SearchHeader
        count={numRecords}
        countTitle="Texts"
        placeHolder="Search texts here"
        addComponent={renderActions()}
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
      <AddTextModal
        id={id}
        bookSha={bookSha}
        showModal={showAddTextModal}
        texts={availableTexts}
        textShaList={textShaList}
        onClose={() => setShowAddTextModal(false)}
        onFetchTexts={handleSearchAvailableTexts}
        onAddTexts={handleAddTexts}
      />
      <ActionModal
        showModal={!!textToDelete}
        title="Remove Text"
        actionText="Remove"
        onClose={() => setTextToDelete(null)}
        onAction={handleRemoveText}>
          Do you want to remove this text?
      </ActionModal>
      <EditTextModal
        value={textToEdit}
        show={!!textToEdit}
        onClose={() => setTextToEdit(null)}
        onUpdateText={handleUpdateText}
      />
    </div>
  );
};

BookTexts.propTypes = {
  bookSha: PropTypes.string
};

export default BookTexts;
