import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import SearchHeader from "../../../../components/SearchHeader/SearchHeader";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};

const renderSound = ({ row }) => {
  const { numSounds } = row;
  const iconToDisplay = numSounds > 0 ? "/img/status-sound.svg" : "/img/status-sound-no.svg";
  return (
    <span>
      <img src={iconToDisplay} className="size-24-24" alt="sound"/> {numSounds > 0 ? numSounds : "No sound"}
    </span>
  );
};

const renderLink = ({ row }) => {
  const { text_sha1 } = row;

  return (
    <div className="grid-actions">
      <Link to={`/texts/details/${text_sha1}`}>Details</Link>
    </div>
  );
};

const columns = [
  { field: 'text_title', headerName: 'Title', width: 400 },
  { field: 'book_title', headerName: 'Book/Notebook', width: 200 },
  { field: 'numSounds', headerName: 'Sound', width: 180, renderCell: renderSound },
  { field: 'author', headerName: 'Author', width: 140 },
  { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
  { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
];

const Texts = ({ data, onFetchTexts }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchTexts({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchTexts({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchTexts({ pageNum: 0, pageSize }, searchTerm);
  };

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Texts"
        placeHolder="Search texts here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.text_sha1}
        />
      </div>
    </div>
  );
};

Texts.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchTexts: PropTypes.func
};

export default Texts;
