import React, { useEffect, useState } from "react";
import uuidv4 from "uuid/v4";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../../components/LinkButton/LinkButton";

import { resetCommon } from "../../../../common/actions/actions";

import {
  createBook,
  uploadFile
} from "../../state/actions";

import "./new-book.scss";

const schema = yup.object().shape({
  title: yup.string().required("This field cannot be empty"),
  author: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty")
});

const NewBook = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.auth);
  const { nextRoute } = useSelector(state => state.common);

  const [ fileKey, setFileKey] = useState(null);
  const [submitting, setSubmitting] = useState(false);


  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    const bookSha1 = uuidv4();
    const payloadBooks = {
      sha1: bookSha1,
      owner: id,
      cover_uri: fileKey,
      nb_texts: 0,
      ...values
    };

    const payloadBookStateItems = {
      sha1: uuidv4(),
      book_cover_uri: fileKey,
      book_sha1: bookSha1,
      book_title: values.title,
      author: values.author,
      book_description: values.description,
      permission: 'owner'
    };

    setSubmitting(true);
    dispatch(createBook(payloadBooks, payloadBookStateItems));
  };

  const handleUploadBookCover = (ev) => {
    const file = ev.target.files[0];

    dispatch(uploadFile({ file }, "png", (key) => {
      document.getElementById("book-cover-image-thumb").src = URL.createObjectURL(file);
      document.getElementById("book-cover-image").src = URL.createObjectURL(file);

      setFileKey(key);
    }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="new-book">
      <div className="header">
        <LinkButton onClick={() => history.goBack()}>
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <h3>New Book</h3>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Book Title</Form.Label>
            <Col xs="8">
              <Form.Control
                name="title"
                type="text"
                placeholder="Enter book title here"
                ref={register}
                isInvalid={!!errors.title}
              />
              <Form.Control.Feedback type="inValid">{errors.title?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Author</Form.Label>
            <Col xs="8">
              <Form.Control
                name="author"
                type="text"
                placeholder="Enter author here"
                ref={register}
                isInvalid={!!errors.author}
              />
              <Form.Control.Feedback type="inValid">{errors.author?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Description</Form.Label>
            <Col xs="8">
              <Form.Control
                name="description"
                as="textarea"
                rows={8}
                placeholder="Enter content here"
                ref={register}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Book Cover</Form.Label>
            <Col xs="8">
              <div className="book-cover-field">
                <img
                  src="/img/book-cover.png"
                  className="img-fluid"
                  id="book-cover-image"
                  alt="class-logo"
                />
                <div>
                  <label>
                    <span className="upload-btn">UPLOAD</span>
                    <Form.Control
                      type="file"
                      name="file"
                      accept=".png"
                      onChange={handleUploadBookCover}
                      className="book-cover-input" />
                  </label>
                </div>
              </div>
            </Col>
          </Form.Group>

          <div className="actions">
            <Button type="submit" disabled={submitting}>
              Create
            </Button>
            <Button type="button" variant="outline-primary" disabled={submitting} onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NewBook;
