import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import VideoList from "./VideoList";

import { resetCommon } from "../../../../common/actions/actions";
import { fetchVideos } from "../../state/actions";

const Videos = () => {
  const dispatch = useDispatch();
  const { videos } = useSelector(state => state.videos);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchVideos({pageNum: 0, pageSize: 10}));
  }, [dispatch]);

  const handleFetchVideos = (pageData, searchTerm) => {
    dispatch(fetchVideos(pageData));
  };

  return (
    <div>
      <VideoList data={videos} onFetchVideos={handleFetchVideos} />
    </div>
  );
};

export default Videos;
