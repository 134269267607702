import React from "react";
import PropTypes from "prop-types";

import SearchBar from "./SearchBar";
import "./SearchHeader.scss";

const SearchHeader = (props) => {
  const { count, countTitle, placeHolder, addComponent, onChange, onSearch } = props;
  return (
    <div className="search-header">
      <h3>{typeof count === 'number' && `${count} ${countTitle}`}</h3>
      <div className="search">
        {typeof onSearch === "function" && <SearchBar placeholder={placeHolder} onChange={onChange} onSearch={onSearch} />}
      </div>
      <div>
        {addComponent}
      </div>
    </div>
  );
};

SearchHeader.propTypes = {
  count: PropTypes.number,
  countTitle: PropTypes.string,
  placeHolder: PropTypes.string,
  addComponent: PropTypes.node,
  onChange: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchHeader;
