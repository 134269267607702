import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../../components/SearchHeader/SearchBar";

import "./AddBookModal.scss";


const AddBookModal = (props) => {
  const { orgId, books, showModal, onHide, onFetchBooks, onAddBooks } = props;
  const [selectedBooks, setSelectedBooks] = useState([]);

  useEffect(() => {
    if (showModal) {
      setSelectedBooks([]);
    }
  }, [showModal]);

  const handleSelectBook = (ev, data) => {
    const selected = ev.target.checked;
    const selectedBook = {
      org_id: orgId,
      book_sha1: data.sha1,
      book_description: data.description,
      book_cover_uri: data.cover_uri,
      book_title: data.title,
      book_level: data.level,
      publish_time: data.last_modified
    };
    if(selected) {
      setSelectedBooks(selectedBooks.concat([selectedBook]));
    } else {
      setSelectedBooks(selectedBooks.filter(book => book.book_sha1 !== data.sha1));
    }
  };

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        onClick={(ev) => handleSelectBook(ev, row)}
      />
    );
  };
  const columns = [
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'author', headerName: 'Author', width: 100 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'id', headerName: '-', width: 100, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    onFetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    onFetchBooks(searchTerm);
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={onHide} className="new-class-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add New Book to This Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="book-search">
            <SearchBar placeHolder="Search: Book with Title" onSearch={handleSearch} />
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={books}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={() => onAddBooks(selectedBooks)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AddBookModal.propTypes = {
  showModal: PropTypes.bool,
  books: PropTypes.array,
  bookSha: PropTypes.array,
  onHide: PropTypes.func,
  onFetchBooks: PropTypes.func,
  onAddBooks: PropTypes.func
};

export default AddBookModal;
