import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup.object().shape({
  idx: yup.string().required("This field cannot be empty"),
  price: yup.string().required("This field cannot be empty"),
  plan: yup.string().required("This field cannot be empty")
});

const EditBookModal = ({ value = {}, show, onClose, onUpdateBook }) => {
  const [submitting, setSubmitting] = useState(false);

  const { index, bookSha1, title, price, plan, idx } = value || {};
  const defaultValues = {
    price,
    plan,
    idx
  };

  const { register, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    const { price, plan } = value || {};
    const defaultValues = {
      price,
      plan,
      idx
    };
    reset(defaultValues);
  }, [reset, value]);

  const onSubmit = (values) => {
    setSubmitting(true);
    onUpdateBook({
      index,
      title,
      bookSha1,
      data: values
    } , () => {
      setSubmitting(false);
      onClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Book</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <Form.Group controlId="idx">
            <Form.Label>IDX</Form.Label>
            <Form.Control
              name="idx"
              type="number"
              placeholder="Enter idx value"
              ref={register}
              isInvalid={!!errors.idx}
            />
            <Form.Control.Feedback type="inValid">{errors.idx?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="title">
            <Form.Label>Price</Form.Label>
            <Form.Control
              name="price"
              type="number"
              placeholder="Enter price here"
              ref={register}
              isInvalid={!!errors.price}
            />
            <Form.Control.Feedback type="inValid">{errors.price?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Plan</Form.Label>
            <Form.Control
              name="plan"
              as="select"
              ref={register}
              isInvalid={!!errors.plan}
            >
              <option value="free">free</option>
              <option value="silver">silver</option>
              <option value="gold">gold</option>
              <option value="diamond">diamond</option>
            </Form.Control>
            <Form.Control.Feedback type="inValid">{errors.plan?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditBookModal;
