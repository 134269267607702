import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import "./VerticalNav.scss";

const menuItems = [
  {
    heading: "Account Settings",
    items: [
      {
        "label": "Profile",
        "link": "/settings",
        "includes": ["/settings"]
      },
      {
        "label": "Account Security",
        "link": "/settings/security",
        "includes": ["/settings/security"]
      },
      {
        "label": "Billing & plans",
        "link": "/settings/billing",
        "includes": ["/settings/billing", "/settings/payments"]
      }
    ]
  }
];

const VerticalNav = () => {
  const { url } = useRouteMatch();
  return (
    <div className="vertical-nav">
      {
        menuItems.map(({ heading, items }) => {
          return (
            <div className="section" key={heading}>
              <div className="menu-heading">{heading}</div>
              {items.map(item => {
                const { label, link, includes } = item;
                const classes = classNames({
                  "menu-item": true,
                  "selected": includes.includes(url)
                });
                return (
                  <Link to={link} className={classes}>{label}</Link>
                );
              })}
            </div>
          );
        })
      }
    </div>
  );
};

export default VerticalNav;
