import React from "react";
import { Link } from "react-router-dom";
import "./cta.scss";

const CTA = ({ hideDownloadOptions }) => {
  return (
    <div className="cta-landing-page">
      <Link to="/signup">
        <button className="cta-btn">Start Your Free 14-Day Trial Now</button>
      </Link>
      { !hideDownloadOptions && (
          <div className="download">
            <a href="https://play.google.com/store/apps/details?id=com.fullchinesePro" target="_blank" rel="noopener noreferrer">
              <img src="/img/google-play.png" alt="Download on Google Play" />
            </a>
            <a href="https://apps.apple.com/in/app/fullchinese-pro/id1477189203" target="_blank" rel="noopener noreferrer">
              <img src="/img/app-store.jpg" alt="Download on App Store" />
            </a>
          </div>
        )
      }
    </div>
  );
};

export default CTA;