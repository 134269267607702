import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Field from "./Field";

const StudyReason = ({ value: val, onSave }) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  return (
    <Field value={value} onSave={onSave}>
      <Form.Control
        name="study_reason"
        as="select"
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
      >
        <option value="At School">At School</option>
        <option value="For Work">For Work</option>
        <option value="For Fun">For Fun</option>
      </Form.Control>
    </Field>
  );
};

StudyReason.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func
};

export default StudyReason;