import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";

import { Link } from "react-router-dom";

import { resetCommon } from '../../common/actions/actions';
import { resetPassword } from './state/actions';

import "./reset-password.scss";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email format").required("This field cannot be empty")
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { nextRoute } = useSelector(state => state.common);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    dispatch(resetPassword({...data }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="reset-container">
      <h3 className="title">Reset Password</h3>
      <p className="sub-title">
        Please enter your email address to receive a link to create a new password via email.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="reset-form">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Your Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email address"
            ref={register}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="inValid">{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>
        
        <div className="actions">
          <span>
            <label><Link to="/login">Nevermind, I got it</Link></label>
          </span>
          <Button type="submit">
            SEND EMAIL
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ResetPassword;
