import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

import LinkButton from "../LinkButton/LinkButton";

import "./EditField.scss";

const EditField = ({ name, value, canEdit, children, onSave }) => {
  const [editing, setEditing] = useState(false);

  const handleSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }
    setEditing(false);
  };

  return (
    <div className="edit-field">
      <div className="form-field">
        {
          editing ? (
            <Fragment>
              {children}
            </Fragment>
          ): value
        }
      </div>
      {
        canEdit && (
          <div className="field-actions">
            {
              editing ? (
                <LinkButton onClick={handleSave}>
                  <img
                    src={"/img/action-save.png"}
                    alt="save"
                    className="size-24-24"
                  />
                </LinkButton>
              ) : (
                <LinkButton onClick={() => setEditing(true)}>
                  <img
                    src={"/img/action-edit.svg"}
                    alt="save"
                    className="size-24-24"
                  />
                </LinkButton>
              )
            }
          </div>
        )
      }
    </div>
  );
};

EditField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  onSave: PropTypes.func
};

export default EditField;