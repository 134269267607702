import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import "./Details.scss";

const Details = (props) => {
  const { level, goal, region, lang, joined } = props;

  const getTimeElapsed = (date) => date && dayjs(date).fromNow();

  return (
    <div className="member-detail">
      <div class="row">
        <span className="col1 label">Level</span>
        <span className="col2"><img src={`/img/${level}.png`} alt="level" class="size-24-24" /> {level}</span>
      </div>
      <div class="row">
        <span className="col1 label">Goal</span>
        <span className="col2">{goal}</span>
      </div>
      <div class="row">
        <span className="col1 label">Region</span>
        <span className="col2"><img src={`/img/flags/${region}.svg`} alt="region" /></span>
      </div>
      <div class="row">
        <span className="col1 label">Display Language</span>
        <span className="col2"><img src={`/img/flags/${lang}.svg`} alt="region" /> {lang}</span>
      </div>
      <div class="row">
        <span className="col1 label">Joined</span>
        <span className="col2">{joined && dayjs(joined).format('DD/MM/YYYY')}</span>
        <span className="col3">{joined && getTimeElapsed(joined)}</span>
      </div>
    </div>
  );
};

Details.propTypes = {
  level: PropTypes.string,
  goal: PropTypes.string,
  region: PropTypes.string,
  lang: PropTypes.string,
  joined: PropTypes.string
};

export default Details;
