import React from "react";
import PropTypes from "prop-types";
import PreviewCard from "./PreviewCard";

import "./news-feed-tab.scss";

const NewsFeedPreview = ({ data = [] }) => {
  return (
    <div className="news-feed-tab">
      {data.map(({ title, description, thumbnail }, index) => (
        <div className="news-feed-card" key={index}>
          <PreviewCard
            title={title}
            description={description}
            thumbnail={thumbnail}
          />
        </div>
      ))}
    </div>
  );
};

NewsFeedPreview.propTypes = {
  data: PropTypes.array
};

NewsFeedPreview.defaultProps = {
  data: []
};

export default NewsFeedPreview;
