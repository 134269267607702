import React from "react";
import PropTypes from "prop-types";

import "./Notification.scss";

const Notification = ({ message, children }) => (
  <div className="notification">
    { message || children }
  </div>
);

Notification.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node
};

export default Notification
