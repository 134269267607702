import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { resetCommon } from "../../common/actions/actions";
import { unsubscribeEmail } from "./state/actions";

import "./unsubscribe.scss";

const UNSUBSCRIBING_STATE = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

const Unsubscribe = () => {
  const dispatch = useDispatch();
  const { nextRoute } = useSelector(state => state.common);

  const [unsubscribeState, setUnsubscribeState] = useState(UNSUBSCRIBING_STATE.IN_PROGRESS);

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const email = decodeURIComponent(params.get("email"));

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const onSuccess = useCallback(() => {
    setUnsubscribeState(UNSUBSCRIBING_STATE.SUCCESS)
  }, []);

  const onFailure = useCallback(() => {
    setUnsubscribeState(UNSUBSCRIBING_STATE.FAIL);
  }, []);

  const handleUnsubscribe = useCallback(() => {
    //if (active_token && email) {
      setUnsubscribeState(UNSUBSCRIBING_STATE.IN_PROGRESS);
      dispatch(unsubscribeEmail(
        {
          email,
          token
        },
        onSuccess,
        onFailure
      ));
    //}
  }, [token, email, dispatch, onSuccess, onFailure]);
  
  useEffect(() => {
    handleUnsubscribe();
  }, [handleUnsubscribe]);

  const getContent = () => {
    if (unsubscribeState === UNSUBSCRIBING_STATE.IN_PROGRESS) {
      return (
        <div className="unsubscribing">
          <Spinner animation="border" role="status"/>
          <h3>Unsubscribing...</h3>
        </div>
      );
    } else if (unsubscribeState === UNSUBSCRIBING_STATE.SUCCESS) {
      return (
        <div className="unsubscribe-success">
          <h3>Unsubscribed Successfully</h3>
          <Button><Link to="/">Go to Homepage</Link></Button>
        </div>
      )
    } else {
      return (
        <div className="unsubscribe-failure">
          <h3>Failed to Unsubscribe</h3>
          <p>The email does not exist or has been unsubscribed already.</p>
          <Button onClick={handleUnsubscribe}>Retry</Button>
        </div>
      );
    }
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="unsubscribe-email">
      {getContent()}
    </div>
  );
};

export default Unsubscribe;
