import uuid from 'uuid/v4';

import { makeApiCall } from "../../../helpers/api-client";
import { fastapiUrl } from "../../../config";
import { throwApiError, showNotice, setNextRoute } from "../../../common/actions/actions";
import * as Actions from "./action-types";


export const getProfile = () => async dispatch => {
  try {
    const response = await makeApiCall("/fc_profile");
    dispatch(getProfileSuccess(response.data[0]));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch profile"
    }));
  }
};

const getProfileSuccess = payload => ({
  type: Actions.GET_PROFILE_SUCCESS,
  payload
});

export const getSubscription = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_subscriptions?owner=eq.${id}&order=id.desc`);
    if (Array.isArray(response.data) && response.data.length > 0) {
      dispatch(getSubscriptionSuccess(response.data[0]));
      dispatch(getGooglePlanId(response.data[0].plan_id));
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch subscription status"
    }));
  }
};

const getSubscriptionSuccess = (payload) => ({
  type: Actions.GET_SUBSCRIPTION_SUCCESS,
  payload
});

export const getGooglePlanId = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_plans?id=eq.${id}`);
    dispatch(getGooglePlanIdSuccess(response.data[0].google_plan_id));
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch Google Plan Id"
    }));
  }
};

const getGooglePlanIdSuccess = (payload) => ({
  type: Actions.GET_GOOGLE_PLAN_ID_SUCCESS,
  payload
});

export const getPayments = (id, queryString = "") => async dispatch => {
  try {
    const response = await makeApiCall(`fc_sub_payments?owner=eq.${id}${queryString}`);
    dispatch(getPaymentsSuccess(response.data));
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch payments"
    }));
  }
};

const getPaymentsSuccess = (payload) => ({
  type: Actions.GET_PAYMENTS_SUCCESS,
  payload
});

export const deleteAccount = (email) => async dispatch => {
  try {
    await makeApiCall(`/rpc/delete_account`, {
      method: 'post',
      data: {
        email
      }
    });
    dispatch(setNextRoute("/"));
    localStorage.clear();
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to delete account"
    }));
  }
};

export const updateProfile = (id, payload) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_profile?owner=eq.${id}`, {
      method: "patch",
      data: payload
    });
    dispatch(updateProfileSuccess(response.data));
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to update profile"
    }));
  }
};

const updateProfileSuccess = (payload) => ({
  type: Actions.UPDATE_PROFILE_SUCCESS,
  payload
});

export const unSubscribe = (payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(`bt/unsubscribe`, {
      baseURL: fastapiUrl,
      method: "post",
      data: payload
    });
    dispatch(unSubscribeSuccess(response.data));
    dispatch(throwApiError({
      type: "alert",
      alertType: "success",
      message: "You have been unsubscribed from FullChinese..."
    }));

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to unsubscribe. Please try again."
    }));
  }
};

const unSubscribeSuccess = (payload) => ({
  type: Actions.UNSUBSCRIBE_SUCCESS,
  payload
});

export const changeAvatar = (payload) => async dispatch => {
  try {
    const { file, ...user } = payload;
    const response = await makeApiCall(`s3/signed?resource=images/${uuid()}.png`, {
      baseURL: fastapiUrl,
      method: "get"
    });
    const { fields: { key, AWSAccessKeyId, policy, signature, acl } } = response.data;
    const credentials = {
      key,
      AWSAccessKeyId,
      policy,
      signature,
      acl,
      file
    };

    dispatch(uploadAvatar(credentials, user));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};

const uploadAvatar = (payload, user) => async dispatch => {
  try {
    const formData = new FormData();
    const {
      key,
      AWSAccessKeyId,
      policy,
      signature,
      acl,
      file
    } = payload;

    formData.append("acl", acl);
    formData.append("Content-Type", "png");
    formData.append("key", key);
    formData.append("AWSAccessKeyId", AWSAccessKeyId);
    formData.append("policy", policy);
    formData.append("signature", signature);
    formData.append("file", file);
  
    await makeApiCall("/", {
      baseURL: "https://fullapi.s3.amazonaws.com",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData
    });

    const { id, userInfo: { level, study_reason, region, display_lang } } = user;
    const profilePayload = {
      avatar: key,
      level,
      study_reason,
      region,
      display_lang
    };

    dispatch(updateProfile(id, profilePayload));

  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};

export const changePassword = (payload, onComplete) => async dispatch => {
  try {
    await makeApiCall("rpc/change_password", {
      method: "post",
      data: payload
    });
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Password updated successfully"
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (e) {
    if (e?.response?.data?.code === "P0001") {
      dispatch(throwApiError({
        type: "alert",
        message: "Invalid old password. Please provide the correct password"
      }));
    } else {
      dispatch(throwApiError({
        type: "alert",
        message: "Failed to update password"
      }));
    }

    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};


