import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Password from "../../../components/Fields/Password";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("This field cannot be empty")
    .min(6, "Password should be atleast 6 character"),
  confirmPassword: yup
    .string()
    .required("This field cannot be empty")
    .oneOf([yup.ref('password')], 'Your passwords do not match.')
});

const ChangePasswordModal = ({ showModal, onClose, onPasswordChange  }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ password }) => {
    setSubmitting(true);
    onPasswordChange({new_password: password}, () => {
      setSubmitting(false);
      handleClose();
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="signup-form">
        <Modal.Body>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Password
              name="password"
              placeholder="Password"
              innerRef={register}
              isInvalid={!!errors.password}
              showIcon={!errors.password}
            />
            <Form.Control.Feedback type="inValid">{errors.password?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Password
              name="confirmPassword"
              placeholder="Retype Password"
              innerRef={register}
              isInvalid={!!errors.confirmPassword}
              showIcon={!errors.confirmPassword}
            />
            <Form.Control.Feedback type="inValid">{errors.confirmPassword?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Change
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;

