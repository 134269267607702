import * as Actions from "./action-types";

const initialState = {
  plans: [],
  coupon: null,
  redeemSuccessful: false,
  selectedPlan: null
};

const plansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.GET_PLANS_SUCCESS: {
      return {...state, plans: payload}
    }
    case Actions.VERIFY_COUPON_CODE_SUCCESS: {
      return {...state, coupon: payload}
    }
    case Actions.RESET_COUPON_CODE: {
      return {...state, coupon: null }
    }
    case Actions.REDEEM_SUCCESS: {
      return {...state, redeemSuccessful: true}
    }
    case Actions.RESET_REDEEM_STATUS: {
      return {...state, redeemSuccessful: false}
    }
    case Actions.PLAN_SUBSCRIBE: {
      return {...state, selectedPlan:  payload}
    }
    default:
      return state;
  }
};

export default plansReducer;
