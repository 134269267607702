import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Videos from "./components/Videos";
import DiscoveryVideos from "./components/DiscoveryVideos";

import { resetCommon } from "../../common/actions/actions";

const VideoTabs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  return (
    <div className="videos-tabs">
      <Tabs defaultActiveKey="videos">
        <Tab eventKey="videos" title="Videos">
          <Videos />
        </Tab>
        <Tab eventKey="discovery-videos" title="Discovery Videos">
          <DiscoveryVideos />
        </Tab>
      </Tabs>
    </div>
  );
};

export default VideoTabs;
