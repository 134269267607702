import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import ActionModal from "../../../components/ActionModal/ActionModal";
import LinkButton from "../../../components/LinkButton/LinkButton";

import AddPostFeedModal from "./AddPostFeedModal";

const FeedList = ({ data, onFetchFeed, onAddPostFeed, onDelete }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showNewModal, setShowNewModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handlePageChange = ({ page, pageSize }) => {
    setPageNum(pageNum);
    onFetchFeed({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    setPageNum(0);
    onFetchFeed({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchFeed({ pageNum: 0, pageSize }, searchTerm);
  };

  const handleDeletePostFeed = () => {
    if (typeof onDelete === "function") {
      onDelete(rowToDelete, () => {
        setRowToDelete(null);
        onFetchFeed({ pageNum, pageSize }, searchTerm || "");
      }, () => {
        setRowToDelete(null);
      });
    }
  };

  const renderTopics = ({ row }) => {
      const { topics } = row;
  
      return topics.join(", ");
  };
  
  const renderActions = ({ row }) => {
    const { feed_owner } = row;
    return (
      <LinkButton onClick={() => setRowToDelete(feed_owner)}>Delete</LinkButton>
    );
  };
  
  const columns = [
    { field: 'feed_owner', headerName: 'Id', width: 180 },
    { field: 'level', headerName: 'Level', width: 180 },
    { field: 'topics', headerName: 'Topics', width: 200, renderCell: renderTopics },
    { field: 'author', headerName: 'Author', width: 150 },
    { field: 'website', headerName: 'Website', width: 200 },
    { field: 'followed', headerName: 'Followed', width: 100 },
    { field: 'actions', headerName: '.', width:200, renderCell: renderActions}
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Post Feed"
        placeHolder="Search feed here"
        addComponent={<ActionIcon icon="action-add-folder" label="ADD NEW" onClick={() => setShowNewModal(true)} />}
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.feed_owner}
        />
      </div>
      <AddPostFeedModal
        showModal={showNewModal}
        onClose={() => setShowNewModal(false)}
        onAddPostFeed={onAddPostFeed}
      />
      <ActionModal
        showModal={!!rowToDelete}
        title="Delete Post Feed"
        actionText="Delete"
        onClose={() => setRowToDelete(null)}
        onAction={handleDeletePostFeed}>
          Do you want to remove this post feed?
      </ActionModal>
    </div>
  );
};

FeedList.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchFeed: PropTypes.func
};

export default FeedList;
