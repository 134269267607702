import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { throwApiError, showNotice, setNextRoute } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchCollections = () => async dispatch => {
  try {
    const response = await makeApiCall("/fc_store_collections");
    dispatch(fetchCollectionsSuccess(response.data));

    response.data.forEach(({ title }, index) => {
      dispatch(fetchCollectionBooks(title, index));
    });
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to store collections"
    }));
  }
};

const fetchCollectionsSuccess = payload => ({
  type: Actions.FETCH_COLLECTIONS_SUCCESS,
  payload
});

export const fetchCollectionBooks = (title, index) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_collection_books?collection_title=eq.${title}&order=idx.asc`);
    dispatch(fetchCollectionBooksSuccess({
      index,
      data: response.data
    }));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch collection"
    }));
  }
};

const fetchCollectionBooksSuccess = payload => ({
  type: Actions.FETCH_COLLECTION_BOOKS_SUCCESS,
  payload
});

export const createCollection = (payload, onComplete) => async dispatch => {
  try {
    const response = await makeApiCall("fc_store_collections", {
      method: "post",
      data: payload
    });
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Collection created successfully"
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
    dispatch(createCollectionSuccess(response.data));
    dispatch(setNextRoute("/store"));
  } catch (e) {
    if (typeof onComplete === "function") {
      onComplete();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Could not create a collection. Please try later."
    }));
  }
};

const createCollectionSuccess = (payload) => ({
  type: Actions.CREATE_COLLECTION_SUCCESS,
  payload
});

export const updateCollection = (title, payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_store_collections?title=eq.${title}`, {
      method: "patch",
      data: payload
    });
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Collection updated successfully"
    }));
    dispatch(updateCollectionSuccess(response.data));

    if (typeof onSuccess === "function") {
      onSuccess();
    }

  } catch (e) {
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Collection could not be updated. Please try later."
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const updateCollectionSuccess = (payload) => ({
  type: Actions.UPDATE_COLLECTION_SUCCESS,
  payload
});

export const deleteCollection = (title, onComplete) => async dispatch => {
  try {
    await makeApiCall(`fc_collection_books?collection_title=eq.${title}`, {
      method: "delete"
    });

    await makeApiCall(`fc_store_collections?title=eq.${title}`, {
      method: "delete"
    });

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Collection deleted successfully"
    }));
    dispatch(deleteCollectionSuccess());

    if (typeof onComplete === "function") {
      onComplete();
    }

  } catch (e) {
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Collection could not be deleted. Please try later."
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

const deleteCollectionSuccess = () => ({
  type: Actions.DELETE_COLLECTION_SUCCESS
});

// Add New Book

export const fetchBooks = (pagination, searchTerm = "") => async (dispatch) => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_books?title=ilike.%${searchTerm}%`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    data.forEach((item) => {
      item.price = 0;
      item.plan = "free";
    });

    dispatch(fetchBooksSuccess({
      numRecords,
      rows: data
    }));
  
  } catch (e) {
    dispatch(fetchBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch list of books. Please try again."
    }));
  }
};

const fetchBooksSuccess = (payload) => ({
  type: Actions.FETCH_BOOKS_SUCCESS,
  payload
});

const fetchBooksFail = () => ({
  type: Actions.FETCH_BOOKS_FAIL
});


export const addBooks = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_collection_books`, {
      method: 'post',
      data: payload
    });

    dispatch(addBooksSuccess(response.data));
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Books added to collection successfully"
    }));

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(addBooksFailure());
    dispatch(throwApiError({
      type: "toast",
      message: "One of the books is already added. Please choose another book."
    }));
    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const addBooksSuccess = (payload) => ({
  type: Actions.ADD_BOOKS_SUCCESS,
  payload
});

const addBooksFailure = () => ({
  type: Actions.ADD_BOOKS_FAIL
});

export const updateBook = (payload, onSuccess, onFail) => async dispatch => {
  try {
    const { bookSha1, title, data } = payload; 
    const response = await makeApiCall(`fc_collection_books?book_sha1=eq.${bookSha1}&collection_title=eq.${title}`, {
      method: "patch",
      data
    });
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book updated successfully"
    }));
    dispatch(updateBookSuccess(response.data));

    if (typeof onSuccess === "function") {
      onSuccess();
    }

  } catch (e) {
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Book could not be updated. Please try later."
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const updateBookSuccess = (payload) => ({
  type: Actions.UPDATE_BOOK_SUCCESS,
  payload
});

export const removeBook = (payload, onComplete) => async dispatch => {
  try {
    const { bookSha1, title } = payload;
    await makeApiCall(`fc_collection_books?book_sha1=eq.${bookSha1}&collection_title=eq.${title}`, {
      method: "delete"
    });

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book removed successfully"
    }));

    dispatch(removeBookSuccess());

    if (typeof onComplete === "function") {
      onComplete();
    }

  } catch (e) {
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Could not remove the book. Please try again later."
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

const removeBookSuccess = () => ({
  type: Actions.REMOVE_BOOK_SUCCESS
});
