import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DiscoveryVideoList from "./DiscoveryVideoList";

import { resetCommon } from "../../../../common/actions/actions";
import {
  fetchDiscoveryVideos,
  updateDiscoveryVideo,
  deleteSelectedVideo
} from "../../state/actions";

const Videos = () => {
  const dispatch = useDispatch();
  const { discoveryVideos } = useSelector(state => state.videos);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDiscoveryVideos({pageNum: 0, pageSize: 10}));
  }, [dispatch]);

  const handleFetchVideos = (pageData, searchTerm) => {
    dispatch(fetchDiscoveryVideos(pageData, searchTerm));
  };

  const handleUpdateVideo = (payload, onSuccess, onFail) => {
    dispatch(updateDiscoveryVideo(payload, onSuccess, onFail));
  };

  const handleDeleteVideo = (sha1, onSuccess) => {
    dispatch(deleteSelectedVideo(sha1, onSuccess));
  };

  return (
    <div>
      <DiscoveryVideoList
        data={discoveryVideos}
        onFetchVideos={handleFetchVideos}
        onUpdate={handleUpdateVideo}
        onDelete={handleDeleteVideo}
      />
    </div>
  );
};

export default Videos;
