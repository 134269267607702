export const FETCH_CHARACTERS_SUCCESS = "app/dictionary/FETCH_CHARACTERS_SUCCESS";
export const FETCH_CHARACTERS_FAIL = "app/dictionary/FETCH_CHARACTERS_FAIL";

export const FETCH_CHARACTER_SUCCESS = "app/dictionary/FETCH_CHARACTER_SUCCESS";
export const FETCH_CHARACTER_FAIL = "app/dictionary/FETCH_CHARACTER_FAIL";

export const FETCH_CHARACTER_STROKES_SUCCESS = "app/dictionary/FETCH_CHARACTER_STROKES_SUCCESS";
export const FETCH_CHARACTER_STROKES_FAIL = "app/dictionary/FETCH_CHARACTER_STROKES_FAIL";

export const FETCH_CHARACTER_INFO_SUCCESS = "app/dictionary/FETCH_CHARACTER_INFO_SUCCESS";
export const FETCH_CHARACTER_INFO_FAIL = "app/dictionary/FETCH_CHARACTER_INFO_FAIL";

export const UPDATE_CHAR_INFO_SUCCESS = "app/dictionary/UPDATE_CHAR_INFO_SUCCESS";
export const UPDATE_CHAR_INFO_FAIL = "app/dictionary/UPDATE_CHAR_INFO_FAIL";

export const FETCH_CHARACTER_DEFS_SUCCESS = "app/dictionary/FETCH_CHARACTER_DEFS_SUCCESS";
export const FETCH_CHARACTER_DEFS_FAIL = "app/dictionary/FETCH_CHARACTER_DEFS_FAIL";

export const FETCH_COMPONENTS_SUCCESS = "app/dictionary/FETCH_COMPONENTS_SUCCESS";
export const FETCH_COMPONENTS_FAIL = "app/dictionary/FETCH_COMPONENTS_FAIL";

export const FETCH_WORDS_WITH_CHARACTER_SUCCESS = "app/dictionary/FETCH_WORDS_WITH_CHARACTER_SUCCESS";
export const FETCH_WORDS_WITH_CHARACTER_FAIL = "app/dictionary/FETCH_WORDS_WITH_CHARACTER_FAIL";

export const FETCH_SENTENCES_WITH_CHARACTER_SUCCESS = "app/dictionary/FETCH_SENTENCES_WITH_CHARACTER_SUCCESS";
export const FETCH_SENTENCES_WITH_CHARACTER_FAIL = "app/dictionary/FETCH_SENTENCES_WITH_CHARACTER_FAIL";

export const UPDATE_CHARACTER_SUCCESS = "app/dictionary/UPDATE_CHARACTER_SUCCESS";
export const UPDATE_CHARACTER_FAIL = "app/dictionary/UPDATE_CHARACTER_FAIL";

export const FETCH_WORDS_SUCCESS = "app/dictionary/FETCH_WORDS_SUCCESS";
export const FETCH_WORDS_FAIL = "app/dictionary/FETCH_WORDS_FAIL";

export const FETCH_WORD_SUCCESS = "app/dictionary/FETCH_WORD_SUCCESS";
export const FETCH_WORD_FAIL = "app/dictionary/FETCH_WORD_FAIL";

export const ADD_WORD_SUCCESS = "app/dictionary/ADD_WORD_SUCCESS";
export const ADD_WORD_FAIL = "app/dictionary/ADD_WORD_FAIL";

export const UPDATE_WORD_SUCCESS = "app/dictionary/UPDATE_WORD_SUCCESS";
export const UPDATE_WORD_FAIL = "app/dictionary/UPDATE_WORD_FAIL";

export const DELETE_WORD_SUCCESS = "app/dictionary/DELETE_WORD_SUCCESS";
export const DELETE_WORD_FAIL = "app/dictionary/DELETE_WORD_FAIL";

export const FETCH_WORD_COMPONENTS_SUCCESS = "app/dictionary/FETCH_WORD_COMPONENTS_SUCCESS";
export const FETCH_WORD_COMPONENTS_FAIL = "app/dictionary/FETCH_WORD_COMPONENTS_SUCCESS";

export const FETCH_SENTENCES_WITH_WORD_SUCCESS = "app/dictionary/FETCH_SENTENCES_WITH_WORD_SUCCESS";
export const FETCH_SENTENCES_WITH_WORD_FAIL = "app/dictionary/FETCH_SENTENCES_WITH_WORD_FAIL";

export const FETCH_SENTENCES_SUCCESS = "app/dictionary/FETCH_SENTENCES_SUCCESS";
export const FETCH_SENTENCES_FAIL = "app/dictionary/FETCH_SENTENCES_FAIL";