import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { fastapiUrl } from "../../../config";
import { throwApiError, showNotice } from "../../../common/actions/actions";
import * as Actions from "./action-types";


export const fetchMember = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_users?user_id=eq.${id}`);
    dispatch(fetchMemberSuccess(response.data?.[0] || {}));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch member profile"
    }));
  }
};

const fetchMemberSuccess = payload => ({
  type: Actions.FETCH_MEMBER_SUCCESS,
  payload
});

export const fetchSubscription = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_admin_subscriptions?owner=eq.${id}&order=id.desc`);
    if (Array.isArray(response.data) && response.data.length > 0) {
      const subscriptionDetails = response.data[0];
      const planId = subscriptionDetails['plan_id'];
      dispatch(getSubscriptionSuccess(subscriptionDetails));
      dispatch(fetchPlanDetails(planId));
      //dispatch(getGooglePlanId(response.data[0].plan_id));
    } else {
      dispatch(getSubscriptionSuccess({}));
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch subscription status"
    }));
  }
};

const getSubscriptionSuccess = (payload) => ({
  type: Actions.FETCH_MEMBER_SUBSCRIPTION_SUCCESS,
  payload
});

export const fetchPlanDetails = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_plans?id=eq.${id}`);
    dispatch(fetchPlanDetailsSuccess(response.data[0]));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to plan details"
    }));
  }
};

const fetchPlanDetailsSuccess = payload => ({
  type: Actions.FETCH_PLAN_SUCCESS,
  payload
});

export const fetchMemberClasses = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_admin_class_members?select=*,class:fc_classes(*)&user_id=eq.${id}`);
    dispatch(fetchMemberClassesSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch member classes"
    }));
  }
};

const fetchMemberClassesSuccess = payload => ({
  type: Actions.FETCH_MEMBER_CLASSES_SUCCESS,
  payload
});

export const fetchMemberBooks = (id, searchTerm = "") => async dispatch => {
  try {
    const response = await makeApiCall(`fc_admin_bookstatitems?owner=eq.${id}&book_title=ilike.%${searchTerm.toLocaleLowerCase()}%`);
    dispatch(fetchMemberBooksSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to member books"
    }));
  }
};

const fetchMemberBooksSuccess = payload => ({
  type: Actions.FETCH_MEMBER_BOOKS_SUCCESS,
  payload
});

export const deleteMemberBook = (id, onComplete) => async dispatch => {
  try {
    await makeApiCall(`/fc_bookstatitems?book_sha1=eq.${id}`);
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book deleted successfully"
    }));
    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to delete book. Please remove all the Text from the Book to delete this Book"
    }));
    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

export const fetchMemberTexts = (id, searchTerm = "") => async dispatch => {
  try {
    const response = await makeApiCall(`fc_admin_textstatitems?owner=eq.${id}&text_title=ilike.%${searchTerm.toLowerCase()}%&order=last_modified.desc`);
    dispatch(fetchMemberTextsSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to get member texts"
    }));
  }
};

const fetchMemberTextsSuccess = payload => ({
  type: Actions.FETCH_MEMBER_TEXTS_SUCCESS,
  payload
});

export const deleteMemberText = (id, onComplete) => async dispatch => {
  try {
    await makeApiCall(`fc_textstatitems?sha1=eq.${id}`);
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text deleted successfully"
    }));
    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to delete text."
    }));
    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

export const changePassword = (payload, onComplete) => async dispatch => {
  try {
    await makeApiCall("rpc/admin_change_password", {
      method: "post",
      data: payload
    });
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Password updated successfully"
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: e.response.data.message
    }));

    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

export const unsubscribeUser = (payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall(`${fastapiUrl}/bt/admin/unsubscribe`, {
      method: "post",
      data: payload
    });

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "User unsubscribed successfully"
    }));

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: e.response.data.message
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

export const fetchEmailsSent = (email) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_mails?recipient=eq.${email}`);
    dispatch(fetchEmailsSentSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to get member emails"
    }));
  }
};

const fetchEmailsSentSuccess = payload => ({
  type: Actions.FETCH_EMAILS_SUCCESS,
  payload
});

export const fetchEvents = (pagination, id) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_events?owner=eq.${id}`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchEventsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchEventsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch events. Please try again."
    }));
  }
};

const fetchEventsSuccess = (payload) => ({
  type: Actions.FETCH_EVENTS_SUCCESS,
  payload
});

const fetchEventsFail = () => ({
  type: Actions.FETCH_EVENTS_FAIL
});

export const fetchMemberPayments = (pagination, id) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_admin_sub_payments?owner=eq.${id}`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchMemberPaymentsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch member payments"
    }));
  }
};

const fetchMemberPaymentsSuccess = payload => ({
  type: Actions.FETCH_MEMBER_PAYMENTS_SUCCESS,
  payload
});

export const updatePlanExpiry = (id, payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall(`fc_admin_subscriptions?id=eq.${id}`, {
      method: "patch",
      data: payload
    });
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Plan expiry date updated successfully"
    }));

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: e.response.data.message
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

export const submitCoupon = (payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall(`${fastapiUrl}coupoun/pay`, {
      method: "post",
      data: payload
    });

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Coupon applied successfully"
    }));

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Coupon could not be applied"
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};


export const createPostFeed = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall("fc_admin_feeds", {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Successfully added feed"
    }));
    dispatch(createPostFeedSuccess(response));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(createPostFeedFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not add feed. Please try again."
    }));
  }
};

const createPostFeedSuccess = (payload) => ({
  type: Actions.CREATE_POST_FEEDS_SUCCESS,
  payload
});

const createPostFeedFail = () => ({
  type: Actions.CREATE_POST_FEEDS_FAIL
});


export const removeFromPostsFeed = (id, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_admin_feeds?feed_owner=eq.${id}`, {
      method: 'delete'
    });
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(removeFromPostsFeedSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(removeFromPostsFeedFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to remove item. Please try again later."
    }));
  }
};
  
const removeFromPostsFeedSuccess = (payload) => ({
  type: Actions.REMOVE_FROM_POST_FEEDS_SUCCESS,
  payload
});

const removeFromPostsFeedFail = () => ({
  type: Actions.REMOVE_FROM_POST_FEEDS_FAIL
});

export const fetchUserPosts = (pagination, id) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_user_posts?post_user_id=eq.${id}`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchUserPostsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchUserPostsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch user posts. Please try again."
    }));
  }
};

const fetchUserPostsSuccess = (payload) => ({
  type: Actions.FETCH_USER_POSTS_SUCCESS,
  payload
});

const fetchUserPostsFail = () => ({
  type: Actions.FETCH_USER_POSTS_FAIL
});
