import {
  FETCH_TEXTS_SUCCESS,
  FETCH_SELECTED_TEXT_SUCCESS,
  RESET_SELECTED_TEXT,
  UPDATE_SELECTED_TEXT_SUCCESS,
  FETCH_BOOKS_SUCCESS,
  FETCH_BOOK_LIST_TO_ADD_SUCCESS,
  FETCH_SELECTED_BOOK_SUCCESS,
  FETCH_BOOK_TEXTS_SHALIST_SUCCESS,
  FETCH_BOOK_TEXTS_SUCCESS,
  UPDATE_BOOK_TEXT_SUCCESS,
  RESET_SELECTED_BOOK,
  FETCH_BOOK_PAGES_SUCCESS,
  FETCH_AVAILABLE_TEXTS_SUCCESS,
  FETCH_NOTEBOOKS_SUCCESS,
  FETCH_ALL_BOOKS_SUCCESS,
  FETCH_ALL_TEXTS_SUCCESS,
  FETCH_PAGE_PREVIEW,
  FETCH_PAGE_PREVIEW_DATA_SUCCESS
} from "./action-types";

const initialValue = {
  texts: {
    numRecords: 0,
    rows: []
  },
  selectedText: {},
  books: {
    numRecords: 0,
    rows: []
  },
  selectedBook: {},
  availableBooks: {
    numRecords: 0,
    rows: []
  },
  bookTexts: {
    textShaList: [],
    numRecords: 0,
    rows: []
  },
  availableTexts: {
    numRecords: 0,
    rows: []
  },
  bookPages: {
    numRecords: 0,
    rows: []
  },
  notebooks: {
    numRecords: 0,
    rows: []
  },
  pagePreview: {},
  allbooks: {
    numRecords: 0,
    rows: []
  },
  alltexts: {
    numRecords: 0,
    rows: []
  }
};

const textsReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_TEXTS_SUCCESS:
      return {...state, texts: payload};
    case FETCH_SELECTED_TEXT_SUCCESS:
      return {
        ...state,
        selectedText: payload
      };
    case UPDATE_SELECTED_TEXT_SUCCESS:
      return {
        ...state,
        selectedText: {
          ...state.selectedText,
          ...payload
        }
      };
    case RESET_SELECTED_TEXT: {
      return {
        ...state,
        selectedText: {}
      }
    };
    case FETCH_BOOKS_SUCCESS:
      return {...state, books: payload};
    case FETCH_BOOK_LIST_TO_ADD_SUCCESS:
      return {...state, availableBooks: payload}
    case FETCH_SELECTED_BOOK_SUCCESS:
      return {
        ...state,
        selectedBook: payload
      };
    case RESET_SELECTED_BOOK: {
      return {
        ...state,
        selectedText: {}
      }
    };
    case FETCH_BOOK_TEXTS_SHALIST_SUCCESS:
      return {
        ...state,
        bookTexts: {
          ...state.bookTexts,
          textShaList: payload
        }
      };
    case FETCH_AVAILABLE_TEXTS_SUCCESS:
      return {...state, availableTexts: payload}
    case FETCH_BOOK_TEXTS_SUCCESS:
      return {
        ...state,
        bookTexts: {
          ...state.bookTexts,
          ...payload
        }
      }
    case UPDATE_BOOK_TEXT_SUCCESS:
      const { textSha1, data } = payload;
      const textRows = Array.from(state.bookTexts.rows);
      const rowToUpdate = textRows.findIndex(({ sha1 }) => sha1 === textSha1);
      textRows[rowToUpdate] = {
        ...textRows[rowToUpdate],
        ...data
      };

      return {
        ...state,
        bookTexts: {
          ...state.bookTexts,
          rows: textRows
        }
      };
    case FETCH_BOOK_PAGES_SUCCESS:
      return {
        ...state,
        bookPages: payload
      }
    case FETCH_NOTEBOOKS_SUCCESS:
      return {...state, notebooks: payload};
    case FETCH_PAGE_PREVIEW:
      return {...state, pagePreview: {}};
    case FETCH_PAGE_PREVIEW_DATA_SUCCESS:
      return {...state, pagePreview: payload};
    case FETCH_ALL_BOOKS_SUCCESS:
      return {
        ...state,
        allbooks: payload
      };
    case FETCH_ALL_TEXTS_SUCCESS:
      return {
        ...state,
        alltexts: payload
      };
    default:
      return state;
  }
};

export default textsReducer;
