import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../components/SearchHeader/SearchHeader";

import "./Payments.scss";

const formatExpireDate = ({ row }) => {
  const { expire_date } = row;
  return dayjs(expire_date).format('DD/MM/YYYY');
};

const formatPaidDate = ({ row }) => {
  const { paid_through_date } = row;
  return dayjs(paid_through_date).format('DD/MM/YYYY');
};

const columns = [
  { field: 'payment', headerName: 'Payment', width: 120 },
  { field: 'coupoun_id', headerName: 'Coupon', width: 120 },
  { field: 'status', headerName: 'Status', width: 100 },
  { field: 'expire_date', headerName: 'Expiry Date', width: 130, valueGetter: formatExpireDate },
  { field: 'paid_through_date', headerName: 'Paid', width: 130, valueGetter: formatPaidDate },
  { field: 'amount', headerName: 'Amount', width: 120 },
  { field: 'currency', headerName: 'Currency', width: 120 }
];

const Payments = ({ data, onSearch }) => {
  const handleSearch = (searchTerm = "") => {
    if (typeof onSearch === "function") {
      const queryString = `&environment=ilike.%${searchTerm.toLowerCase()}%&order=last_modified.desc`;
      onSearch(queryString);
    }
  };

  return (
    <div className="profile-payments">
      <h3 className="title"><Link to="/settings/billing">Billing & Plans</Link> / Payment History</h3>
      <SearchHeader
        placeHolder="Search payments here"
        onSearch={handleSearch}
      />
      <DataGrid
        className="payments-grid"
        autoHeight={true}
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  );
};

Payments.propTypes = {
  data: PropTypes.array,
  onSearch: PropTypes.func
};

Payments.defaultProps = {
  data: []
}

export default Payments;
