import React from "react";
import CTA from "./cta";

const IntroVideo = () => {

  return (
    <div className="intro-video-container">
      <div className="video">
        <video
          className="intro-video"
          controls
          poster="/img/intro-video-thumbail.png">
          <source src="/img/fullchinese.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
      <CTA hideDownloadOptions={true} />
    </div>
  );
};

export default IntroVideo;
