import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import MoreItems from "../../../../components/MoreItems/MoreItems";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionModal from "../../../../components/ActionModal/ActionModal";

import EditVideoModal from "./EditVideoModal";

import "./discovery-video-list.scss";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};

const DiscoveryVideoList = ({ data, onFetchVideos, onUpdate, onDelete }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handlePageChange = ({ page, pageSize }) => {
    setPageNum(pageNum);
    onFetchVideos({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    setPageNum(0);
    onFetchVideos({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setPageNum(0);
    onFetchVideos({ pageNum: 0, pageSize }, searchTerm);
  };

  const handleUpdateVideo = (payload, onSuccess, onFail) => {
    const handleSuccess = () => {
      onSuccess();
      onFetchVideos({ pageNum, pageSize }, searchTerm || "");
    };
    if (typeof onUpdate === "function") {
      onUpdate(payload, handleSuccess, onFail);
    }
  }

  const handleDeleteVideo = () => {
    if (typeof onDelete === "function") {
      onDelete(rowToDelete, () => {
        setRowToDelete(null);
        onFetchVideos({ pageNum, pageSize }, searchTerm || "");
      });
    }
  };

  const renderTitleThumbnail = ({ row }) => {
    const { title, thumbnails } = row;

    const thumbnailUrl = thumbnails?.default?.url || '/img/thumbnail-na.png';

    return (
      <div>
        <img src={thumbnailUrl} alt="video thumbnail" width="50" height="35" /> {title}
      </div>
    )
  };

  const renderActions = ({ row }) => {
    const { video_text_sha1 } = row;
    return (
      <MoreItems>
        <div>
          <LinkButton onClick={() => setRowToEdit(row)}>Edit</LinkButton>
        </div>
        <div>
          <LinkButton onClick={() => setRowToDelete(video_text_sha1)}>Remove</LinkButton>
        </div>
      </MoreItems>
    );
  };
  
  const columns = [
    { field: 'title', headerName: 'Title', width: 380, renderCell: renderTitleThumbnail },
    { field: 'tags', headerName: 'Tags', width: 150 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'priority', headerName: 'Priority', width: 100 },
    { field: 'video_type', headerName: 'Video Type', width: 120 },
    { field: 'video_media_sha1', headerName: 'Video Media Sha', width: 150 },
    { field: 'last_modified', headerName: 'Last Modified', width:100, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width:200, renderCell: renderActions}
  ];

  return (
    <div className="discovery-video-list">
      <SearchHeader
        count={numRecords}
        countTitle="Videos"
        placeHolder="Search videos here"
        addComponent={<ActionIcon icon="action-add-folder" label="New Video" to="/discovery-videos/new" />}
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.video_text_sha1}
        />
      </div>
      <EditVideoModal
        showModal={!!rowToEdit}
        defaultValues={rowToEdit}
        onClose={() => setRowToEdit(null)}
        onUpdate={handleUpdateVideo}
      />
      <ActionModal
        showModal={!!rowToDelete}
        title="Delete Video"
        actionText="Delete"
        onClose={() => setRowToDelete(null)}
        onAction={handleDeleteVideo}>
          Do you want to remove this video?
      </ActionModal>
    </div>
  );
};

DiscoveryVideoList.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchVideos: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func
};

export default DiscoveryVideoList;
