import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import "./NewClassModal.scss";

const schema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty"),
  max_members: yup.number().required("This field cannot be empty"),
  max_books: yup.number().required("This field cannot be empty")
});

const NewClassModal = ({ showModal, onHide, onAddNewClass }) => {

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={onHide} className="new-class-modal">
        <Modal.Header closeButton>
          <Modal.Title>New Class</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onAddNewClass)} noValidate>
          <Modal.Body>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Class Name</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Enter Class Name"
                  ref={register}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Description</Form.Label>
              <Col xs="8">
                <Form.Control
                  sm="10"
                  name="description"
                  as="textarea"
                  placeholder="Enter Description"
                  ref={register}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Max Members</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="max_members"
                  type="number"
                  placeholder="Enter max members"
                  ref={register}
                  isInvalid={!!errors.max_members}
                />
                <Form.Control.Feedback type="inValid">{errors.max_members?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Max Books</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="max_books"
                  type="number"
                  placeholder="Enter max books"
                  ref={register}
                  isInvalid={!!errors.max_books}
                />
                <Form.Control.Feedback type="inValid">{errors.max_books?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

NewClassModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  onAddNewClass: PropTypes.func
};

export default NewClassModal;
