import { combineReducers } from "redux";
import commonReducer from '../common/reducers/common';
import tokenReducer from './tokenReducer';
import subscriptionReducer from './subscriptionReducer';

import loginReducer from '../pages/Login/state/reducer';
import createProfileReducer from '../pages/CreateProfile/state/reducer';
import plansReducer from '../pages/Plans/state/reducer';
import profileReducer from '../pages/Profile/state/reducer';

import membersReducer from "../pages/Members/state/reducer";
import memberProfileReducer from "../pages/Member/state/reducer";

import organizationsReducer from "../pages/Organization/state/reducer";
import classesReducer from "../pages/Classes/state/reducer";
import textsReducer from "../pages/Texts/state/reducer";
import dictionaryReducer from "../pages/Dictionary/state/reducer";
import paymentsReducer from "../pages/Payments/state/reducer";
import storeReducer from "../pages/Store/state/reducer";
import newsfeedReducer from "../pages/Newsfeed/state/reducer";
import eventsReducer from "../pages/Events/state/reducer";
import emailsReducer from "../pages/Emails/state/reducer";
import videosReducer from "../pages/Videos/state/reducer";
import deviceConfigReducer from "../pages/DeviceConfig/state/reducer";
import ytTranscriptsReducer from "../pages/YouTubeTranscripts/state/reducer";
import openAITemplatesReducers from "../pages/OpenAITemplates/state/reducer";
import postFeedsReducer from "../pages/PostFeeds/state/reducer";

const appReducer = combineReducers({
  rpc: tokenReducer,
  auth: loginReducer,
  common: commonReducer,
  profile: profileReducer,
  subscription: subscriptionReducer,
  createProfile: createProfileReducer,
  plans: plansReducer,
  members: membersReducer,
  memberProfile: memberProfileReducer,
  organizations: organizationsReducer,
  classes: classesReducer,
  texts: textsReducer,
  dictionary: dictionaryReducer,
  payments: paymentsReducer,
  store: storeReducer,
  newsfeed: newsfeedReducer,
  events: eventsReducer,
  emails: emailsReducer,
  videos: videosReducer,
  deviceConfig: deviceConfigReducer,
  ytTranscripts: ytTranscriptsReducer,
  openAITemplates: openAITemplatesReducers,
  postFeeds: postFeedsReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;