import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";

import LinkButton from "../../../LinkButton/LinkButton";
import "./HeaderDesktop.scss";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <LinkButton
    elemRef={ref}
    onClick={onClick}
  >
    {children}
    <img src="/img/expand.svg" alt="expand menu" />
  </LinkButton>
));

const HeaderDesktop = ({ avatar, userName }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    dispatch({type: "RESET_APP"});
  };

  return (
    <div className="header-desktop">
      <div className="user-menu">
        <a href="https://www.fullchinesehelp.com/about-us.html" target="_blank" rel="noopener noreferrer">About</a>
        <a href="https://www.fullchinesehelp.com/contact-us.html" target="_blank" rel="noopener noreferrer">Contact</a>
        <a href="https://www.fullchinesehelp.com/faq-by-topics.html" target="_blank" rel="noopener noreferrer">
          FAQ
        </a>
      </div>
      <span><img src="/img/bell.svg" alt="notification-img" /></span>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <span className="user">
            <img
              src={avatar}
              alt="User Profile"
            />
            <span>{userName}</span>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu align="right">
          <Dropdown.Item as="button"><Link to="/settings">Settings</Link></Dropdown.Item>
          <Dropdown.Item href="/" onClick={handleLogout}>Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

HeaderDesktop.propTypes = {
  pageName: PropTypes.string,
  avatar: PropTypes.string,
  userName: PropTypes.string
};

HeaderDesktop.defaultProps = {
  avatar: "/img/newavatar.png"
}

export default HeaderDesktop;