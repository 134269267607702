import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { apiUrl } from "../../config";

const axios = require("axios");
class ChangePass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      old_password: "",
      new_password: ""
    };

    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json"
    };
  }

  updateHandler = e => {
    this.setState({ loading: true });
    e.preventDefault();
    let data = {
      email: this.state.email,
      old_password: this.state.old_password,
      new_password: this.state.new_password
    };

    const headers = {
      Accept: "application/vnd.pgrst.object+json",
      "Content-Type": "application/json",
      Prefer: "return=representation",
      Authorization: "Bearer " + this.props.token
    };

    let url = apiUrl + "rpc/change_password";
    axios
      .post(url, data, {
        headers: headers
      })
      .then(response => {
        toast.success("Password has been Updated Succesfully!");
        setTimeout(() => {}, 1200);

        this.props.history.push("/settings");
      })
      .catch(error => {});
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showPassword = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  render() {
    const { email, old_password, new_password } = this.state;
    return (
      <>
        <div className="kt-login-v2--enabled kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-aside--enabled kt-aside--fixed">
          <div className="kt-grid__item  kt-grid--hor">
            <div className="kt-login-v2__head">
              <div className="kt-login-v2__logo p-5">
                <img src="/img/logo.png" alt="" />
              </div>
            </div>
          </div>
          <div className="back">
            <div className="kt-grid kt-grid--hor kt-grid--root">
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid--hor kt-wrapper signup" id="kt_wrapper">
                  <div className="kt-subheader kt-grid__item mt-5 form-width" id="kt_subheader">
                    <div className="kt-container kt-container--fluid">
                      <div className="kt-header__toolbar" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total"></span>
                      </div>
                      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className="kt-portlet">
                          <div className="kt-portlet__body kt-portlet__body--fit">
                            <div className="kt-wizard-v3" id="kt_wizard_v3" data-ktwizard-state="first">
                              <form className="kt-form" id="kt_form" novalidate="novalidate">
                                <div
                                  className="kt-wizard-v3__content"
                                  data-ktwizard-type="step-content"
                                  data-ktwizard-state="current"
                                >
                                  <div className="kt-heading kt-heading--md">
                                    <h3 classNmae="font-weight-bold">Change Password</h3>
                                  </div>
                                  <div className="kt-form__section ">
                                    <div className="form-group">
                                      <label>Email Id</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        placeholder={this.props.email}
                                        value={email}
                                        aria-describedby="username-error"
                                        aria-invalid="false"
                                        onChange={this.handleChange}
                                      />
                                      <span className="form-text text-muted">
                                        Please enter your email id associated with FullChinese
                                      </span>
                                      <label id="nameerror" className="error-text" />
                                    </div>
                                    <div className="form-group">
                                      <label>Old Password:</label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        name="old_password"
                                        placeholder="New Password"
                                        value={old_password}
                                        aria-describedby="email-error"
                                        onChange={this.handleChange}
                                        minLength="6"
                                      />
                                      <span className="form-text text-muted">Please enter your old password</span>
                                      <label id="emailerror" className="error-text" />
                                    </div>
                                    <div className="form-group">
                                      <label>New Password:</label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        name="new_password"
                                        id="myInput"
                                        placeholder="New Password"
                                        value={new_password}
                                        aria-describedby="password-error"
                                        onChange={this.handleChange}
                                        minLength="6"
                                      />
                                      <label className="kt-checkbox kt-checkbox--solid kt-checkbox--success float-right">
                                        <input type="checkbox" value="checkedall" onClick={this.showPassword} />
                                        <span
                                          style={{
                                            top: "-28px",
                                            backgroundColor: "#fa694b"
                                          }}
                                        ></span>
                                      </label>
                                      <span className="form-text text-muted">Please enter your new password.</span>
                                      <label id="passworderror" className="error-text" />
                                    </div>
                                  </div>
                                </div>
                                <div className="kt-form__actions d-flex">
                                  <div className="row w-100">
                                    <div className="col-md-4 d-flex">
                                      <div
                                        className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-orange change-pass-btn"
                                        data-ktwizard-type="action-submit"
                                        onClick={this.updateHandler}
                                      >
                                        Save
                                      </div>
                                      <Link to="/settings">
                                        <div className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper btn-orange mx-2">
                                          Cancel
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.rpc.tokens,
    email: state.rpc.usermail
  };
};
export default connect(mapStateToProps)(ChangePass);
