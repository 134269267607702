import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form"; 
import { DataGrid } from "@material-ui/data-grid";

import RolePopover from "./components/RolePopover";
import DatePopover from "./components/DatePopover";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchMembers
} from "./state/actions";

import "./Members.scss";

const handleImgError = (ev) => {
  ev.target.src = "/img/newavatar.png";
};

const formatName = ({ row }) => {
  const { avatar, name, user_id } = row;

  return (
    <span>
      <img src={avatar || "/img/newavatar.png"} alt="avatar" className="size-24-24" onError={handleImgError} />
      &nbsp; <Link to={`/members/details/${user_id}`}>{name}</Link>
    </span>
  );
};

const formatRole = ({ row }) => {
  const { role } = row;
  return (
    <span>
      <img src={`/img/${role}.png`} alt="role" className="size-24-24" onError={handleImgError} /> {role}
    </span>
  );
};

const formatLevel = ({ row }) => {
  const { level } = row;
  return level && (
    <span>
      <img src={`/img/${level}.png`} alt="role" className="size-24-24" /> {level}
    </span>
  );
};

const formatDate = ({ row }) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const formatSubscription = ({ row }) => {
  const { subscription } = row;
  return subscription ? 'Yes': 'No'
}

const formatDevice = ({ row }) => {
  const { payment } = row;
  let device;

  switch (payment) {
    case "apple":
      device = "Apple";
      break;
    case "google":
      device = "Android";
      break;
    case "braintree":
      device = "Web";
      break;
    case "coupoun":
      device = "Coupon";
      break;
    default:
      device = "";
  }

  return device;
}

const renderLink = ({ row }) => {
  const { user_id } = row;
  return (
    <Link to={`/members/details/${user_id}`}>Details</Link>
  );
}

const columns = [
  { field: 'name', headerName: 'Username', width: 180, renderCell: formatName },
  { field: 'email', headerName: 'Email Address', width: 250 },
  { field: 'signup_device', headerName: 'Signup Device', width: 150 },
  { field: 'last_modified', headerName: 'Joined', width: 150, valueGetter: formatDate },
  { field: 'subscription', headerName: 'Subscription', width: 150, renderCell: formatSubscription },
  { field: 'payment', headerName: 'Payment', width: 150, renderCell: formatDevice },
  { field: 'user_id', headerName: 'ID', width: 150 },
  { field: 'role', headerName: 'Role', width: 150, renderCell: formatRole },
  { field: 'level', headerName: 'Level', width: 150, renderCell: formatLevel },
  { field: 'study_reason', headerName: 'Goal', width: 150 },
  { field: 'any', headerName: 'Actions', width: 130, renderCell: renderLink}
];

const Members = () => {
  const dispatch = useDispatch();
  const { members } = useSelector(state => state.members);
  const { numRecords, rows } = members;
  const [pageSize, setPageSize] = useState(10);

  const roles = [{
    label: "Student",
    value: "student"
  }, {
    label: "Teacher",
    value: "teacher"
  }, {
    label: "GK Admin",
    value: "gk_admin"
  }, {
    label: "Super Admin",
    value: "super_admin"
  }, {
    label: "Support",
    value: "support"
  }];

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoles, setSelectedRoles] = useState(roles.map(({ value }) => value));
  const [dateRange, setDateRange] = useState(["2000-01-01", "2100-01-01"]);
  const [subscriptionFilter, setSubscriptionFilter] = useState(0);
  const [postFeedFilter, setPostFeedFilter] = useState(0);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const composeFilters = () => {
    let query = `lang=eq.chinese&or=(name.ilike.%25${searchTerm}%25,email.ilike.%25${searchTerm}%25)&role=in.(${selectedRoles.join(",")})&order=last_modified.desc&last_modified=gte.${dateRange[0]}&last_modified=lte.${dateRange[1]})`;
    if (subscriptionFilter === 1) {
      query = `${query}&auto_renew_status=eq.1`
    }

    if (postFeedFilter === 1) {
      query = `${query}&feed_owner=not.is.null`
    }

    return query;
  };

  useEffect(() => {
    dispatch(fetchMembers({pageNum: 0, pageSize: 10}, composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionFilter, postFeedFilter]);

  const handlePageChange = ({ page, pageSize }) => {
    dispatch(fetchMembers({ pageNum: page, pageSize }, composeFilters()));
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    dispatch(fetchMembers({ pageNum: 0, pageSize }, composeFilters()));
  };

  const handleSearch = () => {
    dispatch(fetchMembers({ pageNum: 0, pageSize }, composeFilters()));
  };

  const handleSearchInput = (ev) => {
    setSearchTerm(ev.target.value);
  };

  const handleSubscriptionFilter = (ev) => {
    if (ev.target.checked) {
      setSubscriptionFilter(1);
    } else {
      setSubscriptionFilter(0);
    }
  };

  const handlePostFeedFilter = (ev) => {
    if (ev.target.checked) {
      setPostFeedFilter(1);
    } else {
      setPostFeedFilter(0);
    }
  }

  return (
    <div className="members">
      <div className="header">
        <h3>{numRecords} Members</h3>
        <div className="member-search">
          <form className="search-bar" onSubmit={e => e.preventDefault()}>
            <input
              type="text"
              className="search-input"
              placeholder="Search members by username or role"
              onChange={handleSearchInput} />
            <Button type="submit" onClick={handleSearch} className="search-btn">
              <img src="/img/magnifying-glass-black.png" alt="search" />
            </Button>
          </form>
          <RolePopover roles={roles} onSave={setSelectedRoles} />
          <DatePopover onSave={setDateRange} />
          <div className="users-filter">
            <Form.Check
              onChange={handleSubscriptionFilter}
              type="checkbox"
              label="Show Paid Users"
              id="paid-users"
            />
          </div>
          <div className="users-filter">
            <Form.Check
              onChange={handlePostFeedFilter}
              type="checkbox"
              label="Show Post Feed Users"
              id="post-feed-users"
            />
          </div>
        </div>
      </div>
      <div className="member-results">
        <DataGrid
          className="members-grid"
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.user_id}
         />
      </div>
    </div>
  );
};

export default Members;