import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../../components/SearchHeader/SearchBar";

import "./add-text-modal.scss";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};

const renderSound = ({ row }) => {
  const { numSounds } = row;
  const iconToDisplay = numSounds > 0 ? "/img/status-sound.svg" : "/img/status-sound-no.svg";
  return (
    <span>
      <img src={iconToDisplay} className="size-24-24" alt="sound"/> {numSounds > 0 ? numSounds : "No sound"}
    </span>
  );
};

const AddTextModal = (props) => {
  const {
    bookSha,
    showModal,
    texts,
    onClose,
    onFetchTexts,
    onAddTexts
  } = props;

  const { numRecords, rows } = texts;

  const [selectedTexts, setSelectedTexts] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (showModal) {
      setSelectedTexts([]);
    }
  }, [showModal]);

  const handleSelectText = (ev, data) => {
    const selected = ev.target.checked;
    const selectedText = {
      book_sha1: bookSha,
      text_sha1: data.sha1
    };
    if(selected) {
      setSelectedTexts(selectedTexts.concat([selectedText]));
    } else {
      setSelectedTexts(selectedTexts.filter(text => text.text_sha1 !== data.sha1));
    }
  };

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        onClick={(ev) => handleSelectText(ev, row)}
      />
    );
  };
  const columns = [
    { field: 'title', headerName: 'Title', width: 300 },
    { field: 'numSounds', headerName: 'Sound', width: 180, renderCell: renderSound },
    { field: 'last_modified', headerName: 'Modified', width: 200, valueGetter: formatModifiedDate },
    { field: 'id', headerName: null, width: 50, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    onFetchTexts({ pageNum: 0, pageSize: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchTexts({ pageNum: 0, pageSize }, searchTerm);
  };

  const handlePageChange = ({ page, pageSize }) => {
    onFetchTexts({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchTexts({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSubmit = () => {
    setSubmitting(true);
    if (typeof onAddTexts === "function") {
      onAddTexts(selectedTexts,
        () => {
          setSelectedTexts([]);
          setSubmitting(false);
          onClose();
        },
        () => {
          setSubmitting(false);
        }
      );
    }
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={onClose} className="texts-add-book">
        <Modal.Header closeButton>
          <Modal.Title>Add Books to Your Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="available-texts-search">
            <SearchBar placeholder="Search texts here" onSearch={handleSearch} />
          </div>
          <div className="records-info">
            <span>{numRecords && `${numRecords} Texts`}</span>
            <span>{`${selectedTexts.length} texts selected`}</span>
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={rows}
              columns={columns}
              pageSize={10}
              disableVirtualization={true}
              hideFooterSelectedRowCount={true}
              rowsPerPageOptions={[10, 20, 50]}
              rowCount={numRecords}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" disabled={submitting} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting} onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AddTextModal.propTypes = {
  bookSha: PropTypes.string,
  showModal: PropTypes.bool,
  texts: PropTypes.object,
  onClose: PropTypes.func,
  onFetchTexts: PropTypes.func,
  onAddTexts: PropTypes.func
};

export default AddTextModal;
