import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ActionModal = ({ showModal, title, children, actionText, disablePrimaryAction, onClose, onAction }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Fragment>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          { typeof onAction === "function" && (
              <Button variant="primary" disabled={disablePrimaryAction} onClick={onAction}>
                {actionText}
              </Button>
            )
          }
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

ActionModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  actionText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func.isRequired
};

export default ActionModal;
