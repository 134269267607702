import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../components/LinkButton/LinkButton";
import EditField from "../../../components/Fields/EditField";
import ActionIcon from "../../../components/ActionIcon/ActionIcon";
import ActionModal from "../../../components/ActionModal/ActionModal";

import { resetCommon } from "../../../common/actions/actions";

import { fetchTemplate, updateTemplate, deleteTemplate } from "../state/actions";

import "../open-ai-form.scss";

const schema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  content: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty"),
  sample_data: yup.string().required("This field cannot be empty")
});

const EditTemplate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: selectedTemplateId } = useParams();

  const { nextRoute } = useSelector(state => state.common);
  const { role } = useSelector(state => state.auth);
  const { selectedTemplate } = useSelector(state => state.openAITemplates);

  const { name, content, description, sample_data } = selectedTemplate;

  const isOwner = role === "gk_admin";

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
   dispatch(fetchTemplate(selectedTemplateId))
  }, [selectedTemplateId]);

  const { register, errors, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedTemplate,
      sample_data: JSON.stringify(sample_data)
    }
  });

  useEffect(() => {
    reset({
      ...selectedTemplate,
      sample_data: JSON.stringify(sample_data)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const handleSave = (field) => {
    const values = getValues();

    if (values[field]) {
      const value = field === "sample_data" ? JSON.parse(values[field]) : values[field];
      dispatch(updateTemplate(selectedTemplateId, { [field]: value }));
    }
  };

  const handleDelete = () => {
    setDeleting(true);

    const onComplete = () => {
      setDeleting(false);
    };

    dispatch(deleteTemplate(selectedTemplateId, onComplete));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="open-ai-form">
      <div className="header">
        <div className="left">
          <LinkButton onClick={() => history.goBack()}>
            <img src="/img/back.svg" alt="back button" className="size-24-24" />
          </LinkButton>
          <h3>Edit Template</h3>
        </div>
        <div>
          {isOwner && <ActionIcon icon="action-trashcan" label="DELETE" onClick={() => setShowDeleteModal(true)} />}
          <ActionModal
            showModal={showDeleteModal}
            title="Delete Template"
            actionText="Delete"
            disablePrimaryAction={isDeleting}
            onClose={() => setShowDeleteModal(false)}
            onAction={handleDelete}
          >
            Do you want to delete this template?
          </ActionModal>
        </div>
      </div>
      <div>
        <Form>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Name</Form.Label>
            <Col xs="8">
              <EditField name="name" value={name} canEdit={isOwner} onSave={() => handleSave("name")}>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Enter Template Name"
                  ref={register}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
              </EditField>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Content</Form.Label>
            <Col xs="8">
              <EditField name="content" value={content} canEdit={isOwner} onSave={() => handleSave("content")}>
                <Form.Control
                  sm="10"
                  name="content"
                  as="textarea"
                  placeholder="Enter Content"
                  ref={register}
                  isInvalid={!!errors.content}
                />
                <Form.Control.Feedback type="inValid">{errors.content?.message}</Form.Control.Feedback>
              </EditField>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Description</Form.Label>
            <Col xs="8">
              <EditField name="description" value={description} canEdit={isOwner} onSave={() => handleSave("description")}>
                <Form.Control
                  sm="10"
                  name="description"
                  as="textarea"
                  placeholder="Enter Description"
                  ref={register}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
              </EditField>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Sample Data</Form.Label>
            <Col xs="8">
              <EditField name="sample_data" value={JSON.stringify(sample_data)} canEdit={isOwner} onSave={() => handleSave("sample_data")}>
                <Form.Control
                  sm="10"
                  name="sample_data"
                  as="textarea"
                  placeholder="Enter Sample Data"
                  ref={register}
                  isInvalid={!!errors.sample_data}
                />
                <Form.Control.Feedback type="inValid">{errors.sample_data?.message}</Form.Control.Feedback>
              </EditField>
            </Col>
          </Form.Group>
          <div className="actions">
            <Button type="button" variant="outline-primary" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditTemplate;
