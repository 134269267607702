import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import { DataGrid } from "@material-ui/data-grid";


import EmailDataModal from "./EmailDataModal";
import EmailContentModal from "./EmailContentModal";

import "./Emails.scss";

const Emails = ({ emails }) => {
  const [data, setData] = useState(null);
  const [content, setContent] = useState(false);

  const formatDate = ({ row }) => {
    const { sent_at } = row;
    return dayjs(sent_at).format('DD/MM/YYYY H:m:s');
  };

  const renderData = ({ row }) => {
    const { data } = row;
    return data && (
      <Button onClick={() => setData(data)}>
        View Data
      </Button>
    );
  };

  const renderContent = ({ row }) => {
    const { content } = row;
    return (
      <Button onClick={() => setContent(content)}>
        View Content
      </Button>
    );
  };
  
  const columns = [
    { field: 'sent_at', headerName: 'Sent At', width: 200, renderCell: formatDate },
    { field: 'template', headerName: 'Template', width: 150 },
    { field: 'data', headerName: 'Data', width: 150, renderCell: renderData },
    { field: 'content', headerName: 'Content', width: 150, renderCell: renderContent },
  ];

  return (
    <div className="member-emails">
      <DataGrid
        className="books-grid"
        autoHeight={true}
        rows={emails}
        columns={columns}
        pageSize={10}
        getRowId={({ id }) => id}
      />
      <EmailDataModal
        showModal={!!data}
        data={data}
        onClose={() => setData(null)}
      />
      <EmailContentModal
        showModal={!!content}
        content={content}
        onClose={() => setContent(null)}
      />
    </div>
  );
};

Emails.propTypes = {
  emails: PropTypes.array
};

export default Emails;
