import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Menu from "./Menu";
import { getValidTabs } from "./menu-items";

import "./Nav.scss";

const Nav = ({ hideMenu }) => {
  const { role } = useSelector(state => state.auth);
  const validTabs = getValidTabs(role) || [];
  return (
    <Fragment>
      <div className="brand">
        <Link to="/user/home">
          <img alt="Logo" src="/img/logo-white.svg" />
        </Link>
      </div>
      {
        !hideMenu && (
          <div className="menu-container">
            <Menu config={validTabs} />
          </div>
        )
      }
    </Fragment>
  );
};

export default Nav;