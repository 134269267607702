import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const AddWordModal = ({ showModal, chinese, onClose, onAdd }) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const schema = yup.object().shape({
    priority: yup.number().required("This field cannot be empty"),
    phonetic: yup.string().required("This field cannot be empty"),
    defs: yup.string().required("This field cannot be empty"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const handleAdd = (values) => {
    if (typeof onAdd === "function") {
      onAdd({ chinese, ...values });
    }
  };

  return (
    <Modal size="lg" centered show={show} onHide={handleClose} className="edit-character-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add Definition</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleAdd)} noValidate>
        <Modal.Body>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Priority</Form.Label>
            <Col xs="8">
              <Form.Control
                name="priority"
                type="number"
                placeholder="Enter Priority"
                ref={register}
                isInvalid={!!errors.priority}
              />
              <Form.Control.Feedback type="inValid">{errors.priority?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Phonetic</Form.Label>
            <Col xs="8">
              <Form.Control
                name="phonetic"
                type="text"
                placeholder="Enter phonetic"
                ref={register}
                isInvalid={!!errors.phonetic}
              />
              <Form.Control.Feedback type="inValid">{errors.phonetic?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Defs</Form.Label>
            <Col xs="8">
              <Form.Control
                name="defs"
                type="text"
                placeholder="Enter Defs"
                ref={register}
                isInvalid={!!errors.defs}
              />
              <Form.Control.Feedback type="inValid">{errors.defs?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddWordModal.propTypes = {
  showModal: PropTypes.bool,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onAdd: PropTypes.func
};

export default AddWordModal;
