import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../components/ActionIcon/ActionIcon";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchClasses
} from "./state/actions";

import "./classes.scss";

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const renderLink = ({ row }) => {
  const { id } = row;
  return (
    <Link to={`/classes/details/${id}`}>Details</Link>
  );
};

const columns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'description', headerName: 'Description', width: 400 },
  { field: 'org_name', headerName: 'Organization', width: 200 },
  { field: 'nb_members', headerName: 'Members', width: 150 },
  { field: 'nb_books', headerName: 'Books', width: 150 },
  { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatDate },
  { field: 'id', headerName: '-', width: 100, renderCell: renderLink}
];

const Classes = () => {
  const dispatch = useDispatch();
  const { classes } = useSelector(state => state.classes);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const composeFilters = (searchTerm = "") => {
    return `name=ilike.%25${searchTerm}%25&order=last_modified.desc`;
  };

  useEffect(() => {
    dispatch(fetchClasses(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    dispatch(fetchClasses(composeFilters(searchTerm)));
  };

  return (
    <div className="classes">
      <SearchHeader
        count={classes.length}
        countTitle="Classes"
        placeHolder="Search class here"
        addComponent={<ActionIcon icon="action-add-folder" label="NEW CLASS" to={`/classes/new`} />}
        onSearch={handleSearch}
      />
      <div className="classes-results">
        <DataGrid
          className="classes-grid"
          autoHeight={true}
          rows={classes}
          columns={columns}
          pageSize={10}
         />
      </div>
    </div>
  );
};

export default Classes;