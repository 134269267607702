export const FETCH_ORGANIZATIONS_SUCCESS = "app/organizations/FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAIL = "app/organizations/FETCH_ORGANIZATIONS_FAIL";

export const FETCH_ORGANIZATION_SUCCESS = "app/organizations/FETCH_ORGANIZATION_SUCCESS";
export const FETCH_ORGANIZATION_FAIL = "app/organizations/FETCH_ORGANIZATION_FAIL";

export const CREATE_ORGANIZATION_SUCCESS = "app/organizations/CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAIL = "app/organizations/CREATE_ORGANIZATION_FAIL";

export const UPLOAD_ORG_IMAGE_SUCCESS = "app/organizations/UPLOAD_ORG_IMAGE_SUCCESS";
export const UPLOAD_ORG_IMAGE_FAIL = "app/organizations/UPLOAD_ORG_IMAGE_FAIL";

export const GET_ORG_ROLE_SUCCESS = "app/organizations/GET_ORG_ROLE_SUCCESS";
export const GET_ORG_ROLE_FAIL = "app/organizations/GET_ORG_ROLE_FAIL";

export const UPDATE_ORG_DATA = "app/organizations/UPDATE_ORG_DATA";
export const UPDATE_ORG_SUCCESS = "app/organizations/UPDATE_ORG_SUCCESS";
export const UPLOAD_ORG_FAIL = "app/organizations/UPLOAD_ORG_FAIL";

export const DELETE_ORGANIZATION_SUCCESS = "app/organizations/UPDATE_ORG_SUCCESS";
export const DELETE_ORGANIZATION_FAIL = "app/organizations/UPLOAD_ORG_FAIL";

/**
 * Books
 */

export const FETCH_ORG_BOOKS_SUCCESS = "app/organizations/FETCH_ORG_BOOKS_SUCCESS";
export const FETCH_ORG_BOOKS_FAIL = "app/organizations/FETCH_ORG_BOOKS_FAIL";

export const FETCH_ORG_BOOKS_SHA_SUCCESS = "app/organizations/FETCH_ORG_BOOKS_SHA_SUCCESS";
export const FETCH_ORG_BOOKS_SHA_FAIL = "app/organizations/FETCH_ORG_BOOKS_SHA_FAIL";

export const FETCH_BOOKS_SUCCESS = "app/organizations/FETCH_BOOKS_SUCCESS";
export const FETCH_BOOKS_FAIL = "app/organizations/FETCH_BOOKS_FAIL";

export const ADD_BOOKS_SUCCESS = "app/organizations/ADD_BOOKS_SUCCESS";
export const ADD_BOOKS_FAIL = "app/organizations/ADD_BOOKS_FAIL";

export const DELETE_BOOK_SUCCESS = "app/organizations/DELETE_BOOK_SUCCESS";
export const DELETE_BOOK_FAIL = "app/organizations/DELETE_BOOK_FAIL";

/**
 * Classes
 */

export const FETCH_ORG_CLASSES_SUCCESS = "app/organizations/FETCH_CLASSES_SUCCESS";
export const FETCH_ORG_CLASSES_FAIL = "app/organizations/FETCH_CLASSES_FAIL";

export const ADD_CLASS_SUCCESS = "app/organizations/ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAIL = "app/organizations/ADD_CLASS_FAIL";

export const DELETE_CLASS_SUCCESS = "app/organizations/DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAIL = "app/organizations/DELETE_CLASS_FAIL";

/**
 * Members
 */

export const FETCH_ORG_MEMBERS_SUCCESS = "app/organizations/FETCH_MEMBERS_SUCCESS";
export const FETCH_ORG_MEMBERS_FAIL = "app/organizations/FETCH_MEMBERS_FAIL";

export const ADD_MEMBERS_SUCCESS = "app/organizations/ADD_MEMBERS_SUCCESS";
export const ADD_MEMBERS_FAIL = "app/organizations/ADD_MEMBERS_FAIL";

export const DELETE_MEMBER_SUCCESS = "app/organizations/DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAIL = "app/organizations/DELETE_MEMBER_FAIL";