import React, { Component } from "react";
import swal from "sweetalert";

import { fastapiUrl } from "../../config";

import "./Subscription.scss";
const axios = require("axios");

class Subscription extends Component {
  changeSwitch = () => {
    if (!this.props.subscription.auto_renew_status) {
      this.props.history.push("/PlanTest");
    } else {
      if (this.state.payment === "apple") {
        window.open("https://apps.apple.com/account/billing");
      } else if (this.state.payment === "google") {
        let url =
          "https://play.google.com/store/account/subscriptions?sku=" +
          this.state.googlePlanId +
          "&package=" +
          this.state.googlePackage;
        window.open(url);
      } else {
        const headers = {
          Authorization: "Bearer " + this.props.token,
          "Content-Type": "application/json",
          Accept: "application/json"
        };
        let url = fastapiUrl + "bt/unsubscribe";
        let data = { sub_id: this.state.subscription.plan_id };
        axios
          .post(url, data, { headers: headers })
          .then(response => {
            swal("", "You have been unsubscribed from FullChinese..", "success");
            this.getSubscription();
          })
          .catch(err => {
            swal("", err.response.data.detail, "error");
          });
      }
    }
  };

  render() {
    return (
      <div className="mbd-subscription">
        <div
          className="change-subscription"
          data-toggle="modal"
          data-target="#changeSub"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: -15
          }}
        >
          {this.props.subscription.auto_renew_status ? (
            <button type="button" className="btn btn-elevate cancel-subscription">
              Cancel Subscription
            </button>
          ) : (
            <button type="button" className="btn btn-elevate ok-subscription">
              Subscribe
            </button>
          )}
          {!this.props.subscription.auto_renew_status && <p>No Subscriptions.</p>}
        </div>
        {this.props.subscription.context && this.props.subscription.context.trial_due && (
          <div className="mbd-status">
            <div className="mbd-title">
              <p> Trial Period Due</p>
            </div>
            <div className="mbd-content-details">
              <p>updated {this.props.subscription.context.trial_due.substr(0, 10)}</p>
            </div>
          </div>
        )}

        <div className="mbd-status">
          <div className="mbd-title">
            <p>Membership Expire Date</p>
          </div>
          <div className="mbd-content-details" style={{ width: "70%" }}>
            <p style={{ marginRight: 10 }}>
              {this.props.subscription.plan_expire && this.props.subscription.plan_expire.substr(0, 10)}
            </p>
            {this.props.subscription.is_expired === undefined ? (
              <p></p>
            ) : this.props.subscription.is_expired === 1 ? (
              <p style={{ color: "red" }}>(Your membership is expired)</p>
            ) : (
              <p>(Your membership will be expired at this date)</p>
            )}
            {this.props.user === "admin" && this.props.subscription && this.props.subscription.plan_expire && (
              <div style={{ marginLeft: 45 }}>
                <div className="mb-filter-joined" id="joined-popover" style={{ cursor: "pointer" }}>
                  <button className="btn btn-success">Change</button>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.props.subscription.auto_renew_status === 1 && (
          <div className="mbd-status">
            <div className="mbd-title">
              <p>Last Billing Price</p>
            </div>
            <div className="mbd-content-details">
              <p>{this.props.subscription.price}$</p>
            </div>
            <div className="mbd-others">
              <p>updated {this.props.subscription.updated_at && this.props.subscription.updated_at.substr(0, 10)}</p>
            </div>
          </div>
        )}
        {this.props.subscription.auto_renew_status === 1 && (
          <div className="mbd-status">
            <div className="mbd-title">
              <p>Next Payment Due</p>
            </div>
            <div className="mbd-content-details">
              <p>
                {this.props.subscription.auto_renew_status && this.props.subscription.next_billing_date
                  ? this.props.subscription.next_billing_date.substr(0, 10)
                  : "---"}
              </p>
            </div>
          </div>
        )}
        {this.props.subscription.auto_renew_status === 1 && (
          <div className="mbd-status">
            <div className="mbd-title">
              <p>Next Billing Price</p>
            </div>
            <div className="mbd-content-details">
              <p></p>
            </div>
          </div>
        )}
        {this.props.subscription.auto_renew_status === 1 && (
          <div className="mbd-status">
            <div className="mbd-title">
              <p>Payment</p>
            </div>
            <div className="mbd-content-details">
              <p>{this.props.subscription.payment}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Subscription;
