import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import uuid from "uuid/v4";

import "./EditCharacterModal.scss";

const EditCharacterModal = ({ showModal, defaultValues, onClose, onUpdate }) => {
  const [show, setShow] = useState(false);
  const [componentList, setComponentList] = useState(defaultValues.components || []);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    setComponentList(defaultValues.components);
  }, [defaultValues]);

  const handleClose = () => {
    setShow(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const schema = yup.object().shape({
    character: yup.string().required("This field cannot be empty"),
    mnemonic: yup.string().required("This field cannot be empty"),
    explanation: yup.string().required("This field cannot be empty"),
    hsk_level: yup.number().required("This field cannot be empty")
  });

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleUpdate = (values) => {
    if (typeof onUpdate === "function") {
      onUpdate(values);
    }
  };

  const handleAddComponent = () => {
    const componentsClone = Array.from(componentList);
    componentsClone.push({
      uiKey: uuid()
    });
    setComponentList(componentsClone);
  };

  const handleDeleteComponent = (index) => {
    const componentsClone = Array.from(componentList);
    componentsClone.splice(index, 1);
    setComponentList(componentsClone);
  };

  return (
    <Modal size="lg" centered show={show} onHide={handleClose} className="edit-character-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit Character</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <Modal.Body>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Character</Form.Label>
            <Col xs="8">
              <Form.Control
                name="character"
                type="text"
                placeholder="Enter Character"
                ref={register}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="inValid">{errors.character?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Mnemonic</Form.Label>
            <Col xs="8">
              <Form.Control
                name="mnemonic"
                type="text"
                placeholder="Enter Mnemonic"
                ref={register}
                isInvalid={!!errors.mnemonic}
              />
              <Form.Control.Feedback type="inValid">{errors.mnemonic?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Explanation</Form.Label>
            <Col xs="8">
              <Form.Control
                sm="10"
                name="explanation"
                as="textarea"
                placeholder="Enter explanation"
                ref={register}
                isInvalid={!!errors.explanation}
              />
              <Form.Control.Feedback type="inValid">{errors.explanation?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">HSK Level</Form.Label>
            <Col xs="8">
              <Form.Control
                name="hsk_level"
                type="number"
                placeholder="Enter HSK Level"
                ref={register}
                isInvalid={!!errors.hsk_level}
              />
              <Form.Control.Feedback type="inValid">{errors.hsk_level?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          {/*
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Components</Form.Label>
            {
              Array.isArray(componentList) && componentList.length > 0 ? (
                <Col xs="8">
                {
                  componentList.map((cmp, index) => {
                    const fieldNamePrefix = `components[${index}]`;
                    return (
                      <Fragment key={cmp.comp_id || cmp.uiKey}>
                        <Row className="component-row">
                          <Col xs="3">
                            <Form.Control
                              name={`${fieldNamePrefix}.component`}
                              type="text"
                              placeholder="Component"
                              ref={register}
                              isInvalid={!!errors.mnemonic}
                            />
                          </Col>
                          <Col xs="4">
                            <Form.Control
                              name={`${fieldNamePrefix}.phonetic`}
                              type="text"
                              placeholder="Phonetic"
                              ref={register}
                              isInvalid={!!errors.mnemonic}
                            />
                          </Col>
                          <Col xs="4">
                            <Form.Control
                              name={`${fieldNamePrefix}.defs`}
                              type="text"
                              placeholder="Defs"
                              ref={register}
                              isInvalid={!!errors.mnemonic}
                            />
                          </Col>
                          <Col xs="1">
                            <LinkButton onClick={() => handleDeleteComponent(index)}><i class="far fa-minus-square"></i></LinkButton>
                          </Col>
                        </Row>
                        {
                          index === componentList.length - 1 && (
                            <Row>
                              <Col>
                                <LinkButton onClick={handleAddComponent}>Add Component</LinkButton>
                              </Col>
                            </Row>
                          )
                        }
                      </Fragment>
                    );
                  })
                }
              </Col>
            ) : (
              <Col xs="8">
                <LinkButton onClick={handleAddComponent}>Add Component</LinkButton>
              </Col>
            )}
            <Form.Control.Feedback type="inValid">{errors.components?.message}</Form.Control.Feedback>
          </Form.Group>
        */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditCharacterModal.propTypes = {
  showModal: PropTypes.bool,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func
};

export default EditCharacterModal;
