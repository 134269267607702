import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError, setNextRoute } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchTemplates = () => async dispatch => {
  try {
    const response = await makeApiCall(`fc_openai_template`);
    dispatch(fetchTemplatesSuccess(response.data));
  } catch (error) {
    dispatch(fetchTemplatesFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch templates. Please try again."
    }));
  }
};

const fetchTemplatesSuccess = (payload) => ({
  type: Actions.FETCH_TEMPLATES_SUCCESS,
  payload
});

const fetchTemplatesFail = () => ({
  type: Actions.FETCH_TEMPLATES_FAIL
});

export const fetchTemplate = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_openai_template?id=eq.${id}`);
    dispatch(fetchTemplateSuccess(response.data?.[0] || {}));
  } catch (error) {
    dispatch(fetchTemplateFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch template. Please try again."
    }));
  }
};

const fetchTemplateSuccess = (payload) => ({
  type: Actions.FETCH_TEMPLATE_SUCCESS,
  payload
});

const fetchTemplateFail = () => ({
  type: Actions.FETCH_TEMPLATE_FAIL
});

export const createTemplate = (payload) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_openai_template`, {
      method: "post",
      data: payload
    });
    dispatch(createTemplateSuccess(response));
    dispatch(setNextRoute("/openai-templates"));
  } catch (error) {
    dispatch(createTemplateFail());
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to create new template. Please try again later"
    }));
  }
};

const createTemplateSuccess = (payload) => ({
  type: Actions.CREATE_TEMPLATE_SUCCESS,
  payload
});

const createTemplateFail = () => ({
  type: Actions.CREATE_TEMPLATE_FAIL
});

export const updateTemplate = (id, payload) => async dispatch => {
  try {
    await makeApiCall(`fc_openai_template?id=eq.${id}`, {
      method: 'patch',
      data: payload
    });

    dispatch(updateTemplateSuccess(payload));
  } catch (error) {
    dispatch(updateTemplateFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to update template at this moment. Please try again later."
    }));
  }
};

const updateTemplateSuccess = (payload) => ({
  type: Actions.UPDATE_TEMPLATE_SUCCESS,
  payload
});

const updateTemplateFail = () => ({
  type: Actions.UPDATE_TEMPLATE_FAIL
});

export const deleteTemplate = (id, onComplete) => async dispatch => {
  try {
    await makeApiCall(`fc_openai_template?id=eq.${id}`, {
      method: "delete"
    });

    dispatch(deleteTemplateSuccess());
    dispatch(setNextRoute("/openai-templates/"));
  } catch (e) {
    dispatch(deleteTemplateFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to delete template at the moment. Please try again later."
    }));
  }

  if (typeof onComplete === "function") {
    onComplete();
  }
};

const deleteTemplateSuccess = (payload) => ({
  type: Actions.DELETE_TEMPLATE_SUCCESS,
  payload
});

const deleteTemplateFail = () => ({
  type: Actions.DELETE_TEMPLATE_FAIL
});
