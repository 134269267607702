import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';

import { resetCommon } from '../../common/actions/actions';
import { getAwsCredentials, createProfile } from './state/actions';

import "./create-profile.scss";

const CreateProfile = () => {
  const dispatch = useDispatch();
  const { id, username, role, email } = useSelector(state => state.auth);
  const { nextRoute } = useSelector(state => state.common);
  const { awsCredentials } = useSelector(state => state.createProfile);
  const [avatarSrc, setAvatarSrc] = useState("/img/newavatar.png");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);
  
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      level: "beginner",
      study_reason: "At School",
      study_months: 0,
      most_interested: ""
    }
  });

  const handleChangeAvatar = (ev) => {
    const file = ev.target.files[0];
    setAvatarSrc(URL.createObjectURL(file));
    dispatch(getAwsCredentials());
  };

  const getProfilePicPayload = () => {
    if (Object.keys(awsCredentials).length > 0) {
      const formData = new FormData();
      Object.entries(awsCredentials).forEach(([key, value]) => {
        formData.append(key, value);
      });
      return formData;
    }

    return null;
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    dispatch(createProfile(id, {
      lang: "chinese",
      display_lang: "english",
      profession: role,
      signup_device: "web",
      ...data
    }, () => setSubmitting(false)), getProfilePicPayload());
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="create-profile">
      <div className="content">
        <h2 className="title">Create Your Profile</h2>
        <div className="header">
          <div className="avatar">
            <img src={avatarSrc} alt="avatar" />
            <label>
              <i className="fa fa-pen" />
              <input
                className="avatar-input"
                type="file"
                name="file"
                onChange={handleChangeAvatar}
              />
            </label>
          </div>
          <div className="user-details">
            <h3>{username}</h3>
            <p>{email}</p>
            <p>{role}</p>
          </div>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate className="login-form">
          
          <Form.Group>
            <Form.Label>Level</Form.Label>
            <Form.Control
              name="level"
              as="select"
              ref={register}
              isInvalid={!!errors.level}
            >
              <option value="beginner">Beginner</option>
              <option value="low-intermediate">Low-Intermediate</option>
              <option value="high-intermediate">High-Intermediate</option>
              <option value="advanced">Advanced</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Study Reason</Form.Label>
            <Form.Control
              name="study_reason"
              as="select"
              ref={register}
              isInvalid={!!errors.study_reason}
            >
              <option value="At School">At School</option>
              <option value="For Work">For Work</option>
              <option value="For Fun">For Fun</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Study Months</Form.Label>
            <Form.Control
              name="study_months"
              type="number"
              placeholder="Enter Study Months"
              ref={register}
              isInvalid={!!errors.study_months}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Most Interested</Form.Label>
            <Form.Control
              name="most_interested"
              type="email"
              placeholder="Why you are interested to learn Chinese?"
              ref={register}
            />
          </Form.Group>
          <div className="actions">
            <Button type="submit" disabled={submitting}>
              Create Profile
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateProfile;
