import React, { useState, useEffect, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const EmailDataModal = ({ showModal, data, onClose  }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const beautifiedData = useMemo(() => {
    if (typeof data !== null && typeof data === "object") {
      return JSON.stringify(data, null, '\t');
    }

    return data;
  }, [data]);

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Email Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>
          {beautifiedData}
        </pre>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailDataModal;