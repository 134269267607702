import uuid from 'uuid/v4';

import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError } from "../../../common/actions/actions";
import { fastapiUrl } from "../../../config";

import * as Actions from "./action-types";

export const fetchCharacters = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_chars?character=eq.${char}`);
    dispatch(fetchCharactersSuccess(response.data));
  } catch (error) {
    dispatch(fetchCharactersFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch characters. Please try again."
    }));
  }
};

const fetchCharactersSuccess = (payload) => ({
  type: Actions.FETCH_CHARACTERS_SUCCESS,
  payload
});

const fetchCharactersFail = () => ({
  type: Actions.FETCH_CHARACTERS_FAIL
});

export const fetchWordsWithChar = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_words?chinese=like.%${char}%`, {
      headers : {
        Prefer: "count=exact",
        Range: "0-5"
      }
    });
    dispatch(fetchWordsWithCharSuccess(response.data));
  } catch (error) {
    dispatch(fetchWordsWithCharFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchWordsWithCharSuccess = (payload) => ({
  type: Actions.FETCH_WORDS_WITH_CHARACTER_SUCCESS,
  payload
});

const fetchWordsWithCharFail = () => ({
  type: Actions.FETCH_WORDS_WITH_CHARACTER_FAIL
});

export const fetchSentencesWithChar = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_sentences?chinese=like.%${char}%`, {
      headers : {
        Prefer: "count=exact",
        Range: "0-5"
      }
    });
    dispatch(fetchSentencesWithCharSuccess(response.data));
  } catch (error) {
    dispatch(fetchSentencesWithCharFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchSentencesWithCharSuccess = (payload) => ({
  type: Actions.FETCH_SENTENCES_WITH_CHARACTER_SUCCESS,
  payload
});

const fetchSentencesWithCharFail = () => ({
  type: Actions.FETCH_SENTENCES_WITH_CHARACTER_FAIL
});


export const fetchWords = (word) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_words?chinese=like.%${word}%`);
    dispatch(fetchWordsSuccess(response.data));
  } catch (error) {
    dispatch(fetchWordsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchWordsSuccess = (payload) => ({
  type: Actions.FETCH_WORDS_SUCCESS,
  payload
});

const fetchWordsFail = () => ({
  type: Actions.FETCH_WORDS_FAIL
});

export const fetchWord = (word) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_words?chinese=eq.${word}`);
    dispatch(fetchWordSuccess(response?.data));
  } catch (error) {
    dispatch(fetchWordFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchWordSuccess = (payload) => ({
  type: Actions.FETCH_WORD_SUCCESS,
  payload
});

const fetchWordFail = () => ({
  type: Actions.FETCH_WORD_FAIL
});

export const addWord = (payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall(`fc_words`, {
      method: 'post',
      data: payload
    });

    dispatch(addWordSuccess());

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Could add word. Please try again.";
    dispatch(addWordFail());
    dispatch(throwApiError({
      type: "toast",
      message
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const addWordSuccess = (payload) => ({
  type: Actions.ADD_WORD_SUCCESS,
  payload
});

const addWordFail = () => ({
  type: Actions.ADD_WORD_FAIL
});

export const updateWord = (wordId, payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall(`fc_words?id=eq.${wordId}`, {
      method: 'patch',
      data: payload
    });

    dispatch(updateWordSuccess());

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Could not update word. Please try again.";
    dispatch(updateWordFail());
    dispatch(throwApiError({
      type: "toast",
      message
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const updateWordSuccess = (payload) => ({
  type: Actions.UPDATE_WORD_SUCCESS,
  payload
});

const updateWordFail = () => ({
  type: Actions.UPDATE_WORD_FAIL
});

export const deleteWord = (wordId, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall(`fc_words?id=eq.${wordId}`, {
      method: 'delete'
    });

    dispatch(deleteWordSuccess());

    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Could not delete word def. Please try again.";
    dispatch(deleteWordFail());
    dispatch(throwApiError({
      type: "toast",
      message
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const deleteWordSuccess = (payload) => ({
  type: Actions.DELETE_WORD_SUCCESS,
  payload
});

const deleteWordFail = () => ({
  type: Actions.DELETE_WORD_FAIL
});

export const fetchWordComponents = (word) => async dispatch => {
  try {
    const components = word.split("");
    const finalResponsePayload = [];

    components.forEach(async (component) => {
      const response = await makeApiCall(`fc_chars?character=eq.${component}`);
      finalResponsePayload.push(response.data[0]);
    });
    
    dispatch(fetchWordComponentsSuccess(finalResponsePayload));
  } catch (error) {
    dispatch(fetchWordComponentsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchWordComponentsSuccess = (payload) => ({
  type: Actions.FETCH_WORD_COMPONENTS_SUCCESS,
  payload
});

const fetchWordComponentsFail = () => ({
  type: Actions.FETCH_WORD_COMPONENTS_FAIL
});

export const fetchSentencesWithWord = (word) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_sentences?keywords=like.%${word}%`, {
      headers : {
        Prefer: "count=exact",
        Range: "0-5"
      }
    });
    dispatch(fetchSentencesWithWordSuccess(response.data));
  } catch (error) {
    dispatch(fetchSentencesWithWordFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchSentencesWithWordSuccess = (payload) => ({
  type: Actions.FETCH_SENTENCES_WITH_WORD_SUCCESS,
  payload
});

const fetchSentencesWithWordFail = () => ({
  type: Actions.FETCH_SENTENCES_WITH_WORD_FAIL
});


export const fetchSentences = (sentence) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_sentences?chinese=like.%${sentence}%`);
    dispatch(fetchSentencesSuccess(response.data));
  } catch (error) {
    dispatch(fetchSentencesFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch sentences. Please try again."
    }));
  }
};

const fetchSentencesSuccess = (payload) => ({
  type: Actions.FETCH_SENTENCES_SUCCESS,
  payload
});

const fetchSentencesFail = () => ({
  type: Actions.FETCH_SENTENCES_FAIL
});


export const fetchCharacter = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_chars?character=eq.${char}`);
    dispatch(fetchCharacterSuccess(response.data));
  } catch (error) {
    dispatch(fetchCharacterFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch character. Please try again."
    }));
  }
};

const fetchCharacterSuccess = (payload) => ({
  type: Actions.FETCH_CHARACTER_SUCCESS,
  payload
});

const fetchCharacterFail = () => ({
  type: Actions.FETCH_CHARACTER_FAIL
});

export const fetchCharacterStrokes = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_char_strokes?character=eq.${char}`);
    dispatch(fetchCharacterStrokesSuccess(response.data));
  } catch (error) {
    dispatch(fetchCharacterStrokesFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch character strokes. Please try again."
    }));
  }
};

const fetchCharacterStrokesSuccess = (payload) => ({
  type: Actions.FETCH_CHARACTER_STROKES_SUCCESS,
  payload
});

const fetchCharacterStrokesFail = () => ({
  type: Actions.FETCH_CHARACTER_STROKES_FAIL
});

export const fetchCharacterInfo = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_char_info?character=eq.${char}`);
    dispatch(fetchCharacterInfoSuccess(response.data));
  } catch (error) {
    dispatch(fetchCharacterInfoFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch character information. Please try again."
    }));
  }
};

const fetchCharacterInfoSuccess = (payload) => ({
  type: Actions.FETCH_CHARACTER_INFO_SUCCESS,
  payload
});

const fetchCharacterInfoFail = () => ({
  type: Actions.FETCH_CHARACTER_INFO_FAIL
});

export const fetchCharacterDef = (word) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_words?chinese=eq.${word}`);
    dispatch(fetchCharacterDefSuccess(response?.data));
  } catch (error) {
    dispatch(fetchCharacterDefFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch words. Please try again."
    }));
  }
};

const fetchCharacterDefSuccess = (payload) => ({
  type: Actions.FETCH_CHARACTER_DEFS_SUCCESS,
  payload
});

const fetchCharacterDefFail = () => ({
  type: Actions.FETCH_CHARACTER_DEFS_FAIL
});

export const fetchComponents = (char) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_components?character=eq.${char}`);
    dispatch(fetchComponentsSuccess(response.data));
  } catch (error) {
    dispatch(fetchComponentsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch character components. Please try again."
    }));
  }
};

const fetchComponentsSuccess = (payload) => ({
  type: Actions.FETCH_COMPONENTS_SUCCESS,
  payload
});

const fetchComponentsFail = () => ({
  type: Actions.FETCH_COMPONENTS_FAIL
});

export const updateCharacter = (char, payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_chars?character=eq.${char}`, {
      method: "patch",
      data: payload
    });
    dispatch(updateCharacterSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(updateCharacterFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update character. Please try again."
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const updateCharacterSuccess = (payload) => ({
  type: Actions.UPDATE_CHARACTER_SUCCESS,
  payload
});

const updateCharacterFail = () => ({
  type: Actions.UPDATE_CHARACTER_FAIL
});

export const updateCharInfo = (id, payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_char_info?character=eq.${id}`, {
      method: "patch",
      data: payload
    });
    dispatch(updateCharInfoSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(updateCharInfoFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update character info. Please try again."
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const updateCharInfoSuccess = (payload) => ({
  type: Actions.UPDATE_CHAR_INFO_SUCCESS,
  payload
});

const updateCharInfoFail = () => ({
  type: Actions.UPDATE_CHAR_INFO_FAIL
});

export const submitFile = (file, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(`s3/signed?resource=images/${uuid()}.png`, {
      baseURL: fastapiUrl,
      method: "get"
    });
    const { url, fields: { key, AWSAccessKeyId, policy, signature, acl, "Content-Type": contentType } } = response.data;
    const awsCredentials = {
      key,
      "Content-Type": contentType,
      AWSAccessKeyId,
      policy,
      signature,
      acl,
      file
    };

    const getPayload = () => {
      if (Object.keys(awsCredentials).length > 0) {
        const formData = new FormData();
        Object.entries(awsCredentials).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return formData;
      }
  
      return null;
    };

    const payload = getPayload();

    await fetch(url, {
      method: "post",
      body: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess(`${url}${key}`);
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};

//const getAwsCredentialsSuccess = () => {};
