import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";

const renderLink = ({ row }) => {
  const { name, owner } = row;
  return (
    <Link to={`/members/details/${owner}`}>{name}</Link>
  );
}

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatExpiryDate = ({row}) => {
  const { expire_date } = row;
  return formatDate(expire_date);
};

const formatPaidDate = ({row}) => {
  const { paid_through_date } = row;
  return formatDate(paid_through_date);
};

const formatModifiedDate = ({row}) => {
  const { last_modified } = row;
  return formatDate(last_modified);
};

const columns = [
  { field: 'owner', headerName: 'User Id', width: 120 },
  { field: 'name', headerName: 'User Name', width: 150, renderCell: renderLink },
  { field: 'email', headerName: 'Email', width: 180 },
  { field: 'payment', headerName: 'Payment', width: 140 },
  { field: 'environment', headerName: 'Environment', width: 100 },
  { field: 'coupoun_id', headerName: 'Coupon', width: 150 },
  { field: 'status', headerName: 'Status', width: 120 },
  { field: 'expire_date', headerName: 'Expire Date', width: 150, valueGetter: formatExpiryDate },
  { field: 'paid_through_date', headerName: 'Pay Date', width: 150, valueGetter: formatPaidDate },
  { field: 'amount', headerName: 'Amount', width: 120 },
  { field: 'currency', headerName: 'Currency', width: 130 },
  { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate }
];

const PaymentHistory = ({ data, onFetchPayments }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchPayments({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchPayments({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchPayments({ pageNum: 0, pageSize }, searchTerm);
  };

  return (
    <div className="payment-history">
      <SearchHeader
        count={numRecords}
        countTitle="Payments"
        placeHolder="Search history by name or email"
        onSearch={handleSearch}
      />
      <div className="payment-history-results">
        <DataGrid
          className="payment-history-grid"
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

PaymentHistory.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchPayments: PropTypes.func
};

export default PaymentHistory;
