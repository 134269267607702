import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { apiUrl } from "../config";
import "./Nav.scss";

const axios = require("axios");
class Nav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      header: "",
      avatar: "",
      selectedNavitem: "",
      navHidden: false
    };
  }
  componentDidMount() {
    this.getHeader();
    this.checkUrl();
    this.getAvatar();
    if (this.props.role === "student") {
      document.getElementById("left-nav-bar").style.display = "none";
    }
  }

  getHeader = () => {
    let headerText = "";
    var url = window.location.pathname.split("/");
    this.setState(
      {
        header: url[1]
      },
      () => {
        headerText = document.getElementById("header-name").innerHTML;
        this.props.dispatch({ type: "HEADERS", payload: headerText });
      }
    );
  };

  checkUrl = () => {
    var pathName = window.location.pathname.split("/");
    var newPath = pathName[1];
    this.setState({ selectedNavitem: newPath });
  };
  //Logout function
  logoutHandler = e => {
    localStorage.clear();
    window.location.href = "/";
  };

  getAvatar = () => {
    let headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.auth
    };
    let url = apiUrl + "fc_profile";
    axios
      .get(url, { headers: headers })
      .then(response => {
        this.setState({
          avatar: response.data[0].avatar
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  addDefaultSrc = ev => {
    ev.target.src = "/img/newavatar.png";
  };

  hideNav = e => {
    this.props.dispatch({ type: "HIDENAV", payload: this.state.navHidden });
    this.setState({ navHidden: !this.state.navHidden });
    document.getElementById("kt_aside").style.width = e.target.className === "originLogo" ? "75px" : "initial";
    document.getElementById("kt_aside").style.marginLeft = e.target.className === "originLogo" ? "-40px" : "initial";
    document.getElementById("logo_Image").style.width = e.target.className === "originLogo" ? "24px" : "initial";
    document.getElementById("logo_Image").style.marginLeft = e.target.className === "originLogo" ? "18px" : "initial";
    var x = document.querySelectorAll(".kt-aside--enabled .kt-header.kt-header--fixed");
    x[0].style.left = e.target.className === "originLogo" ? "35px" : "260px";
  };

  render() {
    const { selectedNavitem } = this.state;
    return (
      <div className="kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-aside--enabled kt-aside--fixed">
        <div
          id="kt_header_mobile"
          className="kt-header-mobile  kt-header-mobile--fixed "
          style={{ backgroundColor: "orangeRed", opacity: 0.7 }}
        >
          <div className="kt-header-mobile__logo">
            <Link to="/organization">
              <img alt="Logo" src="/img/logo.svg" />
            </Link>
          </div>
          <div
            className="kt-header-mobile__toolbar"
            style={{ backgroundColor: "orangeRed", opacity: 0.7, marginRight: 15 }}
          >
            <button
              className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span></span>
            </button>
          </div>
        </div>
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page1">
            <div
              className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
              id="kt_aside"
            >
              <div
                className="kt-aside__brand kt-grid__item "
                id="kt_aside_brand"
                kt-hidden-height="75"
                style={{
                  backgroundImage: "linear-gradient(to left, #ff4b2d, #ff7b4a)"
                }}
              >
                <div className="kt-aside__brand-logo">
                  <img
                    alt="Logo"
                    src={this.state.navHidden === false ? "/img/logo.svg" : "/img/listItem.png"}
                    id="logo_Image"
                    onClick={this.hideNav}
                    style={{ cursor: "pointer" }}
                    className={this.state.navHidden === false ? "originLogo" : "listLogo"}
                  />
                </div>
              </div>
              <div
                className="kt-grid__item kt-grid__item--fluid"
                id="kt_aside_menu_wrapper"
                style={{ backgroundColor: "#f7f9f9" }}
              >
                <div
                  id="kt_aside_menu"
                  className="kt-aside-menu kt-scroll ps ps--active-y"
                  data-ktmenu-vertical="1"
                  data-ktmenu-scroll="1"
                  data-ktmenu-dropdown-timeout="500"
                  style={{ height: 805, backgroundColor: "#f7f9f9" }}
                >
                  <div className="kt-menu__nav" style={{ height: "390px" }}>
                    <li
                      className="kt-menu__item  kt-menu__item--submenu kt-menu__item--open kt-menu__item--here"
                      aria-haspopup="true"
                      data-ktmenu-submenu-toggle="hover"
                      id="left-nav-bar"
                    >
                      <div className="kt-menu__submenu ">
                        <span className="kt-menu__arrow"></span>
                        <ul className="kt-menu__subnav">
                          {this.props.organization !== 0 ? (
                            <>
                              <li
                                className={`kt-menu__item ${
                                  selectedNavitem === "organization" ? " selected_navBar" : ""
                                }`}
                                aria-haspopup="true"
                                onClick={this.checkUrl}
                              >
                                <Link to="/organization" className="kt-menu__link">
                                  <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                    {" "}
                                    <img src="/img/organization.svg" alt="ima" className="size-24-24" />
                                    <span className={selectedNavitem === "organization" ? "selected" : ""}>
                                      Organization
                                    </span>
                                  </h3>
                                </Link>
                              </li>
                            </>
                          ) : (
                            ""
                          )}

                          <li
                            className={`kt-menu__item ${selectedNavitem === "classes" ? " selected_navBar" : ""}`}
                            aria-haspopup="true"
                            onClick={this.checkUrl}
                          >
                            <Link to="/classes" className="kt-menu__link">
                              <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                <img src="/img/class.svg" alt="ima" className="size-24-24" />
                                <span className={selectedNavitem === "classes" ? "selected" : ""}>Class</span>
                              </h3>
                            </Link>
                          </li>

                          <li
                            className={`kt-menu__item ${selectedNavitem === "books" ? " selected_navBar" : ""}`}
                            aria-haspopup="true"
                            onClick={this.checkUrl}
                          >
                            <Link to="/books" className="kt-menu__link ">
                              <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                {" "}
                                <img src="/img/texts.svg" alt="ima" className="size-24-24" />
                                <span className={selectedNavitem === "books" ? "selected" : ""}>Text</span>
                              </h3>
                            </Link>
                          </li>
                          <li
                            className={`kt-menu__item ${selectedNavitem === "store" ? " selected_navBar" : ""}`}
                            aria-haspopup="true"
                            onClick={this.checkUrl}
                          >
                            <Link to="/store" className="kt-menu__link ">
                              <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                {" "}
                                <img src="/img/store.svg" alt="ima" className="size-24-24" />
                                <span className={selectedNavitem === "store" ? "selected" : ""}>Store</span>
                              </h3>
                            </Link>
                          </li>
                          <li
                            className={`kt-menu__item ${selectedNavitem === "dictionary" ? " selected_navBar" : ""}`}
                            aria-haspopup="true"
                            onClick={this.checkUrl}
                          >
                            <Link to="/dictionary" className="kt-menu__link ">
                              <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                {" "}
                                <img src="/img/dictionary.svg" alt="ima" className="size-24-24" />
                                <span className={selectedNavitem === "dictionary" ? "selected" : ""}>Dictionary</span>
                              </h3>
                            </Link>
                          </li>
                          {this.props.role === "gk_admin" && (
                            <li
                              className={`kt-menu__item ${selectedNavitem === "Members" ? " selected_navBar" : ""}`}
                              aria-haspopup="true"
                              onClick={this.checkUrl}
                            >
                              <Link to="/members" className="kt-menu__link ">
                                <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                  {" "}
                                  <img src="/img/members.svg" alt="ima" className="size-24-24" />
                                  <span className={selectedNavitem === "Members" ? "selected" : ""}>Members</span>
                                </h3>
                              </Link>
                            </li>
                          )}
                          {this.props.role === "gk_admin" && (
                            <li
                              className={`kt-menu__item ${selectedNavitem === "payments" ? " selected_navBar" : ""}`}
                              aria-haspopup="true"
                              onClick={this.checkUrl}
                            >
                              <Link to="/payments" className="kt-menu__link ">
                                <h3 className="kt-menu__link-text" style={{ fontSize: 18 }}>
                                  {" "}
                                  <img src="/img/payments.svg" alt="ima" className="size-24-24" />
                                  <span className={selectedNavitem === "payments" ? "selected" : ""}>Payments</span>
                                </h3>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                    <div className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true">
                      <div className="kt-menu__submenu ">
                        <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                          <div className="ps__thumb-x" tabIndex="0" style={{ left: 0, width: 0 }}></div>
                        </div>
                        <div className="ps__rail-y" style={{ top: 0, height: 805, right: 3 }}>
                          <div className="ps__thumb-y" tabIndex="0" style={{ top: 0, height: 300 }}></div>
                        </div>
                      </div>
                      <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                        id="kt_wrapper"
                      >
                        <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed ">
                          <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
                            <div id="kt_header_menu" className="kt-header-menu align-items-center d-flex">
                              <div className="kt-subheader__main d-flex">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <h4
                                    className="kt-subheader__title align-items-center d-flex mx-3 my-0 text-capitalize"
                                    id="header-name"
                                  >
                                    <p>{this.state.selectedNavitem}</p>
                                  </h4>
                                  {this.state.header === "MembersDetails" ? (
                                    <img src="img/v-right.svg" alt="imae" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="warning-section">
                            <p>STILL UNDER CONSTUCTION!</p>
                            <p>
                              Please go to{" "}
                              <a href="http://www.fullchinesehelp.com/" target="_blank" rel="noopener noreferrer">
                                fullchinesehelp.com
                              </a>{" "}
                              for the time being
                            </p>
                            <p>Thanks for your patience</p>
                          </div>
                          <div className="kt-header__topbar">
                            <div className="kt-header__topbar-item kt-header__topbar-item--user">
                              <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
                                <div className="kt-header__topbar-user kt-rounded-">
                                  <div className="kt-header__topbar-item dropdown">
                                    <div className="kt-header__topbar-wrapper">
                                      <span className="kt-header__topbar-icon">
                                        <img src="/img/bell.svg" alt="notification-img" />
                                        <span className="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand"></span>
                                      </span>
                                    </div>
                                  </div>
                                  <img
                                    onError={this.addDefaultSrc}
                                    src={
                                      this.state.avatar && this.state.avatar.slice(-4) === ".png"
                                        ? `https://fullapi.s3.amazonaws.com/${this.state.avatar}`
                                        : "/img/newavatar.png"
                                    }
                                    className="kt-rounded-"
                                    alt="https://via.placeholder.com/600/771796-pic"
                                  />

                                  <span className="kt-header__topbar-username kt-hidden-mobile">
                                    {this.props.token}
                                  </span>
                                  <img
                                    className="kt-header-dropdown-expand"
                                    src="/img/dropdown-expand.svg"
                                    alt="dropdown-img"
                                  />
                                </div>
                              </div>
                              <div
                                className="dropdown-menu dropdown-menu-fit dropdown-menu-right 
                              dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm profile-dropdown"
                              >
                                <ul className="kt-nav" style={{ padding: 0 }}>
                                  <li className="kt-nav__item font-weight-bold" onClick={this.checkUrl}>
                                    <img
                                      onError={this.addDefaultSrc}
                                      src={
                                        this.state.avatar && this.state.avatar.slice(-4) === ".png"
                                          ? `https://fullapi.s3.amazonaws.com/${this.state.avatar}`
                                          : "/img/newavatar.png"
                                      }
                                      className="size-24-24 kt-rounded-"
                                      alt="ima"
                                    />
                                    <Link to="/settings">
                                      <span className="kt-nav__link-text ">My Profile</span>
                                    </Link>
                                  </li>
                                  <li className="kt-nav__item font-weight-bold">
                                    <img className="size-24-24" src="/img/logout.png" alt="dropdown-img" />
                                    <Link to="/" onClick={this.logoutHandler}>
                                      <span className="kt-nav__link-text ">Sign Out</span>
                                    </Link>
                                    <i
                                      className="flaticon2-information kt-label-font-color-2"
                                      data-toggle="kt-tooltip"
                                      data-placement="right"
                                      title=""
                                      data-original-title="Click to learn more..."
                                    ></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    auth: state.rpc.tokens,
    role: state.auth.role,
    organizationnumber: state.rpc.organizationnumber,
    classNumber: state.rpc.classNumber,
    booknumber: state.rpc.booknumber,
    storenumber: state.rpc.storenumber,
    members: state.rpc.members,
    textlength: state.rpc.textlength
  };
};
export default connect(mapStateToProps)(Nav);
