import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({ 
  code: yup
    .string()
    .required("This field cannot be empty"),
  period: yup
    .string()
    .required("This field cannot be empty"),
  redeem_times: yup
    .string()
    .required("This field cannot be empty")
});

const NewCouponModal = ({ showModal, onClose, onAddCoupon  }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (payload) => {
    setSubmitting(true);
    onAddCoupon(payload, () => {
      setSubmitting(false);
      handleClose();
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Coupon</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <Form.Group controlId="code">
            <Form.Label>Coupon Code</Form.Label>
            <Form.Control
              name="code"
              type="text"
              placeholder="Enter Coupon Code Here"
              ref={register}
              isInvalid={!!errors.code}
            />
            <Form.Control.Feedback type="inValid">{errors.code?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="period">
            <Form.Label>Period</Form.Label>
            <Form.Control
              name="period"
              type="number"
              placeholder="Enter Period Here"
              ref={register}
              isInvalid={!!errors.period}
            />
            <Form.Control.Feedback type="inValid">{errors.period?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="redeem-times">
            <Form.Label>Redeem Times</Form.Label>
            <Form.Control
              name="redeem_times"
              type="number"
              placeholder="Enter Redeem Times Here"
              ref={register}
              isInvalid={!!errors.redeem_times}
            />
            <Form.Control.Feedback type="inValid">{errors.redeem_times?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewCouponModal;

