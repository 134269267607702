import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError, showNotice, setNextRoute } from "../../../common/actions/actions";
import { getStartAndEndRecord } from "../../../helpers/utils";

import * as Actions from "./action-types";

export const fetchTexts = (pagination) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_free_texts?select=*,text:fc_texts(owner,author,last_modified,title)`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchTextsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch texts. Please try again."
    }));
  }
};

const fetchTextsSuccess = (payload) => ({
  type: Actions.FETCH_TEXTS_SUCCESS,
  payload
});

const fetchTextsFail = () => ({
  type: Actions.FETCH_TEXTS_FAIL
});

export const addTexts = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall("fc_free_texts", {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text Added To Book Successfully"
    }));

    dispatch(addTextsSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(addTextsFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to add the text at this moment. Please try again later."
    }));
  }
};

const addTextsSuccess = (payload) => ({
  type: Actions.ADD_TEXTS_SUCCESS,
  payload
});

const addTextsFail = () => ({
  type: Actions.ADD_TEXTS_FAIL
});

export const deleteText = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_free_texts?id=eq.${id}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text removed successfully"
    }));
    dispatch(deleteTextSuccess(response.data));
  } catch (error) {
    dispatch(deleteTextFail());

    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Could not delete selected text. Please try again."
    }));
  }
};

const deleteTextSuccess = (payload) => ({
  type: Actions.DELETE_TEXT_SUCCESS,
  payload
});

const deleteTextFail = () => ({
  type: Actions.DELETE_TEXT_FAIL
});


export const fetchConfig = () => async dispatch => {
  try {
    const response = await makeApiCall("fc_server_config");
    dispatch(fetchConfigSuccess(response.data[0]));
  } catch (error) {
    dispatch(fetchConfigFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch config. Please try again."
    }));
  }
};

const fetchConfigSuccess = (payload) => ({
  type: Actions.FETCH_CONFIG_SUCCESS,
  payload
});

const fetchConfigFail = () => ({
  type: Actions.FETCH_CONFIG_FAIL
});


export const updateConfig = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall("fc_server_config", {
      method: 'patch',
      data: payload
    });

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Config updated successfully"
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(updateConfigSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateConfigFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update config. Please try again."
    }));
  }
};

const updateConfigSuccess = (payload) => ({
  type: Actions.UPDATE_CONFIG_SUCCESS,
  payload
});

const updateConfigFail = () => ({
  type: Actions.UPDATE_CONFIG_FAIL
});

export const fetchAllTexts = (pagination, textShaList, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_admin_textstatitems?select=*,text:fc_texts(author,last_modified)&sha1=not.in.(${textShaList.join()})&text_title=ilike.%${searchTerm}%`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchAllTextsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchAllTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch config. Please try again."
    }));
  }
};

const fetchAllTextsSuccess = (payload) => ({
  type: Actions.FETCH_ALL_TEXTS_SUCCESS,
  payload
});

const fetchAllTextsFail = () => ({
  type: Actions.FETCH_ALL_TEXTS_FAIL
});
