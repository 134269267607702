/* eslint-disable no-restricted-globals */
//export let apiUrl = "http://3.209.149.222:8088/api/1/";
//export let fastapiUrl = "http://3.209.149.222:8088/fastapi/";
// for the staging server deployment

// export let apiUrl = "https://server-staging.fullchinese.com/api/1/";
// export let fastapiUrl = "https://server-staging.fullchinese.com/fastapi/";
// export let mediaUrl = "https://media.fullchinese.com";

const apiHost = location.hostname === "www.fullchinese.com" || location.hostname === "fullchinese.com"
  ? "server" : "server-staging";

export const apiUrl = `https://${apiHost}.fullchinese.com/api/1/`;
export const fastapiUrl = `https://${apiHost}.fullchinese.com/fastapi/`;
export const ytapiUrl = `https://yt.fullchinese.com/youtube/`;
export const mediaUrl = "https://media.fullchinese.com";
