import {
    FETCH_POST_FEEDS_SUCCESS
  } from "./action-types";
  
  const initialValue = {
    postFeeds: {
      numRecords: 0,
      rows: []
    },
    selectedPostFeed: {},
  };
  
  const postFeedsReducer = (state = initialValue, { type, payload }) => {
    switch(type) {
      case FETCH_POST_FEEDS_SUCCESS:
        return {...state, postFeeds: payload}
      default:
        return state;
    }
  };
  
  export default postFeedsReducer;
  