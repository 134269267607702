import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DataGrid } from "@material-ui/data-grid";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../../components/SearchHeader/SearchBar";

import "./AddBookModal.scss";


const AddBookModal = (props) => {
  const { classId, books, showModal, onHide, onFetchBooks, onAddBooks } = props;
  const [selectedBooks, setSelectedBooks] = useState([]);

  useEffect(() => {
    if (showModal) {
      setSelectedBooks([]);
    }
  }, [showModal]);

  const handleSelectBook = (ev, data) => {
    const selected = ev.target.checked;
    const selectedBook = {
      class_id: classId,
      book_sha1: data.sha1,
      book_title: data.title,
      book_description: data.description,
      book_level: data.level
    };
    if(selected) {
      setSelectedBooks(selectedBooks.concat([selectedBook]));
    } else {
      setSelectedBooks(selectedBooks.filter(book => book.book_sha1 !== data.sha1));
    }
  };

  const formatDate = ({ row }) => {
    const { last_modified } = row;
    return dayjs(last_modified).format('DD/MM/YYYY');
  };
  

  const renderCheckbox = ({ row }) => {
    return (
      <Form.Check
        type="checkbox"
        onClick={(ev) => handleSelectBook(ev, row)}
      />
    );
  };

  const handleClose = () => {
    setSelectedBooks([]);
    if (typeof onHide === "function") {
      onHide();
    }
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 250 },
    { field: 'description', headerName: 'Description', width: 450 },
    { field: 'author', headerName: 'Author', width: 130 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'last_modified', headerName: 'Modified', width: 140, renderCell: formatDate},
    { field: 'id', headerName: '-', width: 50, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    onFetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm) => {
    onFetchBooks(searchTerm);
  };

  return (
    <Modal centered show={showModal} onHide={handleClose} dialogClassName="cls-new-book-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New Book to This Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="book-search">
          <SearchBar placeholder="Search: Book with Title" onSearch={handleSearch} />
        </div>
        <div className="books-results">
          <DataGrid
            className="books-grid"
            autoHeight={true}
            rows={books}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            getRowId={row => row.sha1}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" onClick={() => onAddBooks(selectedBooks)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddBookModal.propTypes = {
  classId: PropTypes.string,
  showModal: PropTypes.bool,
  books: PropTypes.array,
  bookSha: PropTypes.array,
  onHide: PropTypes.func,
  onFetchBooks: PropTypes.func,
  onAddBooks: PropTypes.func
};

export default AddBookModal;
