import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./LinkButton.scss";

const LinkButton = ({ elemRef, className, children, ...otherProps }) => {
  const classes = classNames({
    [className]: !!className,
    "link-button": true
  });

  return (
    <button type="button" ref={elemRef} className={classes} {...otherProps}>
      {children}
    </button>
  );
};

LinkButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default LinkButton;