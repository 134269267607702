import { FETCH_VIDEO_TRANSCRIPT_SUCCESS } from "./action-types";

const initialValue = {
  data: {
    transcript: []
  }
};

const videosReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_VIDEO_TRANSCRIPT_SUCCESS:
      return {...state, data: payload}
    default:
      return state;
  }
};

export default videosReducer;
