import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import "./PayPal.scss";

const SubscribeSuccess = () => {
  const { role } = useSelector(state => state.auth);

  return (
    <div className="subscribe-success">
      <div className="text-center">
        <img src="/img/logo.svg" className="img-fluid mt-5" alt="logo" />
      </div>

      <div className="card-body text-center ">
        <img
          src="/img/successsubs.svg"
          className="img-fluid mt-5 success-img"
          alt="subscription successful"
        />

        <h2>Congratulations!</h2>
        <p>You have successfully Subscribed.</p>
        <p>You get a 14 Days Free trial. Please Login to continue.</p>

        {role === "student" && (
          <Link to="/user/home">
            <button
              type="submit"
              id="kt_login_submit"
              className="btn btn-brand btn-elevate btn-pill mx-3 landingBtn w-25 h-5"
            >
              Go to Main Page
            </button>
          </Link>
        )}
        {role !== "student" && (
          <Link to="/organization">
            <button
              type="submit"
              id="kt_login_submit"
              className="btn btn-brand btn-elevate btn-pill mx-3 landingBtn w-25 h-5"
            >
              Go to Main Page
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}


export default SubscribeSuccess;
