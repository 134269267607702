import React, { Component } from "react";
import { connect } from "react-redux";
import { apiUrl } from "../../../config";
import swal from "sweetalert";

const axios = require("axios");

class OrganizationNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidenavStatus: false,
      name: "",
      description: "",
      max_members: "",
      max_books: "",
      org_id: ""
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hidenav !== nextProps.hidenav) {
      this.setState({ hidenavStatus: this.props.hidenav });
    }
  }

  clickCreate = () => {
    this.setState({ clickCreate: true });
    if (this.state.name && this.state.description) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Prefer: "count=exact",
        Authorization: "Bearer " + this.props.token
      };
      let url = apiUrl + "fc_classes?user_id=eq." + this.props.id;
      let data = {
        name: this.state.name.trim(),
        description: this.state.description,
        org_id: this.props.orgid,
        max_books: this.state.max_books,
        max_members: this.state.max_members
      };
      axios
        .post(url, data, {
          headers: headers
        })
        .then(response => {
          this.props.history.goBack();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        })
        .catch(error => {
          swal(
            "",
            "Not able to add Class at this time. Pleaes try again later",

            "error"
          );
        });
    }
  };

  changeHandler = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <div
          className="
        kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed"
        >
          <div className="kt-grid kt-grid--hor kt-grid--root">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
              <div
                className={
                  this.state.hidenavStatus === false
                    ? "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                    : "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper navbar-hided"
                }
                id="kt_wrapper"
              >
                <div
                  className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                  id="kt_content"
                  style={{ paddingLeft: 0, marginTop: 50 }}
                >
                  <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="cc-main-content">
                      <div className="cc-header">
                        <div className="cc-header-details">
                          <div className="go-back" onClick={() => this.goBack()}>
                            <img src="/img/back.svg" alt="back" className="size-24-24" />
                          </div>
                          <span>New Class</span>
                        </div>
                      </div>
                      <div className="cc-content-details">
                        <div className="cc-content-row">
                          <div className="cc-subtitle">
                            <span>Class Name</span>
                          </div>
                          <div className="cc-subcontent">
                            <input
                              type="text"
                              value={this.state.name}
                              name="name"
                              className="cc-input"
                              placeholder="Enter Class Name"
                              onChange={this.changeHandler}
                              style={{
                                borderBottom: !this.state.name && this.state.clickCreate ? "1px solid red" : ""
                              }}
                            />
                          </div>
                        </div>

                        <div className="cc-content-row">
                          <div className="cc-subtitle">
                            <span>Description</span>
                          </div>
                          <div className="cc-subcontent">
                            <input
                              type="text"
                              value={this.state.description}
                              name="description"
                              className="cc-input"
                              placeholder="Enter Description"
                              onChange={this.changeHandler}
                              style={{
                                width: 420,
                                borderBottom: !this.state.description && this.state.clickCreate ? "1px solid red" : ""
                              }}
                            />
                          </div>
                        </div>

                        <div className="cc-content-row">
                          <div className="cc-subtitle">
                            <span>Max members</span>
                          </div>
                          <div className="cc-subcontent">
                            <input
                              type="number"
                              value={this.state.max_members}
                              name="max_members"
                              className="cc-input"
                              placeholder="Enter Max Members"
                              onChange={this.changeHandler}
                              style={{
                                borderBottom: !this.state.max_members && this.state.clickCreate ? "1px solid red" : ""
                              }}
                            />
                          </div>
                        </div>

                        <div className="cc-content-row">
                          <div className="cc-subtitle">
                            <span>Max books</span>
                          </div>
                          <div className="cc-subcontent">
                            <input
                              type="number"
                              value={this.state.max_books}
                              name="max_books"
                              className="cc-input"
                              placeholder="Enter Max Books"
                              onChange={this.changeHandler}
                              style={{
                                borderBottom: !this.state.max_books && this.state.clickCreate ? "1px solid red" : ""
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="cc-buttons">
                        <button className="cc-create" onClick={this.clickCreate}>
                          <span>Create</span>
                        </button>
                        <button className="cc-cancel" onClick={this.goBack}>
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.rpc.tokens,
    role: state.rpc.role,
    hidenav: state.rpc.hidenav,
    id: state.rpc.id,
    orgid: state.rpc.orgid
  };
};
export default connect(mapStateToProps)(OrganizationNew);
