import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "./page-detail-modal.scss";

const PageDetailModal = ({ data = {}, showModal, onClose }) => {
  const [show, setShow] = useState(showModal);
  const { title, desc, thumbnail } = data;

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <Modal centered show={show} onHide={handleClose} className="page-detail-modal">
      <Modal.Header closeButton>
        <Modal.Title>Page Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="field">
          <label>Title</label>
          <div className="value">
            {title}
          </div>
        </div>

        <div className="field">
          <label>Description</label>
          <div className="value">
            {desc || "Not available"}
          </div>
        </div>

        <div className="field">
          <label>Thumbnail</label>
          <div className="thumbnail">
            {thumbnail ? <img src={thumbnail} alt="thumbnail" /> : "Not available"}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PageDetailModal;
