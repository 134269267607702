import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Texts from "./components/Texts";
import Config from "./components/Config";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchTexts,
  fetchConfig,
  fetchAllTexts,
  updateConfig,
  addTexts,
  deleteText
} from "./state/actions";

const DeviceConfig = () => {
  const dispatch = useDispatch();
  const { texts, config, textShaList, allTexts } = useSelector(state => state.deviceConfig);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTexts({pageNum: 0, pageSize: 10}));
    dispatch(fetchConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchTexts = (pageParams) => {
    dispatch(fetchTexts(pageParams));
  };

  const handleFetchAllTexts = (pagination = {pageNum: 0, pageSize: 10}, searchTerm) => {
    dispatch(fetchAllTexts(pagination, textShaList, searchTerm));
  };

  const handleUpdateConfig = (payload, onSuccess, onFail) => {
    const handleSuccess = () => {
      dispatch(fetchConfig());
      onSuccess();
    };
    dispatch(updateConfig(payload, handleSuccess, onFail));
  };

  const handleAddTexts = (payload, onSuccess, onFail) => {
    const handleSuccess = () => {
      dispatch(fetchTexts({pageNum: 0, pageSize: 10}));
      onSuccess();
    };
    dispatch(addTexts(payload, handleSuccess, onFail));
  };

  const handleDeleteText = (payload, onSuccess, onFail) => {
    const handleSuccess = () => {
      dispatch(fetchTexts({pageNum: 0, pageSize: 10}));
      onSuccess();
    };
    dispatch(deleteText(payload, handleSuccess, onFail));
  };

  return (
    <div className="device-config">
      <h2>Device Configuration</h2>
      <div>
        <Texts
          data={texts}
          allTexts={allTexts}
          onFetchTexts={handleFetchTexts}
          onFetchAllTexts={handleFetchAllTexts}
          onAddTexts={handleAddTexts}
          onDeleteText={handleDeleteText}
        />
        <Config data={config} onUpdate={handleUpdateConfig} />
      </div>
    </div>
  );
};

export default DeviceConfig;