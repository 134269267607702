import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";

import LinkButton from "../../../../components/LinkButton/LinkButton";
import YoutubeURLForm from "./YoutubeURLForm";
import TextSelectionModal from "./TextSelectionModal";

import { resetCommon } from "../../../../common/actions/actions";
import { fetchUserTexts, addDiscoveryVideo, addDiscoveryVideoYoutube } from "../../state/actions";

import "./new-discovery-video.scss";

const NewDiscoveryVideo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { videoTexts } = useSelector(state => state.videos);
  const { nextRoute } = useSelector(state => state.common);

  const [ showTextSelectionModal, setShowTextSelectionModal] = useState(null);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const handleAddYoutubeVideo = (payload, onSuccess, onFail) => {
    dispatch(addDiscoveryVideoYoutube(payload, onSuccess, onFail));
  };

  const handleFetchVideoTexts = (pagination, searchTerm) => {
    dispatch(fetchUserTexts(pagination, searchTerm));
  };

  const handleAddVideoText = (payload, onSuccess, onFail) => {
    dispatch(addDiscoveryVideo(payload, onSuccess, onFail));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="new-discovery-video">
      <div className="header">
        <LinkButton onClick={() => history.goBack()}>
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <h3>New Discovery Video</h3>
      </div>
      <div class="sub-section">
        <h4>Add from Youtube URL</h4>
        <YoutubeURLForm onAddVideo={handleAddYoutubeVideo} />
      </div>
      <div class="sub-section">
        <h4>Add from Texts</h4>
        <div>
          <Button type="button" onClick={() => setShowTextSelectionModal(true)}>
            Select Video Text
          </Button>
        </div>
      </div>

      <TextSelectionModal
        showModal={showTextSelectionModal}
        videoTexts={videoTexts}
        onClose={() => setShowTextSelectionModal(false)}
        onFetchVideoTexts={handleFetchVideoTexts}
        onAddVideoText={handleAddVideoText}
      />
    </div>
  );
};

export default NewDiscoveryVideo;
