import { makeApiCall } from "../../../helpers/api-client";
import { setNextRoute, throwApiError } from "../../../common/actions/actions";

export const changePassword = (payload) => async dispatch => {
  try {
    await makeApiCall("rpc/reset_password", {
      method: 'post',
      data: payload
    });
    dispatch(setNextRoute("/changepassword/notice"));
  } catch (error) {
    const message = error?.response?.data?.message || "Failed to update password. Please try again later";
    if (error)
    dispatch(throwApiError({
      type: "alert",
      message
    }));
  }
};
