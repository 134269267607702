import {
  THROW_API_ERROR,
  RESET_API_ERROR,
  SHOW_NOTICE,
  RESET_NOTICE,
  RESET_ALL_NOTICES,
  SET_NEXT_ROUTE,
  RESET_NEXT_ROUTE,
  RESET_ALL
} from '../actions/action-types';

const initialState = {
  apiError: null,
  notice: null,
  nextRoute: null
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case THROW_API_ERROR:
      return {...state, apiError: payload}
    case RESET_API_ERROR:
      return {...state, apiError: null};
    case SHOW_NOTICE:
      return {...state, notice: payload}
    case RESET_NOTICE:
      return {...state, notice: null};
    case RESET_ALL_NOTICES:
      return {
        ...state,
        apiError: null,
        notice: null
      }
    case SET_NEXT_ROUTE:
      return {...state, nextRoute: payload};
    case RESET_NEXT_ROUTE:
      return {...state, nextRoute: null};
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default commonReducer;