const tokenReducer = (state = {}, action) => {
  switch (action.type) {
    case "TOKEN":
      return { ...state, token: action.payload };
    case "TOKENS":
      return { ...state, tokens: action.payload };
    case "ROLE":
      return { ...state, role: action.payload };
    case "ID":
      return { ...state, id: action.payload };
    case "ORGID":
      return { ...state, orgid: action.payload };
    case "ORGNAME":
      return { ...state, orgname: action.payload };
    case "CLASSID":
      return { ...state, classid: action.payload };
    case "SHA1":
      return { ...state, sha1: action.payload };
    case "USERMAIL":
      return { ...state, usermail: action.payload };
    case "OWNER":
      return { ...state, owner: action.payload };
    case "AUTH":
      return { ...state, auth: action.payload };
    case "USERID":
      return { ...state, userid: action.payload };
    case "CLASSNAME":
      return { ...state, classname: action.payload };
    case "HEADERS":
      return { ...state, headers: action.payload };
    case "ORGANIZATIONNUMBER":
      return { ...state, organizationnumber: action.payload };
    case "PROFILE":
      return { ...state, profile: action.payload };
    case "RESET":
      return { ...state, reset: action.payload };
    case "RESETEMAIL":
      return { ...state, resetemail: action.payload };
    case "ORGLIST":
      return { ...state, orglist: action.payload };
    case "ORGCLASS":
      return { ...state, orgclass: action.payload };
    case "BOOKLIST":
      return { ...state, booklist: action.payload };
    case "TEXT":
      return { ...state, text: action.payload };
    case "CLASSNAVID":
      return { ...state, classnavid: action.payload };
    case "ORGCLASSDETAILS":
      return { ...state, orgclassdetails: action.payload };
    case "ORGMEMBER":
      return { ...state, orgmember: action.payload };
    case "STORE":
      return { ...state, store: action.payload };
    case "BOOKLENGTH":
      return { ...state, booklength: action.payload };
    case "BOOKDATA":
      return { ...state, bookdata: action.payload };
    case "STORETITLE":
      return { ...state, storetitle: action.payload };
    case "ABHINAV":
      return { ...state, abhinav: action.payload };
    case "PLANID":
      return { ...state, planid: action.payload };
    case "SUBSCRIBE_DETAIL":
      return { ...state, subscribe_detail: action.payload };
    case "CLASSNUMBER":
      return { ...state, classNumber: action.payload };
    case "BOOKNUMBER":
      return { ...state, booknumber: action.payload };
    case "STORENUMBER":
      return { ...state, storenumber: action.payload };
    case "OWNERID": {
      return { ...state, ownerid: action.payload };
    }
    case "MEMBERS": {
      return { ...state, members: action.payload };
    }
    case "TEXTSHA1": {
      return { ...state, textsha1: action.payload };
    }
    case "TEXTTITLE": {
      return { ...state, texttitle: action.payload };
    }
    case "TEXTLENGTH": {
      return { ...state, textlength: action.payload };
    }
    case "MEMBERSORG": {
      return { ...state, membersorg: action.payload };
    }
    case "USERIDMEMBERDETAILS": {
      return { ...state, useridmembersdetails: action.payload };
    }
    case "USERINFO": {
      return { ...state, userinfo: action.payload };
    }
    case "PRICE": {
      return { ...state, price: action.payload };
    }
    case "DURATION": {
      return { ...state, duration: action.payload };
    }
    case "GET_ERRORS": {
      return { ...state, error: action.payload };
    }
    case "HIDENAV": {
      return { ...state, hidenav: action.payload };
    }
    case "COLLECTBOOKS": {
      return { ...state, collectbooks: action.payload };
    }
    case "TEXTS": {
      return { ...state, texts: action.payload };
    }
    case "TEXTSTATITEMS": {
      return { ...state, textstatitems: action.payload };
    }
    case "TABKEY": {
      return { ...state, tabkey: action.payload };
    }
    case "BOOKS": {
      return { ...state, books: action.payload };
    }
    case "TSI_ALL": {
      return { ...state, tsi_all: action.payload };
    }
    case "COLLECTIONID": {
      return { ...state, collectionid: action.payload };
    }
    case "BOOKSTATITEMS": {
      return { ...state, bookstatitems: action.payload };
    }
    case "NOTEBOOKS": {
      return { ...state, notebooks: action.payload };
    }
    case "ORGTABKEY": {
      return { ...state, orgtabkey: action.payload };
    }
    case "CLASSTABKEY": {
      return { ...state, classtabkey: action.payload };
    }
    case "DICTIONARYTABKEY": {
      return { ...state, dictionarytabkey: action.payload };
    }
    case "ORGBOOKS": {
      return { ...state, orgbooks: action.payload };
    }
    case "CLASSMEMBERS": {
      return { ...state, classmembers: action.payload };
    }
    case "CLASSTEXTS": {
      return { ...state, classtexts: action.payload };
    }
    case "DICKEYWORD": {
      return { ...state, dickeyword: action.payload };
    }
    case "ITEMSOFPAGE": {
      return { ...state, itemsofpage: action.payload };
    }
    case "EMAILVERIFY": {
      return { ...state, emailverify: action.payload };
    }
    default:
      break;
  }
  return state;
};

export default tokenReducer;
