import * as Actions from "./action-types";

const initialState = {
  userInfo: {},
  subscription: {},
  payments: [],
  awsCredentials: {}
};

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.GET_PROFILE_SUCCESS: {
      return {...state, userInfo: payload}
    }
    case Actions.GET_SUBSCRIPTION_SUCCESS: {
      return {...state, subscription: payload}
    }
    case Actions.GET_GOOGLE_PLAN_ID_SUCCESS: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          googlePlanId: payload
        }
      }
    }
    case Actions.GET_PAYMENTS_SUCCESS: {
      return {...state, payments: payload}
    }
    default:
      return state;
  }
};

export default profileReducer;
