import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";

import LinkButton from "../../../components/LinkButton/LinkButton";
import EditWordModal from "./EditWordModal";
import AddWordModal from "./AddWordModal";
import DeleteWordModal from "./DeleteWordModal";

import {
  addWord,
  updateWord,
  deleteWord
} from "../state/actions";

import "./Definitions.scss";

const Definitions = ({id, defs, role, onSuccess: onActionSuccess}) => {
  const dispatch = useDispatch();

  const [activeDef, setActiveDef] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddDefModal, setShowAddDefModal] = useState(false);

  const handleEdit = (def) => {
    setShowEditModal(true);
    setActiveDef(def);
  };

  const handleUpdateWord = (wordId, payload) => {
    const onSuccess = () => {
      if (typeof onActionSuccess === "function") {
        onActionSuccess();
      }
      setShowEditModal(false);
    };

    const onFail = () => {
      setShowEditModal(false);
    };
    dispatch(updateWord(wordId, payload, onSuccess, onFail));
  };

  const handleDelete = (defId) => {
    const onSuccess = () => {
      if (typeof onActionSuccess === "function") {
        onActionSuccess();
      }
      setShowDeleteModal(false);
    };

    const onFail = () => {
      setShowEditModal(false);
    };
    dispatch(deleteWord(defId, onSuccess, onFail));
  };

  const handleDeleteConfirm = (def) => {
    setShowDeleteModal(true);
    setActiveDef(def);
  };

  const handleAddWord = (payload) => {
    const onSuccess = () => {
      if (typeof onActionSuccess === "function") {
        onActionSuccess();
      }
      setShowAddDefModal(false);
    };

    const onFail = () => {
      setShowAddDefModal(false);
    };
    dispatch(addWord(payload, onSuccess, onFail));
  };

  return (
    <div className="char-definition">
      <div>
        {
          defs.map((def) => {
            return (
              <div className="def-item-row" key={def.id}>
                <span className="def-phonetic">
                  {def.phonetic}
                </span>
                <span className="def-defs">
                  {def.defs}
                </span>
                {
                  (role === 'gk_admin' || role === 'support') && (
                    <Fragment>
                      <span className="def-edit">
                        <LinkButton onClick={() => handleEdit(def)}>
                          <img
                            alt="Edit Definition"
                            src="/img/action-edit.svg"
                            className="size-24-24" />
                        </LinkButton>
                      </span>
                      <span className="def-delete">
                        <LinkButton onClick={() => handleDeleteConfirm(def)}>
                          <img
                            alt="Delete Definition"
                            src="/img/action-trashcan.svg"
                            className="size-24-24" />
                        </LinkButton>
                      </span>
                    </Fragment>
                  )
                }
              </div>
            );
          })
        }
      </div>
      {(role === 'gk_admin' || role === 'support') && (
        <div className="new-def">
          <Button onClick={() => setShowAddDefModal(true)}>
            New Definition
          </Button>
        </div>
      )}

      <EditWordModal
        showModal={showEditModal}
        defaultValue={activeDef}
        onClose={() => setShowEditModal(false)}
        onUpdate={handleUpdateWord} />
      <AddWordModal
        showModal={showAddDefModal}
        chinese={id}
        onClose={() => setShowAddDefModal(false)}
        onAdd={handleAddWord} />
      <DeleteWordModal
        showModal={showDeleteModal}
        recordId={activeDef?.id}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDelete} />
    </div>
  );
};

Definitions.propTypes = {
  defs: PropTypes.array,
  role: PropTypes.string,
  onSuccess: PropTypes.func
};

export default Definitions;