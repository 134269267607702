import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup.object().shape({
  plan: yup.string().required("This field cannot be empty"),
  level: yup.string().required("This field cannot be empty")
});

const EditTextModal = ({ value = {}, show, onClose, onUpdateText }) => {

  const [submitting, setSubmitting] = useState(false);

  const { title, description } = value || {};
  const defaultValues = {
    title,
    description
  };

  const { register, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  const onSubmit = (values) => {
    const { sha1 } = value;
    setSubmitting(true);
    onUpdateText(sha1, values , () => {
      setSubmitting(false);
      onClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Text</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Plan</Form.Label>
            <Form.Control
              name="plan"
              as="select"
              ref={register}
              isInvalid={!!errors.plan}
            >
              <option value="free">Free</option>
              <option value="silver">Silver</option>
              <option value="gold">Gold</option>
              <option value="diamond">Diamond</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Level</Form.Label>
            <Form.Control
              name="level"
              as="select"
              ref={register}
              isInvalid={!!errors.level}
            >
              <option value="beginner">Beginner</option>
              <option value="low-intermediate">Low-Intermediate</option>
              <option value="high-intermediate">High-Intermediate</option>
              <option value="advanced">Advanced</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditTextModal;
