import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import CancelSubscriptionModal from "./CancelSubscriptionModal";

import "./BillingAndPlans.scss";

const BrainTreePayments = ({ plan, subscription, onUnSubscribe }) => {
  const {
    status,
    auto_renew_status,
    is_expired,
    plan_expire,
    context,
    next_billing_date
  } = subscription;

  const { bt_subscribe_price, currency } = plan;

  return (
    <div className="billing-plans">
      {
        status === "canceled" && (
          <div className="card subscription-status">
            <h4>Subscription Cancelled</h4>
            <span><Link to="/plans">Restart subscription</Link></span>
          </div>
        )
      }
      {
        context?.trial_due && (
          <div className="card trial-validity">
            <h4>FREE TRIAL until</h4>
            <span className="date-block">{context?.trial_due && dayjs(context.trial_due).format('MMM D YYYY')}</span>
          </div>
        )
      }
      {
        is_expired === 0 && (
          <div className="card account-status">
            <h4>Account active until</h4>
            <span className="date-block">{plan_expire && dayjs(plan_expire).format('MMM D YYYY')}</span>
            <span><Link to="/settings/payments">View Payment History</Link></span>
          </div>
        )
      }
      {
        is_expired === 1 && (
          <div className="card account-status">
            <h4>Account not active</h4>
            <span><Link to="/settings/payments">View Payment History</Link></span>
          </div>
        )
      }
      {
        auto_renew_status === 1 && is_expired === 0 && (
          <div className="card bill">
            <h4>Current Monthly Bill</h4>
            <span className="date-block">{`${currency?.toUpperCase()} ${bt_subscribe_price}`}</span>
            <CancelSubscriptionModal onUnSubscribe={onUnSubscribe} />
          </div>
        )
      }
      {
        auto_renew_status === 1 && next_billing_date && (
          <div className="card payment-due">
            <h4>Next payment due</h4>
            <span className="date-block">{dayjs(next_billing_date).format('MMM D YYYY')}</span>
            <span><Link to="/settings/payments">View Payment History</Link></span>
          </div>
        )
      }
      {
        (typeof auto_renew_status === "undefined" || (status !== "canceled" && auto_renew_status === 0) || is_expired === 1) && (
          <div className="card bill">
            <h4>Not Subscribed</h4>
            <span><Link to="/plans">Subscribe Now</Link></span>
          </div>
        )
      }
    </div>
  );
};

const ApplePayments = () => {
  return (
    <div className="non-web-payments">
      <p>You are currently subscribed through your iOS device (iPhone or iPad)</p>
      <p>To manage your subscription,</p>
      <ul>
        <li>Use your iPhone or iPad</li>
        <li>Go to settings on your iPhone or iPad</li>
        <li>Open "Subscription"</li>
        <li>Look for "FullChinese Pro"</li>
      </ul>
    </div>
  );
};

const AndroidPayments = () => {
  return (
    <div className="non-web-payments">
      <p>You are currently subscribed through your android device</p>
      <p>To manage your subscription,</p>
      <ul>
        <li>Use your Android Device</li>
        <li>Go to settings on your android device</li>
        <li>Open "Subscription"</li>
        <li>Look for "FullChinese Pro"</li>
      </ul>
    </div>
  );
};

const BillingAndPlans = ({ plan, subscription, onUnSubscribe }) => {
  const { payment } = subscription;

  if (payment === "apple") {
    return <ApplePayments />;
  } else if (payment === "google") {
    return <AndroidPayments />;
  } else {
    return <BrainTreePayments plan={plan} subscription={subscription} onUnSubscribe={onUnSubscribe} />;
  }
};

export default BillingAndPlans;