import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

import "./MoreItems.scss";

const MoreItems = ({ children }) => {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={
        <Popover>
          <Popover.Content>
            <div className="more-items__content">
              {children}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <Button variant="light">{"..."}</Button>
    </OverlayTrigger>
  );
};

export default MoreItems;
