import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import "./PricingCard.scss";

const PricingCard = props => {
  const { frequency, price, monthlyPrice, savings, trialPeriod, onSubscribe } = props;
  return (
    <div className="pricing-card">
      <div className="top">
        <div className="frequency">{frequency}</div>
        <div className="price">${monthlyPrice}/mo</div>
      </div>
      <div className="bottom">
        <div className="savings">Save ${savings}%</div>
        <div className="trial">{trialPeriod}-Day Free Trial</div>
        <div className="due">${price} due at end of trial</div>
        <div className="cta">
          <Button variant="outline-light" onClick={onSubscribe}>
            GET STARTED
          </Button>
        </div>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  frequency: PropTypes.string,
  price: PropTypes.number,
  monthlyPrice: PropTypes.string,
  savings: PropTypes.string,
  trialPeriod: PropTypes.number,
  onSubscribe: PropTypes.func
};

export default PricingCard;
