import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchHeader from "../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../components/ActionIcon/ActionIcon";

import Collections from "./components/Collections";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchCollections,
  fetchCollectionBooks,
  updateCollection,
  deleteCollection,
  fetchBooks,
  addBooks,
  updateBook,
  removeBook
} from "./state/actions";

const StoreContainer = () => {
  const dispatch = useDispatch();
  const { id, role } = useSelector(state => state.auth);
  const { collections, books, availableBooks } = useSelector(state => state.store);

  const [activeBooks, setActiveBooks] = useState(books);
  const hasPrivilege = role === 'support' || role === 'gk_admin';

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCollections());
  }, [dispatch]);

  useEffect(() => {
    setActiveBooks(books);
  }, [books]);

  const handleSearch = (val = "") => {

    if (val === "") {
      setActiveBooks(books);
      return;
    }

    const filteredBooks = [];
    const filterTerm = val.toLowerCase();
    books.forEach((collection, index) => {
      const matchingBooks = collection.filter(({ book_title }) => book_title.toLowerCase().includes(filterTerm));
      filteredBooks[index] = matchingBooks;
    });

    setActiveBooks(filteredBooks);
  };

  const handleUpdateCollection = (title, payload, onSuccess, onFail) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchCollections());
    };
    dispatch(updateCollection(title, payload, () => handleSuccess(onSuccess), onFail));
  };

  const handleDeleteCollection = (title, onComplete) => {
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchCollections());
    };
    dispatch(deleteCollection(title, () => handleSuccess(onComplete)));
  };

  const handleAddBooks = (collection, payload, onSuccess, onFail) => {
    const { index, title } = collection;
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchCollectionBooks(title, index));
    };
    dispatch(addBooks(payload, () => handleSuccess(onSuccess), onFail));
  };

  const handleUpdateBook = (payload, onSuccess, onFail) => {
    const { index, title } = payload;
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchCollectionBooks(title, index));
    };
    dispatch(updateBook(payload, () => handleSuccess(onSuccess), onFail));
  };

  const handleRemoveBook = (payload, onComplete) => {
    const { index, title } = payload; 
    const handleSuccess = (onSuccess) => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
      dispatch(fetchCollectionBooks(title, index));
    };
    dispatch(removeBook(payload, () => handleSuccess(onComplete)));
  };

  return (
    <div>
      <div>
        <SearchHeader
          count={collections.length}
          countTitle="Collections"
          placeHolder="Search store here"
          addComponent={hasPrivilege && <ActionIcon icon="action-add-folder" label="NEW COLLECTION" to="/store/new-collection" />}
          onChange={handleSearch}
          onSearch={handleSearch}
        />
      </div>
      <div>
        <Collections
          owner={id}
          canEdit={hasPrivilege}
          collections={collections}
          books={activeBooks}
          availableBooks={availableBooks}
          onUpdateCollection={handleUpdateCollection}
          onDeleteCollection={handleDeleteCollection}
          onFetchBooks={(pagination, searchTerm) => dispatch(fetchBooks(pagination, searchTerm))}
          onAddBooks={handleAddBooks}
          onUpdateBook={handleUpdateBook}
          onRemoveBook={handleRemoveBook}
        />
      </div>
    </div>
  );
};

export default StoreContainer;
