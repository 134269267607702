import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Field from "./Field";


const Bio = ({ value: val, onSave }) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  const handleChange = (ev) => {
    const val = ev.target.value;
    setValue(val);
  };

  return (
    <Field value={value} onSave={onSave}>
      <Form.Control
        sm="10"
        name="bio"
        as="textarea"
        value={value}
        placeholder="Something about yourself"
        onChange={handleChange}
      />
    </Field>
  );
};

Bio.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func
};

export default Bio;