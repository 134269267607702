import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import MoreItems from "../../../../components/MoreItems/MoreItems";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionModal from "../../../../components/ActionModal/ActionModal";

import EditBookModal from "./EditBookModal";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};


const AllBooks = ({ data, onFetchAllBooks, onDeleteBook, onUpdateBook }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [bookToDelete, setBookToDelete] = useState(null);
  const [bookToEdit, setBookToEdit] = useState(null);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchAllBooks({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchAllBooks({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchAllBooks({ pageNum: 0, pageSize }, searchTerm);
  };

  const handleDeleteBook =  () => {
    if (typeof onDeleteBook === "function") {
      onDeleteBook(bookToDelete, () => {
        setBookToDelete(null);
      });
    }
  };

  const renderLink = ({ row }) => {
    const { sha1, title, description } = row;
  
    return (
      <div className="grid-actions">
        <Link to={`/books/details/${sha1}`}>Details</Link>
        <MoreItems>
          <div>
            <LinkButton onClick={
              () => setBookToEdit({
                sha1,
                title,
                description
              })
            }>
              Edit
            </LinkButton>
          </div>
          <div>
            <LinkButton onClick={() => setBookToDelete(sha1)}>Delete</LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'title', headerName: 'Title', width: 300 },
    { field: 'description', headerName: 'Description', width: 400 },
    { field: 'author', headerName: 'Author', width: 200 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Books"
        placeHolder="Search books here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
      <ActionModal
        showModal={!!bookToDelete}
        title="Delete Book"
        actionText="Delete"
        onClose={() => setBookToDelete(null)}
        onAction={handleDeleteBook}>
          Do you want to delete this book?
      </ActionModal>
      <EditBookModal
        show={!!bookToEdit}
        value={bookToEdit}
        onUpdateBook={onUpdateBook}
        onClose={() => setBookToEdit(null)}
      />
    </div>
  );
};

AllBooks.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchAllBooks: PropTypes.func,
  onDeleteBook: PropTypes.func,
  onUpdateBook: PropTypes.func
};

export default AllBooks;
