import React, { Component } from "react";
import "./Footer.scss";

class TermsOfService extends Component {
  render() {
    return (
      <div className="terms-of-service">
        <div style={{ height: 340 }}>
          <img src="/img/termsofservice.jpg" className="img-fluid w-100" alt="" style={{ height: 320 }} />
          <button
            className="goSignIn position-absolute"
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            Back
          </button>
          <div className="centered position-absolute text-white pt-5" style={{ top: "15%" }}>
            <h1 className="text-uppercase">Fullchinese Hosted Terms of Service</h1>
          </div>
        </div>
        <div className="container">
          <div className="space-60"></div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>1. General</h3>
              <hr />
              <p>
                Clover.AI websites , FullChinese websites (“Websites”) and mobile applications, including the
                FullChinese and FullChinese Pro applications (“Apps”) and related services (together with the Websites,
                the “Service”) are operated by Clover.AI , Inc. (“ Clover.AI ,” “us,” or “we”). Access and use of the
                Service is subject to the following Terms and Conditions of Service (“Terms and Conditions”). By
                accessing or using any part of the Service, you represent that you have read, understood, and agree to
                be bound by these Terms and Conditions including any future modifications. Clover.AI may amend, update
                or change these Terms and Conditions .
              </p>
              <p>
                PLEASE NOTE THAT THESE TERMS AND CONDITIONS CONTAIN A MANDATORY ARBITRATION OF DISPUTES PROVISION THAT
                REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN CIRCUMSTANCES,
                RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>2. Description of Website and Service</h3>
              <hr />
              <p>
                The Service allows users to access and use a variety of educational services, including learning or
                practicing a language, and creating and viewing flashcards on the FullChinese App. Clover.AI may, in its
                sole discretion and at any time, update, change, suspend, make improvements to or discontinue any aspect
                of the Service, temporarily or permanently.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>3. Registration; Submission of Content</h3>
              <hr />
              <p className="sub-title">a. Registration</p>
              <p>
                In connection with registering for and using the Service, you agree (i ) to provide accurate, current
                and complete information about you and/or your organization as requested by Clover.AI ; (ii) to maintain
                the confidentiality of your password and other information related to the security of your account;
                (iii) to maintain and promptly update any registration information you provide to Clover.AI , to keep
                such information accurate, current and complete; and (iv) to be fully responsible for all use of your
                account and for any actions that take place through your account.
              </p>
              <p className="sub-title">b . General Content</p>
              <p>
                As a condition of submitting any ratings, reviews, information, data, text, photographs, audio clips,
                audiovisual works, translations, flashcards or other materials on the Services (“Content”), you hereby
                grant to Clover.AI a royalty free, perpe tual, irrevocable, worldwide, nonexclusive, transferable, and
                sublicensable license to use, reproduce, copy, adapt, modify, merge, distribute, publicly display,
                create derivative works from, incorporate such Content into other works; sublicense through mul tiple
                tiers the Content, and acknowledge that this license cannot be terminated by you once your Content is
                submitted to the Services. You represent that you own or have secured all legal rights necessary for the
                Content submitted by you to be used by you, Clover.AI , and others as described and otherwise
                contemplated in these Terms and Conditions. You understand that other users will have access to the
                Content and that neither they or Clover.AI have any obligation to you or anyone else to maintain the
                confi dentiality of the Content.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>4. Your Representations and Warranties</h3>
              <hr />
              <p>
                You represent and warrant to Clover.AI that your access and use of the Service will be in accordance
                with these Terms and Conditions and with all applicable laws, rules and regulations of the United States
                and any other relevant jurisdiction, including those regarding online conduct or acceptable content, and
                those regarding the transmission of data or information exported from the United States and/or the
                jurisdiction in which you reside. You further represent and warrant that you have created or own any
                material you submit via the Service (including Translation Materials, Course Contributor Materials,
                Activity Materials, and Content) and that you have the right, as applicable, to grant us a license to
                use that material as set forth above or the right to assign that material to us as set forth below.
              </p>
              <p>
                You represent and warrant that (1) you are not organized under the laws of, operating from, or otherwise
                ordinarily resident in a country or territory that is the target or comprehensive U.S. economic or trade
                sanctions (i.e., an embargo) or (2) identified on a list of prohibited or restricted persons, such as
                the U.S. Treasury Department’s List of Specially Designated Nationals and Blocked Persons, or (3)
                otherwise the target of U.S. sanctions.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>5. Inappropriate Use</h3>
              <hr />
              <p>
                You will not upload, display or otherwise provide on or through the Service any content that: (i) is
                libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise viol ates any law
                or infringes upon the right of any third party (including copyright, trademark, privacy, publicity or
                other personal or proprietary rights); or (ii) in Clover.AI ’s sole judgment, is objectionable or which
                restricts or inhibits any other person from using the Service or which may expose Clover.AI or its users
                to any harm or liability of any kind.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>6. Indemnification of Clover.AI</h3>
              <hr />
              <p>
                You agree to defend, indemnify and hold harmless Clover.AI and its directors, officers, employees,
                contractors, agents, suppliers, licensors, successors and assigns, from and against any and all losses,
                claims, causes of action, obligations, liabilities and damages whatsoever, including attorneys' fees,
                arising o ut of or relating to your access or use of the Service, any false representation made to us
                (as part of these Terms and Conditions or otherwise), your breach of any of these Terms and Conditions,
                or any claim that any translation we provide to you is inaccurate, inappropriate or defective in any way
                whatsoever.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>7. License to Apps</h3>
              <hr />
              <p>
                Subject to the terms of these Terms and Conditions, Clover.AI grants you a non - transferable, non -
                exclusive license to download, install, and use one copy of each App in object code fo rm only on an
                interactive wireless device that you own or control. You may not derive or attempt to derive the source
                code of all or any portion of any App, permit any third party to derive or attempt to derive such source
                code, or reverse engineer, decompile, disassemble, or translate any App or any part thereof. Clover.AI
                and its licensors own and shall retain all intellectual property rights and other rights in and to the
                Apps, and any changes, modifications, or corrections thereto. The following terms and conditions apply
                to you only if you are using the Apps from the Apple App Store. To the extent the other terms and
                conditions of these Terms and Conditions are less restrictive than, or otherwise conflict with, the
                terms and conditions of this paragraph , the more restrictive or conflicting terms and conditions in
                this paragraph apply, but solely with respect to Apps from the Apple App Store. You acknowledge and
                agree that these Terms and Conditions are solely between you and Clover.AI , not Apple, and that Apple
                has no responsibility for the Apps or content thereof. Your use of any App must comply with the App
                Store Terms of Service. You acknowledge that Apple has no obligation whatsoever to furnish any
                maintenance and support services with respect to the Apps. In the event of any failure of any App to
                conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price, if
                any, for the App to you; to the maximum extent permitted by applicable law, Apple will have no other
                warr anty obligation whatsoever with respect to the Apps, and any other claims, losses, liabilities,
                damages, costs or expenses attributable to any failure to conform to any warranty will be solely
                governed by these Terms and Conditions. You and Clover.AI acknowledge that Apple is not responsible for
                addressing any claims of you or any third party relating to the Apps or your possession and/or use of
                any App, including, but not limited to: (i) product liability claims; (ii) any claim that an App fails
                to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer
                protection or similar legislation. You and Clover.AI acknowledge that, in the event of any third - party
                claim that any App or your possession and use of that App infringes that third party’s intellectual
                property rights, Clover.AI , not Apple, will be solely responsible for the investigation, defense,
                settlement and discharge of any such intellectual property infringement claim to the extent required by
                these Terms and Conditions. You must comply with applicable third party terms of agreement when using
                any App. You and Clover.AI acknowledge and agree that Apple, and Apple’s subsidiaries, are third party
                beneficiaries of these Terms and Conditions as they relate to your license of the Apps, and that, upon
                your acceptance of these Terms and Conditions, Apple will have the right (and will be deemed to have
                accepted the right) to enforce these Terms and Conditions against you as a third party beneficiary
                thereof.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>8. In - App Purchases</h3>
              <hr />
              <p>
                If you purchase an auto - renewing periodic subscription through the Service, your Clover.AI account
                will be billed continuously for the subscription until you terminate it as set forth below. After your
                initial subscription period, and again after an your subsequent subscription period, your subscription
                will automatically renew for an additional equivalent period. If you do not wish your subscription to
                renew automatically, or if you want to change or terminate your subscription, you will need to log in to
                your Clover.AI account and follow instructions to terminate or change your subscription, even if you
                have deleted your account.
              </p>
              <p>
                In the Service, you may purchase, with “real world” money, a limited, personal, non - transferable, non
                - sublicensable, revocable license to use (a) “virtual currency,” including but not limited to virtual
                gems, solely for use in the Service, and (b) “virtual in - app items” (together with “virtual currency,”
                “Virtual Items”). You are allowed to purchase Virtual Items through the Service, and not in any other
                way.
              </p>
              <p>
                Clover.AI may manage, regulate, control, modify, or eliminate Virtual Items at any time, with or without
                notice. Clover.AI may update the pricing of Virtual Items at any time in its sole discretion, and may
                add new Virtual Items for additional fees. Clover.AI shall have no liability to you or any third party
                in the event that Clover.AI exercises any such rights.
              </p>
              <p>
                The transfer of Virtual Items is prohibited except where expressly authorized in the Service. Other than
                as expressly authorized in the Service, you shall not sell, redeem or otherwise transfer Virtual Items
                to any person or entity, including but not limited to Company, another user, or any third party.
              </p>
              <p>
                You agree to pay all fees and applicable taxes incurred by you or anyone using a Clover.AI account
                registered to you. Clover.AI may revise the pricing for the goods and services offered through the
                Service at any time. All information that you provide in connection with a purchase or transaction or
                other monetary transaction interaction with the Service must be accurate, complete, and current. You
                agree to pay all charges incurred by users of your credit card, debit card, or other payment method used
                in connection with a purchase or transaction or ot her monetary transaction interaction with the Service
                at the prices in effect when such charges are incurred. You will pay any applicable taxes, if any,
                relating to any such purchases, transactions or other monetary transaction interactions.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>9. Payment Processors</h3>
              <hr />
              <p>
                All financial transactions made in connection with the Service will be processed by a third party in
                accordance with their respective terms of use, privacy policy, and/or any applicable payment terms and
                conditions. We encourage you to learn about the practices of such third party. In no event will
                Clover.AI be responsible for the actions or inactions of any third - party payment processor, including,
                but not limited to, system downtime or payment service outages.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>10. Refund Policy</h3>
              <hr />
              <p>
                In the event that Clover.AI suspends or terminates your use of the Service or these Terms and Conditions
                or you close your account voluntarily, you understand and agree that you will receive no refund or
                exchange of any kind, including for any unused virtual currency or other Virtual Item, any Content or
                data associated with your use of the Service, or for anything else.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>11. Third - Party Links, Sites, and Services</h3>
              <hr />
              <p>
                The Service may contain links to third - party websites, advertisers, services, special offers, or other
                events or acti vities that are not owned or controlled by Clover.AI . We do not endorse or assume any
                responsibility for any such third - party sites, information, materials, products, or services. If you
                access any third - party website, service, or content from Clover.AI , you understand that these Terms
                and Conditions and our Privacy Policy do not apply to your use of such sites. You expressly acknowledge
                and agree that Clover.AI shall not be responsible or liable, directly or indirectly, for any damage or
                loss arising from your use of any third - party website, service, or content.
              </p>
              <p>
                The Service may include advertisements, which may be targeted to the Content or information on the
                Service, or other information. The types and extent of advertising by Clover.AI on the Service are
                subject to change. In consideration for Clover.AI granting you access to and use of the Service, you
                agree that Clover.AI and its third - party providers and partners may place such advertising in
                connection with the display of content or information submitt ed by you or others.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>12. NO REPRESENTATIONS OR WARRANTIES BY Clover.AI</h3>
              <hr />
              <p>
                THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT THEREIN, AND ANY OTHER INFORMATION,
                PROPERTY AND RIGHTS GRANTED OR PROVIDED TO YOU BY Clover.AI ARE PROVIDED TO YOU ON AN “A S IS” BASIS.
                Clover.AI AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE
                SERVICE, EITHER EXPRESS OR IMPLIED, AND ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULA R PURPOSE OR NON - INFRINGEMENT, ARE EXPRESSLY DISCLAIMED.
                WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Clover.AI DOES NOT MAKE ANY REPRESENTATION OR WARRANTY
                OF ANY KIND RELATING TO ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT, ERROR -
                FREE OPERATION, RESULTS TO BE OBTAINED FROM USE, OR NON - INFRINGEMENT. ACCESS AND USE OF THE SERVICE
                MAY BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS OR SCHEDULED OR
                UNSCHEDULED MAINTENANCE OR FOR OTHER REASONS. SOME JURI SDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>13. LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY</h3>
              <hr />
              <p>
                IN NO EVENT WILL Clover.AI BE LIABLE TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU (WHETHER BAS ED IN
                CONTRACT, TORT, STRICT LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO ACCESS OR USE,
                THE SERVICE OR ANY PORTION THEREOF, INCLUDI NG BUT NOT LIMITED TO THE LOSS OF USE OF THE SERVICE,
                INACCURATE RESULTS, LOSS OF PROFITS, BUSINESS INTERRUPTION, OR DAMAGES STEMMING FROM LOSS OR CORRUPTION
                OF DATA OR DATA BEING RENDERED INACCURATE, THE COST OF RECOVERING ANY DATA, THE COST OF SUBSTITUTE
                SERVICES OR CLAIMS BY THIRD PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS, TELEPHONES OR OTHER
                PROPERTY, EVEN IF Clover.AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Clover.AI ’S LIABILITY
                TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU FO R ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF
                THE ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO Clover.AI FOR THE SERVICE IN THE 12 MONTHS
                PRIOR TO THE INITIAL ACTION GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF MORE
                THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>14. Termination</h3>
              <hr />
              <p>
                Clover.AI may terminate your access and use of the Service immediately at any time, for any reason, and
                at such time you will have no further right to use the Service. You may terminate your Clover.AI account
                at any time by following the instructions available through the Service. The provisions of these T erms
                and Conditions relating to the protection and enforcement of Clover.AI ’s proprietary rights, your
                representations and warranties, disclaimer of representations and warranties, release and indemnities,
                limitations of liability and types of damages, ownership of data and information, governing law and
                venue, and miscellaneous provisions shall survive any such termination.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>15. Proprietary Rights in Service Content and Activity Materials</h3>
              <hr />
              <p>
                All content available through the Service, including designs, text, graphics, images, information,
                software, audio and other files, and their selection and arrangement (the "Service Content"), are the
                proprietary property of Clover.AI or its licensors. No Service Content may be modified, copied,
                distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or
                sold in any form or by any means, in whole or in part, other than as expressly permitted in these Terms
                and Conditions. You may not use any data mining, robots, scraping or similar data gathering or
                extraction methods to obtain Service Content. As between you and Clover.AI , all data, information and
                materials generated from your access and use of the educational activities made available on or through
                the Service, including translated con tent generated by you (collectively, the “Activity Materials”),
                shall be exclusively owned by Clover.AI , and you shall not have any right to use such Activity
                Materials except as expressly authorized by these Terms and Conditions. Activity Materials will not
                include Translation Materials. By using the Service, you hereby assign to Clover.AI any and all rights,
                title and interest, including any intellectual property rights or proprietary rights, in the Activity
                Materials. All rights of Clover.AI or its licen sors that are not expressly granted in these Terms and
                Conditions are reserved to Clover.AI and its licensors.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>16. Trademarks</h3>
              <hr />
              <p>
                “ Clover.AI ,” “ FullChinese ” and all other trademarks, service marks, graphics and logos used in
                connection with the Service are tradema rks or service marks of Clover.AI or their respective owners,
                and certain of them are registered with the United States Patent and Trademark Office. Access and use of
                the Service does not grant or provide you with the right or license to reproduce or other wise use the
                Clover.AI name or any Clover.AI or third - party trademarks, service marks, graphics or logos.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>17. Privacy</h3>
              <hr />
              <p>
                Use of the Service is also governed by our Privacy Policy, a copy of which is located at
                <a
                  href="https://www.fullchinese.com/privacy.html"
                  style={{ marginLeft: 5, textDecoration: "underline" }}
                >
                  www.fullchinese.com/privacy.html
                </a>
                . By using the Service, you consent to the terms of the Privacy Policy.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>18. Notice for Claims of Copyright Violations and Agent for Notice</h3>
              <hr />
              <p>
                If you are a copyright owner and have a good faith belief that any material available through the
                Service infringes upon your copyrights, you may submit a copyright infringement notification to
                Clover.AI pursuant to the Digital Millennium Copyright Act by providing us with the following
                information in writing:
              </p>
              <ul class="list">
                <li>
                  <i class="ion-checkmark"></i>an electronic or physical signature of the copyright owner or the person
                  authorized to act on behalf of the owner of the copyright interest;
                </li>
                <li>
                  <i class="ion-checkmark"></i>a description of the copyrighted work that you claim has been infringed;
                </li>
                <li>
                  <i class="ion-checkmark"></i>a description of where the material that you claim is infringing is
                  located on the Service, with enough detail that we may find it on the Service;
                </li>
                <li>
                  <i class="ion-checkmark"></i>your address, telephone number, and email address;
                </li>
                <li>
                  <i class="ion-checkmark"></i>a statement by you that you have a good faith belief that the disputed
                  use is not authorized by the copyright owner, its agent, or the law ; and
                </li>
                <li>
                  <i class="ion-checkmark"></i>a statement by you, made under penalty of perjury, that the above
                  information in your notice is accurate and that you are the copyright owner or are authorized to act
                  on the copyright owner's behalf.
                </li>
              </ul>
              <p>
                Please consult your legal counsel for further details or see 17 U.S.C. §512(c)(3). Clover.AI ’s Agent
                for Notice of claims of copyright infringement can be reached as follows:
              </p>
              <p>
                By email: <a href="mailto:legal@Clover.AI .com">legal@Clover.AI .com</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>19. Governing Law and Arbitration; No Class Action</h3>
              <hr />
              <p>
                These Terms and Conditions, its subject matter and Gam makite ’s and your respective rights under these
                Terms and Conditions, as well as and any claim, cause of action or dispute (“claim”) arising out of or
                related to these Terms and Conditions, shall be governed by and construed under the laws of the
                Commonwea lth of Pennsylvania, United States of America, excluding the conflict of law provisions of
                that or any other jurisdiction, regardless of your country of origin or where you access the Service.
                ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THESE TERMS AND CON DITIONS OR THE SERVICE WILL BE RESOLVED
                BY BINDING ARBITRATION, RATHER THAN IN COURT, except for Clover.AI ’s right to seek injunctive relief as
                set forth below. Unless otherwise expressly required by applicable law, each party shall bear its own
                attorneys’ fees without regard to which party is deemed the prevailing party in the arbitration
                proceeding.
              </p>
              <p>
                If you do not want to arbitrate disputes with Clover.AI and you are an individual, you may opt out of
                this arbitration agreement by sending an email to{" "}
                <a href="mailto:legal@Clover.AI .com">legal@Clover.AI .com</a> within 30 days of the day you first
                access or use the Service.
              </p>
              <p>
                If you intend to seek arbitration you must first send written notice to Clover.AI ’s Administration
                Office of your intent to arbitrate (“Notice”). The Notice to Clover.AI should be sent by any of the
                following means: (i) electronic mail to <a href="mailto:legal@Clover.AI .com">legal@Clover.AI .com</a>;
                or (ii) sending the Notice by U.S. Postal Service certified mail to Clover.AI , Inc., Attention: Legal,
                5900 Penn Ave; Pittsburgh, PA 15206. The Notice must (x) describe the na ture and basis of the claim or
                dispute; and (y) set forth the specific relief sought; and (z) set forth your name, address and contact
                information. If we intend to seek arbitration against you, we will send any notice of dispute to you at
                the contact information we have for you.
              </p>
              <p>
                The arbitration will be conducted before a neutral single arbitrator in the County of Middlesex in the
                Commonwealth of Massachusetts , whose decision will be final and binding, and the arbitral proceedings
                will be governed by JAMS under its Comprehensive Arbitration Rules and Procedures (the “JAMS Rules”) as
                modified by these Terms and Conditions. The JAMS Rules are available at
                <a href="https://www.jamsadr.com/" style={{ marginLeft: 5, textDecoration: "underline" }}>
                  www.jamsadr.com
                </a>
                . All issues are for the arbitrator to decide, in cluding the scope of this arbitration clause, but the
                arbitrator is bound by the terms of these Terms and Conditions. If you initiate arbitration, your
                arbitration fees will be limited to the filing fee set forth in the JAMS Rules. We will reimburse allot
                her JAMS filing, administration and arbitrator fees paid by you, unless the arbitrator determines that
                the arbitration was frivolous or brought for an improper purpose, in which case the payment of all such
                fees shall be governed by the JAMS Rules. The arbitration will be conducted in the English language.
                Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                For any claim where the potential award is reasonably likely to be $10,000 or less, either you or Ga
                mmakite may elect to have the dispute resolved through non - appearance - based arbitration.
              </p>
              <p>
                To the fullest extent permitted by applicable law, YOU AND Clover.AI EACH AGREE THAT ANY DISPUTE
                RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
                REPRESENTATIVE ACTION. If for any reason a claim proceeds in court rather than in arbitration, YOU AND
                Clover.AI EACH WAIVE ANY RIGHT TO A JURY TRIAL. If a court of competent jurisdiction finds the foregoing
                arbitration pr ovisions invalid or inapplicable, you and Clover.AI agree that all claims arising out of
                or related to these Terms and Conditions must be resolved exclusively by a state or federal court
                located in the County of Allegheny in the Commonwealth of Pennsylvani a, and you and Clover.AI each
                agree to submit to the exercise of personal jurisdiction of such courts for the purpose of litigating
                all such claims. Notwithstanding the above, you agree that Clover.AI shall still be allowed to apply for
                and obtain injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>20. Language</h3>
              <hr />
              <p>
                This agreement was originally written in English (US). To the extent any translated version of this
                agreement conflicts with the English version, the English version controls.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>21. California Resident</h3>
              <hr />
              <p>
                If you are a California resident, in accordance with Cal. Civ. Code § 1789.3, you may report complaints
                to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of
                Consumer Affairs by c ontacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA
                95834, or by telephone at (800) 952 - 5210.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>22. Miscellaneous</h3>
              <hr />
              <p>
                These Terms and Conditions constitute the entire agreement between Clover.AI and you concerning the
                subject matter here of. In the event that any of the Terms and Conditions are held by a court or other
                tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated
                to the minimum extent necessary so that these Terms and Conditions sha ll otherwise remain in full force
                and effect. A waiver by Clover.AI or you of any provision of these Terms and Conditions or any breach
                thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
                Clover.AI may assi gn its rights or obligations under these Terms and Conditions without condition.
                These Terms and Conditions will be binding upon and will inure to the benefit of Clover.AI and you, and
                Clover.AI ’s and your respective successors and permitted assigns.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3>Last revised on July 13 th, 2020</h3>
            </div>
          </div>
          <div className="space-60"></div>
        </div>
      </div>
    );
  }
}

export default TermsOfService;
