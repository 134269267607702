import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PropTypes from "prop-types";

import Books from "./Books/Books";
import Classes from "./Classes/Classes";
import Members from "./Members/Members";

const OrganizationResources = ({ orgId, isEditor }) => {
  return (
    <Tabs defaultActiveKey="books">
      <Tab eventKey="books" title="Books">
        <Books orgId={orgId} />
      </Tab>
      <Tab eventKey="classes" title="Classes">
        <Classes orgId={orgId} />
      </Tab>
      {
        isEditor && (
          <Tab eventKey="members" title="Members">
            <Members orgId={orgId} />
          </Tab>
        )
      }
    </Tabs>
  );
};

OrganizationResources.propTypes = {
  orgId: PropTypes.string,
  isEditor: PropTypes.bool
};

export default OrganizationResources;
