import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const BasicChinese = ({ onStepComplete }) => {
  const [value, setValue] = useState(false);

  const handleChange = (ev) => {
    setValue({
      basicChineseKnowledge: ev.target.checked
    });
  };

  const handleContinue = () => {
    onStepComplete(value);
  };


  return (
    <div className="condition-container">
      <div className="condition-content">
        <h3>
          Confirm required level
        </h3>
        <p className="sub-text">To use FullChinese Pro you need to know the basics of Chinese:</p>
        <div>
          <div className="prereq-item">
            <img src="/img/icon-pinyin.svg" alt="icon" />
            <div className="item">
              <span className="title">You need to know pinyin (being able to read ”Nǐ hǎo ma”)</span><br />
              <span className="sub-title">Being able to read Nǐ hǎo, míngtiān jiàn</span>
            </div>
          </div>
          <div className="prereq-item">
            <img src="/img/icon-characters.svg" alt="icon" />
            <div className="item">
              <span className="title">You need to know at least ten Chinese characters (你，好，。。)</span><br />
              <span className="sub-title">你好，明天见</span>
            </div>
          </div>
        </div>
        <div className="condition-checkbox">
          <Form.Check
            onChange={handleChange}
            type="checkbox"
            label="I have a basic knowledge of Chinese"
            id="basic-knowledge"
          />
        </div>
      </div>
      <div className="condition-actions">
        <Button type="submit" variant="primary" disabled={!value.basicChineseKnowledge} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </div>
  );
};

BasicChinese.propTypes = {
  onStepComplete: PropTypes.func
};

export default BasicChinese;