import React from "react";
import { Link } from "react-router-dom";

const formatMatchingChar = (item = "", keyword) => {
  const matchKeyword = item.replace(
    keyword,
    match => `<span class="dictionary_match--keyword">${match}</span>`
  );

  return <span dangerouslySetInnerHTML={{ __html: matchKeyword }}></span>;
};

const formatDetailsLink = (type, id) => {
  return (
    <Link to={`/dictionary/${type}/${id}`}>Details</Link>
  );
};



export const getColumns = (props) => {
  return {
    characters: [
      { field: 'character', headerName: 'Character', width: 150, renderCell: ({ row: { character } }) => formatMatchingChar(character, props.keyword) },
      { field: 'phonetic', headerName: 'Pinyin', width: 200 },
      { field: 'defs', headerName: 'English Definition', width: 300 },
      { field: 'imp_words', headerName: 'Imported Words', width: 300 },
      { field: 'details', headerName: '.', width: 150, renderCell: ({ row: { character } }) => formatDetailsLink('character', character) }
    ],
    words: [
      { field: 'chinese', headerName: 'Word', width: 150, renderCell: ({ row: { chinese } }) => formatMatchingChar(chinese, props.keyword) },
      { field: 'phonetic', headerName: 'Pinyin', width: 300 },
      { field: 'defs', headerName: 'English Definition', width: 400 },
      { field: 'source', headerName: 'Source', width: 150 },
      { field: 'details', headerName: '.', width: 150, renderCell: ({ row: { chinese } }) => formatDetailsLink('word', chinese) }
    ],
    sentences: [
      { field: 'chinese', headerName: 'Sentence', width: 200, renderCell: ({ row: { chinese } }) => formatMatchingChar(chinese, props.keyword)},
      { field: 'phonetic', headerName: 'Pinyin', width: 400 },
      { field: 'defs', headerName: 'English Definition', width: 400 },
      { field: 'source', headerName: 'Source', width: 150 },
      { field: 'wordbyword', headerName: 'Word By Word', width: 500 }
    ]
  };
};