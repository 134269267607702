import React, { Component } from "react";
import { apiUrl, fastapiUrl } from "../../config";
import Footer from "../../components/HomePage/Footer";
import { connect } from "react-redux";
import { MdClear } from "react-icons/md";
import swal from "sweetalert";
import "./PlanTest.scss";
const axios = require("axios");

class PlanTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: [],
      textsAll: [],
      couponCode: "",
      couponVerify: "",
      user_id: 0,
      plans: [],
      planexplains: [
        "Full access to dozens of books and texts",
        "Downloading materials for offline use",
        "Entire library of Chinese characters, words and sentences"
      ]
    };
  }

  componentDidMount() {
    this.setState({
      user_id: this.props.location && this.props.location.state && this.props.location.state.response
    });
    this.getPlans();
    this.getSubPayments();
  }

  getPlans = () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Prefer: "count=exact",
      Authorization: "Bearer " + this.props.token
    };
    let url = apiUrl + "fc_plans?order=id.asc";
    axios
      .get(url, { headers: headers })
      .then(response => {
        this.setState({ plans: response.data });
      })
      .catch(error => {});
  };

  getSubPayments = () => {
    let apiObj = {};
    apiObj.headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.token
    };
    apiObj.method = "GET";
    let url = "fc_sub_payments?owner=eq." + this.props.id;
    url = apiUrl + url;
    apiObj.url = url;
    axios(apiObj)
      .then(response => {
        this.setState({
          textsAll: response.data,
          textsAlllength: response.headers["content-range"].split("/")[1]
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  changeCoupon = e => {
    this.setState({ couponCode: e.target.value });
  };

  createCoupon = () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Prefer: "count=exact",
      Authorization: "Bearer " + this.props.token
    };
    let url = fastapiUrl + "coupoun/verify";
    let data = { coupoun: this.state.couponCode };
    axios
      .post(url, data, { headers: headers })
      .then(response => {
        this.setState({ couponVerify: response.data });
      })
      .catch(error => {
        let msg = { message: "Coupon verification is failed" };
        this.setState({ couponVerify: msg });
        // swal("", "Coupon verification is failed.", "error");
      });
  };

  clickRedeem = () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Prefer: "count=exact",
      Authorization: "Bearer " + this.props.token
    };
    let url = fastapiUrl + "coupoun/pay";
    let data =
      this.state.user_id === 0
        ? { coupoun: this.state.couponCode }
        : { coupoun: this.state.couponCode, user_id: this.state.user_id };
    axios
      .post(url, data, { headers: headers })
      .then(response => {
        swal("", "Redeem is performed successfully", "success");
        setTimeout(() => {
          if (this.props.role === "student") {
            this.props.history.push("/settings");
          } else {
            this.props.history.push("/organization");
          }
        }, 2000);
      })
      .catch(error => {
        swal("", "Redeem is failed. Please try again later", "error");
      });
  };

  getPlanid = index => {
    this.props.dispatch({
      type: "PRICE",
      payload: this.state.plans[index].bt_subscribe_price
    });
    this.props.dispatch({
      type: "DURATION",
      payload: this.state.plans[index].name
    });
    this.props.dispatch({
      type: "PLANID",
      payload: index + 1
    });
    this.props.history.push("/paypal");
  };

  logoClicked = () => {
    if (this.props.role === "student") {
      this.props.history.push("/settings");
    } else {
      this.props.history.push("/organization");
    }
  };

  render() {
    const couponAction = (cell, row) => {
      return (
        <div className="st-table-bookTitle">
          <span>{cell}</span>
        </div>
      );
    };

    const dateAction = (cell, row) => {
      let newDate = cell.slice(0, 10);
      return <span>{newDate}</span>;
    };

    const amountAction = (cell, row) => {
      return <span>{cell}</span>;
    };

    const statusAction = (cell, row) => {
      return <span>{cell}</span>;
    };

    const currencyAction = (cell, row) => {
      return <span>{cell}</span>;
    };

    const columns = [
      {
        dataField: "coupoun_id",
        sort: true,
        formatter: couponAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          if (!order) {
            return (
              <div className="mb-image-content">
                <div>Coupon</div>
                <img src={`/img/sorting.png`} alt="imae" />
              </div>
            );
          } else if (order === "asc") {
            return (
              <div className="mb-image-content">
                <div>Coupon</div>
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              </div>
            );
          } else if (order === "desc") {
            return (
              <div className="mb-image-content">
                <div>Coupon</div>
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              </div>
            );
          }
        }
      },
      {
        dataField: "status",
        sort: true,
        formatter: statusAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          if (!order) {
            return (
              <div className="mb-image-content">
                <div>Status</div>
                <img src={`/img/sorting.png`} alt="imae" />
              </div>
            );
          } else if (order === "asc") {
            return (
              <div className="mb-image-content">
                <div>Status</div>
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              </div>
            );
          } else if (order === "desc") {
            return (
              <div className="mb-image-content">
                <div>Status</div>
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              </div>
            );
          }
        }
      },

      {
        dataField: "paid_through_date",
        sort: true,
        formatter: dateAction,

        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          if (!order) {
            return (
              <div className="mb-image-content">
                <div>Paid Date</div>
                <img src={`/img/sorting.png`} alt="imae" />
              </div>
            );
          } else if (order === "asc") {
            return (
              <div className="mb-image-content">
                <div>Paid Date</div>
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              </div>
            );
          } else if (order === "desc") {
            return (
              <div className="mb-image-content">
                <div>Paid Date</div>
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              </div>
            );
          }
        }
      },
      {
        dataField: "amount",
        sort: true,
        formatter: amountAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          if (!order) {
            return (
              <div className="mb-image-content">
                <div>Amount</div>
                <img src={`/img/sorting.png`} alt="imae" />
              </div>
            );
          } else if (order === "asc") {
            return (
              <div className="mb-image-content">
                <div>Amount</div>
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              </div>
            );
          } else if (order === "desc") {
            return (
              <div className="mb-image-content">
                <div>Amount</div>
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              </div>
            );
          }
        }
      },
      {
        dataField: "currency",
        sort: true,
        formatter: currencyAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          if (!order) {
            return (
              <div className="mb-image-content">
                <div>Currency</div>
                <img src={`/img/sorting.png`} alt="imae" />
              </div>
            );
          } else if (order === "asc") {
            return (
              <div className="mb-image-content">
                <div>Currency</div>
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              </div>
            );
          } else if (order === "desc") {
            return (
              <div className="mb-image-content">
                <div>Currency</div>
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              </div>
            );
          }
        }
      }
    ];

    return (
      <div
        className="kt-header--fixed 
      kt-header-mobile--fixed kt-subheader--enabled kt-aside--enabled kt-aside--fixed plan-test-page"
      >
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
              style={{ paddingLeft: "0px" }}
            >
              <div id="kt_content">
                <div>
                  <div className="row header-section">
                    <img src="/img/background-plans.png" alt="" />
                    <div className="header-content">
                      <div>
                        <img
                          src="/img/logo.svg"
                          alt=""
                          onClick={() => {
                            this.logoClicked();
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <h3>Try FullChinese PRO, free for 14 days</h3>
                        <h4>Choose a plan, try out 14 days free trial, cancel at anytime.</h4>
                        <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6 coupon-section">
                          <input
                            name="org"
                            type="text"
                            className="form-control"
                            placeholder="Enter Coupon Code"
                            aria-label="Search"
                            value={this.state.couponCode}
                            onChange={this.changeCoupon}
                          />
                          <button
                            onClick={() => {
                              this.createCoupon();
                            }}
                            className="btn"
                            disabled={this.state.couponCode === "" ? true : false}
                            data-toggle="modal"
                            data-target="#redeem"
                          >
                            REDEEM
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row select-plan-section">
                    <div className="plans-container row">
                      {this.state.plans.map((plan, index) => {
                        return (
                          <div
                            className="col-lg-3 col-md-6 col-sm-12"
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                            key={index}
                          >
                            <div className="each-plan">
                              <div className="kt-pricing-v1__header center-center">
                                <div className="kt-iconbox__title">
                                  <p className="plan-name">{plan.name}</p>
                                  <br />
                                  <p className="plan-price">
                                    {" "}
                                    ${plan.context.bt_monthly_price}
                                    <small>/mo</small>
                                  </p>
                                </div>
                              </div>
                              <div className="kt-pricing-v1__body center-center">
                                <p className="day-free-trial">14-Day Free Trial</p>
                                <p className="price-trial">${plan.bt_subscribe_price} due at end of trial</p>
                                <div
                                  className="kt-pricing-v1__button center-center"
                                  onClick={() => {
                                    this.getPlanid(index);
                                  }}
                                >
                                  Subscribe
                                </div>
                              </div>
                              <div className="save-percentage center-center">
                                <p>Save {plan.context.bt_discount}%</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="plans-explanation">
                      <p>All Plans Include</p>
                      {this.state.planexplains.map((plan, index) => {
                        return (
                          <div className="plans-explanation-details" key={index}>
                            <img src="/img/bullet.png" alt="ima" />
                            <span>{plan}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ width: "100%", marginTop: 30 }}>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="redeem"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              {this.state.couponVerify.is_valid ? (
                <div className="modal-dialog modal-dialog-centered modal-confirm" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Submit to Redeem
                      </h5>
                      <span data-dismiss="modal" aria-label="Close">
                        <MdClear />
                      </span>
                    </div>
                    <div
                      className="modal-body verified-coupon"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: 200
                      }}
                    >
                      <span style={{ marginBottom: 20 }}>
                        <strong>
                          This coupon is verified successfully to redeem. You can submit with this coupon to redeem now.
                        </strong>
                      </span>
                      <span>Result: {this.state.couponVerify.message}</span>
                      <span>Coupon: {this.state.couponVerify.coupoun}</span>
                      <span>Period: {this.state.couponVerify.period}</span>
                      <span>User_Level: {this.state.couponVerify.user_level}</span>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-elevate btn-pill modal-ok"
                        onClick={this.clickRedeem}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                      <button type="button" className="btn btn-elevate btn-pill modal-cancel" data-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="modal-dialog modal-dialog-centered modal-warning" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Error
                      </h5>
                      <span data-dismiss="modal" aria-label="Close">
                        <MdClear />
                      </span>
                    </div>
                    <div className="modal-body">{this.state.couponVerify.message}</div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-elevate btn-pill modal-ok" data-dismiss="modal">
                        Try again
                      </button>
                      <button type="button" className="btn btn-elevate btn-pill modal-cancel" data-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.rpc.auth,
    token: state.auth.token,
    id: state.auth.id,
    role: state.auth.role
  };
};

export default connect(mapStatetoProps)(PlanTest);
