export const FETCH_CLASSES_SUCCESS = "app/classes/FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_FAIL = "app/classes/FETCH_CLASSES_FAIL";

export const FETCH_CLASS_SUCCESS = "app/classes/FETCH_CLASS_SUCCESS";
export const FETCH_CLASS_FAIL = "app/classes/FETCH_CLASS_FAIL";

export const FETCH_ORGANIZATIONS_SUCCESS = "app/classes/FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAIL = "app/classes/FETCH_ORGANIZATIONS_FAIL";

export const ADD_CLASS_SUCCESS = "app/classes/ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAIL = "app/classes/ADD_CLASS_FAIL";

export const UPDATE_CLASS_DATA = "app/classes/UPDATE_CLASS_DATA";
export const UPDATE_CLASS_SUCCESS = "app/classes/UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAIL = "app/classes/UPDATE_CLASS_FAIL";

export const DELETE_CLASS_SUCCESS = "app/classes/DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAIL = "app/classes/DELETE_CLASS_FAIL";

export const FETCH_CLASS_ROLE_SUCCESS = "app/classes/FETCH_CLASS_ROLE_SUCCESS";
export const FETCH_CLASS_ROLE_FAIL = "app/classes/FETCH_CLASS_ROLE_FAIL";

/**
 * Books
 */

export const FETCH_CLASS_BOOKS_SUCCESS = "app/classes/FETCH_CLASS_BOOKS_SUCCESS";
export const FETCH_CLASS_BOOKS_FAIL = "app/classes/FETCH_CLASS_BOOKS_FAIL";

export const FETCH_CLASS_BOOKS_SHA_SUCCESS = "app/classes/FETCH_CLASS_BOOKS_SHA_SUCCESS";
export const FETCH_CLASS_BOOKS_SHA_FAIL = "app/classes/FETCH_CLASS_BOOKS_SHA_FAIL";

export const FETCH_BOOKS_SUCCESS = "app/classes/FETCH_BOOKS_SUCCESS";
export const FETCH_BOOKS_FAIL = "app/classes/FETCH_BOOKS_FAIL";

export const ADD_BOOKS_SUCCESS = "app/classes/ADD_BOOKS_SUCCESS";
export const ADD_BOOKS_FAIL = "app/classes/ADD_BOOKS_FAIL";

export const DELETE_BOOK_SUCCESS = "app/classes/DELETE_BOOK_SUCCESS";
export const DELETE_BOOK_FAIL = "app/classes/DELETE_BOOK_FAIL";

/**
 * Texts
 */

export const FETCH_CLASS_TEXTS_SUCCESS = "app/classes/FETCH_CLASS_TEXTS_SUCCESS";
export const FETCH_CLASS_TEXTS_FAIL = "app/classes/FETCH_CLASS_TEXTS_FAIL";

export const FETCH_CLASS_TEXTS_SHA_SUCCESS = "app/classes/FETCH_CLASS_TEXTS_SHA_SUCCESS";
export const FETCH_CLASS_TEXTS_SHA_FAIL = "app/classes/FETCH_CLASS_TEXTS_SHA_FAIL";

export const FETCH_TEXTS_SUCCESS = "app/classes/FETCH_TEXTS_SUCCESS";
export const FETCH_TEXTS_FAIL = "app/classes/FETCH_TEXTS_FAIL";

export const ADD_TEXTS_SUCCESS = "app/classes/ADD_TEXTS_SUCCESS";
export const ADD_TEXTS_FAIL = "app/classes/ADD_TEXTS_FAIL";

export const DELETE_TEXT_SUCCESS = "app/classes/DELETE_TEXT_SUCCESS";
export const DELETE_TEXT_FAIL = "app/classes/DELETE_TEXT_FAIL";
 
/**
* Members
*/
 
export const FETCH_CLASS_MEMBERS_SUCCESS = "app/classes/FETCH_MEMBERS_SUCCESS";
export const FETCH_CLASS_MEMBERS_FAIL = "app/classes/FETCH_MEMBERS_FAIL";

export const ADD_MEMBERS_SUCCESS = "app/classes/ADD_MEMBERS_SUCCESS";
export const ADD_MEMBERS_FAIL = "app/classes/ADD_MEMBERS_FAIL";

export const DELETE_MEMBER_SUCCESS = "app/classes/DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAIL = "app/classes/DELETE_MEMBER_FAIL";