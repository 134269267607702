import { makeApiCall } from "../../../helpers/api-client";
import * as Actions from "./action-types";

export const verifyEmail = (payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall("rpc/verify_email", {
      method: 'post',
      data: payload
    });
    dispatch(verifyEmailSuccess());
    onSuccess();
  } catch (error) {
    dispatch(verifyEmailFail());
    onFail();
  }
};

const verifyEmailSuccess = () => ({
  type: Actions.VERIFY_EMAIL_SUCCESS
});

const verifyEmailFail = () => ({
  type: Actions.VERIFY_EMAIL_FAIL
});
