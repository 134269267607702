import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import LinkButton from "../../../../components/LinkButton/LinkButton";
import EditField from "../../../../components/Fields/EditField";
import VideoTranscripts from "../../../YouTubeTranscripts/components/VideoTranscripts";

import { resetCommon } from "../../../../common/actions/actions";
import { fetchSelectedVideo } from "../../state/actions";

import "./video-details.scss"

const VideoDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedVideo } = useSelector(state => state.videos);
  const {
    title,
    video_string_id,
    url,
    thumbnails = {},
    comment_counts,
    likes,
    view_counts,
    transcripts = []
  } = selectedVideo;
  const { standard } = thumbnails;

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedVideo(id));
  }, [id, dispatch]);
  

  return (
    <div className="video-details">
      <div className="section-top">
        <div className="header">
          <LinkButton onClick={() => history.goBack()}>
            <img src="/img/back.svg" alt="back button" className="size-24-24" />
          </LinkButton>
          <h3>{title}</h3>
        </div>
        <Form>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Book Title</Form.Label>
            <Col xs="8">
              <EditField name="Video String Id" value={video_string_id} canEdit={false}>
                <Form.Control
                  name="title"
                  type="text"
                  placeholder="Enter video string ID"
                />
              </EditField>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">URL</Form.Label>
            <Col xs="8">
              <EditField name="author" value={url} canEdit={false}>
                <Form.Control
                  name="author"
                  type="text"
                  placeholder="Enter video URL here"
                />
              </EditField>
            </Col>
          </Form.Group>
        </Form>

        <Form.Group as={Row} className="form-row">
          <Form.Label column xs="4">Comment Count</Form.Label>
          <Col xs="8">
            <EditField name="comment_counts" value={comment_counts} canEdit={false} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="form-row">
          <Form.Label column xs="4">Likes</Form.Label>
          <Col xs="8">
            <EditField name="likes" value={likes} canEdit={false} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="form-row">
          <Form.Label column xs="4">View Count</Form.Label>
          <Col xs="8">
            <EditField name="view_counts" value={view_counts} canEdit={false} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="form-row">
          <Col xs="8">
            {
              standard?.url && (
                <a href={url} target="_blank">
                  <img src={standard?.url} alt="video thumbnail" width="600" />
                </a>
              )
            }
          </Col>
        </Form.Group>
      </div>
      <div>
        <VideoTranscripts data={transcripts} />
      </div>
    </div>
  )
};

export default VideoDetails;
