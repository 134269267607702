import React, { Component } from "react";
import { apiUrl, fastapiUrl } from "../../config";
import { connect } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Popover, PopoverBody } from "reactstrap";
import InfiniteCalendar from "react-infinite-calendar";
import swal from "sweetalert";
import { MdClear } from "react-icons/md";
import "react-infinite-calendar/styles.css";

import Classes from "./components/classes";
import Books from "./components/books";
import Texts from "./components/texts";
import Subscription from "../../components/Subscription";
import "./Members.scss";
const axios = require("axios");
class MembersDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      status: "",
      price: 0,
      first_billing_date: "",
      next_billing_date: "",
      updated_at: "",
      plan_id: 0,
      lang: "",
      display_lang: "",
      level: "",
      user_id: "",
      user_type: "",
      plan_expire: "",
      subscription: 0,
      study_months: 0,
      study_reason: "",
      region: null,
      avatar: "",
      profession: "",
      most_interested: "",
      planId: 0,
      subs: undefined,
      hidenavStatus: false,
      tabkey: "details",
      displayPopover: false,
      checked: false,
      subscriptions: {},
      joinedDate: "",
      showpassword: "password",
      showConfirmPassword: "password",
      newPassword: "",
      newConfirmPassword: "",
      clickedSubmit: false
    };
  }
  componentDidMount() {
    this.getProfile();
    this.getSubscription();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hidenav !== nextProps.hidenav) {
      this.setState({ hidenavStatus: this.props.hidenav });
    }
  }

  changePlan = () => {
    this.setState({ displayPopover: false });
    let dates = document.querySelectorAll(".Cal__Header__wrapper");
    let startDate = dates[0].getAttribute("aria-label");
    let startMonth = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(startDate.slice(0, 3)) / 3 + 1;
    let startDay = startDate.slice(4, 6).match(/(\d+)/)[0];
    let startYear = startDate.slice(startDate.length - 4, startDate.length);
    let newPlanExpire = startYear + "-" + startMonth + "-" + startDay;
    let headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.token
    };
    let url = apiUrl + "fc_admin_subscriptions?id=eq." + this.state.selectedID;
    let data = { plan_expire: newPlanExpire };
    axios
      .patch(url, data, { headers: headers })
      .then(response => {
        swal("", "Plan Expire date is changed successfully", "success");
        this.getSubscription();
      })
      .catch(error => {
        swal("", "Change action is failed. Please try again later.", "error");
      });
  };

  clear = () => {
    this.setState({ displayPopover: false });
  };

  getCurrentTime = () => {
    const registeredDate = this.props.owneridmem.last_modified;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const regDay = registeredDate ? registeredDate.slice(8, 10) : 0;
    const regMon = registeredDate ? registeredDate.slice(5, 7) : 0;
    const regYear = registeredDate ? registeredDate.slice(0, 4) : 0;
    return yyyy - regYear > 0 ? (
      <p>{yyyy - regYear} years ago</p>
    ) : mm - regMon > 0 ? (
      <p>{mm - regMon} months ago</p>
    ) : (
      <p>{dd - regDay} days ago</p>
    );
  };

  getProfile = () => {
    let headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.token
    };
    let url = apiUrl + "fc_users?user_id=eq." + this.props.owneridmem.user_id;
    axios
      .get(url, { headers: headers })
      .then(response => {
        this.setState({
          user_id: response.data[0].user_id,
          user: response.data[0],
          lang: response.data[0].lang,
          display_lang: response.data[0].display_lang,
          level: response.data[0].level,
          user_type: response.data[0].user_type,
          plan_expire: response.data[0].plan_expire,
          subscription: response.data[0].subscription,
          study_months: response.data[0].study_months,
          study_reason: response.data[0].study_reason,
          region: response.data[0].region,
          avatar: response.data[0].avatar,
          profession: response.data[0].profession,
          most_interested: response.data[0].most_interested,
          joinedDate:
            response.data[0].last_modified.slice(8, 10) +
            "/" +
            response.data[0].last_modified.slice(5, 7) +
            "/" +
            response.data[0].last_modified.slice(0, 4)
        });
      })
      .catch(error => {});
  };

  getSubscription = () => {
    let headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.token
    };
    let url = apiUrl + "fc_admin_subscriptions?owner=eq." + this.props.owneridmem.user_id + "&order=id.desc";
    axios
      .get(url, { headers: headers })
      .then(response => {
        this.setState({
          subscriptions: response.data[0],
          selectedID: response.data[0].id,
          checked: response.data[0].auto_renew_status ? true : false,
          membership: response.data[0].is_expired
        });
        let planID = response.data[0].plan_id;
        let url1 = apiUrl + "fc_plans?id=eq." + planID;
        axios
          .get(url1, { headers: headers })
          .then(response => {
            this.setState({
              googlePlanId: response.data[0].google_plan_id,
              subscriptionStatus:
                "Premium ( " + response.data[0].name + " ) - $" + response.data[0].context.bt_monthly_price + "/mo"
            });
          })
          .catch(error => {});
      })
      .catch(error => {});
  };

  changeSwitch = () => {
    if (!this.state.checked) {
      // this.props.history.push("/PlanTest", {
      //   response: this.props.owneridmem.user_id
      // });
    } else {
      const headers = {
        Authorization: "Bearer " + this.props.token,
        "Content-Type": "application/json",
        Range: "0 - 19",
        Accept: "application/json",
        Prefer: "count=exact"
      };
      let url = fastapiUrl + "bt/admin/unsubscribe";
      let data = { user_id: this.state.user_id };
      axios
        .post(url, data, { headers: headers })
        .then(response => {
          swal("", "You have been unsubscribed from FullChinese..", "success");
          this.getSubscription();
        })
        .catch(err => {
          swal("", "Unsubscribe is failed. Please try again later.", "error");
        });
    }
  };

  changeTab = key => {
    this.setState({ tabkey: key });
    // this.props.dispatch({ type: "CLASSTABKEY", payload: key });
  };

  addDefaultSrc = ev => {
    ev.target.src = "/img/newavatar.png";
  };

  convertIdStyle = cell => {
    var s = "000" + cell;
    return s.substr(s.length - 4);
  };

  getInputChange = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePassword = () => {
    this.setState({ clickedSubmit: true });
    if (
      this.state.newPassword !== "" &&
      this.state.newConfirmPassword !== "" &&
      this.state.newPassword === this.state.newConfirmPassword
    ) {
      let headers = {
        Accept: "application/json",
        Prefer: "count=exact",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token
      };
      let data = { email: this.state.user.email, new_password: this.state.newPassword };
      let url = apiUrl + "rpc/admin_change_password";
      axios
        .post(url, data, { headers: headers })
        .then(response => {
          swal("", "Password is updated successfully.", "success");
        })
        .catch(error => {
          swal("", error.response.data.message, "error");
        });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  joinedToggle = () => {
    this.setState({
      displayPopover: !this.state.displayPopover
    });
  };

  changePasswordType = () => {
    this.setState({
      showpassword: this.state.showpassword === "password" ? "text" : "password"
    });
  };

  changeConfirmPasswordType = () => {
    this.setState({
      showConfirmPassword: this.state.showConfirmPassword === "password" ? "text" : "password"
    });
  };

  joinedFilter = () => {
    const today = new Date();
    return (
      <InfiniteCalendar
        selected={today}
        minDate={today}
        locale={{
          headerFormat: "MMM Do"
        }}
      />
    );
  };

  render() {
    const { avatar, user, subscriptions } = this.state;
    return (
      <div
        className={
          this.state.hidenavStatus === false
            ? "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            : "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper navbar-hided"
        }
        id="kt_wrapper"
      >
        <div
          className="profileBody"
          // style={{
          //   paddingLeft: this.state.hidenavStatus === false ? "260px" : "30px"
          // }}
        >
          <div className="mbd-content" style={{ marginBottom: 50 }}>
            <div className="mbd-subheader">
              <div className="mbd-description">
                <img src="/img/back.svg" alt="back" className="size-24-24" onClick={() => this.goBack()} />

                <img
                  onError={this.addDefaultSrc}
                  src={
                    avatar && avatar.slice(-4) === ".png"
                      ? `https://fullapi.s3.amazonaws.com/${avatar}`
                      : "/img/newavatar.png"
                  }
                  alt="imae"
                  className="mbd-avatar"
                />
                <div className="mbd-pInfo">
                  <p className="mbd-title-paragragh mbd-name">{user.name}</p>
                </div>
              </div>
              <div className="mbd-icons">
                <div className="mbd-block-icon">
                  <img src={`/img/block.svg`} alt="role" />
                  <p>BLOCK</p>
                </div>
                <div className="mbd-password-icon">
                  <img src={`/img/password.svg`} alt="role" />
                  <p>PASSWORD</p>
                </div>
                <div className="mbd-refresh-icon">
                  <img
                    src={`/img/refresh.svg`}
                    alt="refresh"
                    onClick={() => {
                      window.location.reload(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <p>REFRESH</p>
                </div>
              </div>
            </div>
            <div className="mbd-profile-details">
              <div className="mbd-userid">
                <div className="mbd-title">
                  <p>User ID</p>
                </div>
                <div className="mbd-content-details">
                  <p>{this.convertIdStyle(user.user_id)}</p>
                </div>
              </div>
              <div className="mbd-email">
                <div className="mbd-title">
                  <p>Email</p>
                </div>
                <div className="mbd-content-details">
                  <p>{user.email}</p>
                </div>
              </div>
              <div className="mbd-role">
                <div className="mbd-title">
                  <p>Role</p>
                </div>
                <div className="mbd-content-details">
                  <img src={`/img/${user.role}.png`} alt="role" className="size-24-24" />
                  <p>{user.role}</p>
                </div>
              </div>
              <div className="mbd-password">
                <div className="mbd-title">
                  <p>Password</p>
                </div>
                <div className="mbd-content-details">
                  <button className="btn btn-success" data-toggle="modal" data-target="#changePassword">
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.tabkey}
            onSelect={k => this.changeTab(k)}
            className="tabs member-detail-tabs"
            style={{
              paddingLeft: 0,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Tab eventKey="details" title="Details" style={{ maxWidth: 520, margin: "auto" }}>
              <div className="mbd-details">
                {/* <p className="mbd-title-paragragh">Details</p> */}
                <div className="mbd-level">
                  <div className="mbd-title">
                    <p>Level</p>
                  </div>
                  <div className="mbd-content-details">
                    <img src={`/img/${user.level}.png`} alt="imae" className="size-24-24" />
                    <p>{user.level}</p>
                  </div>
                </div>
                <div className="mbd-goal">
                  <div className="mbd-title">
                    <p>Goal</p>
                  </div>
                  <div className="mbd-content-details">
                    <p>{user.study_reason}</p>
                  </div>
                </div>
                <div className="mbd-region">
                  <div className="mbd-title">
                    <p>Region</p>
                  </div>
                  <div className="mbd-content-details">
                    <img src={`/img/flags/${user.region}.svg`} alt="imae" />
                    <p>{user.region}</p>
                  </div>
                </div>
                <div className="mbd-dis_lang">
                  <div className="mbd-title">
                    <p>Display Language</p>
                  </div>
                  <div className="mbd-content-details">
                    <img src={`/img/flags/${user.display_lang}.svg`} alt="imae" />
                    <p>{user.display_lang}</p>
                  </div>
                </div>
                <div className="mbd-joined">
                  <div className="mbd-title">
                    <p>Joined</p>
                  </div>
                  <div className="mbd-content-details">
                    <p>{this.state.joinedDate}</p>
                  </div>
                  <div className="mbd-others">{this.getCurrentTime()}</div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="subscription" title="Subscription" style={{ maxWidth: 520, margin: "auto" }}>
              <Subscription subscription={this.state.subscriptions} user={"admin"} />
              {subscriptions && subscriptions.plan_expire && (
                <div style={{ marginLeft: 45 }}>
                  <Popover
                    className="plan-expire-popover joined-popover"
                    trigger="legacy"
                    placement="bottom-start"
                    isOpen={this.state.displayPopover}
                    target="joined-popover"
                    toggle={this.joinedToggle}
                    hideArrow
                  >
                    <PopoverBody>
                      {this.joinedFilter()}
                      <div className="mb-calendar-confirm">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.clear();
                          }}
                        >
                          Clear
                        </span>
                        <span style={{ cursor: "pointer" }} onClick={() => this.changePlan()}>
                          Save
                        </span>
                      </div>
                    </PopoverBody>
                  </Popover>
                </div>
              )}
            </Tab>

            {this.state.user_id !== "" && (
              <Tab eventKey="classes" title="Classses">
                <Classes userid={this.state.user_id} />
              </Tab>
            )}
            {this.state.user_id !== "" && (
              <Tab eventKey="books" title="Books">
                <Books userid={this.state.user_id} />
              </Tab>
            )}
            {this.state.user_id !== "" && (
              <Tab eventKey="texts" title="Texts">
                <Texts userid={this.state.user_id} />
              </Tab>
            )}
          </Tabs>
          <div
            className="modal fade"
            id="changeSub"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-confirm" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Change Subscription Status
                  </h5>
                  <span data-dismiss="modal" aria-label="Close">
                    <MdClear />
                  </span>
                </div>
                <div className="modal-body">
                  {this.state.checked ? "Do you want to unsubscribe?" : "Do you want to subscribe?"}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-elevate btn-pill modal-ok"
                    onClick={this.changeSwitch}
                    data-dismiss="modal"
                  >
                    {this.state.checked ? "Unsubscribe" : "Subscribe"}
                  </button>
                  <button type="button" className="btn btn-elevate btn-pill modal-cancel" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="changePassword"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-confirm" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Change Password
                  </h5>
                  <span data-dismiss="modal" aria-label="Close">
                    <MdClear />
                  </span>
                </div>
                <div className="modal-body">
                  <p>New Password</p>
                  <input
                    className={
                      this.state.newPassword === "" && this.state.clickedSubmit
                        ? "loginError form-control"
                        : "form-control"
                    }
                    id="memberChangePassword"
                    type={this.state.showpassword}
                    name="newPassword"
                    autoComplete="nope"
                    value={this.state.newPassword}
                    onChange={this.getInputChange}
                  />
                  {this.state.newPassword !== "" && (
                    <label
                      style={{
                        float: "right",
                        position: "relative",
                        top: -26,
                        right: 10
                      }}
                    >
                      {this.state.showpassword === "password" ? (
                        <i className="fas fa-eye" onClick={this.changePasswordType}></i>
                      ) : (
                        <i className="fas fa-eye-slash" onClick={this.changePasswordType}></i>
                      )}
                    </label>
                  )}

                  {this.state.newPassword === "" && this.state.clickedSubmit && (
                    <label id="passworderror" className="error-text">
                      Password is required
                    </label>
                  )}

                  <p style={{ marginTop: 10 }}>Confirm Password</p>
                  <input
                    className={
                      this.state.newConfirmPassword === "" && this.state.clickedSubmit
                        ? "loginError form-control"
                        : "form-control"
                    }
                    id="memberChangePassword"
                    type={this.state.showConfirmPassword}
                    name="newConfirmPassword"
                    autoComplete="nope"
                    value={this.state.newConfirmPassword}
                    onChange={this.getInputChange}
                  />
                  {this.state.newConfirmPassword !== "" && (
                    <label
                      style={{
                        float: "right",
                        position: "relative",
                        top: -26,
                        right: 10
                      }}
                    >
                      {this.state.showConfirmPassword === "password" ? (
                        <i className="fas fa-eye" onClick={this.changeConfirmPasswordType}></i>
                      ) : (
                        <i className="fas fa-eye-slash" onClick={this.changeConfirmPasswordType}></i>
                      )}
                    </label>
                  )}

                  {this.state.newConfirmPassword === "" && this.state.clickedSubmit && (
                    <label id="passworderror" className="error-text">
                      Password is required
                    </label>
                  )}
                  {this.state.newConfirmPassword !== this.state.newPassword && (
                    <label id="passworderror" className="error-text">
                      Passwords are not matching
                    </label>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-elevate btn-pill modal-ok"
                    onClick={this.changePassword}
                    disabled={
                      this.state.newPassword !== "" &&
                      this.state.newConfirmPassword !== "" &&
                      this.state.newPassword === this.state.newConfirmPassword
                        ? false
                        : true
                    }
                    data-dismiss="modal"
                  >
                    Change
                  </button>
                  <button type="button" className="btn btn-elevate btn-pill modal-cancel" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    owneridmem: state.rpc.useridmembersdetails,
    token: state.rpc.tokens,
    hidenav: state.rpc.hidenav
  };
};

export default connect(mapStatetoProps)(MembersDetails);
