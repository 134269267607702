import uuid from 'uuid/v4';
import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { fastapiUrl } from "../../../config";
import { throwApiError, showNotice, setNextRoute } from "../../../common/actions/actions";

import * as Actions from "./action-types";

/**
 * Texts
 */

export const fetchTexts = (pagination, id, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_textstatitems?owner=eq.${id}&text_title=ilike.%${searchTerm}%&order=last_modified.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    const promises = [];

    const getTextShaData = (textSha) => {
      return new Promise((resolve) => {
        makeApiCall(`fc_texts?sha1=eq.${textSha}`)
          .then(resp => {
            const { json_text: { sounds } } = resp.data[0];
            resolve(Array.isArray(sounds) ? sounds.length : 0)
          })
          .catch(e => {
            resolve(0);
          });
      });
    }
    data.forEach(({ text_sha1 }) => {
      promises.push(getTextShaData(text_sha1));
    });

    Promise.all(promises)
      .then(sounds => {
        const updatedData = data.map((item, i) => {
          item['numSounds'] = sounds[i];
          return item;
        });

        dispatch(fetchTextsSuccess({
          numRecords,
          rows: updatedData
        }));
      })
      .catch(e => {
        dispatch(fetchTextsSuccess({
          numRecords,
          rows: data
        }));
      });
  } catch (error) {
    dispatch(fetchTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch texts. Please try again."
    }));
  }
}

const fetchTextsSuccess = (payload) => ({
  type: Actions.FETCH_TEXTS_SUCCESS,
  payload
});

const fetchTextsFail = () => ({
  type: Actions.FETCH_TEXTS_FAIL
});

export const createText = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall("fc_texts", {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text Created Successfully"
    }));
    dispatch(createTextSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(createTextSuccessFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to add the text at this moment. Please try again later."
    }));
  }
};

const createTextSuccess = (payload) => ({
  type: Actions.CREATE_TEXT_SUCCESS,
  payload
});

const createTextSuccessFail = () => ({
  type: Actions.CREATE_TEXT_FAIL
});


/**
 * Selected Text
 */

export const fetchSelectedText = (sha) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_texts?sha1=eq.${sha}`);
    dispatch(fetchSelectedTextSuccess(response?.data?.[0]));
  } catch (error) {
    dispatch(fetchSelectedTextFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch selected text. Please try again."
    }));
  }
};

const fetchSelectedTextSuccess = (payload) => ({
  type: Actions.FETCH_SELECTED_TEXT_SUCCESS,
  payload
});

const fetchSelectedTextFail = () => ({
  type: Actions.FETCH_SELECTED_TEXT_FAIL
});

export const resetSelectedText = () => ({
  type: Actions.RESET_SELECTED_TEXT
});


export const updateSelectedText = (textSha1, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_texts?sha1=eq.${textSha1}`, {
      method: 'patch',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(updateSelectedTextSuccess(payload));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateSelectedTextFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update selected text. Please try again."
    }));
  }
};

const updateSelectedTextSuccess = (payload) => ({
  type: Actions.UPDATE_SELECTED_TEXT_SUCCESS,
  payload
});

const updateSelectedTextFail = () => ({
  type: Actions.UPDATE_SELECTED_TEXT_FAIL
});

export const deleteSelectedText = (textSha, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_texts?sha1=eq.${textSha}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(deleteSelectedTextSuccess(response.data));
  } catch (error) {
    dispatch(deleteSelectedTextFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete selected text. Please try again."
    }));
  }
};

const deleteSelectedTextSuccess = (payload) => ({
  type: Actions.DELETE_SELECTED_TEXT_SUCCESS,
  payload
});

const deleteSelectedTextFail = () => ({
  type: Actions.DELETE_SELECTED_TEXT_FAIL
});

/**
 * All Texts
 */
export const fetchAllTexts = (pagination, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_texts?title=ilike.%${searchTerm}%&order=last_modified.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchAllTextsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchAllTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch texts. Please try again."
    }));
  }
}

const fetchAllTextsSuccess = (payload) => ({
  type: Actions.FETCH_ALL_TEXTS_SUCCESS,
  payload
});

const fetchAllTextsFail = () => ({
  type: Actions.FETCH_ALL_TEXTS_FAIL
});

export const deleteText = (textSha, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_texts?sha1=eq.${textSha}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book deleted successfully"
    }));

    dispatch(deleteTextSuccess(response.data));
  } catch (error) {
    dispatch(deleteTextFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete selected book. Please try again."
    }));
  }
};

const deleteTextSuccess = (payload) => ({
  type: Actions.DELETE_TEXT_SUCCESS,
  payload
});

const deleteTextFail = () => ({
  type: Actions.DELETE_TEXT_FAIL
});

export const updateText = (sha1, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_texts?sha1=eq.${sha1}`, {
      method: 'patch',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text updated successfully"
    }));
    dispatch(updateTextSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateTextFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update the text. Please try again."
    }));
  }
};

const updateTextSuccess = (payload) => ({
  type: Actions.UPDATE_TEXT_SUCCESS,
  payload
});

const updateTextFail = () => ({
  type: Actions.UPDATE_TEXT_FAIL
});

/**
 * Books
 */
export const fetchBooks = (pagination, id, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_bookstatitems?owner=eq.${id}&book_title=ilike.%${searchTerm}%&permission=eq.readonly&order=last_modified.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchBooksSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchBooksSuccess = (payload) => ({
  type: Actions.FETCH_BOOKS_SUCCESS,
  payload
});

const fetchBooksFail = () => ({
  type: Actions.FETCH_BOOKS_FAIL
});

export const deleteBookStatItems = (bookSha, id, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_bookstatitems?book_sha1=eq.${bookSha}${id ? `&owner=eq.${id}` : ""}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book deleted successfully"
    }));

    dispatch(deleteBookStatItemsSuccess(response.data));
  } catch (error) {
    dispatch(deleteBookStatItemsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete selected book. Please try again."
    }));
  }
};

const deleteBookStatItemsSuccess = (payload) => ({
  type: Actions.DELETE_BOOK_SUCCESS,
  payload
});

const deleteBookStatItemsFail = () => ({
  type: Actions.DELETE_BOOK_FAIL
});

// Add New Book
export const fetchBooksToAdd = (pagination, id, searchTerm = "") => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_bookstatitems?owner=eq.${id}&select=book_sha1`);
    const shaList = Array.isArray(response.data) ? response.data.join("") : "";

    dispatch(fetchBookListToAdd(pagination, shaList, searchTerm));
  } catch (e) {
    dispatch(fetchBooksToAddFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch list of book sha. Please try again."
    }));
  }
};

const fetchBooksToAddFail = () => ({
  type: Actions.FETCH_BOOKS_TO_ADD_FAIL
});

const fetchBookListToAdd = (pagination, shaList, searchTerm) => async (dispatch) => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_books?sha1=not.in.(${shaList})&title=ilike.%${searchTerm}%`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchBookListToAddSuccess({
      numRecords,
      rows: data
    }));
  
  } catch (e) {
    dispatch(fetchBookListToAddFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch list of books. Please try again."
    }));
  }
};

const fetchBookListToAddSuccess = (payload) => ({
  type: Actions.FETCH_BOOK_LIST_TO_ADD_SUCCESS,
  payload
});

const fetchBookListToAddFail = () => ({
  type: Actions.FETCH_BOOK_LIST_TO_ADD_FAIL
});


export const addBooks = (payload, id, onComplete) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_bookstatitems`, {
      method: 'post',
      data: payload
    });
    dispatch(addBooksSuccess(response.data));
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Books added successfully"
    }));
    dispatch(fetchBooks({ pageNum: 0, pageSize: 10 }, id));
    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (e) {
    dispatch(addBooksFailure());
    dispatch(throwApiError({
      type: "toast",
      message: "Book already added. Please choose another book."
    }));
    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

const addBooksSuccess = (payload) => ({
  type: Actions.ADD_BOOKS_SUCCESS,
  payload
});

const addBooksFailure = () => ({
  type: Actions.ADD_BOOKS_FAIL
});


export const fetchBookTextsShaList = (sha1, onComplete) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_book_texts?book_sha1=eq.${sha1}&select=text_sha1`);
    const textShaList = response.data.map(({ text_sha1 }) => text_sha1) || [];
    dispatch(fetchBookTextShaListSuccess(textShaList));
    if (typeof onComplete === 'function') {
      onComplete(textShaList);
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not get text_sha1 list. Please try again."
    }));

    if (typeof onComplete === 'function') {
      onComplete([]);
    }
  }
};

const fetchBookTextShaListSuccess = (payload) => ({
  type: Actions.FETCH_BOOK_TEXTS_SHALIST_SUCCESS,
  payload
});

export const fetchBookTexts = (pagination, bookSha, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_book_texts?book_sha1=eq.${bookSha}&select=*,text:fc_texts(sha1,title,author,nb_sounds,last_modified)&order=idx.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchBookTextsSuccess({
      numRecords,
      rows: data.map((row) => ({
        idx: row.idx,
        ...row.text
      }))
    }));
  } catch (error) {
    dispatch(fetchBookTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchBookTextsSuccess = (payload) => ({
  type: Actions.FETCH_BOOK_TEXTS_SUCCESS,
  payload
});

const fetchBookTextsFail = () => ({
  type: Actions.FETCH_BOOK_TEXTS_FAIL
});


export const fetchBookPages = (pagination, bookSha1, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_book_pages?book_sha1=eq.${bookSha1}`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    const promises = [];

    const getPageShaData = async (pageSha1) => {
      return new Promise((resolve, reject) => {
        makeApiCall(`fc_pages?select=*&sha1=eq.${pageSha1}`)
          .then(response => {
            resolve(response.data[0]);
          })
          .catch(e => {
            reject();
          });
      });
    }
    data.forEach(({ page_sha1 }) => {
      promises.push(getPageShaData(page_sha1));
    });

    Promise.all(promises)
      .then(response => {
        dispatch(fetchBookPagesSuccess({
          numRecords,
          rows: response
        }));
      })
      .catch(e => {
        dispatch(fetchBookPagesSuccess({
          numRecords,
          rows: response
        }));
      });
  } catch (error) {
    dispatch(fetchBookPagesFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch book pages. Please try again."
    }));
  }
};

const fetchBookPagesSuccess = (payload) => ({
  type: Actions.FETCH_BOOK_PAGES_SUCCESS,
  payload
});

const fetchBookPagesFail = () => ({
  type: Actions.FETCH_BOOK_PAGES_FAIL
});

export const fetchPagePreviewData = (pageSha1, onSuccess, onFail) => async (dispatch) => {
  dispatch(fetchPagePreview());
  try {
    const response = await makeApiCall(`fatjson/newsfeeds?pageSha1=${pageSha1}`, {
      baseURL: fastapiUrl,
      method: "get"
    });
    
    dispatch(fetchPagePreviewDataSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(fetchPagePreviewDataFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to fetch preview. Please try again later."
    }));
  }
};

const fetchPagePreview = () => ({
  type: Actions.FETCH_PAGE_PREVIEW
});

const fetchPagePreviewDataSuccess = (payload) => ({
  type: Actions.FETCH_PAGE_PREVIEW_DATA_SUCCESS,
  payload
});

const fetchPagePreviewDataFail = () => ({
  type: Actions.FETCH_PAGE_PREVIEW_DATA_FAIL
});

export const publishPageToNewsFeed = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_news_feeds`, {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Page published to news feed successfully"
    }));

    dispatch(publishPageToNewsFeedSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(publishPageToNewsFeedFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to publish to news feed. Please try again later."
    }));
  }
};

const publishPageToNewsFeedSuccess = (payload) => ({
  type: Actions.PUBLISH_TO_NEWSFEED_SUCCESS,
  payload
});

const publishPageToNewsFeedFail = () => ({
  type: Actions.PUBLISH_TO_NEWSFEED_FAIL
});

/**
 * Selected Book
 */

export const fetchSelectedBook = (sha, id) => async (dispatch) => {
  try {
    const book = await makeApiCall(`fc_books?sha1=eq.${sha}`);
    const notebook = await makeApiCall(`fc_bookstatitems?book_sha1=eq.${sha}&owner=eq.${id}&permission=eq.owner`);
    dispatch(fetchSelectedBookSuccess({
      ...book?.data?.[0],
      isNotebook: notebook?.data?.length > 0
    }));
  } catch (error) {
    dispatch(fetchSelectedBookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch selected book. Please try again."
    }));
  }
};

const fetchSelectedBookSuccess = (payload) => ({
  type: Actions.FETCH_SELECTED_BOOK_SUCCESS,
  payload
});

const fetchSelectedBookFail = () => ({
  type: Actions.FETCH_SELECTED_BOOK_FAIL
});

export const resetSelectedBook = () => ({
  type: Actions.RESET_SELECTED_BOOK
});

export const updateSelectedBook = (bookSha1, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_books?sha1=eq.${bookSha1}`, {
      method: 'patch',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(updateSelectedBookSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateSelectedBookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update selected book. Please try again."
    }));
  }
};

const updateSelectedBookSuccess = (payload) => ({
  type: Actions.UPDATE_SELECTED_BOOK_SUCCESS,
  payload
});

const updateSelectedBookFail = () => ({
  type: Actions.UPDATE_SELECTED_BOOK_FAIL
});

export const fetchAvailableTexts = (pagination, id, textShaList, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_texts?owner=eq.${id}&sha1=not.in.(${textShaList})&title=ilike.%${searchTerm}%`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchAvailableTextsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchAvailableTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch available texts. Please try again."
    }));
  }
};

const fetchAvailableTextsSuccess = (payload) => ({
  type: Actions.FETCH_AVAILABLE_TEXTS_SUCCESS,
  payload
});

const fetchAvailableTextsFail = () => ({
  type: Actions.FETCH_AVAILABLE_TEXTS_FAIL
});

export const addTextsToBook = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_book_texts`, {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text Added To Book Successfully"
    }));

    dispatch(addTextToBookSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(addTextToBookFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to add the text at this moment. Please try again later."
    }));
  }
};

const addTextToBookSuccess = (payload) => ({
  type: Actions.ADD_TEXT_TO_BOOK_SUCCESS,
  payload
});

const addTextToBookFail = () => ({
  type: Actions.ADD_TEXT_TO_BOOK_FAIL
});

export const updateBookText = (bookSha1, textSha1, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_book_texts?book_sha1=eq.${bookSha1}&text_sha1=eq.${textSha1}`, {
      method: "patch",
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text updated successfully"
    }));

    dispatch(updateBookTextSuccess({
      textSha1,
      data: payload
    }));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateBookTextFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to update the text at this moment. Please try again later."
    }));
  }
};

const updateBookTextSuccess = (payload) => ({
  type: Actions.UPDATE_BOOK_TEXT_SUCCESS,
  payload
});

const updateBookTextFail = () => ({
  type: Actions.UPDATE_BOOK_TEXT_FAIL
});

export const deleteBookText = (bookSha1, textSha1, onComplete) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_book_texts?book_sha1=eq.${bookSha1}&text_sha1=eq.${textSha1}`, {
      method: 'delete'
    });

    if (typeof onComplete === "function") {
      onComplete();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Text Removed Successfully"
    }));

    dispatch(deleteBookTextSuccess(response.data));
  } catch (error) {
    if (typeof onComplete === "function") {
      onComplete();
    }
    dispatch(deleteBookTextFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to remove the text at this moment. Please try again later."
    }));
  }
};

const deleteBookTextSuccess = (payload) => ({
  type: Actions.DELETE_BOOK_TEXT_SUCCESS,
  payload
});

const deleteBookTextFail = () => ({
  type: Actions.DELETE_BOOK_TEXT_FAIL
});

/**
 * Notebooks
 */
export const fetchNotebooks = (pagination, id, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_bookstatitems?owner=eq.${id}&permission=eq.owner&book_title=ilike.%${searchTerm}%&order=last_modified.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchNotebooksSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchNotebooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch notebooks. Please try again."
    }));
  }
};

const fetchNotebooksSuccess = (payload) => ({
  type: Actions.FETCH_NOTEBOOKS_SUCCESS,
  payload
});

const fetchNotebooksFail = () => ({
  type: Actions.FETCH_NOTEBOOKS_FAIL
});

export const createBook = (payloadBooks, payloadBookStatItems, onSuccess) => async (dispatch) => {
  try {
    await makeApiCall(`fc_books`, {
      method: 'post',
      data: payloadBooks
    });

    await makeApiCall(`fc_bookstatitems`, {
      method: 'post',
      data: payloadBookStatItems
    });
     

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book created successfully"
    }));
    dispatch(setNextRoute("/books"));
    dispatch(createBookSuccess());
  } catch (error) {
    dispatch(createBookFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to create a book at this moment. Please try again later."
    }));
  }
};

const createBookSuccess = (payload) => ({
  type: Actions.ADD_TEXT_TO_BOOK_SUCCESS,
  payload
});

const createBookFail = () => ({
  type: Actions.ADD_TEXT_TO_BOOK_FAIL
});


export const deleteNotebook = (bookSha, id, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_bookstatitems?owner=eq.${id}&permission=eq.owner&book_sha1=eq.${bookSha}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book deleted successfully"
    }));

    dispatch(deleteNotebookSuccess(response.data));
  } catch (error) {
    dispatch(deleteNotebookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete selected book. Please try again."
    }));
  }
};

const deleteNotebookSuccess = (payload) => ({
  type: Actions.DELETE_NOTEBOOK_SUCCESS,
  payload
});

const deleteNotebookFail = () => ({
  type: Actions.DELETE_NOTEBOOK_FAIL
});

export const updateNotebook = (sha1, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_bookstatitems?sha1=eq.${sha1}`, {
      method: 'patch',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book updated successfully"
    }));
    dispatch(updateNotebookSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateNotebookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update the book. Please try again."
    }));
  }
};

const updateNotebookSuccess = (payload) => ({
  type: Actions.UPDATE_NOTEBOOK_SUCCESS,
  payload
});

const updateNotebookFail = () => ({
  type: Actions.UPDATE_NOTEBOOK_FAIL
});

/**
 * All Books
 */

export const fetchAllBooks = (pagination, searchTerm = "") => async dispatch => {
  const rowCount = getStartAndEndRecord(pagination);
  try {
    const response = await makeApiCall(`fc_books?title=ilike.%${searchTerm}%&order=last_modified.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });
    
    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchAllBooksSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchAllBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch all books. Please try again."
    }));
  }
};

const fetchAllBooksSuccess = (payload) => ({
  type: Actions.FETCH_ALL_BOOKS_SUCCESS,
  payload
});

const fetchAllBooksFail = () => ({
  type: Actions.FETCH_ALL_BOOKS_FAIL
});

export const deleteBook = (bookSha, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_books?sha1=eq.${bookSha}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book deleted successfully"
    }));

    dispatch(deleteBookSuccess(response.data));
  } catch (error) {
    dispatch(deleteBookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete selected book. Please try again."
    }));
  }
};

const deleteBookSuccess = (payload) => ({
  type: Actions.DELETE_BOOK_SUCCESS,
  payload
});

const deleteBookFail = () => ({
  type: Actions.DELETE_BOOK_FAIL
});

export const updateBook = (sha1, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_books?sha1=eq.${sha1}`, {
      method: 'patch',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Book updated successfully"
    }));
    dispatch(updateBookSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateBookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update the book. Please try again."
    }));
  }
};

const updateBookSuccess = (payload) => ({
  type: Actions.UPDATE_BOOK_SUCCESS,
  payload
});

const updateBookFail = () => ({
  type: Actions.UPDATE_BOOK_FAIL
});



/**
 * Uploads
 */

export const uploadFile = (payload, extension, onSuccess) => async dispatch => {
  try {
    const { file } = payload;
    const prefix = extension === 'mp3' ? 'fullchinese/sounds' : 'images';
    const response = await makeApiCall(`s3/signed?resource=${prefix}/${uuid()}.${extension}`, {
      baseURL: fastapiUrl,
      method: "get"
    });
    const { fields: { key, AWSAccessKeyId, policy, signature, acl } } = response.data;
    const credentials = {
      key,
      AWSAccessKeyId,
      policy,
      signature,
      acl,
      file,
      extension
    };

    dispatch(uploadFormData(credentials, onSuccess));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with file upload. Please try again."
    }));
  }
};

const uploadFormData = (payload, onSuccess) => async dispatch => {
  try {
    const formData = new FormData();
    const {
      key,
      AWSAccessKeyId,
      policy,
      signature,
      acl,
      file,
      extension
    } = payload;

    formData.append("acl", acl);
    formData.append("Content-Type", extension);
    formData.append("key", key);
    formData.append("AWSAccessKeyId", AWSAccessKeyId);
    formData.append("policy", policy);
    formData.append("signature", signature);
    formData.append("file", file);
  
    await makeApiCall("/", {
      baseURL: "https://fullapi.s3.amazonaws.com",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData
    });

    if (typeof onSuccess === "function") {
      onSuccess(key);
    }

  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "There was a problem with your request. Please try again."
    }));
  }
};