import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeedList from "./components/FeedList";
import { resetCommon } from "../../common/actions/actions";

import {
  fetchPostFeeds,
  createPostFeed,
  updatePostFeed,
  removeFromPostsFeed
} from "./state/actions";

const FeedsContainer = () => {
  const dispatch = useDispatch();
  const { postFeeds } = useSelector(state => state.postFeeds);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPostFeeds({
      pageNum: 0,
      pageSize: 10
    }));
  }, [dispatch]);

  const handleFetchFeed = (pagination) => {
    dispatch(fetchPostFeeds(pagination));
  };

  const handleAddPostFeed = (payload, onSuccess, onFail) => {
    dispatch(createPostFeed(payload, onSuccess, onFail));
  };

  const handleDelete = (payload, onSuccess, onFail) => {
    dispatch(removeFromPostsFeed(payload, onSuccess, onFail));
  };

  return (
    <div>
      <FeedList
        data={postFeeds}
        onFetchFeed={handleFetchFeed}
        onAddPostFeed={handleAddPostFeed}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default FeedsContainer;
