import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import * as yup from "yup";

import Password from "../../components/Fields/Password";

import { resetCommon } from "../../common/actions/actions";
import { signIn } from "./state/actions";

import "./login.scss";

const schema = yup.object().shape({
  email: yup.string().required("This field cannot be empty"),
  password: yup.string().required("This field cannot be empty"),
});

const Login = () => {
  const dispatch = useDispatch();
  const { nextRoute } = useSelector(state => state.common);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);
  
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    localStorage.clear();
    setSubmitting(true);
    dispatch(signIn(data, () => setSubmitting(false)));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="login-container">
      <h3 className="title">Sign in to FullChinese</h3>
      <p className="sub-title">Please enter your details below</p>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="login-form">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Your Username or your Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter username or email address"
            ref={register}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="inValid">{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <Form.Label>Password</Form.Label>
          <div className="password-field">
            <Link to="/resetpassword">Forgot your password?</Link>
            <Password
              name="password"
              placeholder="Password"
              innerRef={register}
              isInvalid={!!errors.password}
              showIcon={!errors.password}
            />
          </div>
          <Form.Control.Feedback type="inValid">{errors.password?.message}</Form.Control.Feedback>
        </Form.Group>
        <div className="login-actions">
          <span>
            <label>Don't have an account? <Link to="/signup">Sign Up</Link></label>
          </span>
          <Button type="submit" disabled={submitting}>
            SIGN IN
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
