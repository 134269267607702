import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "./newsfeed-preview-modal.scss";

const extractFeedData = (info = []) => {
  const heading = info.find(({ blockType }) => blockType === 'heading1');
  const audio = info.find(({ blockType }) => blockType === 'audio');
  const image = info.find(({ blockType }) => blockType === 'image');
  const text = info.find(({ blockType }) => blockType === 'linkToText');

  return {
    heading: heading?.blockText?.text,
    audio: audio?.blockMedia?.remoteURL,
    image: image?.blockMedia?.remoteURL,
    text: text?.blockLinkToText?.content,
    link: text?.blockLinkToText?.blockLinkToPage
  };
};

const NewsFeedPreviewModal = (props) => {
  const {
    pageData = {},
    showModal,
    onClose,
    onPublishPage
  } = props;
  const { title, desc, thumbnail } = pageData;
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    if (typeof onPublishPage === "function") {
      onPublishPage(
        () => {
          setSubmitting(false);
          onClose();
        },
        () => {
          setSubmitting(false);
        }
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal size="lg" centered show={showModal} onHide={handleClose} className="newsfeed-preview-modal">
      <Modal.Header closeButton>
        <Modal.Title>Page Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="newsfeed-preview-body">
          <div className="newsfeed-preview-card">
            <h1 className="newsfeed-title">{title}</h1>
            {thumbnail && <img src={thumbnail} alt="newsfeed item" className="newsfeed-img" />}
            <div className="newsfeed-content">
              <h2>{desc}</h2>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" disabled={submitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" disabled={submitting} onClick={handleSubmit}>
          Publish
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewsFeedPreviewModal.propTypes = {
  pageData: PropTypes.object,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onPublishPage: PropTypes.func
};

export default NewsFeedPreviewModal;
