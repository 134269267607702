import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ChangeExpiryModal = ({ showModal, date, onClose, onExpiryDateChange  }) => {
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date(date));
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setSubmitting(true);

    onExpiryDateChange({
      plan_expire: expiryDate
    }, () => {
      setSubmitting(false);
      handleClose();
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Plan Expiry</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit} noValidate>
        <Modal.Body>
          <Form.Group controlId="datePicker">
            <Form.Label>Select Expiry Date</Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={expiryDate}
              onChange={(date) => setExpiryDate(date)}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={!expiryDate || submitting}>
            Change
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangeExpiryModal;

