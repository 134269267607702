import {
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASS_SUCCESS,
  UPDATE_CLASS_DATA,
  FETCH_CLASS_ROLE_SUCCESS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_BOOKS_SUCCESS,
  FETCH_CLASS_BOOKS_SUCCESS,
  FETCH_CLASS_BOOKS_SHA_SUCCESS,
  FETCH_CLASS_MEMBERS_SUCCESS,
  FETCH_TEXTS_SUCCESS,
  FETCH_CLASS_TEXTS_SUCCESS,
  FETCH_CLASS_TEXTS_SHA_SUCCESS
 } from "./action-types";

const initialValue = {
  classes: [],
  organizations: [],
  currentClass: {},
  classRole: "",
  classMembers: [],
  classBooks: [],
  books: [],
  bookSha: [],
  classTexts: [],
  texts:[]
};

const classesReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_CLASSES_SUCCESS:
      return {...state, classes: payload};
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {...state, organizations: payload};
    case FETCH_CLASS_SUCCESS:
      return {...state, currentClass: payload};
    case UPDATE_CLASS_DATA:
      return {
        ...state,
        currentClass: { ...state.currentClass, ...payload }
      };
    case FETCH_CLASS_ROLE_SUCCESS:
      return {
        ...state,
        classRole: payload
      };
    case FETCH_CLASS_MEMBERS_SUCCESS:
      return {
        ...state,
        classMembers: payload
      };
    case FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        books: payload
      };
    case FETCH_CLASS_BOOKS_SUCCESS:
      return {
        ...state,
        classBooks: payload
      };
    case FETCH_CLASS_BOOKS_SHA_SUCCESS:
      return {
        ...state,
        bookSha: payload
      };
    case FETCH_TEXTS_SUCCESS:
      return {
        ...state,
        texts: payload
      };
    case FETCH_CLASS_TEXTS_SUCCESS:
      return {
        ...state,
        classTexts: payload
      };
    case FETCH_CLASS_TEXTS_SHA_SUCCESS:
      return {
        ...state,
        textSha: payload
      };
    default:
      return state;
  }
};

export default classesReducer;
