import React from "react";
import { useSelector } from "react-redux";

import Header from "./Header/Header";
// import Notification from "../../Notification/Notification";
import Alert from "../../Alert";

import "./UserLayoutWide.scss";

const UserLayoutWide = (props) => {
  const { apiError, notice } = useSelector(state => state.common);
  return (
    <div className="user-layout-wide">
      <Header wideView={true} />
      <div className="content-container">
        {/*<Notification>
          <p>
            Premium Membership Free Trial ends on Nov 20, 2020 <br/>
            You will be charged $20.99 on Nov 20, 2020. Learn more.
          </p>
        </Notification>*/}
        <div className="content">{props.children}</div>
      </div>
      <Alert {...(apiError || notice)} />
    </div>
  );
};

export default UserLayoutWide;
