import {
  FETCH_TEXTS_SUCCESS,
  FETCH_ALL_TEXTS_SUCCESS,
  FETCH_CONFIG_SUCCESS
} from "./action-types";

const initialValue = {
  texts: {
    numRecords: 0,
    rows: []
  },
  allTexts: {
    numRecords: 0,
    rows: []
  },
  textShaList: [],
  config: {}
};

const getTextShaList = (texts) => texts.map(({ text_sha1 }) => text_sha1);

const deviceConfigReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_TEXTS_SUCCESS:
      return {...state, texts: payload, textShaList: getTextShaList(payload.rows)}
    case FETCH_CONFIG_SUCCESS:
      return {...state, config: payload}
    case FETCH_ALL_TEXTS_SUCCESS:
      return {...state, allTexts: payload}
    default:
      return state;
  }
};

export default deviceConfigReducer;
