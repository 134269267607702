import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DatePopover = ({ onSave }) => {
  const defaultDate = [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ];

  const [dates, setDates] = useState(defaultDate);

  const handleSave = () => {
    if (typeof onSave === "function") {
      const startDate = dayjs(dates[0].startDate).format('DD/MM/YYYY');
      const endDate = dayjs(dates[0].endDate).format('DD/MM/YYYY');
      onSave([startDate, endDate]);
    }
  };

  const handleClear = () => {
    setDates(defaultDate);
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <Popover>
          <Popover.Content>
            <div className="members-date-range">
              <DateRange
                editableDateInputs={true}
                onChange={item => setDates([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dates}
              />
              <div className="members-filter-footer">
                <Button variant="link" onClick={handleClear}>Clear</Button>
                <Button variant="link" onClick={handleSave}>Save</Button>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <Button variant="secondary">Joined</Button>
    </OverlayTrigger>
  );
};

DatePopover.propTypes = {
  onSave: PropTypes.func
};

export default DatePopover;
