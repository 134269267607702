import React, { Component } from "react";
import { connect } from "react-redux";
import { apiUrl } from "../../../config";
import "react-toastify/dist/ReactToastify.css";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationNew from "../../Pagination";

const axios = require("axios");

class Classes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgclass: [],
      loading: false,
      value: 0,
      obj: {},
      searchClass: "",
      hidenavStatus: false
    };
  }

  componentDidMount() {
    // this.getClass();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hidenav !== nextProps.hidenav) {
      this.setState({ hidenavStatus: this.props.hidenav });
    }
  }

  getClass = (start, end) => {
    let apiObj = {};
    apiObj.headers = {
      Accept: "application/json",
      Prefer: "count=exact",
      Range: `${start}-${end}`,
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.props.token
    };
    apiObj.method = "GET";
    let url = "fc_admin_class_members?select=*,class:fc_classes(*)&user_id=eq." + this.props.userid;
    url = apiUrl + url;
    apiObj.url = url;
    this.setState({ loading: true });
    axios(apiObj)
      .then(response => {
        let newArray = response.data;
        let temp = [];
        newArray.forEach(element => {
          temp.push(element.class);
        });
        this.setState({
          orgclass: temp,
          orgclasslength: response.headers["content-range"].split("/")[1],
          loading: true
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  searchClass = event => {
    this.setState({ searchClass: event.target.value });
  };

  searchButton = () => {
    this.getClass(0, 9);
  };

  //   goDetails = (cell, row) => {
  //     this.props.dispatch({ type: "CLASSID", payload: row.id });
  //     this.props.dispatch({ type: "ORGID", payload: row.org_id });
  //     this.props.history.push("/classes/details", {
  //       response: row
  //     });
  //   };

  changeCurrentData = (start, end) => {
    this.getClass(start, end);
  };

  render() {
    const nameAction = (cell, row) => {
      return <span>{cell}</span>;
    };

    const descriptionAction = (cell, row) => {
      return <span>{cell}</span>;
    };

    const membersAction = (cell, row) => {
      return (
        <span>
          {cell}/{row.max_members}
        </span>
      );
    };

    const booksAction = (cell, row) => {
      return (
        <span>
          {cell}/{row.max_books}
        </span>
      );
    };

    const modifiedAction = (cell, row) => {
      var year = cell.slice(0, 4);
      var month = cell.slice(5, 7);
      var day = cell.slice(8, 10);
      return (
        <span>
          {day}/{month}/{year}
        </span>
      );
    };

    const columns = [
      {
        dataField: "name",
        text: "",
        sort: true,
        formatter: nameAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Name</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "description",
        text: "",
        sort: true,
        formatter: descriptionAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Description</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "nb_members",
        text: "",
        sort: true,
        formatter: membersAction,

        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Members</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "nb_books",
        text: "",
        sort: true,
        formatter: booksAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Books</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      },
      {
        dataField: "last_modified",
        text: "",
        sort: true,
        formatter: modifiedAction,
        headerStyle: {
          fontWeight: "normal"
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {
            for (var i = 0; i < columns.length; i++) {
              columns[i].headerStyle = {
                fontWeight: "normal"
              };
            }
            column.headerStyle = {
              fontWeight: "bold"
            };
          }
        },
        sortCaret: (order, column) => {
          return (
            <div className="mb-image-content">
              <div>Modified</div>
              {order === "asc" ? (
                <img src={`/img/sorting-asc-active.svg`} alt="imae" />
              ) : order === "desc" ? (
                <img src={`/img/sorting-desc-active.svg`} alt="imae" />
              ) : (
                <img src={`/img/sorting.png`} alt="imae" />
              )}
            </div>
          );
        }
      }
    ];

    return (
      <div className="kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed">
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div>
            <div id="kt-wrapper" style={{ width: "100%" }}>
              <div id="kt-content">
                <div className="kt-container kt-container--fluid kt-grid__item kt-grid_-item--fluid">
                  <div className="btt-subheader">
                    <div className="kt-total-collections">
                      <span className="content-subheader-title">
                        {this.state.orgclasslength}
                        &nbsp;&nbsp;Classes
                      </span>
                    </div>
                    <div className="col-md-6 my-auto">
                      <div className="searchbox input-group md-form form-sm form-2 pl-0">
                        <input
                          name="org"
                          type="text"
                          className="form-control"
                          placeholder="Search class here"
                          id="search-input"
                          value={this.state.searchClass}
                          onChange={this.searchClass}
                          aria-label="Search"
                          onKeyPress={event => {
                            if (event.key === "Enter") {
                              this.searchButton();
                            }
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary input-group-text amber lighten-3"
                            id="basic-text1"
                            onClick={() => {
                              this.searchButton();
                            }}
                          >
                            <i className="fas fa-search text-grey" aria-hidden="true" style={{ width: 15 }}></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>
                  <div className="row">
                    <BootstrapTable
                      keyField="id"
                      data={this.state.orgclass}
                      columns={columns}
                      tabIndexCell
                      responsive
                      wrapperClasses="table-responsive"
                    />
                    <PaginationNew data={this.state.orgclasslength} changeCurrentData={this.changeCurrentData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.rpc.tokens,
    role: state.rpc.role,
    hidenav: state.rpc.hidenav,
    id: state.rpc.id,
    orgclass: state.rpc.orgclass
  };
};
export default connect(mapStateToProps)(Classes);
