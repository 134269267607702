import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import "./Header.scss";

const Header = ({ showMenu }) => {
  const clickMenu = () => {
    const menu = document.getElementById("mobileLinks");
    menu.style.display = "block";
  };

  const closeMenu = () => {
    const menu = document.getElementById("mobileLinks");
    menu.style.display = "none";
  };

  return (
    <div className="menubar flex-justify-center">
      <div className="elements-part flex-align-center">
        <div className="menubar-logo w-50">
          <Link to="/">
            <img src="/img/logo.svg" alt="" />
          </Link>
        </div>
        { showMenu && (
          <div className="menubar-actions w-50">
            <Link to="/plans">
              Pricing
            </Link>
            <a href="https://www.fullchinesehelp.com/about-us.html" target="_blank" rel="noopener noreferrer">About</a>
            <a href="https://www.fullchinesehelp.com/contact-us.html" target="_blank" rel="noopener noreferrer">Contact</a>
            <a href="https://www.fullchinesehelp.com/faq-by-topics.html" target="_blank" rel="noopener noreferrer">
              FAQ
            </a>
            <Link to="/login">
              Login
            </Link>
            <Link to="/signup">
              Sign Up
            </Link>
          </div>
          )
        }
        { showMenu && <i className="fas fa-bars w-50" onClick={clickMenu}></i> }
      </div>
      <div className="mobile-menubar" id="mobileLinks">
        <div className="mobile-menu-header">
          <div className="menubar-logo w-50">
            <Link to="/">
              <img src="/img/logo.svg" alt="logo" />
            </Link>
          </div>
          <button className="close-btn" onClick={closeMenu}>
            <img src="/img/close.png" alt="close" />
          </button>
        </div>
        
        <div className="menu-top">
          <ul>
            <li>
              <Link to="/plans">
                Pricing
              </Link>
            </li>
            <li>
              <a href="https://www.fullchinesehelp.com/about-us.html" target="_blank" rel="noopener noreferrer">
                About
              </a>
            </li>
            <li>
              <a href="https://www.fullchinesehelp.com/contact-us.html" target="_blank" rel="noopener noreferrer">
                Contact
              </a>
            </li>
            <li>
              <a href="https://www.fullchinesehelp.com/faq-by-topics.html" target="_blank" rel="noopener noreferrer">
                FAQ
              </a>
            </li>
            <li>
              <Link to="/login">
                Login
              </Link>
            </li>
          </ul>
        </div>
        <Button type="secondary" className="sign-up">
          <Link to="/signup">
            SIGN UP
          </Link>
        </Button>
      </div>
    </div>
  );
}

export default Header;
