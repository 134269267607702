import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import uuid from "uuid/v4";

import { makeApiCall } from "../api-client";
import { throwApiError } from "../../common/actions/actions";

const useImageUpload = (file) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(null);
  const [formData, setFormData] = useState(null);

  const uploadImage = async () => {
    try {
      const response = await makeApiCall(`s3/signed?resource=images/${uuid()}.png`, {
        baseURL: "https://server.fullchinese.com/fastapi/",
        method: "get"
      });
      
      return response;
    } catch(e) {
      dispatch(throwApiError({
        type: "toast",
        message: "Failed to upload image. Please try again."
      }));
    }
  };

  useEffect(() => {

    const getFields = async () => {
      if (file) {
        const response = await uploadImage();
        const fields = response?.data?.fields;
        if (fields) {
          const fData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            fData.append(key, value);
          });

          fData.append("AWSAccessKeyId", "AKIA5ZXHSS7RR2SMS54W");
          fData.append("file", file);
  
          setKey(fields.key);
          setFormData(fData);
        }
      }
    };

    getFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return [key, formData];
};

export default useImageUpload;
