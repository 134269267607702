import { makeApiCall } from "../../../helpers/api-client";
import { fastapiUrl } from "../../../config";
import * as Actions from "./action-types";

export const unsubscribeEmail = (payload, onSuccess, onFail) => async dispatch => {
  try {
    await makeApiCall('rpc/disable_email', {
      method: 'post',
      data: payload
    });

    dispatch(unsubscribeEmailSuccess());
    onSuccess();
  } catch (error) {
    dispatch(unsubscribeEmailFail());
    onFail();
  }
};

const unsubscribeEmailSuccess = () => ({
  type: Actions.UNSUBSCRIBE_EMAIL_SUCCESS
});

const unsubscribeEmailFail = () => ({
  type: Actions.UNSUBSCRIBE_EMAIL_FAIL
});
