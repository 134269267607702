import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import LinkButton from "../LinkButton/LinkButton";

import "./ActionIcon.scss";

const ActionIcon = ({ icon, label, to, onClick }) => {

  const innerContent = (
    <Fragment>
      <img src={`/img/${icon}.svg`} className="size-24-24" alt={label} />
      <span>
        {label}
      </span>
    </Fragment>
  );

  return to ? (
    <Link className="action-icon" to={to}>
      {innerContent}
    </Link>
  ) : (
    <LinkButton className="action-icon" onClick={onClick}>
      {innerContent}
    </LinkButton>
  );
};

ActionIcon.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default ActionIcon;
