import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({
  timeline: yup
    .string()
    .required("This field cannot be empty")
});

const TimestampEditModal = ({ showModal, values, onClose, onTimestampChange  }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: values
  });

  useEffect(() => {
    reset(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onSubmit = (val) => {
    const { id } = values;
    setSubmitting(true);
    onTimestampChange({
      id,
      ...val
    }, () => {
      setSubmitting(false);
      handleClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Timestamp</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="signup-form">
        <Modal.Body>
          <Form.Group controlId="timeline">
            <Form.Label>Timestamp</Form.Label>
            <Form.Control
              name="timeline"
              type="text"
              placeholder="Enter new timestamp here"
              ref={register}
              isInvalid={!!errors.timeline}
            />
            <Form.Control.Feedback type="inValid">{errors.timeline?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Change
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TimestampEditModal;


