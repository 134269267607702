import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../components/LinkButton/LinkButton";

import { resetCommon } from "../../../common/actions/actions";
import useImageUpload from "../../../helpers/hooks/use-image-upload";
import { createOrganization, uploadOrgImage } from "../state/actions";

import "./new-organization.scss";

const schema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty"),
  max_members: yup.number().required("This field cannot be empty"),
  max_books: yup.number().required("This field cannot be empty"),
  max_classes: yup.number().required("This field cannot be empty")
});

const NewOrganization = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { nextRoute } = useSelector(state => state.common);
  const [orgImage, setOrgImage] = useState("/img/book-cover.png");
  const [orgImageFile, setOrgImageFile] = useState();

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const [key, formData] = useImageUpload(orgImageFile);

  useEffect(() => {
    if (key) {
      dispatch(uploadOrgImage(formData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const handleChangeOrgImage = (ev) => {
    const file = ev.target.files[0];
    setOrgImageFile(file);
    setOrgImage(URL.createObjectURL(file));
  };

  const onSubmit = (data) => {
    dispatch(createOrganization({...data, image: key }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="new-organization">
      <div className="header">
        <LinkButton onClick={() => history.goBack()}>
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <img src={orgImage} alt="organization logo" />
        <h3>New Organization</h3>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Organization Name</Form.Label>
            <Col xs="8">
              <Form.Control
                name="name"
                type="text"
                placeholder="Enter Organization Name"
                ref={register}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Description</Form.Label>
            <Col xs="8">
              <Form.Control
                sm="10"
                name="description"
                as="textarea"
                placeholder="Enter Organization Description"
                ref={register}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Organization Cover</Form.Label>
            <Col xs="8">
              <img src={orgImage} alt="organization logo" className="org-img"/>
              <Form.File label="Upload Image" onChange={handleChangeOrgImage} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Max Members</Form.Label>
            <Col xs="8">
              <Form.Control
                name="max_members"
                type="number"
                placeholder="Enter max members"
                ref={register}
                isInvalid={!!errors.max_members}
              />
              <Form.Control.Feedback type="inValid">{errors.max_members?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Max Books</Form.Label>
            <Col xs="8">
              <Form.Control
                name="max_books"
                type="number"
                placeholder="Enter max books"
                ref={register}
                isInvalid={!!errors.max_books}
              />
              <Form.Control.Feedback type="inValid">{errors.max_books?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Max Classes</Form.Label>
            <Col xs="8">
              <Form.Control
                name="max_classes"
                type="number"
                placeholder="Enter max classes"
                ref={register}
                isInvalid={!!errors.max_classes}
              />
              <Form.Control.Feedback type="inValid">{errors.max_classes?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className="actions">
            <Button type="submit">
              Create
            </Button>
            <Button type="button" variant="outline-primary" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NewOrganization;
