import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError } from "../../../common/actions/actions";
import { fastapiUrl } from "../../../config";
import * as Actions from "./action-types";

export const getPlans = () => async dispatch => {
  try {
    const response = await makeApiCall(`fc_plans?order=id.asc`);
    dispatch(getPlansSuccess(response.data));
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to fetch plans. Please try again later."
    }));
  }
};

const getPlansSuccess = (payload) => ({
  type: Actions.GET_PLANS_SUCCESS,
  payload
});

export const verifyCouponCode = (payload) => async dispatch => {
  dispatch(resetCouponCode());
  try {
    const response = await makeApiCall("coupoun/verify", {
      baseURL: fastapiUrl,
      method: 'post',
      data: payload
    });
    if (response.data?.is_valid) {
      dispatch(verifyCouponCodeSuccess(response.data));
    } else {
      dispatch(throwApiError({
        type: "alert",
        message: "Invalid Coupon Code"
      }));
    }
  } catch (e) {
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to verify coupon"
    }));
  }
};

const resetCouponCode = () => ({
  type: Actions.RESET_COUPON_CODE
});

const verifyCouponCodeSuccess = (payload) => ({
  type: Actions.VERIFY_COUPON_CODE_SUCCESS,
  payload
});

export const redeemCoupon = (payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall("coupoun/pay", {
      baseURL: fastapiUrl,
      method: 'post',
      data: payload
    });
    if (response.data.success) {
      dispatch(redeemSuccess());
    }
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    let message = "Failed to apply coupon";
    if (e?.response?.status === 422) {
      message = "This coupon has already been redeemed.";
    }

    dispatch(throwApiError({
      type: "alert",
      message
    }));

    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const redeemSuccess = (payload) => ({
  type: Actions.REDEEM_SUCCESS,
  payload
});

export const resetRedeemStatus = () => ({
  type: Actions.RESET_REDEEM_STATUS
});
 
export const subscribe = (payload) => ({
  type: Actions.PLAN_SUBSCRIBE,
  payload
});