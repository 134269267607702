import { makeApiCall } from "../../../helpers/api-client";
import { setNextRoute, throwApiError } from "../../../common/actions/actions";
import { setResetEmail } from "../../Login/state/actions";

export const resetPassword = (payload) => async dispatch => {
  try {
    const response = await makeApiCall("rpc/request_reset_token", {
      method: 'post',
      data: payload
    });
    dispatch(setResetEmail(response.data.email));
    dispatch(setNextRoute("/resetpassword/notice"));
  } catch (error) {
    dispatch(throwApiError({
      type: "alert",
      message: "The email address entered is not associated with an account."
    }));
  }
};
