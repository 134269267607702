export const THROW_API_ERROR = "THROW_API_ERROR";
export const RESET_API_ERROR = "RESET_API_ERROR";

export const SHOW_NOTICE = "SHOW_NOTICE";
export const RESET_NOTICE = "RESET_NOTICE";
export const RESET_ALL_NOTICES = "RESET_ALL_NOTICES";

export const SET_NEXT_ROUTE = "SET_NEXT_ROUTE";
export const RESET_NEXT_ROUTE = "RESET_NEXT_ROUTE";
export const RESET_ALL = "RESET_ALL";
