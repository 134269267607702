import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import ActionModal from "../../../components/ActionModal/ActionModal";
import MoreItems from "../../../components/MoreItems/MoreItems";
import LinkButton from "../../../components/LinkButton/LinkButton";

const Texts = ({ texts, onSearch, onDelete }) => {
  const [recordIdToDelete, setToDeleteRecordId] = useState(false);

  const handleDelete = () => {
    if (recordIdToDelete) {
      onDelete(recordIdToDelete, () => setToDeleteRecordId(null));
    }
  };

  const formatDate = (row) => {
    const { last_modified } = row;
    return dayjs(last_modified).format('DD/MM/YYYY');
  };

  const renderLink = ({ row }) => {
    const { book_sha1: id, text_sha1 } = row;
    return (
      <div className="grid-actions">
        <Link to={`/texts/details/${text_sha1}`}>Details</Link>
        <MoreItems>
          <LinkButton onClick={() => setToDeleteRecordId(id)}>Delete</LinkButton>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'text_title', headerName: 'Title', width: 400 },
    { field: 'book_title', headerName: 'Book/Notebook', width: 250 },
    { field: 'author', headerName: 'Author', width: 250 },
    { field: 'last_modified', headerName: 'Modified', width: 250, valueGetter: formatDate },
    { field: 'id', headerName: '.', width: 300, renderCell: renderLink}
  ];

  return (
    <div className="organization-books">
      <SearchHeader
        count={texts.length}
        countTitle="Texts"
        placeHolder="Search texts"
        onSearch={onSearch}
      />
      <div className="books-results">
        <DataGrid
          className="books-grid"
          autoHeight={true}
          rows={texts}
          columns={columns}
          pageSize={10}
          getRowId={({ sha1 }) => sha1}
        />
      </div>
      <ActionModal
        showModal={!!recordIdToDelete}
        title="Delete Text"
        actionText="Delete"
        onClose={() => setToDeleteRecordId(null)}
        onAction={handleDelete}>
          Do you want to delete this text?
      </ActionModal>
    </div>
  );
};

Texts.propTypes = {
  texts: PropTypes.array,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func
};

export default Texts;
