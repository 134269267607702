import React from "react";
import PropTypes from "prop-types";
import "./preview-card.scss";

const PreviewCard = ({ title, thumbnail, description }) => {
  return (
    <div className="newsfeed-preview">
      <div className="newsfeed-preview-card">
        {
          thumbnail ? (
            <>
              <h1 className="newsfeed-title">{title}</h1>
              {thumbnail && <img src={thumbnail} alt="newsfeed item" className="newsfeed-img" />}
              <div className="newsfeed-content">
                <h2>{description}</h2>
              </div>
            </>
          ) : (
            <p>
              No Preview available - Missing thumbnail
            </p>
          )
        }
        
      </div>
    </div>
  )
};

PreviewCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string
};

export default PreviewCard;