import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError, showNotice } from "../../../common/actions/actions";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { fastapiUrl } from "../../../config";

import * as Actions from "./action-types";

export const fetchNewsfeed = (pagination) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall("fc_news_feeds", {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchNewsfeedSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchNewsfeedFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not load news feed. Please try again."
    }));
  }
};

const fetchNewsfeedSuccess = (payload) => ({
  type: Actions.FETCH_NEWSFEED_SUCCESS,
  payload
});

const fetchNewsfeedFail = () => ({
  type: Actions.FETCH_NEWSFEED_FAIL
});


export const removeFromNewsfeed = (id, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_news_feeds?id=eq.${id}`, {
      method: 'delete'
    });
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(removeFromNewsfeedSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(removeFromNewsfeedFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to remove item from newsfeed. Please try again later."
    }));
  }
};

const removeFromNewsfeedSuccess = (payload) => ({
  type: Actions.REMOVE_FROM_NEWSFEED_SUCCESS,
  payload
});

const removeFromNewsfeedFail = () => ({
  type: Actions.REMOVE_FROM_NEWSFEED_FAIL
});


export const updateNewsfeed = (id, payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_news_feeds?id=eq.${id}`, {
      method: "patch",
      data: payload
    });

    dispatch(updateNewsfeedSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }

  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateNewsfeedFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to update timestamp. Please try again later."
    }));
  }
};

const updateNewsfeedSuccess = (payload) => ({
  type: Actions.UPDATE_NEWSFEED_SUCCESS,
  payload
});

const updateNewsfeedFail = () => ({
  type: Actions.UPDATE_NEWSFEED_FAIL
});

export const fetchPagePreviewData = (pageSha1, onSuccess, onFail) => async (dispatch) => {
  dispatch(resetPagePreviewData());
  try {
    const response = await makeApiCall(`fatjson/newsfeeds?pageSha1=${pageSha1}`, {
      baseURL: fastapiUrl,
      method: "get"
    });

    dispatch(fetchPagePreviewDataSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(fetchPagePreviewDataFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to publish to news feed. Please try again later."
    }));
  }
};

const resetPagePreviewData = () => ({
  type: Actions.RESET_PREVIEW_DATA
});

const fetchPagePreviewDataSuccess = (payload) => ({
  type: Actions.FETCH_NEWSFEED_ITEM_SUCCESS,
  payload
});

const fetchPagePreviewDataFail = () => ({
  type: Actions.FETCH_NEWSFEED_ITEM_FAIL
});
