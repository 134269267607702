import {
  FETCH_VIDEOS_SUCCESS,
  FETCH_SELECTED_VIDEO_SUCCESS,
  FETCH_DISCOVERY_VIDEOS_SUCCESS,
  FETCH_USER_TEXTS_SUCCESS
} from "./action-types";

const initialValue = {
  videos: {
    numRecords: 0,
    rows: []
  },
  discoveryVideos: {
    numRecords: 0,
    rows: []
  },
  selectedVideo: {},
  videoTexts: {
    numRecords: 0,
    rows: []
  }
};

const videosReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_VIDEOS_SUCCESS:
      return {...state, videos: payload}
    case FETCH_SELECTED_VIDEO_SUCCESS:
      return {...state, selectedVideo: payload}
    case FETCH_DISCOVERY_VIDEOS_SUCCESS:
      return {...state, discoveryVideos: payload}
    case FETCH_USER_TEXTS_SUCCESS:
      return {...state, videoTexts: payload}
    default:
      return state;
  }
};

export default videosReducer;
