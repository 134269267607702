import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "./youtube-url-form.scss";

function getYoutubeID(url){
  var ID = '';
  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if(url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
  return ID;
}

const schema = yup.object().shape({
  videoUrl: yup.string().url().required("This field cannot be empty")
});

const YoutubeURLForm = ({ onAddVideo }) => {

  const [submitting, setSubmitting] = useState(false)

  const { register, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    const { videoUrl } = values;
    const videoId = getYoutubeID(videoUrl);

    setSubmitting(true);
    onAddVideo(videoId, () => {
      setSubmitting(false)
      reset()
    }, () => {
      setSubmitting(false)
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate className="youtube-url-form">
      <Form.Group as={Row} className="form-row">
        <Form.Label column xs="4">Youtube Video URL</Form.Label>
        <Col xs="8">
          <div className="horizontal-form">
            <div className="url-field">
              <Form.Control
                name="videoUrl"
                type="text"
                placeholder="Enter video URL here"
                ref={register}
                isInvalid={!!errors.videoUrl}
              />
              <Form.Control.Feedback type="inValid">{errors.videoUrl?.message}</Form.Control.Feedback>
            </div>
            <div className="inline-actions">
              <Button type="submit" block disabled={submitting}>
                Add
              </Button>
            </div>
          </div>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default YoutubeURLForm;
