import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { throwApiError, showNotice } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchPostFeeds = (pagination) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall("fc_user_post_feeds", {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchPostFeedsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchPostFeedsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch post feeds. Please try again."
    }));
  }
};

const fetchPostFeedsSuccess = (payload) => ({
  type: Actions.FETCH_POST_FEEDS_SUCCESS,
  payload
});

const fetchPostFeedsFail = () => ({
  type: Actions.FETCH_POST_FEEDS_FAIL
});

/* Create new */

export const createPostFeed = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall("fc_post_feeds", {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Successfully added feed"
    }));
    dispatch(createPostFeedSuccess(response));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(createPostFeedFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not add feed. Please try again."
    }));
  }
};

const createPostFeedSuccess = (payload) => ({
  type: Actions.CREATE_POST_FEEDS_SUCCESS,
  payload
});

const createPostFeedFail = () => ({
  type: Actions.CREATE_POST_FEEDS_FAIL
});


/*
Update from Post Feed
*/

export const updatePostFeed = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const { id, ...otherPayload } = payload;
    const response = await makeApiCall(`fc_post_feeds?id=eq.${id}`, {
      method: 'patch',
      data: otherPayload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(updatePostFeedSuccess(response));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updatePostFeedFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update post feed. Please try again."
    }));
  }
};

const updatePostFeedSuccess = (payload) => ({
  type: Actions.UPDATE_POST_FEED_SUCCESS,
  payload
});

const updatePostFeedFail = () => ({
  type: Actions.UPDATE_POST_FEED_FAIL
});

/*
Remove from Post Feed
*/

export const removeFromPostsFeed = (id, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_post_feeds?feed_owner=eq.${id}`, {
      method: 'delete'
    });
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(removeFromPostsFeedSuccess(response.data));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(removeFromPostsFeedFail());
    dispatch(showNotice({
      type: "alert",
      alertType: "error",
      message: "Not able to remove item. Please try again later."
    }));
  }
};
  
const removeFromPostsFeedSuccess = (payload) => ({
  type: Actions.REMOVE_FROM_POST_FEEDS_SUCCESS,
  payload
});

const removeFromPostsFeedFail = () => ({
  type: Actions.REMOVE_FROM_POST_FEEDS_FAIL
});



