import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import { DataGrid } from "@material-ui/data-grid";

import ActionIcon from "../../components/ActionIcon/ActionIcon";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchOrganizations
} from "./state/actions";

import "./organization.scss";

const handleImgError = (ev) => {
  ev.target.src = "/img/newavatar.png";
};

const formatName = ({ row }) => {
  const { name, image } = row;
  const src = image ? `https://fullapi.s3.amazonaws.com/${row.image}` : "/img/book-cover.png";
  return (
    <span>
      <img src={src} alt="avatar" className="size-24-24" onError={handleImgError} /> {name}
    </span>
  );
};

const formatDate = (row) => {
  const { last_modified } = row;
  return dayjs(last_modified).format('DD/MM/YYYY');
};

const renderLink = ({ row }) => {
  const { id } = row;
  return (
    <Link to={`/organization/details/${id}`}>Details</Link>
  );
}

const columns = [
  { field: 'name', headerName: 'Name', width: 150, renderCell: formatName },
  { field: 'description', headerName: 'Description', width: 300 },
  { field: 'nb_members', headerName: 'Members', width: 150 },
  { field: 'nb_books', headerName: 'Books', width: 150 },
  { field: 'nb_classes', headerName: 'Classes', width: 150 },
  { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatDate },
  { field: 'id', headerName: '-', width: 100, renderCell: renderLink}
];

const Organization = () => {
  const dispatch = useDispatch();
  const { role } = useSelector(state => state.auth);
  const { organizations } = useSelector(state => state.organizations);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const composeFilters = () => {
    return `name=ilike.%25${searchTerm}%25&order=last_modified.desc`;
  };

  useEffect(() => {
    dispatch(fetchOrganizations(composeFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    dispatch(fetchOrganizations(composeFilters()));
  };

  const handleSearchInput = (ev) => {
    setSearchTerm(ev.target.value);
  };

  return (
    <div className="organizations">
      <div className="header">
        <h3>{organizations.length} Organizations</h3>
        <div className="organizations-search">
          <div className="search-bar">
            <input
              type="text"
              className="search-input"
              placeholder="Search organization here"
              onChange={handleSearchInput} />
            <Button onClick={handleSearch}>
              <img src="/img/magnifying-glass-black.png" alt="search" />
            </Button>
          </div>
        </div>
        <div>
          {(role === "gk_admin" || role === "support") && <ActionIcon icon="action-add-folder" label="NEW ORGANIZATION" to="/organization/new" />}
        </div>
      </div>
      <div className="organizations-results">
        <DataGrid
          className="organizations-grid"
          autoHeight={true}
          rows={organizations}
          columns={columns}
          pageSize={10}
         />
      </div>
    </div>
  );
};

export default Organization;