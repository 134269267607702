import { GET_SUBSCRIPTION_SUCCESS, GET_SUBSCRIPTION_PRICE_SUCCESS } from "../constants/actions";

const initialState = {
  subscription: {},
  plan: {}
};

const subscriptionReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_SUBSCRIPTION_SUCCESS:
      return {...state, subscription: payload};
    case GET_SUBSCRIPTION_PRICE_SUCCESS:
      return {...state, plan: payload};
    default:
      return state;
  }
};

export default subscriptionReducer;