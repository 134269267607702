import React from "react";
import { Link } from "react-router-dom";

import HamburgerMenu from "./HamburgerMenu";

import "./HeaderMobile.scss";

const HeaderMobile = (props) => {

  return (
    <div className="header-mobile">
      <Link to="/user/home">
        <img alt="Logo" src="/img/logo-white.svg" />
      </Link>
      <HamburgerMenu {...props} />
    </div>
  );
};

export default HeaderMobile;