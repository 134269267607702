import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({
  feed_owner: yup
    .number()
    .required("This field cannot be empty"),
  level: yup
    .number()
    .required("This field cannot be empty"),
  topics: yup
    .string()
    .required("This field cannot be empty"),
  author: yup
    .string()
});

const AddPostFeedModal = ({ showModal, onClose, onAddPostFeed }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (payload) => {
    setSubmitting(true);
    
    const finalPayload = {
      ...payload,
      topics: payload.topics.split(",")
    };

    onAddPostFeed(finalPayload, () => {
      setSubmitting(false);
      handleClose();
    }, () => {
      setSubmitting(false);
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Post Feed</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <Form.Group controlId="feed_owner">
            <Form.Label>Feed Owner ID</Form.Label>
            <Form.Control
              name="feed_owner"
              type="number"
              placeholder="Feed Owner ID"
              ref={register}
              isInvalid={!!errors.feed_owner}
            />
            <Form.Control.Feedback type="inValid">{errors.feed_owner?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Level</Form.Label>
            <Form.Control
              name="level"
              type="number"
              placeholder="Level"
              ref={register}
              isInvalid={!!errors.level}
            />
            <Form.Control.Feedback type="inValid">{errors.level?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="topics">
            <Form.Label>Topics</Form.Label>
            <Form.Control
              name="topics"
              type="text"
              placeholder="Enter comma separated topics"
              ref={register}
              isInvalid={!!errors.topics}
            />
            <Form.Control.Feedback type="inValid">{errors.topics?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="author">
            <Form.Label>Author</Form.Label>
            <Form.Control
              name="author"
              type="text"
              placeholder="Enter author name"
              ref={register}
              isInvalid={!!errors.author}
            />
            <Form.Control.Feedback type="inValid">{errors.author?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={submitting}>
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddPostFeedModal;

