import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { fastapiUrl } from "../../../config";
import { throwApiError, showNotice } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchVideos = (pagination) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall("fc_youtube_videos?select=video_string_id,url,download_time,title,sha1", {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchVideosSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchVideosFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch videos. Please try again."
    }));
  }
};

const fetchVideosSuccess = (payload) => ({
  type: Actions.FETCH_VIDEOS_SUCCESS,
  payload
});

const fetchVideosFail = () => ({
  type: Actions.FETCH_VIDEOS_FAIL
});

export const fetchSelectedVideo = (sha1) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_youtube_videos?sha1=eq.${sha1}&select=video_string_id,url,download_time,title,sha1,thumbnails,comment_counts,likes,view_counts,transcripts`);

    const { data } = response

    dispatch(fetchSelectedVideoSuccess(data[0]));
  } catch (error) {
    dispatch(fetchSelectedVideoFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch video. Please try again."
    }));
  }
};

const fetchSelectedVideoSuccess = (payload) => ({
  type: Actions.FETCH_SELECTED_VIDEO_SUCCESS,
  payload
});

const fetchSelectedVideoFail = () => ({
  type: Actions.FETCH_SELECTED_VIDEO_FAIL
});


export const fetchDiscoveryVideos = (pagination, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_discovery_videos?order=last_modified.desc&title=ilike.%25${searchTerm}%25`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchDiscoveryVideosSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchDiscoveryVideosFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch discovery videos. Please try again."
    }));
  }
};

const fetchDiscoveryVideosSuccess = (payload) => ({
  type: Actions.FETCH_DISCOVERY_VIDEOS_SUCCESS,
  payload
});

const fetchDiscoveryVideosFail = () => ({
  type: Actions.FETCH_DISCOVERY_VIDEOS_FAIL
});

export const addDiscoveryVideo = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await makeApiCall("fc_admin_discovery_videos", {
      method: 'post',
      data: payload
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Successfully added video"
    }));
    dispatch(addDiscoveryVideoSuccess(response));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(addDiscoveryVideoFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not add discovery video. Please try again."
    }));
  }
};

const addDiscoveryVideoSuccess = (payload) => ({
  type: Actions.ADD_DISCOVERY_VIDEO_SUCCESS,
  payload
});

const addDiscoveryVideoFail = () => ({
  type: Actions.ADD_DISCOVERY_VIDEO_FAIL
});

export const updateDiscoveryVideo = (payload, onSuccess, onFail) => async (dispatch) => {
  try {
    const { video_text_sha1, ...props } = payload;
    const response = await makeApiCall(`fc_admin_discovery_videos?video_text_sha1=eq.${video_text_sha1}`, {
      method: 'patch',
      data: props
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(updateDiscoveryVideoSuccess(response));
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(updateDiscoveryVideoFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update discovery video. Please try again."
    }));
  }
};

const updateDiscoveryVideoSuccess = (payload) => ({
  type: Actions.UPDATE_DISCOVERY_VIDEO_SUCCESS,
  payload
});

const updateDiscoveryVideoFail = () => ({
  type: Actions.UPDATE_DISCOVERY_VIDEO_FAIL
});

export const deleteSelectedVideo = (sha1, onSuccess) => async (dispatch) => {
  try {
    const response = await makeApiCall(`fc_admin_discovery_videos?video_text_sha1=eq.${sha1}`, {
      method: 'delete'
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }
    dispatch(deleteSelectedVideoSuccess(response.data));
  } catch (error) {
    dispatch(deleteSelectedVideoFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete selected video. Please try again."
    }));
  }
};

const deleteSelectedVideoSuccess = (payload) => ({
  type: Actions.DELETE_DISCOVERY_VIDEO_SUCCESS,
  payload
});

const deleteSelectedVideoFail = () => ({
  type: Actions.DELETE_DISCOVERY_VIDEO_FAIL
});

export const fetchUserTexts = (pagination, searchTerm) => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall("fc_texts?video_media_sha1=not.is.null&select=title,sha1", {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { data, headers } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchUserTextsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(fetchUserTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch texts. Please try again."
    }));
  }
};

const fetchUserTextsSuccess = (payload) => ({
  type: Actions.FETCH_USER_TEXTS_SUCCESS,
  payload
});

const fetchUserTextsFail = () => ({
  type: Actions.FETCH_USER_TEXTS_FAIL
});

export const addDiscoveryVideoYoutube = (videoId, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`/fatjson/youtube_texts?videoId=${videoId}&add_to_discovery=1`, {
      baseURL: fastapiUrl
    });

    if (typeof onSuccess === "function") {
      onSuccess();
    }

    dispatch(showNotice({
      type: "alert",
      alertType: "success",
      message: "Successfully added video"
    }));
    dispatch(addDiscoveryVideoYoutubeSuccess(response));
  } catch (error) {
    let errMsg = "Could not add discovery video. Please try again.";

    if (error?.response?.data?.detail?.code === "Y000") {
      errMsg = "Duplicate record"
    }
    dispatch(throwApiError({
      type: "toast",
      message: errMsg
    }));
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(addDiscoveryVideoYoutubeFail());
  }
};

const addDiscoveryVideoYoutubeSuccess = (payload) => ({
  type: Actions.ADD_DISCOVERY_VIDEO_YOUTUBE_SUCCESS,
  payload
});

const addDiscoveryVideoYoutubeFail = () => ({
  type: Actions.ADD_DISCOVERY_VIDEO_YOUTUBE_FAIL
});
