export const FETCH_PAYMENTS_SUCCESS = "app/payments/FETCH_PAYMENTS_SUCCESS";

export const FETCH_COUPONS_SUCCESS = "app/payments/FETCH_COUPONS_SUCCESS";
export const ADD_NEW_COUPON_SUCCESS = "app/payments/ADD_NEW_COUPON_SUCCESS";
export const UPDATE_COUPON_SUCCESS = "app/payments/UPDATE_COUPON_SUCCESS";
export const DELETE_COUPON_SUCCESS = "app/payments/DELETE_COUPON_SUCCESS";

export const FETCH_PLANS_SUCCESS = "app/payments/FETCH_PLANS_SUCCESS";
export const ADD_NEW_PLAN_SUCCESS = "app/payments/ADD_NEW_PLAN_SUCCESS";
export const UPDATE_PLAN_SUCCESS = "app/payments/UPDATE_PLAN_SUCCESS";
export const DELETE_PLAN_SUCCESS = "app/payments/DELETE_PLAN_SUCCESS";
