import { makeApiCall } from "../../../helpers/api-client";
import { getStartAndEndRecord } from "../../../helpers/utils";
import { throwApiError } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchPayments = (pagination, searchTerm = "") => async dispatch => {
  try {
    const rowCount = getStartAndEndRecord(pagination);
    const response = await makeApiCall(`fc_admin_sub_payments?or=(name.ilike.%${searchTerm}%,email.ilike.%${searchTerm}%)&order=last_modified.desc`, {
      headers: {
        Prefer: "count=exact",
        Range: rowCount
      }
    });

    const { headers, data } = response;
    const { "content-range": contentRange } = headers;
    const numRecords = parseInt(contentRange.split("/")[1]);

    dispatch(fetchPaymentsSuccess({
      numRecords,
      rows: data
    }));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch payments. Please try again."
    }));
  }
};

const fetchPaymentsSuccess = (payload) => ({
  type: Actions.FETCH_PAYMENTS_SUCCESS,
  payload
});

export const fetchCoupons = (searchTerm = "") => async dispatch => {
  try {
    const response = await makeApiCall(`fc_coupouns?code=ilike.${searchTerm}%&order=last_modified.desc`);
    dispatch(fetchCouponsSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch coupons. Please try again."
    }));
  }
};

const fetchCouponsSuccess = (payload) => ({
  type: Actions.FETCH_COUPONS_SUCCESS,
  payload
});

export const addNewCoupon = (payload, onComplete) => async dispatch => {
  try {
    const response = await makeApiCall("fc_coupouns", {
      method: 'post',
      data: payload
    });
    dispatch(addNewCouponSuccess(response.data));

    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (error) {
    if (typeof onComplete === "function") {
      onComplete();
    }
    dispatch(throwApiError({
      type: "toast",
      message: "Could not add new coupon. Please try again."
    }));
  }
};

const addNewCouponSuccess = (payload) => ({
  type: Actions.ADD_NEW_COUPON_SUCCESS,
  payload
});

export const updateCoupon = (id, payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_coupouns?id=eq.${id}`, {
      method: 'patch',
      data: payload
    });
    dispatch(updateCouponSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    if (typeof onFail === "function") {
      onFail();
    }
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update coupon. Please try again."
    }));
  }
};

const updateCouponSuccess = (payload) => ({
  type: Actions.UPDATE_COUPON_SUCCESS,
  payload
});


export const deleteCoupon = (id, onComplete) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_coupouns?id=eq.${id}`, {
      method: 'delete'
    });
    dispatch(deleteCouponSuccess(response.data));
    if (typeof onComplete === "function") {
      onComplete();
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete coupon. Please try again."
    }));
    if (typeof onComplete === "function") {
      onComplete();
    }
  }
};

const deleteCouponSuccess = (payload) => ({
  type: Actions.DELETE_COUPON_SUCCESS,
  payload
});

export const fetchPlans = (searchTerm = "") => async dispatch => {
  try {
    const response = await makeApiCall(`fc_plans?name=ilike.%${searchTerm}%&order=id.asc`);
    dispatch(fetchPlansSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch plans. Please try again."
    }));
  }
};

const fetchPlansSuccess = (payload) => ({
  type: Actions.FETCH_PLANS_SUCCESS,
  payload
});

export const addNewPlan = (payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall("fc_plans", {
      method: 'post',
      data: payload
    });
    dispatch(addNewPlanSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not add new plan. Please try again."
    }));
    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const addNewPlanSuccess = (payload) => ({
  type: Actions.ADD_NEW_PLAN_SUCCESS,
  payload
});

export const updatePlan = (id, payload, onSuccess, onFail) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_plans?id=eq.${id}`, {
      method: 'patch',
      data: payload
    });
    dispatch(updatePlanSuccess(response.data));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not update plan. Please try again."
    }));
    if (typeof onFail === "function") {
      onFail();
    }
  }
};

const updatePlanSuccess = (payload) => ({
  type: Actions.UPDATE_PLAN_SUCCESS,
  payload
});


export const deletePlan = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_plans?id=eq.${id}`, {
      method: 'delete'
    });
    dispatch(deletePlanSuccess(response.data));
  } catch (error) {
    dispatch(throwApiError({
      type: "toast",
      message: "Could not delete plan. Please try again."
    }));
  }
};

const deletePlanSuccess = (payload) => ({
  type: Actions.DELETE_PLAN_SUCCESS,
  payload
});
