import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import SearchHeader from "../../../../components/SearchHeader/SearchHeader";

const renderLink = ({ row }) => {
  const { sha1, video_string_id } = row;

  return (
    <Link to={`/videos/details/${sha1}`}>{video_string_id}</Link>
  );
};

const renderUrl = ({ row }) => {
  const { url } = row;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
  );
};

const columns = [
  { field: 'video_string_id', headerName: 'Video String Id', width: 180, renderCell: renderLink },
  { field: 'url', headerName: 'URL', width: 200, renderCell: renderUrl },
  { field: 'download_time', headerName: 'Downloaded Time', width: 250 },
  { field: 'title', headerName: 'Title', width: 400 }
];

const VideoList = ({ data, onFetchVideos }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchVideos({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchVideos({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchVideos({ pageNum: 0, pageSize }, searchTerm);
  };

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Videos"
        placeHolder="Search videos here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
    </div>
  );
};

VideoList.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchVideos: PropTypes.func
};

export default VideoList;
