import React from "react";
import PropTypes from "prop-types";

const UserType = ({ onStepComplete }) => {

  const handleChange = (val) => {
    onStepComplete({
      role: val
    });
  };

  return (
    <div className="condition-container">
      <div className="condition-content">
        <h3>Tell us who you are</h3>
        <p className="sub-text">Please choose an option below:</p>
        <div className="user-selection">
          <div className="user-radio" onClick={() => handleChange('student')}>
            <img src="/img/icon-onboarding-student.svg" alt="icon"/>
            <span>Student</span>
          </div>
          <div className="user-radio" onClick={() => handleChange('teacher')}>
            <img src="/img/icon-onboarding-teacher.svg" alt="icon" />
            <span>Teacher</span>
          </div>
        </div>
      </div>
    </div>
  );
};

UserType.propTypes = {
  onStepComplete: PropTypes.func
};

export default UserType;