export const FETCH_COLLECTIONS_SUCCESS = "app/store/FETCH_COLLECTIONS_SUCCESS";
export const FETCH_COLLECTIONS_FAIL = "app/store/FETCH_COLLECTIONS_FAIL";

export const FETCH_COLLECTION_BOOKS_SUCCESS = "app/store/FETCH_COLLECTION_BOOKS_SUCCESS";
export const FETCH_COLLECTION_BOOKS_FAIL = "app/store/FETCH_COLLECTION_BOOKS_FAIL";

export const CREATE_COLLECTION_SUCCESS = "app/store/CREATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION_FAIL = "app/store/CREATE_COLLECTION_FAIL";

export const UPDATE_COLLECTION_SUCCESS = "app/store/UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_FAIL = "app/store/UPDATE_COLLECTION_FAIL";

export const DELETE_COLLECTION_SUCCESS = "app/store/DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAIL = "app/store/DELETE_COLLECTION_FAIL";

export const FETCH_BOOKS_SUCCESS = "app/store/FETCH_BOOKS_SUCCESS";
export const FETCH_BOOKS_FAIL = "app/store/FETCH_BOOKS_FAIL";

export const ADD_BOOKS_SUCCESS = "app/store/ADD_BOOKS_SUCCESS";
export const ADD_BOOKS_FAIL = "app/store/ADD_BOOKS_FAIL";

export const UPDATE_BOOK_SUCCESS = "app/store/UPDATE_BOOK_SUCCESS";
export const UPDATE_BOOK_FAIL = "app/store/UPDATE_BOOK_FAIL";

export const REMOVE_BOOK_SUCCESS = "app/store/REMOVE_BOOK_SUCCESS";
export const REMOVE_BOOK_FAIL = "app/store/REMOVE_BOOK_FAIL";
