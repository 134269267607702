import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../components/LinkButton/LinkButton";
import EditCharacterModal from "./EditCharacterModal";
import Definitions from "./Definitions";

import { resetCommon } from "../../../common/actions/actions";
import {
  fetchCharacter,
  updateCharacter,
  fetchCharacterStrokes,
  fetchCharacterInfo,
  fetchCharacterDef,
  fetchComponents,
  fetchWordsWithChar,
  fetchSentencesWithChar,
  submitFile,
  updateWord
} from "../state/actions";

import { mediaUrl } from "../../../config";

import "./Character.scss";

const Character = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { role } = useSelector(state => state.auth);
  const { activeCharacter } = useSelector(state => state.dictionary);
  const { list, stroke, components, words, sentences, defs } = activeCharacter;
  const {
    hsk_level,
    character,
    traditional,
    simplified,
    mnemonic,
    explanation
  } = list?.[0] || {};

  const [showEditModal, setShowEditModal] = useState(false);
  const [sortedDefs, setSortedDefs] = useState([]);
  const [selectedDef, setSelectedDef] = useState(null);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const loadChar = useCallback((id) => {
    dispatch(fetchCharacter(id));
    dispatch(fetchCharacterStrokes(id));
    dispatch(fetchComponents(id));
    dispatch(fetchCharacterInfo(id));
    dispatch(fetchCharacterDef(id));
    dispatch(fetchWordsWithChar(id));
    dispatch(fetchSentencesWithChar(id));
  }, [dispatch]);

  useEffect(() => {
    loadChar(id);
  }, [id, loadChar, dispatch]);

  useEffect(() => {
    const sortByPriority = Array.from(defs).sort((a, b) => a.priority - b.priority);
    setSortedDefs(sortByPriority);
  }, [defs]);

  const handleEdit = (item) => {
    setShowEditModal(true);
    setSelectedDef(item);
  };

  const handleUpdate = (payload) => {
    const onSuccess = () => {
      loadChar(id);
      setShowEditModal(false);
    };

    const onFail = () => {
      setShowEditModal(false);
    };
    dispatch(updateCharacter(id, payload, onSuccess, onFail));
  };

  const handleFileChange = (ev) => {
    const onSuccess = (path) => {
      defs.forEach(({ id: wordId }, index) => {
        console.log(index, list.length);
        const successFunc = index === list.length - 1 ? () => loadChar(id) : null;
        dispatch(updateWord(wordId, {
          image: path
        }, successFunc));
      });
    };
    dispatch(submitFile(ev.target.files[0], onSuccess));
  };

  return (
    <div>
      <div class="dictionary-character">
        <div className="char-header">
          <div>
            <Link to="/dictionary" className="back-btn">
              <img
                src="/img/back.svg"
                alt="back"
                className="size-24-24"
              />
            </Link>
            <h3>Character</h3>
          </div>
          {
            (role === 'gk_admin' || role === 'support') && (
              <LinkButton onClick={handleEdit}>
                <img
                  alt="Edit Character"
                  src="/img/action-edit.svg"
                  className="size-24-24" />
              </LinkButton>
            )
          }
        </div>
        <div className="char-details">
          <div className="char-basics">
            <div className="hsk">
              <div className="hsk-circled">
                <span className="hsk-label">HSK</span>
                <span className="hsk-value">{hsk_level}</span>
              </div>
              <div className="hsk-level">HSK {hsk_level}</div>
            </div>
            <div className="basics">
              <h2>{character}</h2>
              <div className="phonetic">{sortedDefs?.[0]?.phonetic}</div>
              <div className="defs">{sortedDefs?.[0]?.defs}</div>
            </div>
            <div className="chinese-type">
              { (traditional || simplified) && (
                  <>
                    <div className="type-char">{character}</div>
                    <div className="type">{(traditional && 'Traditional') || (simplified && 'Simplified')}</div>
                  </>
                )
              }
            </div>
          </div>

          <div className="char-strokes">
            {stroke && <img src={`${mediaUrl}/${stroke}`} alt="strokes" />}
          </div>

          <div className="char-components">
            {
              Array.isArray(components) && components.length > 0 && components.map((cmp) => {
                const { component, phonetic, defs } = cmp;
                return (
                  <div className="char-component">
                    <div className="item-top">{component}</div>
                    <div className="item-middle">{phonetic}</div>
                    <div className="item-bottom">{defs}</div>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="char-mnemonic">
          <span>{mnemonic}</span>
          <div className="char-mnemonic-img">
            <img src={sortedDefs?.[0]?.image} alt="mnemonic" />
            {
              (role === 'gk_admin' || role === 'support') && (
                <label>
                  <i className="fa fa-pen" />
                  <input
                    className="mnemonic-input"
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                  />
                </label>
              )
            }
          </div>
        </div>
        <div className="char-explanation">
          <h4>Explanation</h4>
          <div>
            <span>{character}</span>
            <span>{explanation}</span>
          </div>
        </div>

        <div className="char-definition">
          <h4>Definition</h4>
          <Definitions id={id} defs={sortedDefs} role={role} onSuccess={() => loadChar(id)} />
        </div>
      </div>
      <div className="char-bottom-sections">
        <div className="section">
          <h4>Characters Containing {character}</h4>
          <div className="char-grid">
            {
              Array.isArray(components) && components.length > 0 && components.map((cmp) => {
                const { character, phonetic, defs } = cmp;

                return (
                  <div className="char-grid-row char-row">
                    <span>{character}</span>
                    <span>{phonetic}</span>
                    <span>{defs}</span>
                    <span>
                      <Link to={`/dictionary/character/${character}`}>Details</Link>
                    </span>
                  </div>
                );
              })
            }
          </div>
        </div>

        <div className="section">
          <h4>Words Containing {character}</h4>
          <div className="char-grid">
            {
              Array.isArray(words) && words.length > 0 && words.map((word) => {
                const { chinese, phonetic, defs } = word;

                return (
                  <div className="char-grid-row word-row">
                    <span>{chinese}</span>
                    <span>{phonetic}</span>
                    <span>{defs}</span>
                    <span>
                      <Link to={`/dictionary/word/${chinese}`}>Details</Link>
                    </span>
                  </div>
                );
              })
            }
          </div>
        </div>

        <div className="section">
          <h4>Sentences</h4>
          <div className="char-grid">
            {
              Array.isArray(sentences) && sentences.length > 0 && sentences.map((sentence) => {
                const { chinese, phonetic, defs } = sentence;

                return (
                  <div className="char-grid-row sentence-row">
                    <span>{chinese}</span>
                    <span>{phonetic}</span>
                    <span>{defs}</span>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      <EditCharacterModal
        showModal={showEditModal}
        defaultValues={{
          character,
          mnemonic,
          explanation,
          hsk_level
        }}
        onClose={() => setShowEditModal(false)}
        onUpdate={handleUpdate} />
    </div>
  );
};

export default Character;