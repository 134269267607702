import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Alert from "../../Alert";
import "./AccountLayout.scss";
/**
 * Layout to be used for Login, Signup, Reset Password, Email Confirmation Pages
 */

const pageMap = new Map([
  ["/login", { bgType: 1, contentWidth: 480 }],
  ["/signup", { bgType: 1, contentWidth: 480 }],
  ["/createprofile", { bgType: 0, contentWidth: 480 }],
  ["/ResetPass", { bgType: 0, contentWidth: 480 }],
  ["/resetpassword", { bgType: 0, contentWidth: 480 }],
  ["/resetpassword/notice", { bgType: 0, contentWidth: 480 }],
  ["/changepassword", { bgType: 0, contentWidth: 480 }],
  ["/changepassword/notice", { bgType: 0, contentWidth: 480 }],
]);

const AccountLayout = (props) => {
  const { apiError, notice } = useSelector(state => state.common);

  const currentPath = window.location.pathname;
  const { bgType = 0, contentWidth = 480 } = pageMap.get(currentPath) || {};
  const classes = classNames({
    "account-layout": true,
    "account-bg-plain": bgType === 0,
    "account-bg-color": bgType === 1
  });

  return (
    <div className={classes}>
      <div className="account-inner">
        <div className="account-header">
          <Link to="/">
            <img alt="Logo" src="/img/logo.svg" />
          </Link>
        </div>
        <div className="account-body">
          <div className="account-content" style={{ maxWidth: `${contentWidth}px` }}>
            <div className="app-logo">
              <img src="/img/app-icon.png" alt="fullchinese logo" />
            </div>
            {props.children}
          </div>
        </div>
      </div>
      <Alert {...(apiError || notice)} />
    </div>
  );
};

export default AccountLayout;