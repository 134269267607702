import {
  FETCH_NEWSFEED_SUCCESS,
  RESET_PREVIEW_DATA,
  FETCH_NEWSFEED_ITEM_SUCCESS
} from "./action-types";

const initialValue = {
  pages: {
    numRecords: 0,
    rows: []
  },
  selectedPage: {}
};

const newsfeedReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_NEWSFEED_SUCCESS:
      return {...state, pages: payload}
    case RESET_PREVIEW_DATA:
      return {...state, selectedPage: {}}
    case FETCH_NEWSFEED_ITEM_SUCCESS:
      return {...state, selectedPage: payload}
    default:
      return state;
  }
};

export default newsfeedReducer;
