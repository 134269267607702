import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../components/ActionIcon/ActionIcon";

import { resetCommon } from "../../common/actions/actions";
import {
  fetchTemplates
} from "./state/actions";

const TemplatesList = () => {
  const dispatch = useDispatch();
  const { templates } = useSelector(state => state.openAITemplates);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const formatDate = (row) => {
    const { last_modified } = row;
    return dayjs(last_modified).format('DD/MM/YYYY');
  };

  const renderLink = ({ row }) => {
    const { id } = row;
    return (
      <Link to={`/openai-templates/details/${id}`}>Details</Link>
    );
  };

  const handleSearch = () => {};

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'content', headerName: 'Content', width: 450 },
    { field: 'description', headerName: 'Description', width: 250 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatDate },
    { field: 'id', headerName: '-', width: 100, renderCell: renderLink }
  ];

  return (
    <div className="openai-templates">
      <SearchHeader
        count={templates.length}
        countTitle="Templates"
        placeHolder="Search templates here"
        addComponent={<ActionIcon icon="action-add-folder" label="NEW TEMPLATE" to={`/openai-templates/new`} />}
        onSearch={handleSearch}
      />
      <div className="openai-templates-results">
        <DataGrid
          className="templates-grid"
          autoHeight={true}
          rows={templates}
          columns={columns}
          pageSize={10}
         />
      </div>
    </div>
  );
};

export default TemplatesList;