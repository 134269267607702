import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify({ auth: state.auth });
    localStorage.setItem("state", serializedState);
  } catch (err) {}
};

const persistedStore = loadState();
const initialState = persistedStore;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      createLogger(),
      thunk
    )
  )
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
