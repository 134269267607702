import {
  FETCH_CHARACTERS_SUCCESS,
  FETCH_CHARACTER_SUCCESS,
  FETCH_CHARACTER_STROKES_SUCCESS,
  FETCH_CHARACTER_INFO_SUCCESS,
  FETCH_CHARACTER_DEFS_SUCCESS,
  FETCH_COMPONENTS_SUCCESS,
  FETCH_WORDS_WITH_CHARACTER_SUCCESS,
  FETCH_SENTENCES_WITH_CHARACTER_SUCCESS,
  FETCH_WORDS_SUCCESS,
  FETCH_WORD_SUCCESS,
  FETCH_SENTENCES_WITH_WORD_SUCCESS,
  FETCH_SENTENCES_SUCCESS
} from "./action-types";

const initialValue = {
  characters: [],
  words: [],
  sentences: [],
  activeCharacter: {
    list: [],
    stroke: null,
    charInfo: [],
    components: [],
    words: [],
    sentences: [],
    defs: []
  },
  activeWord: {
    list: [],
    components: [],
    sentences: []
  }
};

const setActiveCharacter = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      list: payload
    }
  };
};

const setActiveCharacterStrokes = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      stroke: payload.image
    }
  };
};

const setActiveCharacterComponents = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      components: payload
    }
  };
};

const setActiveCharacterInfo = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      charInfo: payload
    }
  };
};

const setActiveCharacterDefs = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      defs: payload
    }
  };
};

const setContainingWords = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      words: payload
    }
  };
};

const setContainingSentences = (state, payload) => {
  const activeChar = state.activeCharacter;
  return {
    ...state,
    activeCharacter: {
      ...activeChar,
      sentences: payload
    }
  };
};

const setActiveWord = (state, payload) => {
  const activeWord = state.activeWord;
  return {
    ...state,
    activeWord: {
      ...activeWord,
      list: payload
    }
  };
};

const setWordContainingSentences = (state, payload) => {
  const activeWord = state.activeWord;
  return {
    ...state,
    activeWord: {
      ...activeWord,
      sentences: payload
    }
  };
};

const dictionaryReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_CHARACTERS_SUCCESS:
      return {...state, characters: payload};
    case FETCH_CHARACTER_SUCCESS:
      return setActiveCharacter(state, payload);
    case FETCH_CHARACTER_STROKES_SUCCESS:
      return setActiveCharacterStrokes(state, payload);
    case FETCH_CHARACTER_INFO_SUCCESS:
      return setActiveCharacterInfo(state, payload);
    case FETCH_CHARACTER_DEFS_SUCCESS:
      return setActiveCharacterDefs(state, payload);
    case FETCH_COMPONENTS_SUCCESS:
      return setActiveCharacterComponents(state, payload);
    case FETCH_WORDS_WITH_CHARACTER_SUCCESS:
      return setContainingWords(state, payload);
    case FETCH_SENTENCES_WITH_CHARACTER_SUCCESS:
      return setContainingSentences(state, payload);
    case FETCH_WORDS_SUCCESS:
      return {...state, words: payload};
    case FETCH_WORD_SUCCESS:
      return setActiveWord(state, payload);
    case FETCH_SENTENCES_WITH_WORD_SUCCESS:
      return setWordContainingSentences(state, payload);
    case FETCH_SENTENCES_SUCCESS:
      return {...state, sentences: payload};
    default:
      return state;
  }
};

export default dictionaryReducer;
