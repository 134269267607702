import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";

import NewsFeedPreviewModal from "./NewsFeedPreviewModal";

import {
  fetchBookPages,
  fetchPagePreviewData,
  publishPageToNewsFeed
} from "../../state/actions";

import "./book-texts.scss";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const formatModifiedDate = ({ row }) => {
  const { last_modified } = row;
  return last_modified ? formatDate(last_modified) : null;
};

const BookPages = ({ bookSha }) => {
  const dispatch = useDispatch();

  const { bookPages, pagePreview } = useSelector(state => state.texts);
  const { numRecords, rows } = bookPages;

  const [searchTerm, setSearchTerm] = useState("");
  const [pageToPush, setPageToPush] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(fetchBookPages({
      pageNum: 0,
      pageSize: 10
    }, bookSha))
  }, [dispatch, bookSha]);

  const handlePageChange = ({ page, pageSize }) => {
    dispatch(fetchBookPages({ pageNum: page, pageSize }, bookSha, searchTerm || ""));
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    dispatch(fetchBookPages({ pageNum: 0, pageSize }, bookSha, searchTerm || ""));
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    dispatch(fetchBookPages({ pageNum: 0, pageSize }, bookSha ,searchTerm || ""));
  };

  const handlePublishPage = (onSuccess, onFail) => {
    const { page_sha1, tags } = pageToPush;
    dispatch(publishPageToNewsFeed({
      page_sha1,
      tags,
      title: pagePreview.title,
      thumbnail: pagePreview.thumbnail,
      description: pagePreview.desc,
    }, onSuccess, onFail))
  };

  const renderLink = ({ row }) => {
    const { sha1, tags } = row;

    const handleClick = () => {
      dispatch(fetchPagePreviewData(sha1));
      setPageToPush({
        page_sha1: sha1,
        tags
      })
    };

    return (
      <Button onClick={handleClick}>
        Push to Newsfeed
      </Button>
    );
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 400 },
    { field: 'author', headerName: 'Author', width: 200 },
    { field: 'level', headerName: 'Author', width: 200 },
    { field: 'lang', headerName: 'Lang', width: 200 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width: 150, renderCell: renderLink}
  ];

  return (
    <div className="book-texts-section">
      <SearchHeader
        count={numRecords}
        countTitle="Pages"
        placeHolder="Search pages here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
      <NewsFeedPreviewModal
        pageData={pagePreview}
        showModal={!!pageToPush}
        onClose={() => setPageToPush(null)}
        onPublishPage={handlePublishPage}
       />
    </div>
  );
};

BookPages.propTypes = {
  bookSha: PropTypes.string
};

export default BookPages;
