import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import SearchHeader from "../../../../components/SearchHeader/SearchHeader";
import ActionIcon from "../../../../components/ActionIcon/ActionIcon";
import MoreItems from "../../../../components/MoreItems/MoreItems";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import ActionModal from "../../../../components/ActionModal/ActionModal";

import AddBookModal from "./AddBookModal";

const formatDate = date => dayjs(date).format('DD/MM/YYYY');

const Books = (props) => {
  const {
    owner,
    data,
    availableBooks,
    onFetchBooks,
    onDeleteBook,
    onFetchBooksToAdd,
    onAddBooks
  } = props;
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [bookToDelete, setBookToDelete] = useState(null);
  const [showAddBookModal, setShowAddBookModal] = useState(false);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchBooks({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchBooks({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchBooks({ pageNum: 0, pageSize }, searchTerm);
  };

  const handleDeleteBook =  () => {
    if (typeof onDeleteBook === "function") {
      onDeleteBook(bookToDelete, () => {
        setBookToDelete(null);
      });
    }
  };

  const formatModifiedDate = ({ row }) => {
    const { last_modified } = row;
    return last_modified ? formatDate(last_modified) : null;
  };
  
  const renderLink = ({ row }) => {
    const { book_sha1 } = row;

    return (
      <div className="grid-actions">
        <Link to={`/books/details/${book_sha1}`}>Details</Link>
        <MoreItems>
          <div>
            <LinkButton onClick={() => setBookToDelete(book_sha1)}>
              Delete
            </LinkButton>
          </div>
        </MoreItems>
      </div>
    );
  };
  
  const columns = [
    { field: 'book_title', headerName: 'Title', width: 400 },
    { field: 'book_description', headerName: 'Description', width: 600 },
    { field: 'author', headerName: 'Author', width: 140 },
    { field: 'last_modified', headerName: 'Modified', width: 150, valueGetter: formatModifiedDate },
    { field: 'actions', headerName: '.', width: 300, renderCell: renderLink}
  ];

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Books"
        placeHolder="Search texts here"
        addComponent={<ActionIcon icon="action-add-folder" label="Add Books" onClick={() => setShowAddBookModal(true)} />}
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.sha1}
        />
      </div>
      <ActionModal
        showModal={!!bookToDelete}
        title="Delete Book"
        actionText="Delete"
        onClose={() => setBookToDelete(null)}
        onAction={handleDeleteBook}>
          Do you want to delete this book?
      </ActionModal>
      <AddBookModal
        owner={owner}
        showModal={showAddBookModal}
        books={availableBooks}
        onClose={() => setShowAddBookModal(false)}
        onFetchBooks={onFetchBooksToAdd}
        onAddBooks={onAddBooks}
      />
    </div>
  );
};

Books.propTypes = {
  owner: PropTypes.number,
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  availableBooks: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchBooks: PropTypes.func,
  onDeleteBook: PropTypes.func
};

export default Books;
