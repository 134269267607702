import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetCommon } from "../../../common/actions/actions";

import Definitions from "./Definitions";

import {
  fetchWord,
  fetchSentencesWithWord,
  updateWord,
  submitFile
} from "../state/actions";

import "./Word.scss";

const Word = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [primaryWord, setPrimaryWord] = useState({});
  const [sortedDefs, setSortedDefs] = useState([]);

  const { role } = useSelector(state => state.auth);
  const { activeWord } = useSelector(state => state.dictionary);
  const { sentences, list = [] } = activeWord;
 
  const {
    chinese,
    phonetic,
    defs,
    image
  } = primaryWord;

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchWord(id));
    dispatch(fetchSentencesWithWord(id));
  }, [id, dispatch]);

  useEffect(() => {
    const sortByPriority = Array.from(list).sort((a, b) => a.priority - b.priority);
    setPrimaryWord(sortByPriority[0] || {});
    setSortedDefs(sortByPriority);
  }, [list]);

  const loadWord = (id) => {
    dispatch(fetchWord(id));
    dispatch(fetchSentencesWithWord(id));
  };

  const handleFileChange = (ev) => {
    const onSuccess = (path) => {
      list.forEach(({ id: wordId }, index) => {
        const successFunc = index === list.length - 1 ? () => loadWord(id) : null;
        dispatch(updateWord(wordId, {
          image: path
        }, successFunc));
      });
    };
    dispatch(submitFile(ev.target.files[0], onSuccess));
  };

  return (
    <div>
      <div className="dictionary-word">
        <div className="word-header">
          <div>
            <Link to="/dictionary" className="back-btn">
              <img
                src="/img/back.svg"
                alt="back"
                className="size-24-24"
              />
            </Link>
            <h3>Word</h3>
          </div>
        </div>
        <div className="word-details">
          <div className="word-basics">
            <div className="basics">
              <h2>{chinese}</h2>
              <div className="phonetic">{phonetic}</div>
              <div className="defs">{defs}</div>
            </div>
          </div>
          <div className="word-components">
            {
              (chinese || "").split("").map((char) => {
                return (
                  <div className="word-component">
                      <Link to={`/dictionary/character/${char}`}>
                      <div className="item-top">{char}</div>
                    </Link>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="word-mnemonic">
          <img src={image} alt="mnemonic" />
          {
            (role === 'gk_admin' || role === 'support') && (
              <label>
                <i className="fa fa-pen" />
                <input
                  className="mnemonic-input"
                  type="file"
                  name="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleFileChange}
                />
              </label>
            )
          }
        </div>

        <div className="word-definition">
          <h4>Definition</h4>
          <Definitions id={id} defs={sortedDefs} role={role} onSuccess={() => loadWord(id)} />
        </div>
      </div>
      <div className="word-bottom-sections">
        <div className="section">
          <h4>Sentences</h4>
          <div className="word-grid">
            {
              Array.isArray(sentences) && sentences.length > 0 && sentences.map((sentence, index) => {
                const { chinese, phonetic, defs } = sentence;

                return (
                  <div className="word-grid-row sentence-row" key={index}>
                    <span>{chinese}</span>
                    <span>{phonetic}</span>
                    <span>{defs}</span>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Word;