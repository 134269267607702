import React, { Component } from "react";
import { connect } from "react-redux";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

class PaginationNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsOfPage: 10,
      currentOfPage: 1
    };
  }
  componentDidMount() {
    this.getResult(this.props.data);
    document.getElementById("leftIcon").style.opacity = 0.3;
    this.setState({ firstItem: "10" + String.fromCharCode(160) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.hidenav !== nextProps.hidenav) {
      this.setState({ hidenavStatus: this.props.hidenav });
    }
    if (this.props.data !== nextProps.data) {
      this.setState({
        currentOfPage: 1,
        itemsOfPage: this.props.items ? this.props.items : 10
      });
      // this.getResult(nextProps.data);
    }
  }

  itemsChange = event => {
    document.getElementById("leftIcon").style.opacity = 0.3;
    document.getElementById("rightIcon").style.opacity = 1;
    this.setState({ itemsOfPage: event.target.value, currentOfPage: 1 }, () => {
      this.getResult(this.props.data);
    });
  };

  prevPage = () => {
    if (this.state.currentOfPage > 2) {
      document.getElementById("leftIcon").style.opacity = 1;
      document.getElementById("rightIcon").style.opacity = 1;
      this.setState({ currentOfPage: this.state.currentOfPage - 1 }, () => {
        this.getResult(this.props.data);
      });
    } else if (this.state.currentOfPage === 2) {
      document.getElementById("leftIcon").style.opacity = 0.3;
      document.getElementById("rightIcon").style.opacity = 1;
      this.setState({ currentOfPage: this.state.currentOfPage - 1 }, () => {
        this.getResult(this.props.data);
      });
    }
  };
  nextPage = () => {
    if (this.state.currentOfPage + 1 < Math.ceil(this.props.data / this.state.itemsOfPage)) {
      document.getElementById("rightIcon").style.opacity = 1;
      document.getElementById("leftIcon").style.opacity = 1;
      this.setState({ currentOfPage: this.state.currentOfPage + 1 }, () => {
        this.getResult(this.props.data);
      });
    } else if (this.state.currentOfPage + 1 === Math.ceil(this.props.data / this.state.itemsOfPage)) {
      document.getElementById("rightIcon").style.opacity = 0.3;
      document.getElementById("leftIcon").style.opacity = 1;
      this.setState({ currentOfPage: this.state.currentOfPage + 1 }, () => {
        this.getResult(this.props.data);
      });
    }
  };

  getResult = data => {
    let start = 0,
      end = 0;

    start = (this.state.currentOfPage - 1) * this.state.itemsOfPage;
    end =
      data === 0
        ? 0
        : this.state.currentOfPage * this.state.itemsOfPage > data
        ? data - 1
        : this.state.currentOfPage * this.state.itemsOfPage - 1;
    this.props.changeCurrentData(start, end);
  };

  render() {
    const { itemsOfPage, currentOfPage } = this.state;

    return (
      <div className="mb-pagination">
        <p className="mb-rowsPerPage">Rows per page:</p>
        <select id="itemsofPerPage" onChange={this.itemsChange} value={this.state.itemsOfPage}>
          <option value="10">{this.state.firstItem}</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        {itemsOfPage === 1 ? (
          <p className="mb-currentList">
            {currentOfPage * itemsOfPage}&nbsp;of&nbsp;
            {this.props.data}
          </p>
        ) : (
          <p className="mb-currentList">
            {(currentOfPage - 1) * itemsOfPage + 1} -
            {currentOfPage * itemsOfPage > this.props.data ? this.props.data : currentOfPage * itemsOfPage}
            &nbsp;of&nbsp; {this.props.data}
          </p>
        )}
        <div className="mb-pagination-pages">
          <img src="/img/basic-v-left.png" className="size-24-24" alt="ima" onClick={this.prevPage} id="leftIcon" />
          <img src="/img/basic-v-right.png" className="size-24-24" alt="ima" onClick={this.nextPage} id="rightIcon" />
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    token: state.rpc.tokens,
    orgMembers: state.rpc.membersorg,
    hidenav: state.rpc.hidenav
  };
};

export default connect(mapStatetoProps)(PaginationNew);
