import { makeApiCall } from "../helpers/api-client";
import * as Actions from "../constants/actions";

const getProfileSuccess = payload => ({
  type: Actions.GET_PROFILE_SUCCESS,
  payload
});

const getProfileFailure = payload => ({
  type: Actions.GET_PROFILE_FAILURE,
  payload
});

export const getProfile = () => async dispatch => {
  try {
    const response = await makeApiCall("/fc_profile");
    dispatch(getProfileSuccess(response));
  } catch (error) {
    dispatch(getProfileFailure(error));
  }
};