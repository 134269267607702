import {
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS
} from "./action-types";

const initialValue = {
  templates: [],
  selectedTemplate: {}
};

const updateTemplate = (state, payload) => {
  return {
    ...state,
    selectedTemplate: {
      ...state.selectedTemplate,
      ...payload
    }
  }
};

const templatesReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_TEMPLATES_SUCCESS:
      return {...state, templates: payload};
    case FETCH_TEMPLATE_SUCCESS:
      return {...state, selectedTemplate: payload};
    case UPDATE_TEMPLATE_SUCCESS:
      return updateTemplate(state, payload);
    case DELETE_TEMPLATE_SUCCESS:
      return {...state, selectedTemplate: {}};
    default:
      return state;
  }
};

export default templatesReducer;
