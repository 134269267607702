import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import Form from "react-bootstrap/Form";

import { makeApiCall } from "../../../../helpers/api-client";

import "./NewMemberModal.scss";


const NewMemberModal = ({ showModal, orgId, onHide, onAddNewMember }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const schema = yup.object().shape({
    role: yup.string().required("Please select a role"),
    user: yup.array().of(
      yup.object().shape({
        email: yup.string().email().required("Email is required"),
        user_name: yup.string().required("Username is required")
      })
    ).length(1, "Please select a user")
  });

  const { register, errors, control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      role: 'teacher',
      user: []
    }
  });

  const handleSearch = async (query) => {
    setIsLoading(true);

    const { data } = await makeApiCall(`fc_users?or=(name.ilike.%25${query}%25,email.ilike.%25${query}%25)`);
    const options = data.map((i) => ({
      email: i.email,
      user_name: i.name,
    }));

    setOptions(options);
    setIsLoading(false);
  };

  const handleBeforeSubmit = (values) => {
    const { role, user } = values;
    const { email, user_name } = user[0];
    const data = {
      email,
      user_name,
      role,
      org_id: orgId
    };
    onAddNewMember([data]);
  };
  const filterBy = () => true;

  return (
    <Fragment>
      <Modal size="md" centered show={showModal} onHide={onHide} className="new-member-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add New Member to This Organization</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleBeforeSubmit)} noValidate>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                name="role"
                as="select"
                ref={register}
                isInvalid={!!errors.role}
              >
                <option value="teacher">Teacher</option>
                <option value="org_admin">Org Admin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Row} className="form-row">
              <Form.Label>Email or Username</Form.Label>
              <Controller
                as={<AsyncTypeahead />}
                className="select-member"
                filterBy={filterBy}
                id="async-members"
                isLoading={isLoading}
                labelKey="email"
                minLength={3}
                onSearch={handleSearch}
                options={options}
                placeholder="Search for a username or email"
                renderMenuItemChildren={(option, props) => (
                  <span>{option.user_name} - {option.email}</span>
                )}
                name="user"
                control={control}
                ref={register}
                isInvalid={!!errors.user}
              />
              <Form.Control.Feedback type="inValid">{errors.user?.message}</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

NewMemberModal.propTypes = {
  orgId: PropTypes.string,
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  onAddNewMember: PropTypes.func
};

export default NewMemberModal;
