import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../components/LinkButton/LinkButton";

import { resetCommon } from "../../../common/actions/actions";

import { fetchOrganizations, addClass } from "../state/actions";

const schema = yup.object().shape({
  org_id: yup.string().required("This field cannot be empty"),
  name: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty"),
  max_members: yup.number().typeError('Max members must be a number').required("This field cannot be empty"),
  max_books: yup.number().typeError('Max books must be a number').required("This field cannot be empty")
});

const NewClass = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { nextRoute } = useSelector(state => state.common);
  const { id } = useSelector(state => state.auth);
  const { organizations } = useSelector(state => state.classes);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    const { org_id } = data;
    const { name } = organizations.find(({ id }) => id == org_id);

    dispatch(addClass(id, { ...data, org_name: name }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="new-organization">
      <div className="header">
        <LinkButton onClick={() => history.goBack()}>
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <h3>New Class</h3>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Organization</Form.Label>
            <Col xs="8">
              <Form.Control
                name="org_id"
                as="select"
                placeholder="Enter Organization Name"
                ref={register}
                isInvalid={!!errors.org_id}
              >
                {
                  organizations.map(({id, name}) => <option key={id} value={id}>{name}</option>)
                }
              </Form.Control>
              <Form.Control.Feedback type="inValid">{errors.org_id?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Class Name</Form.Label>
            <Col xs="8">
              <Form.Control
                name="name"
                type="text"
                placeholder="Enter Class Name"
                ref={register}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="inValid">{errors.name?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Description</Form.Label>
            <Col xs="8">
              <Form.Control
                sm="10"
                name="description"
                as="textarea"
                placeholder="Enter Description"
                ref={register}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Max Members</Form.Label>
            <Col xs="8">
              <Form.Control
                name="max_members"
                type="number"
                placeholder="Enter max members"
                ref={register}
                isInvalid={!!errors.max_members}
              />
              <Form.Control.Feedback type="inValid">{errors.max_members?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="form-row">
            <Form.Label column xs="4">Max Books</Form.Label>
            <Col xs="8">
              <Form.Control
                name="max_books"
                type="number"
                placeholder="Enter max books"
                ref={register}
                isInvalid={!!errors.max_books}
              />
              <Form.Control.Feedback type="inValid">{errors.max_books?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className="actions">
            <Button type="submit">
              Create
            </Button>
            <Button type="button" variant="outline-primary" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NewClass;
