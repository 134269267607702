import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@material-ui/data-grid";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import SearchBar from "../../../components/SearchHeader/SearchBar";

import "./AddTextModal.scss";


const AddTextModal = (props) => {
  const { texts, showModal, onHide, onFetchTexts, onAddTexts } = props;
  const { numRecords, rows } = texts;
  const [pageSize, setPageSize] = useState(10);
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (showModal) {
      setSelectedTexts([]);
    }
  }, [showModal]);

  const handleSelectText = (ev, text_sha1) => {
    const selected = ev.target.checked;
    const selectedText = {
      text_sha1
    };

    if(selected) {
      setSelectedTexts(selectedTexts.concat([selectedText]));
    } else {
      setSelectedTexts(selectedTexts.filter(text => text.text_sha1 !== text_sha1));
    }
  };

  const renderCheckbox = ({ row }) => {
    const { text_sha1 } = row;
    return (
      <Form.Check
        type="checkbox"
        checked={selectedTexts.find((text) => text.text_sha1 === text_sha1)}
        onClick={(ev) => handleSelectText(ev, text_sha1)}
      />
    );
  };

  const handleClose = () => {
    setSelectedTexts([]);
    if (typeof onHide === "function") {
      onHide();
    }
  };

  const columns = [
    { field: 'text_title', headerName: 'Title', width: 350 },
    { field: 'author', headerName: 'Author', width: 200 },
    { field: 'id', headerName: 'Select', width: 100, renderCell: renderCheckbox}
  ];

  useEffect(() => {
    onFetchTexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchTerm = '') => {
    setSearchTerm(searchTerm);
    onFetchTexts({pageNum: 0, pageSize: 10}, searchTerm);
  };

  const handlePageChange = ({ page, pageSize }) => {
    onFetchTexts({ pageNum: page, pageSize }, searchTerm);
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchTexts({ pageNum: 0, pageSize }, searchTerm);
  };

  return (
    <Fragment>
      <Modal size="lg" centered show={showModal} onHide={handleClose} className="new-class-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add New Text to This Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="book-search">
            <SearchBar placeholder="Search: Text with Title" onSearch={handleSearch} />
          </div>
          <div className="books-results">
            <DataGrid
              className="books-grid"
              autoHeight={true}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              rowCount={numRecords}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowId={row => row.sha1}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={() => onAddTexts(selectedTexts)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

AddTextModal.propTypes = {
  classId: PropTypes.string,
  showModal: PropTypes.bool,
  texts: PropTypes.array,
  textSha: PropTypes.array,
  onHide: PropTypes.func,
  onFetchTexts: PropTypes.func,
  onAddTexts: PropTypes.func
};

export default AddTextModal;
