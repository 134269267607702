const config = [{
  name: 'Home',
  link: '/user/home',
  icon: '/img/menu-home.svg',
  alt: 'User Home Icon'
}, {
  name: 'Organization',
  link: '/organization',
  icon: '/img/organization.svg',
  alt: 'Organization Icon'
}, {
  name: 'Class',
  link: '/classes',
  icon: '/img/class.svg',
  alt: 'Class Icon'
}, {
  name: 'Text',
  link: '/books',
  icon: '/img/texts.svg',
  alt: 'Text Icon'
}, {
  name: 'Store',
  link: '/store',
  icon: '/img/store.svg',
  alt: 'Store Icon'
}, {
  name: 'Dictionary',
  link: '/dictionary',
  icon: '/img/dictionary.svg',
  alt: 'Dictionary Icon'
}, {
  name: 'Members',
  link: '/members',
  icon: '/img/members.svg',
  alt: 'Members Icon'
}, {
  name: 'Payments',
  link: '/payments',
  icon: '/img/payments.svg',
  alt: 'Payments Icon'
}, {
  name: 'Newsfeed',
  link: '/newsfeed',
  icon: '/img/organization.svg',
  alt: 'Newsfeed Icon'
}, {
  name: 'Events',
  link: '/events',
  icon: '/img/organization.svg',
  alt: 'Events Icon'
}, {
  name: 'Emails',
  link: '/emails',
  icon: '/img/organization.svg',
  alt: 'Emails Icon'
}, {
  name: 'Videos',
  link: '/videos',
  icon: '/img/organization.svg',
  alt: 'Videos Icon'
}, {
  name: 'Device Config',
  link: '/device-config',
  icon: '/img/organization.svg',
  alt: 'Device config Icon'
}, {
  name: 'Youtube',
  link: '/youtube',
  icon: '/img/organization.svg',
  alt: 'youtube icon'
}, {
  name: 'Open AI Templates',
  link: '/openai-templates/',
  icon: '/img/organization.svg',
  alt: 'Open AI Templates Icon'
}, {
  name: 'Post Feeds',
  link: '/post-feeds',
  icon: '/img/organization.svg',
  alt: 'post feeds icon'
}];

const roles = {
  student: ['Home', 'Dictionary' /*, 'Class', 'Text', 'Store', 'Dictionary'*/],
  teacher: ['Home', 'Organization', 'Class'],
  support: ['Home', 'Organization', 'Class', 'Text', 'Store', 'Dictionary', 'Members', 'Payments', 'Videos'],
  gk_admin: ['Home', 'Organization', 'Class', 'Text', 'Store', 'Dictionary', 'Members', 'Payments', 'Newsfeed', 'Events', 'Emails', 'Videos', 'Device Config', 'Youtube Transcript', 'Open AI Templates', 'Post Feeds']
};

export const getValidTabs = role => {
  const tabs = roles[role] || [];
  return config.filter(tab => tabs.includes(tab.name));
};