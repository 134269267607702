import React, { useState } from "react";
import PropTypes from "prop-types";

import { DataGrid } from "@material-ui/data-grid";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";

const renderParams1 = ({ row }) => {
  const { params1 } = row;

  return typeof params1 === 'object' ? (
    <pre>{JSON.stringify(params1)}</pre>
  ) : (
    <span>{params1}</span>
  );
};

const renderParams2 = ({ row }) => {
  const { params2 } = row;

  return typeof params2 === 'object' ? (
    <pre>{JSON.stringify(params2)}</pre>
  ) : (
    <span>{params2}</span>
  );
};


const renderParams = ({ row }) => {
  const { params } = row;

  return (
    <pre>{JSON.stringify(params)}</pre>
  );
};

const renderData = ({ row }) => {
  const { data } = row;

  return (
    <pre>{JSON.stringify(data)}</pre>
  );
};

const columns = [
  { field: 'id', headerName: 'id', width: 70 },
  { field: 'owner', headerName: 'Owner', width: 120 },
  { field: 'device', headerName: 'Device', width: 170 },
  { field: 'timestamp', headerName: 'Timestamp', width: 230 },
  { field: 'event_name', headerName: 'Event Name', width: 150 },
  { field: 'event_type', headerName: 'Event Type', width: 170 },
  { field: 'params1', headerName: 'Params 1', width: 150, renderCell: renderParams1 },
  { field: 'params2', headerName: 'Params 2', width: 150, renderCell: renderParams2 },
  { field: 'params', headerName: 'Params', width: 280, renderCell: renderParams },
  { field: 'data', headerName: 'Data', width: 280, renderCell: renderData }
];

const EventsList = ({ data, onFetchEvents }) => {
  const { numRecords, rows } = data;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = ({ page, pageSize }) => {
    onFetchEvents({ pageNum: page, pageSize }, searchTerm || "");
  };

  const handlePageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
    onFetchEvents({ pageNum: 0, pageSize }, searchTerm || "");
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    onFetchEvents({ pageNum: 0, pageSize }, searchTerm);
  };

  return (
    <div>
      <SearchHeader
        count={numRecords}
        countTitle="Events"
        placeHolder="Search events here"
        onSearch={handleSearch}
      />
      <div>
        <DataGrid
          autoHeight={true}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={numRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          getRowId={row => row.id}
        />
      </div>
    </div>
  );
};

EventsList.propTypes = {
  data: PropTypes.shape({
    numRecords: PropTypes.number,
    rows: PropTypes.array
  }),
  onFetchEvents: PropTypes.func
};

export default EventsList;
