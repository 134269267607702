import { makeApiCall } from "../../../helpers/api-client";
import { throwApiError, setNextRoute } from "../../../common/actions/actions";

import * as Actions from "./action-types";

export const fetchClasses = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_classes?${queryString}`);
    dispatch(fetchClassesSuccess(response.data));
  } catch (error) {
    dispatch(fetchClassesFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch classes. Please try again."
    }));
  }
};

const fetchClassesSuccess = (payload) => ({
  type: Actions.FETCH_CLASSES_SUCCESS,
  payload
});

const fetchClassesFail = () => ({
  type: Actions.FETCH_CLASSES_FAIL
});

export const fetchOrganizations = () => async dispatch => {
  try {
    const response = await makeApiCall("fc_organization");
    dispatch(fetchOrganizationsSuccess(response.data));
  } catch (error) {
    dispatch(fetchOrganizationsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch organizations. Please try again."
    }));
  }
};

const fetchOrganizationsSuccess = (payload) => ({
  type: Actions.FETCH_ORGANIZATIONS_SUCCESS,
  payload
});

const fetchOrganizationsFail = () => ({
  type: Actions.FETCH_ORGANIZATIONS_FAIL
});

export const addClass = (id, payload) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_classes?user_id=eq.${id}`, {
      method: "post",
      data: payload
    });
    dispatch(addClassSuccess(response));
    dispatch(setNextRoute("/classes"));
  } catch (error) {
    dispatch(addClassFail());
    dispatch(throwApiError({
      type: "alert",
      message: "Failed to add class. Please try again later"
    }));
  }
};

const addClassSuccess = (payload) => ({
  type: Actions.ADD_CLASS_SUCCESS,
  payload
});

const addClassFail = () => ({
  type: Actions.ADD_CLASS_FAIL
});

export const fetchClass = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_classes?id=eq.${id}`);
    dispatch(fetchClassSuccess(response.data[0]));
  } catch (error) {
    dispatch(fetchClassFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch classes. Please try again."
    }));
  }
};

const fetchClassSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_SUCCESS,
  payload
});

const fetchClassFail = () => ({
  type: Actions.FETCH_CLASS_FAIL
});

export const updateClass = (id, payload) => async dispatch => {
  try {
    dispatch(updateClassData(payload));
    const response = await makeApiCall(`fc_classes?id=eq.${id}`, {
      method: 'patch',
      data: payload
    });
    dispatch(updateClassSuccess(response.data));
  } catch (error) {
    dispatch(updateClassFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to update class at this moment. Please try again later."
    }));
  }
};

const updateClassData = (payload) => ({
  type: Actions.UPDATE_CLASS_DATA,
  payload
});

const updateClassSuccess = (payload) => ({
  type: Actions.UPDATE_CLASS_SUCCESS,
  payload
});

const updateClassFail = () => ({
  type: Actions.UPDATE_CLASS_FAIL
});


export const deleteClass = (id, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_classes?id=eq.${id}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteClassSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Class deleted successfully."
    }));
    dispatch(setNextRoute("/classes"));
  } catch (e) {
    dispatch(deleteClassFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to delete class at the moment. Please try again later."
    }));
  }
};

const deleteClassSuccess = (payload) => ({
  type: Actions.DELETE_CLASS_SUCCESS,
  payload
});

const deleteClassFail = () => ({
  type: Actions.DELETE_CLASS_FAIL
});

export const fetchClassRole = (id, classId) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_members?user_id=eq.${id}&class_id=eq.${classId}`);
    dispatch(fetchClassRoleSuccess(response.data[0].role));
  } catch (error) {
    dispatch(fetchClassRoleFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch class role. Please try again."
    }));
  }
};

const fetchClassRoleSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_ROLE_SUCCESS,
  payload
});

const fetchClassRoleFail = () => ({
  type: Actions.FETCH_CLASS_ROLE_FAIL
});

/**
 * Members
 */

 export const fetchClassMembers = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_members?${queryString}`);
    dispatch(fetchClassMembersSuccess(response.data));
  } catch (error) {
    dispatch(fetchClassMembersFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch members. Please try again."
    }));
  }
};

const fetchClassMembersSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_MEMBERS_SUCCESS,
  payload
});

const fetchClassMembersFail = () => ({
  type: Actions.FETCH_CLASS_MEMBERS_FAIL
});

export const addMembers = (payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_members`, {
      method: 'post',
      data: payload
    });
    dispatch(addMembersSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Members added successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(addMembersFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to add the member at this moment. Please try again later."
    }));
  }
};

const addMembersSuccess = (payload) => ({
  type: Actions.ADD_MEMBERS_SUCCESS,
  payload
});

const addMembersFail = () => ({
  type: Actions.ADD_MEMBERS_FAIL
});

export const deleteMember = (id, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(
      `fc_class_members?id=eq.${id}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteMemberSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Member deleted successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(deleteMemberFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Failed to delete member. Please try again later."
    }));
  }
};

const deleteMemberSuccess = (payload) => ({
  type: Actions.DELETE_MEMBER_SUCCESS,
  payload
});

const deleteMemberFail = () => ({
  type: Actions.DELETE_MEMBER_FAIL
});


/**
 * Books
 */

 export const fetchClassBooks = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_books?${queryString}`);
    dispatch(fetchClassBooksSuccess(response.data));
  } catch (error) {
    dispatch(fetchClassBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchClassBooksSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_BOOKS_SUCCESS,
  payload
});

const fetchClassBooksFail = () => ({
  type: Actions.FETCH_CLASS_BOOKS_FAIL
});

export const fetchClassBooksSha = (id, ) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_books?class_id=eq.${id}&select=book_sha1`);
    dispatch(fetchClassBooksShaSuccess(response.data.map(({ book_sha1 }) => book_sha1)));
  } catch (error) {
    dispatch(fetchClassBooksShaFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchClassBooksShaSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_BOOKS_SHA_SUCCESS,
  payload
});

const fetchClassBooksShaFail = () => ({
  type: Actions.FETCH_CLASS_BOOKS_SHA_FAIL
});

export const fetchBooks = (classId, searchTerm) => async dispatch => {
  try {
    const bookShaResponse = await makeApiCall(`fc_class_books?class_id=eq.${classId}&select=book_sha1`);
    const bookShaList = bookShaResponse.data.map(({ book_sha1 }) => book_sha1);
    const response = await makeApiCall(`fc_books?sha1=not.in.(${bookShaList})&title=ilike.%25${searchTerm}%25`);
    dispatch(fetchBooksSuccess(response.data));
  } catch (error) {
    dispatch(fetchBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchBooksSuccess = (payload) => ({
  type: Actions.FETCH_BOOKS_SUCCESS,
  payload
});

const fetchBooksFail = () => ({
  type: Actions.FETCH_BOOKS_FAIL
});

export const addBooks = (payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall("fc_class_books", {
      method: 'post',
      data: payload
    });
    dispatch(addBooksSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Books added successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(addBooksFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to add books at the moment. Please try again later."
    }));
  }
};

const addBooksSuccess = (payload) => ({
  type: Actions.ADD_BOOKS_SUCCESS,
  payload
});

const addBooksFail = () => ({
  type: Actions.ADD_BOOKS_FAIL
});

export const deleteBook = (classId, bookSha1, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(
      `fc_class_books?class_id=eq.${classId}&book_sha1=eq.${bookSha1}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteBookSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Book deleted successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(deleteBookFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to delete book at the moment. Please try again later."
    }));
  }
};

const deleteBookSuccess = (payload) => ({
  type: Actions.DELETE_BOOK_SUCCESS,
  payload
});

const deleteBookFail = () => ({
  type: Actions.DELETE_BOOK_FAIL
});

/**
 * Texts
 */

 export const fetchClassTexts = (queryString) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_texts?${queryString}`);
    dispatch(fetchClassTextsSuccess(response.data));
  } catch (error) {
    dispatch(fetchClassTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch texts. Please try again."
    }));
  }
};

const fetchClassTextsSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_TEXTS_SUCCESS,
  payload
});

const fetchClassTextsFail = () => ({
  type: Actions.FETCH_CLASS_TEXTS_FAIL
});

export const fetchClassTextsSha = (id) => async dispatch => {
  try {
    const response = await makeApiCall(`fc_class_texts?class_id=eq.${id}&select=text_sha1`);
    dispatch(fetchClassTextsShaSuccess(response.data.map(({ text_sha1 }) => text_sha1)));
  } catch (error) {
    dispatch(fetchClassTextsShaFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch books. Please try again."
    }));
  }
};

const fetchClassTextsShaSuccess = (payload) => ({
  type: Actions.FETCH_CLASS_TEXTS_SHA_SUCCESS,
  payload
});

const fetchClassTextsShaFail = () => ({
  type: Actions.FETCH_CLASS_TEXTS_SHA_FAIL
});

export const fetchTexts = (classId, searchTerm) => async dispatch => {
  try {
    const textShaResponse = await makeApiCall(`fc_class_texts?class_id=eq.${classId}&select=text_sha1`);
    const textShaList = textShaResponse.data.map(({ text_sha1 }) => text_sha1);
    const response = await makeApiCall(`fc_texts?sha1=not.in.(${textShaList})&title=ilike.%25${searchTerm}%25`);
    dispatch(fetchTextsSuccess(response.data));
  } catch (error) {
    dispatch(fetchTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Could not fetch texts. Please try again."
    }));
  }
};

const fetchTextsSuccess = (payload) => ({
  type: Actions.FETCH_TEXTS_SUCCESS,
  payload
});

const fetchTextsFail = () => ({
  type: Actions.FETCH_TEXTS_FAIL
});

export const addTexts = (payload, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall("fc_class_texts", {
      method: 'post',
      data: payload
    });
    dispatch(addTextsSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Texts added successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (error) {
    dispatch(addTextsFail());
    dispatch(throwApiError({
      type: "toast",
      message: "This text is already added"
    }));
  }
};

const addTextsSuccess = (payload) => ({
  type: Actions.ADD_TEXTS_SUCCESS,
  payload
});

const addTextsFail = () => ({
  type: Actions.ADD_TEXTS_FAIL
});

export const deleteText = (classId, textSha1, onSuccess) => async dispatch => {
  try {
    const response = await makeApiCall(
      `fc_class_texts?class_id=eq.${classId}&text_sha1=eq.${textSha1}`,
      {
        method: "delete"
      }
    );

    dispatch(deleteTextSuccess(response.data));
    dispatch(throwApiError({
      type: "toast",
      alertType: "success",
      message: "Text deleted successfully."
    }));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (e) {
    dispatch(deleteTextFail());
    dispatch(throwApiError({
      type: "toast",
      message: "Not able to delete text at the moment. Please try again later."
    }));
  }
};

const deleteTextSuccess = (payload) => ({
  type: Actions.DELETE_TEXT_SUCCESS,
  payload
});

const deleteTextFail = () => ({
  type: Actions.DELETE_TEXT_FAIL
});
