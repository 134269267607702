import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import LinkButton from "../../../components/LinkButton/LinkButton";

import { resetCommon } from "../../../common/actions/actions";

import {
  createCollection
} from "../state/actions";

import "./new-collection.scss";

const schema = yup.object().shape({
  title: yup.string().required("This field cannot be empty"),
  book_type: yup.string().required("This field cannot be empty"),
  description: yup.string().required("This field cannot be empty")
});

const NewCollection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { nextRoute } = useSelector(state => state.common);

  const [submitting, setSubmitting] = useState(false);


  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    setSubmitting(true);
    dispatch(createCollection(values, () => setSubmitting(false)));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="new-collection">
      <div className="header">
        <LinkButton onClick={() => history.goBack()}>
          <img src="/img/back.svg" alt="back button" className="size-24-24" />
        </LinkButton>
        <h3>New Collection</h3>
      </div>
      <div>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Collection Title</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="title"
                  type="text"
                  placeholder="Enter text title here"
                  ref={register}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="inValid">{errors.title?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Book Type</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="book_type"
                  as="select"
                  ref={register}
                  isInvalid={!!errors.book_type}
                >
                  <option value="0">Audio</option>
                  <option value="1">Video</option>
                </Form.Control>
                <Form.Control.Feedback type="inValid">{errors.book_type?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="form-row">
              <Form.Label column xs="4">Description</Form.Label>
              <Col xs="8">
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={8}
                  placeholder="Enter description here"
                  ref={register}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="inValid">{errors.description?.message}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <div className="actions">
              <Button type="submit" disabled={submitting}>
                Create
              </Button>
              <Button type="button" variant="outline-primary" disabled={submitting} onClick={() => history.goBack()}>
                Cancel
              </Button>
            </div>
          </Form>
      </div>
    </div>
  );
};

export default NewCollection;
