export const FETCH_POST_FEEDS_SUCCESS = "app/postfeeds/FETCH_POST_FEEDS_SUCCESS";
export const FETCH_POST_FEEDS_FAIL = "app/postfeeds/FETCH_POST_FEEDS_FAIL";

export const CREATE_POST_FEEDS_SUCCESS = "app/postfeeds/CREATE_POST_FEEDS_SUCCESS";
export const CREATE_POST_FEEDS_FAIL = "app/postfeeds/CREATE_POST_FEEDS_FAIL";

export const UPDATE_POST_FEED_SUCCESS = "app/postfeeds/UPDATE_POST_FEED_SUCCESS";
export const UPDATE_POST_FEED_FAIL = "app/postfeeds/UPDATE_POST_FEED_FAIL";

export const REMOVE_FROM_POST_FEEDS_SUCCESS = "app/postfeeds/REMOVE_FROM_POST_FEEDS_SUCCESS";
export const REMOVE_FROM_POST_FEEDS_FAIL = "app/postfeeds/REMOVE_FROM_POST_FEEDS_FAIL";
