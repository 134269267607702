import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";

import Password from "../../../components/Fields/Password";

import { resetCommon } from '../../../common/actions/actions';
import { changePassword } from '../state/actions';

import "./AccountSecurity.scss";

const schema = yup.object().shape({
  password: yup.string().required("This field cannot be empty")
    .min(6, "Password should be atleast 6 character"),
  newPassword: yup.string().required("This field cannot be empty")
    .min(6, "Password should be atleast 6 character"),
  confirmNewPassword: yup.string().required("This field cannot be empty")
    .oneOf([yup.ref('newPassword'), null], "Passwords must match")
});

const AccountSecurity = () => {
  const dispatch = useDispatch();
  const { nextRoute } = useSelector(state => state.common);
  const { email } = useSelector(state => state.auth);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(resetCommon());

    return () => dispatch(resetCommon());
  }, [dispatch]);

  
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    const payload = {
      old_password: data.password,
      new_password: data.newPassword,
      email
    };
    setSubmitting(true);
    dispatch(changePassword(payload, () => {
      setSubmitting(false);
      reset();
    }));
  };

  if (nextRoute) {
    return <Redirect to={nextRoute} />
  }

  return (
    <div className="account-security-container">
      <h3 className="title">Change Password</h3>
      <p className="sub-title">Please enter your new password below</p>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="change-form">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Old Password</Form.Label>
          <Password
              name="password"
              innerRef={register}
              isInvalid={!!errors.password}
              showIcon={!errors.password}
            />
          <Form.Control.Feedback type="inValid">{errors.password?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formGroupPassword">
          <Form.Label>New Password</Form.Label>
          <Password
            name="newPassword"
            innerRef={register}
            isInvalid={!!errors.newPassword}
            showIcon={!errors.newPassword}
          />
          <Form.Control.Feedback type="inValid">{errors.newPassword?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formGroupPassword">
          <Form.Label>Confirm new Password</Form.Label>
          <Password
            name="confirmNewPassword"
            innerRef={register}
            isInvalid={!!errors.confirmNewPassword}
            showIcon={!errors.confirmNewPassword}
          />
          <Form.Control.Feedback type="inValid">{errors.confirmNewPassword?.message}</Form.Control.Feedback>
        </Form.Group>
        <div className="actions">
          <Button type="submit" disabled={submitting}>
            Update Password
          </Button>
          <Link to="/resetpassword">I forgot my password</Link>
        </div>
      </Form>
    </div>
  );
};

export default AccountSecurity;
