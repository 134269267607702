import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  GET_PROFILE_SUCCESS,
  SET_RESET_EMAIL
} from './action-types';

const initialState = {
  loading: false,
  id: null,
  emailVerified: false,
  token: null,
  username: null,
  role: null,
  email: null,
  profile: {},
  resetEmail: null
};

const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN:
      return {...state, loading: true, loginError: false}
    case SIGN_IN_SUCCESS:
      return {...state, ...payload};
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload
      }
    case SET_RESET_EMAIL:
      return {
        ...state,
        resetEmail: payload
      }
    default:
      return state;
  }
};

export default loginReducer;