import { FETCH_EVENTS_SUCCESS } from "./action-types";

const initialValue = {
  events: {
    numRecords: 0,
    rows: []
  }
};

const eventsReducer = (state = initialValue, { type, payload }) => {
  switch(type) {
    case FETCH_EVENTS_SUCCESS:
      return {...state, events: payload}
    default:
      return state;
  }
};

export default eventsReducer;
