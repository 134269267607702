import React, { useState } from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const RolePopover = ({ roles, onSave }) => {
  const defaultSelectedRoles = () => {
    const defaultSelected = {};
    roles.forEach(({ value }) => {
      defaultSelected[value] = true;
    });
    return defaultSelected;
  };

  const [selectedRoles, setSelectedRoles] = useState(defaultSelectedRoles());

  const setRole = (ev, role) => {
    const val = ev.target.checked;
    const roles = {
      ...selectedRoles,
      [role]: val
    };
    setSelectedRoles(roles);
  };

  const handleClear = () => {
    setSelectedRoles(defaultSelectedRoles());
  };

  const handleSave = () => {
    const values = [];
    const entries = Object.entries(selectedRoles);
    for (const [key, val] of entries) {
      if (val) {
        values.push(key);
      }
    }
    if (typeof onSave === "function") {
      onSave(values);
    }
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <Popover>
          <Popover.Content>
            <div className="members-role-list">
              {
                roles.map(({ label, value }) => (
                  <div key={value}>
                    <Form.Check
                      type="checkbox"
                      id={`filter-${value}`}
                      checked={!!selectedRoles[value]}
                      label={label}
                      onChange={(ev) => setRole(ev, value)}
                    />
                  </div>
                ))
              }
              <div className="members-filter-footer">
                <Button variant="link" onClick={handleClear}>Clear</Button>
                <Button variant="link" onClick={handleSave}>Save</Button>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <Button variant="secondary">Role</Button>
    </OverlayTrigger>
  );
};

RolePopover.propTypes = {
  roles: PropTypes.array,
  onSave: PropTypes.func
};

export default RolePopover;
